const ComponentEdit = ({ showEdition, setShowEdition, componenteEdit }) => {
  return (
    <div className={`editar ${showEdition ? 'mostrar' : ''}`}>
      <div
        className="editar__sombra"
        onClick={() => setShowEdition(false)}
      ></div>
      <div className="editar__contenedor">{componenteEdit}</div>
    </div>
  );
};

export default ComponentEdit;
