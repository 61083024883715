import React from 'react'
import imgVR from "../../../styles/images/IMG_20230727_151123 (2).jpg";

const Acerca = () => {
  return (
    <div className="leerMas__content-header">
          <div className="leerMas__content-header-hero">
            <h1 className="leerMas__content-header-hero-title">
              Acerca de nosotros
            </h1>
            <h2>
              Reseña historica
            </h2>
            <p>
              Esfuerzo, trabajo e innovación fueron las bases en las cuales se
              fundó Venezuela Responsable, con mucha fe surgió la idea que dio
              origen a esta organización y su puesta en marcha estuvo acompañada
              de los valores y creencias de sus fundadores quienes pusieron lo
              mejor de sí para el desarrollo de este nuevo proyecto
            </p>
            <p>
              La empresa fue registrada el 18 de agosto del año 2009 y comenzó
              sus operaciones el 2 de septiembre de ese mismo año, iniciando en
              cuatro representativas ciudades del estado Zulia como lo son
              Maracaibo, San Francisco, Cabimas y Los Puertos de Altagracia,
              siendo esta última la ciudad donde se vendió el primero de miles
              de contratos que estarán por venir. Desde entonces, la empresa ha
              mantenido un crecimiento sostenido en el mercado venezolano,
              llevando la bandera de la innovación y la calidad de servicio
            </p>
            <p>
              Actualmente Venezuela Responsable cuenta con más de 50 oficinas de
              ventas en todo el territorio nacional, una sede principal ubicada
              en Maracaibo, estado Zulia, y proyectos de expansión a lo largo
              del país.
            </p>
          </div>
      
          <img src={imgVR} alt="logo de VR"/>
        </div>
  )
}

export default Acerca
