import { IoClose } from 'react-icons/io5';
import { useState } from 'react';

const ModalConfirmacion = ({
  info,
  modalShowInfo,
  setModalShowInfo,
  aprobar,
  cargandoAprobar,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const partes = info?.cd_co?.split('-');
  const resultado =
    partes && partes.length >= 5 ? partes[0] + '-' + partes[4] : '';
  return (
    <div
      className={
        modalShowInfo === true
          ? 'modalConfirmacion active'
          : 'modalConfirmacion'
      }
    >
      <div className="modalConfirmacion__card">
        <div
          className={
            !showConfirm
              ? 'modalConfirmacion__card-confirm'
              : 'modalConfirmacion__card-confirm active'
          }
        >
          <h5>¿Estás seguro/a de que quieres aprobar este contrato?</h5>
          <h5 className="modalConfirmacion__card-confirm-resultado">
            {resultado}
          </h5>
        </div>
        <ul
          className={
            !showConfirm
              ? 'modalConfirmacion__card-list'
              : 'modalConfirmacion__card-list active'
          }
        >
          <li className="modalConfirmacion__card-list-top">
            <h5>Aprobar contrato</h5>
            <button
              onClick={() => {
                setModalShowInfo(false);
                setShowConfirm(false);
              }}
              className="modalConfirmacion__boton"
            >
              <IoClose />
            </button>
          </li>

          <li className="modalConfirmacion__card-list-header">
            <span>DATOS DEL CLIENTE</span>
          </li>

          <li className="modalConfirmacion__card-list-item">
            <span>Nombre:</span> {info?.NOMBRE.toUpperCase()}
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>Tel&eacute;fono:</span> {info?.TFCL01}
          </li>
          <li className="modalConfirmacion__card-list-header">
            <span>DATOS DEL VEH&Iacute;CULO</span>
          </li>

          <li className="modalConfirmacion__card-list-item">
            <span>Placa:</span> {info?.pl_vh.toUpperCase()}
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>Marca :</span> {info?.ds_mc}
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>Modelo:</span> {info?.ds_md}
          </li>
          <li className="modalConfirmacion__card-list-header">
            <span>DATOS DE PAGO</span>
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>Plan:</span> {info?.ds_pq}
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>T.Pago:</span> {info?.ds_tp_pg}
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>Referencia:</span> {info?.dt_tp_pg}
          </li>
          <li className="modalConfirmacion__card-list-item">
            <span>Monto:</span> {info?.mt_pg.toFixed(2)}
          </li>
        </ul>
        <div
          className={
            showConfirm
              ? 'modalConfirmacion__card-confirma'
              : 'modalConfirmacion__card-confirma active'
          }
        >
          <div className="modalConfirmacion__card-confirma-buttons">
            <button
              onClick={() => {
                setModalShowInfo(false);
                setShowConfirm(false);
              }}
              className="modalConfirmacion__card-confirma-buttons-cancelar"
            >
              CANCELAR
            </button>
            <button
              className="modalConfirmacion__card-confirma-buttons-aceptar"
              onClick={() => {
                aprobar(info.id_co, info.id_fc);
                setTimeout(() => {
                  setShowConfirm(false);
                }, 2000);
              }}
            >
              {cargandoAprobar ? (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              ) : (
                'ACEPTAR'
              )}
            </button>
          </div>
        </div>
        <div className="modalConfirmacion__card-over">
          <button
            onClick={() => {
              setModalShowInfo(false);
              setShowConfirm(false);
            }}
            className={
              !showConfirm
                ? 'buttonizer modalConfirmacion__card-over-cerrar'
                : 'modalConfirmacion__card-over-cerrar active'
            }
          >
            CERRAR
          </button>
          <button
            onClick={() => setShowConfirm(true)}
            className={
              !showConfirm
                ? 'buttonizer modalConfirmacion__card-over-aprobarbutton'
                : 'modalConfirmacion__card-over-aprobarbutton active'
            }
          >
            APROBAR
          </button>
        </div>
      </div>
      )
    </div>
  );
};

export default ModalConfirmacion;
