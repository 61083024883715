import React, { useState, useEffect } from 'react';
import {
  FaCarSide,
  FaTaxi,
  FaBus,
  FaShuttleVan,
  FaTruck,
  FaTrailer,
  FaTruckMonster,
  FaCaravan,
} from 'react-icons/fa';
import { RiMotorbikeFill } from 'react-icons/ri';
import { RiEBike2Fill } from 'react-icons/ri';
import { TbCarCrane } from 'react-icons/tb';
import { FaMotorcycle } from 'react-icons/fa';
import { FaTruckPickup } from 'react-icons/fa';
import ClaseEsqueleto from '../../Esqueletos/ClaseEsqueleto';

const Clase = ({ clases, handleSubmit, loading }) => {
  return (
    <div className="clases">
      {loading ? (
        <ClaseEsqueleto />
      ) : (
        clases?.map((x, indice) => (
          <div
            className="clases__item"
            key={indice}
            onClick={e => handleSubmit(e, x)}
          >
            {x.ds_cl_vh === 'AUTOMOVIL' ? (
              <FaCarSide />
            ) : x.ds_cl_vh === 'CAMIONETA' ? (
              <FaTruckPickup />
            ) : x.ds_cl_vh === 'GRUA' ? (
              <TbCarCrane />
            ) : x.ds_cl_vh === 'RUSTICO' ? (
              <FaTruckMonster />
            ) : x.ds_cl_vh === 'REMOLQUE' ? (
              <FaCaravan />
            ) : x.ds_cl_vh === 'SEMI REMOLQUE' ? (
              <FaTrailer />
            ) : x.ds_cl_vh === 'CAMION' ? (
              <FaTruck />
            ) : x.ds_cl_vh === 'AUTOMOVIL/TAXI - POR PUESTO' ? (
              <FaTaxi />
            ) : x.ds_cl_vh === 'AUTOBUS' ? (
              <FaBus />
            ) : x.ds_cl_vh === 'MOTO' ? (
              <RiMotorbikeFill />
            ) : x.ds_cl_vh === 'MOTO TAXI' ? (
              <FaMotorcycle />
            ) : x.ds_cl_vh === 'VANS' ? (
              <FaShuttleVan />
            ) : (
              <RiEBike2Fill />
            )}
            <span>{x.ds_cl_vh}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default Clase;
