import React from 'react';
import {
  Page,
  Document,
  StyleSheet,
  Font,
  View,
  Text,
} from '@react-pdf/renderer';
import ContratoVehi from './Contrato/ContratoVehi';
import ContratoPlanes from './Contrato/ContratoPlanes';
import ContratoInfo from './Contrato/ContratoInfo';
import ContratoAsis from './Contrato/ContratoAsis';
import ContratoBene from './Contrato/ContratoBene';
import Arial from '../../styles/Fonts/arial.ttf';
import ArialBold from '../../styles/Fonts/arial-bold.ttf';
import ContratoLogo from './Contrato/ContratoLogo';
import ContratoCod from './Contrato/ContratoCod';
import ContratoFecha from './Contrato/ContratoFecha';
import ContratoVendedor from './Contrato/InfoPequeña/ContratoVendedor';
import ContratoVigencia from './Contrato/InfoPequeña/ContratoVigencia';
import ContratoImpresoFecha from './Contrato/InfoPequeña/ContratoImpresoFecha';
import ContratoDocumento from './Contrato/InfoPequeña/ContratoDocumento';
import ContratoQRS from './Contrato/ContratoFooter/ContratoQRS';
import ContratoFooterInfo from './Contrato/ContratoFooterInfo';
import ContratoFooterInfoCod from './Contrato/ContratoFooterInfoCod';
import ContratoFooterInfoRedes from './Contrato/ContratoFooterInfoRedes';
import ContratoPag from './Contrato/ContratoPag';
import ContratoLinea from './Contrato/ContratoLinea';

Font.register({ family: 'Arial', src: Arial });

Font.register({
  family: 'Arial',
  fonts: [
    { src: Arial, fontStyle: 'normal', fontWeight: 'normal' },
    { src: ArialBold, fontStyle: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    padding: '5px',
    position: 'relative',
  },
  container: {
    border: '1.5px solid black',
    borderRadius: '8px',
    alignItems: 'center',
    padding: '2px',
    width: '550px',
    maxWidth: '550px',
    display: 'flex',
    marginTop: '5px',
  },
  containerCarac: {
    marginTop: '5px',
  },
  containerSon: {
    display: 'flex',
    gap: '90px',
    flexDirection: 'row',
    width: '540px',
    alignItems: 'center',
  },
  footerContenedor: {
    position: 'absolute',
    bottom: '75px',
    left: 'auto',
  },
  containerSon2: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '20px',
    maxWidth: '160px',
    gap: '4px',
  },
  font: {
    fontSize: 8,
  },
  footerPiePag: {
    position: 'absolute',
    bottom: '-20px',
    left: '-40px',
  },
  fontBold: {
    fontFamily: 'Arial',
    fontStyle: 'bold',
    fontSize: '8px',
  },
});

const PDFDocument = ({
  imagenqr,
  imgContratoQR,
  contratoData,
  fecha,
  hora,
  planA,
  planB,
  planC,
  planD,
  sumaA,
  sumaB,
  sumaC,
  sumaD,
  datosA,
  datosB,
  datosC,
  datosD,
  sumaTotal,
}) => {
  let servicioGrua = contratoData?.sv_gr;
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        {/** <ContratoFecha contratoData={contratoData} />*/}
        <ContratoVigencia contratoData={contratoData} />
        {/** <ContratoVendedor contratoData={contratoData} />*/}
        <ContratoImpresoFecha fecha={fecha} hora={hora} />
        <ContratoDocumento contratoData={contratoData} />
        <ContratoCod contratoData={contratoData} />
        <ContratoLogo />
        <ContratoInfo contratoData={contratoData} />

        <View style={styles.containerCarac}>
          <Text style={styles.fontBold}>CARACTERISTICAS DEL VEHICULO</Text>
        </View>

        <ContratoVehi contratoData={contratoData} styles={styles} />
        <ContratoPlanes
          contratoData={contratoData}
          planA={planA}
          planB={planB}
          planC={planC}
          planD={planD}
          sumaA={sumaA}
          sumaB={sumaB}
          sumaC={sumaC}
          sumaD={sumaD}
          datosA={datosA}
          datosB={datosB}
          datosC={datosC}
          datosD={datosD}
          servicioGrua={servicioGrua}
        />
        {/*servicioGrua === 1 ? (
          <ContratoAsis contratoData={contratoData} />
        ) : (
          <ContratoLinea />
        )*/}
        <div style={styles.footerContenedor}>
          <ContratoBene
            servicioGrua={servicioGrua}
            sumaTotal={sumaTotal}
            contratoData={contratoData}
          />
          <ContratoQRS
            contratoData={contratoData}
            imagenqr={imagenqr}
            imgContratoQR={imgContratoQR}
          />
          <ContratoFooterInfo />
          <ContratoFooterInfoCod contratoData={contratoData} />
          <ContratoFooterInfoRedes />
          <div style={styles.footerPiePag}>
            {' '}
            <ContratoPag />
          </div>
        </div>
      </Page>
    </Document>
  );
};
export default PDFDocument;
