import axios from '../api/axios';
const approveContracts = async (
  idco,
  idfc,
  setModalShow,
  setErrorMessage,
  setTokenCambiar,
  setTokenVencido
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const json = JSON.stringify({
    contrato: {
      id_co: idco,
      id_fc: idfc,
      in_ac: '1',
      ob_ac: '',
    },
    trace: '0',
    token: token,
    ori: 'web',
  });

  try {
    const data = await axios.post(
      process.env.REACT_APP_CONTRATOS_APPROVED,
      json
    );
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
  } catch (error) {
    if (error.response && error.response.status === 403) {
      setTokenCambiar(true);
      setTokenVencido(error.response.data.token);
      console.log(error.response.data.token);
    } else {
      console.log(error);
      setErrorMessage(error.response.data.message);
      setModalShow(true);
    }
  }
};

export default approveContracts;
