import { useEffect, useState, useContext } from 'react';
import ClienteResume from './ResumeItems/ClienteResume';
import PropietarioResume from './ResumeItems/PropietarioResume';
import VehiculoResume from './ResumeItems/VehiculoResume';
import CarritoResume from './ResumeItems/CarritoResume';
import ResumeTotal from './ResumeTotal/ResumeTotal';
import { Link, useNavigate } from 'react-router-dom';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import { postResumen } from '../../../helpers/postResumen';
import { IoClose } from 'react-icons/io5';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';
import { TokenContext } from '../../../context/TokenContext';

const Resumen = () => {
  const context = useProcesoProvider();
  const [resumen, setResumen] = useState();
  const [loading, setLoading] = useState(false);

  const { setTokenCambiar, setTokenVencido, setToken } =
    useContext(TokenContext);
  const {
    errors,
    setErrors,
    setErrorAdvice,
    handlePrev,
    setTokenController,
    setTokenExp,
    token,
    setMostrarErrores,
    setNavbarErrores,
    errorAdvice,
    setShowEdition,
    setComponenteEdit,
    cambiarPaso,
  } = context;

  useEffect(() => {
    setLoading(true);
    postResumen(setTokenCambiar, setTokenVencido, setToken, setResumen).finally(
      setLoading(false)
    );
  }, []);

  const navigate = useNavigate();
  const datosCliente = JSON.parse(localStorage.getItem('RCV_DIGITAL_CLIENTE'));
  const datosVehiculo = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_VEHICULO')
  );
  const datosPropietario = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_PROPIETARIO')
  );

  useEffect(() => {
    cambiarPaso(7, 5);
  }, []);

  return (
    <div className="resumen">
      <div className="resumen__header">
        <h2>
          Tu<span> resumen.</span>
        </h2>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>}
        >
          <Link to={'/'}>
            <IoClose />
          </Link>
        </OverlayTrigger>
      </div>
      <ClienteResume
        datosCliente={datosCliente && datosCliente}
        setShowEdition={setShowEdition}
        setComponenteEdit={setComponenteEdit}
        setMostrarErrores={setMostrarErrores}
        setNavbarErrores={setNavbarErrores}
        setTokenController={setTokenController}
        setTokenExp={setTokenExp}
        token={token}
        errors={errors}
        setErrors={setErrors}
        setErrorAdvice={setErrorAdvice}
        errorAdvice={errorAdvice}
        setToken={setToken}
      />
      {datosCliente?.soy_pr === null ||
      datosCliente?.soy_pr === undefined ||
      datosCliente?.soy_pr === true ? null : (
        <PropietarioResume
          datosPropietario={datosPropietario}
          setShowEdition={setShowEdition}
          setComponenteEdit={setComponenteEdit}
          setMostrarErrores={setMostrarErrores}
          setNavbarErrores={setNavbarErrores}
          setTokenController={setTokenController}
          setTokenExp={setTokenExp}
          token={token}
          errors={errors}
          setErrors={setErrors}
          setErrorAdvice={setErrorAdvice}
          errorAdvice={errorAdvice}
          setToken={setToken}
        />
      )}

      <VehiculoResume
        datosVehiculo={datosVehiculo}
        setShowEdition={setShowEdition}
        setComponenteEdit={setComponenteEdit}
        setMostrarErrores={setMostrarErrores}
        setNavbarErrores={setNavbarErrores}
        setTokenController={setTokenController}
        setTokenExp={setTokenExp}
        token={token}
        errors={errors}
        setErrors={setErrors}
        setErrorAdvice={setErrorAdvice}
        errorAdvice={errorAdvice}
        setToken={setToken}
      />

      <CarritoResume carrito={resumen?.car} />

      <ResumeTotal resumen={resumen?.bill} />
      {loading || !resumen ? (
        <div className="lds-ellipsisg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="resumen__botones">
          <div></div>
          <div className="proceso__botones-contenedor">
            <button
              className="anterior"
              onClick={() => handlePrev('/proceso/descuento')}
            >
              ANTERIOR
            </button>
            <button
              className="siguiente"
              onClick={() => {
                navigate('/proceso/pago');
              }}
            >
              SIGUIENTE
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Resumen;
