import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';

const postDescuento = async (
  formCreador,
  setToken,
  setMostrarCreador,
  setNavbarErrores,
  setTokenVencido,
  setTokenCambiar
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const json = JSON.stringify({
    cupon: formCreador,
    trace: '0',
    token: token,
    ori: 'web',
  });

  const handleError = error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        console.log(error);
      }
    } else if (error.response && error.response.data.statusCode === 426) {
      localStorage.setItem(
        'CLIENT_ID',
        JSON.stringify(error.response.data.data.id_cl)
      );
      console.log(error.response.data.message);
    } else {
      if (error.response && error.response.data) {
        setNavbarErrores([error.response.data.message]);
      } else {
        setNavbarErrores(['Un error inesperado ha ocurrido']);
      }
      console.log(error);
      throw error;
    }
  };
  try {
    const data = await axios.post(process.env.REACT_APP_AGREGAR_CUPON, json);
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    setToken(data.data.token);
    setMostrarCreador(false);
    return true;
  } catch (error) {
    console.log(error);
    await handleError(error);
    return false;
  }
};

export default postDescuento;
