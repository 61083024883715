import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '4px',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableColHeader: {
    fontFamily: 'Arial',
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 5,
    padding: 4,
    paddingTop: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: ' center',
  },
  tableCol: {
    width: '100%',
    borderStyle: 'solid',

    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 5,
    padding: 5,
  },
});

const data = [
  {
    fechaContrato: '29',
    control: '27',
    notaCredito: '0',
    contratante: '0',
    rif: '1',
    exento: '1',
    base: '85',
    iva: '6',
    lgft: '7.669,97',
    ingreso: '5.661,45',
    tasa: '',
    ingresoRef: '14.237,25',
    cobertura: '392,56',
  },
];

const Tablaitem = ({ reporte }) => {
  let tasa = reporte?.VentasDiarias[0].tasa;
  const sumarMtBaTt = campo => {
    const ventasDiarias = reporte?.VentasDiarias;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta[campo];
    });

    if (sumaMtBaTt === 0) {
      return '0, 00';
    } else {
      return sumaMtBaTt.toLocaleString('es-VE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const sumarRef = () => {
    const ventasDiarias = reporte?.VentasDiarias;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta.mt_ge_tt;
    });

    return sumaMtBaTt / Number(tasa);
  };

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={styles.tableColHeader}>EXENTO</Text>
        <Text style={styles.tableColHeader}>BASE IMP</Text>
        <Text style={styles.tableColHeader}>I.V.A</Text>
        <Text style={styles.tableColHeader}>I.G.T.F</Text>
        <Text style={styles.tableColHeader}>INGRESO</Text>
        <Text style={styles.tableColHeader}>INGRESO REF</Text>
      </View>
      {data.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={styles.tableCol}>{sumarMtBaTt('mt_ex_tt')}</Text>
          <Text style={styles.tableCol}>{sumarMtBaTt('mt_ba_tt')}</Text>
          <Text style={styles.tableCol}>{sumarMtBaTt('mt_iv_tt')}</Text>
          <Text style={styles.tableCol}>{sumarMtBaTt('mt_igtf')}</Text>
          <Text style={styles.tableCol}>{sumarMtBaTt('mt_ge_tt')}</Text>
          <Text style={styles.tableCol}>{sumarRef().toFixed(2)}</Text>
        </View>
      ))}
    </View>
  );
};

export default Tablaitem;
