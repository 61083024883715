import React, { useState, useEffect, useContext } from 'react';
import IniciarSesion from './IniciarSesion/IniciarSesion';
import Registro from './Registro/Registro';
import Opcion from './Opcion';
import { Link, useLocation, useParams } from 'react-router-dom';
import { LoginContext } from '../../context/LoginContext';
import Volver from '../Volver/Volver';

const IniciarYRegistro = ({
  setVerified,
  verified,
  handlePrev,
  setMostrarErrores,
  setNavbarErrores,
  show,
  setShow,
  fullscreen,
  setFullscreen,
  setMostrarModalCorrecto,
  setMensajeCorrecto,
  setPaso,
  paso,
  setLogin,
  setUserProfile,
  locationPath,
}) => {
  const location = useLocation();
  const { setProcesando } = useContext(LoginContext);
  const { id } = useParams();
  const [opcion, setOpcion] = useState();
  const [mostrarOp, setMostrarOp] = useState(false);
  const [cambiarOpcion, setCambiarOpcion] = useState(false);
  useEffect(() => {
    if (id != undefined || id != null) {
      Number(id) === 2 ? setOpcion(2) : setOpcion(1);
    }
  }, [id]);

  return (
    <div className="inicioRegistro">
      <Link to="/" className="inicioRegistro__volver">
        Volver al inicio
      </Link>
      {location && location?.state?.location?.pathname === '/proceso/pago' ? (
        <h2 className="IRAdvertencia">
          Para continuar con el proceso, debes iniciar sesi&oacute;n
        </h2>
      ) : null}
      <div className={`iniciarYRegistro ${opcion === 3 ? 'recuperando' : ''}`}>
        <Opcion opcion={opcion} setOpcion={setOpcion} mostrarOp={mostrarOp} />

        <Registro
          setMostrarErrores={setMostrarErrores}
          setNavbarErrores={setNavbarErrores}
          show={show}
          setShow={setShow}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          setMostrarModalCorrecto={setMostrarModalCorrecto}
          setMensajeCorrecto={setMensajeCorrecto}
          setOpcion={setOpcion}
          opcion={opcion}
          cambiarOpcion={cambiarOpcion}
          setCambiarOpcion={setCambiarOpcion}
          setProcesando={setProcesando}
        />
        <IniciarSesion
          setOpcion={setOpcion}
          setVerified={setVerified}
          verified={verified}
          handlePrev={handlePrev}
          setMostrarErrores={setMostrarErrores}
          setNavbarErrores={setNavbarErrores}
          opcion={opcion}
          setPaso={setPaso}
          paso={paso}
          setLogin={setLogin}
          setUserProfile={setUserProfile}
          cambiarOpcion={cambiarOpcion}
          setCambiarOpcion={setCambiarOpcion}
          locationPath={locationPath}
        />
      </div>
    </div>
  );
};

export default IniciarYRegistro;
