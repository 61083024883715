import React from 'react';
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import Arial from '../../styles/Fonts/arial.ttf';
import ArialBold from '../../styles/Fonts/arial-bold.ttf';
import FacturaLogo from './Factura/FacturaLogo';
import FacturaSedePrin from './Factura/InfoPeq/FacturaSedePrin';
import FacturaIns from './Factura/InfoPeq/FacturaIns';
import FacturaInfo from './Factura/FacturaInfo';
import FacturaPlan from './Factura/FacturaPlan';
import FacturaCopia from './Factura/FacturaCopia';
import FacturaTickets from './Factura/FacturaTickets';
import FacturaContrato from './Factura/InfoPeq/FacturaContrato';
import FacturaVendedor from './Factura/InfoPeq/FacturaVendedor';
import FacturaImpreso from './Factura/InfoPeq/FacturaImpreso';
import FacturaFinCon from './Factura/InfoPeq/FacturaFinCon';

Font.register({ family: 'Arial', src: Arial });

Font.register({
  family: 'Arial',
  fonts: [
    { src: Arial, fontStyle: 'normal', fontWeight: 'normal' },
    { src: ArialBold, fontStyle: 'bold' },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    padding: '5px',
    position: 'relative',
  },
});
const PDFDocumentFactura = ({ factura, imagenqr }) => {
  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <FacturaSedePrin />
        <FacturaLogo factura={factura} />
        <FacturaContrato factura={factura} />
        <FacturaVendedor factura={factura} />
        <FacturaImpreso factura={factura} />
        <FacturaFinCon factura={factura} />
        <FacturaIns />
        <FacturaInfo factura={factura} />
        <FacturaPlan factura={factura} />
        <FacturaCopia />
        <FacturaTickets factura={factura} imagenqr={imagenqr} />
      </Page>
    </Document>
  );
};

export default PDFDocumentFactura;
