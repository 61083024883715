import { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LoginContext } from '../../context/LoginContext';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';

const GuardaReferido = () => {
  const location = useLocation();
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);
  const [referido, setReferido] = useState();
  useEffect(() => {
    if (referido) {
      localStorage.setItem('RCV_DIGITAL_REFERIDO', JSON.stringify(referido[0]));
    }
  }, [referido]);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const a = params.get('a');
    const c = params.get('c');

    if (a) {
      let urlReferido = `v1/getAgent?p1=${a}&p2=&p3=&p4=&op=1&token=`;

      consultarApi(
        urlReferido,
        0,
        setReferido,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
    }

    if (c) {
      const data = {
        codigoPresente: true,
        codigoDescuento: c,
      };

      localStorage.setItem('RCV_DIGITAL_CODIGO_CUPON', JSON.stringify(data));
    }
  }, [location.search]);

  return null;
};

export default GuardaReferido;
