import { Text, View, StyleSheet } from '@react-pdf/renderer';
import ContratoAsis from './ContratoAsis';
import ContratoLinea from './ContratoLinea';

const styles = StyleSheet.create({
  container: {
    fontFamily: 'Arial',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '540px',
  },

  containerFather: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    gap: '5px',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '5px',
    borderTop: '1px solid black',
  },

  containerSonLast: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '1px',
    borderTop: '1px solid black',
  },

  containerFooter: {
    fontFamily: 'Arial',
    width: '540px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  /** */
  font: {
    fontSize: 9,
    marginLeft: '30px',
    maxWidth: '88%',
    textAlign: 'justify',
  },
  fontBoldEs: {
    fontSize: 9,
    marginLeft: '30px',
    maxWidth: '88%',
    textAlign: 'justify',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  fontPrice: {
    fontSize: 9,
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  fontDif: {
    fontSize: 9,
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },

  fontBold3: {
    fontSize: 10,
    fontStyle: 'bold',
  },

  fontBold2: {
    fontSize: 9,
    fontStyle: 'bold',
  },
  fontBold: {
    fontSize: '10px',
    textDecoration: 'underline',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  montosReflejados: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontStyle: 'bold',
  },
  fontFirma: {
    fontSize: 8,
    fontStyle: 'bold',
  },

  firmaContainer: {
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
    marginTop: '25px',
  },
  containerBene: {
    fontFamily: 'Arial',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '350px',
  },
  fontBoldBeneficio: {
    fontSize: 9,
    fontStyle: 'bold',
  },
});

const ContratoPlanes = ({
  contratoData,
  planA,
  planB,
  planC,
  planD,
  sumaA,
  sumaB,
  sumaC,
  sumaD,
  datosA,
  datosB,
  datosC,
  datosD,
  servicioGrua,
}) => {
  function formatearMonto(monto) {
    const partes = monto?.toFixed(2).split('.');
    const entero = partes && partes[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${entero},${partes && partes[1]}`;
  }

  const sumaAFor = formatearMonto(sumaA);
  const sumaBFor = formatearMonto(sumaB);
  const sumaCFor = formatearMonto(sumaC);
  const sumaDFor = formatearMonto(sumaD);
  /*cd_tp_bn === '0000' && cd_pq_dt === isNumber*/
  return (
    <div style={styles.containerFather}>
      <div style={styles.container}>
        <View>
          <Text style={styles.fontDif}>
            {contratoData?.cd_pq} - {contratoData?.ds_pq}
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            MONTOS REFLEJADOS Y PAGADOS TASA B.C.V
          </Text>
        </View>
      </div>

      {/*servicioGrua === 1 ? (
        <ContratoAsis contratoData={contratoData} />
      ) : (
        <ContratoLinea />
      )*/}

      {/*servicioGrua === 1 ? (
        <View style={styles.firmaContainer}>
          <Text style={styles.fontFirma}>ACEPTO</Text>
        </View>
      ) : (
        <View>
          <Text></Text>
        </View>
      )*/}

      {/* DIV CONTENEDOR DE PLANES*/}
      {/** <View style={styles.montosReflejados}>
        <Text style={styles.fontBold}></Text>
        <Text style={styles.fontBold}>
          MONTOS REFLEJADOS Y PAGADOS TASA B.C.V
        </Text>
      </View>*/}
      <div>
        {planD.length > 0 ? (
          <div style={styles.containerSon}>
            <div style={styles.container}>
              <View>
                <Text style={styles.fontBold2}>
                  {datosD?.cod} - {datosD?.nombre}
                </Text>
              </View>
              <View>
                <Text style={styles.fontBold}></Text>
              </View>
            </div>
            {planD?.map((x, indice) => {
              const isNumeric = /^\d+$/.test(x.cd_pq_dt);

              return (
                <div style={styles.container} key={indice}>
                  <View>
                    <Text
                      style={
                        x.cd_tp_bn === '0000' && isNumeric
                          ? styles.fontBoldEs
                          : styles.font
                      }
                    >
                      {x?.ds_pq_dt}
                    </Text>
                  </View>
                  <View>
                    <Text style={styles.fontPrice}>
                      {x?.mt_pq_dt === 0 ? null : formatearMonto(x?.mt_pq_dt)}
                    </Text>
                  </View>
                </div>
              );
            })}
            {/*sumaDFor === '0,00' ? null : (
              <div style={styles.containerFooter}>
                <View>
                  <Text style={styles.fontBold3}>
                    SUB TOTAL BENEFICIOS: {sumaDFor}
                  </Text>
                </View>
              </div>
            )*/}
          </div>
        ) : null}
        {planA.length === 0 &&
        planB.length === 0 &&
        planC.length === 0 ? null : (
          <div style={styles.containerBene}>
            <View>
              <Text style={styles.fontBoldBeneficio}>
                BENEFICIOS ADICIONALES
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}></Text>
            </View>
          </div>
        )}

        {planA.length > 0 ? (
          <div style={styles.containerSon}>
            <div style={styles.container}>
              <View>
                <Text style={styles.fontBold2}>
                  {datosA?.cod} - {datosA?.nombre}
                </Text>
              </View>
              <View>
                <Text style={styles.fontBold}></Text>
              </View>
            </div>
            {planA?.map((x, indice) => {
              return (
                <div style={styles.container} key={indice}>
                  <View>
                    <Text style={styles.font}>{x?.ds_pq_dt}</Text>
                  </View>
                  <View>
                    <Text style={styles.fontPrice}>
                      {x?.mt_pq_dt === 0 ? null : formatearMonto(x?.mt_pq_dt)}
                    </Text>
                  </View>
                </div>
              );
            })}
            <div style={styles.containerFooter}>
              <View>
                <Text style={styles.fontBold3}>
                  SUB TOTAL BENEFICIOS: {sumaAFor}
                </Text>
              </View>
            </div>
          </div>
        ) : null}

        {planB.length > 0 ? (
          <div style={styles.containerSon}>
            <div style={styles.container}>
              <View>
                <Text style={styles.fontBold2}>
                  {datosB?.cod} - {datosB?.nombre}
                </Text>
              </View>
              <View>
                <Text style={styles.fontBold}></Text>
              </View>
            </div>

            {planB?.map((x, indice) => {
              return (
                <div style={styles.container} key={indice}>
                  <View>
                    <Text style={styles.font}>{x?.ds_pq_dt}</Text>
                  </View>
                  <View>
                    <Text style={styles.fontPrice}>
                      {formatearMonto(x?.mt_pq_dt)}
                    </Text>
                  </View>
                </div>
              );
            })}
            <div style={styles.containerFooter}>
              <View>
                <Text style={styles.fontBold3}>
                  SUB TOTAL BENEFICIOS: {sumaBFor}
                </Text>
              </View>
            </div>
          </div>
        ) : null}
        {planC.length > 0 ? (
          <div style={styles.containerSonLast}>
            <div style={styles.container}>
              <View>
                <Text style={styles.fontBold2}>
                  {datosC?.cod} - {datosC?.nombre}
                </Text>
              </View>
              <View>
                <Text style={styles.fontBold}></Text>
              </View>
            </div>
            {planC?.map((x, indice) => {
              return (
                <div style={styles.container} key={indice}>
                  <View>
                    <Text style={styles.font}>{x?.ds_pq_dt}</Text>
                  </View>
                  <View>
                    <Text style={styles.fontPrice}>
                      {x?.mt_pq_dt === 0 ? null : formatearMonto(x?.mt_pq_dt)}
                    </Text>
                  </View>
                </div>
              );
            })}
            <div style={styles.containerFooter}>
              <View>
                <Text style={styles.fontBold3}>
                  SUB TOTAL BENEFICIOS: {sumaCFor}
                </Text>
              </View>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ContratoPlanes;
