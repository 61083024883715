import React from 'react';
import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';
import TableComision from './TableComision';
import HeaderReportesComisiones from './HeaderReportesComisiones';
import ResumenVentaComision from './ResumenVentaComision';
import PieDePagina from '../PieDePagina';
const PDFDocumentComisiones = ({
  reporte,
  userProfile,
  fecha,
  hora,
  fechaini,
  fechafin,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    resumen: {
      fontSize: 9,
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontStyle: 'bold',
      width: '100%',
      textAlign: 'center',
      marginTop: '20px',
    },
  });
  return (
    <Document>
      <Page size="LETTER" style={styles?.page} orientation="landscape">
        <HeaderReportesComisiones
          reporte={reporte}
          userProfile={userProfile}
          fecha={fecha}
          hora={hora}
        />
        <Text style={styles?.resumen}>
          REPORTE DE COMISI&Oacute;N AL {reporte?.VentasCommission[0]?.pc_cm_ag}
          % ({fechaini?.split('-').reverse().join('/')} -{' '}
          {fechafin?.split('-').reverse().join('/')})
        </Text>
        <TableComision reporte={reporte} />
        <ResumenVentaComision reporte={reporte} />
        <PieDePagina nombre={'PDFDocumentComisiones.js'} />
      </Page>
    </Document>
  );
};

export default PDFDocumentComisiones;
