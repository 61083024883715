import React from 'react';

const DescuentoCartas = ({ descuentos, setMostrarEditor, setEdicionProp }) => {
  return (
    descuentos &&
    descuentos.map((x, i) => {
      return (
        <div className="descuentoCartas" key={i}>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">Código Cupón: </span>
            <span className="valor">{x.cd_prom}</span>
          </div>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">Descripción: </span>
            <span className="valor">{x.ds_prom}</span>
          </div>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">Monto: </span>
            <span className="valor">{x.mt_prom.toFixed(2)}</span>
          </div>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">Estado: </span>
            <span className="valor">
              {' '}
              {x.es_rg === 'A'
                ? 'ACTIVO'
                : x.es_rg === 'X'
                ? 'PROTEGIDO'
                : 'INACTIVO'}
            </span>
          </div>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">OTP: </span>
            <span className="valor">{x.is_otp ? 'SI' : 'NO'}</span>
          </div>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">URL: </span>
            <span className="valor">{x.rutaVenta}</span>
          </div>
          <div className="descuentoCartas__contenedor">
            <span className="nombre">Tipo: </span>
            <span className="valor">{x.tp_mt_prom}</span>
          </div>
          <div className="descuentoCartas__contenedor">
            {x.es_rg === 'X' ? null : (
              <button
                className="botoneditar"
                onClick={() => {
                  setMostrarEditor(true);
                  setEdicionProp(x);
                }}
              >
                EDITAR
              </button>
            )}
          </div>
        </div>
      );
    })
  );
};

export default DescuentoCartas;
