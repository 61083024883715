import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  container: {
    width: '540px',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    gap: '120px',
    marginTop: '2px',
  },

  containerSon: {
    width: '300px',
  },
  font: {
    fontSize: '7px',
  },
  fontBold: {
    fontSize: '6.5px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});
const ContratoFooterInfoCod = ({ contratoData }) => (
  <div style={styles.container}>
    <div>
      <View>
        <Text style={styles.font}>Código Web: {contratoData?.cd_web}</Text>
      </View>
    </div>

    <div>
      <View style={styles.containerSon}>
        <Text style={styles.fontBold}>
          ESTE CONTRATO SERA VALIDO LUEGO DE SU PAGO TOTAL Y EL MISMO DEBERA
          ESTAR FIRMADO Y SELLADO POR LA PERSONA AUTORIZADA.
        </Text>
      </View>
    </div>
  </div>
);

export default ContratoFooterInfoCod;
