const validationsFromVehiculo = form => {
  let errors = {};
  let expRegLeNu = /^[0-9a-zA-Z ]+$/;
  let expRegNu = /^[0-9]+$/;

  const {
    pl_vh,
    mc_vh,
    md_vh,
    se_ca_vh,
    se_mo_vh,
    cl_vh,
    nr_pu_vh,
    an_vh,
    pe_vh,
    tp_vh,
    uso_vh,
  } = form;

  /**.:. VALIDAR LA PLACA .:. */
  if (!pl_vh.trim()) {
    errors.pl_vh = 'Debe indicar la placa del vehículo, por favor verifique.';
  } else if (pl_vh.trim().length > 10) {
    errors.pl_vh =
      'La placa no debe exceder los 10 caracteres, por favor verifique.';
  } else if (!expRegLeNu.test(pl_vh.trim())) {
    errors.pl_vh = 'La placa no es válida, por favor verifique.';
  }

  /**.:. VALIDAR LA MARCA .:. */
  if (mc_vh.length === 0 || mc_vh === '.') {
    errors.mc_vh = 'Debe indicar la marca del vehículo, por favor verifique.';
  }

  /**.:. VALIDAR EL MODELO .:. */
  if (!md_vh.trim() || md_vh === '') {
    errors.md_vh = 'Debe indicar el modelo del vehículo, por favor verifique.';
  } else if (md_vh.trim().length > 100) {
    errors.md_vh =
      'El modelo no debe exceder los 100 caracteres, por favor verifique.';
  }

  /**.:. VALIDAR EL SERIAL DE LA CARROSERIA .:. */
  if (!se_ca_vh.trim()) {
    errors.se_ca_vh =
      'Debe indicar el serial de carrosería del vehículo, por favor verifique.';
  } else if (se_ca_vh.trim().length > 30) {
    errors.se_ca_vh =
      'El serial de carrosería no debe exceder los 30 caracteres, por favor verifique.';
  } else if (!expRegLeNu.test(se_ca_vh.trim())) {
    errors.se_ca_vh =
      'El serial de carrosería no es válido, por favor verifique.';
  }

  /**.:. VALIDAR EL SERIAL DEL MOTOR .:. */
  if (!se_mo_vh.trim()) {
    errors.se_mo_vh =
      'Debe indicar el serial de motor del vehículo, por favor verifique.';
  } else if (se_mo_vh.trim().length > 30) {
    errors.se_mo_vh =
      'El serial del motor no debe exceder los 20 caracteres, por favor verifique.';
  } else if (!expRegLeNu.test(se_mo_vh.trim())) {
    errors.se_mo_vh = 'El serial del motor no es válido, por favor verifique.';
  }

  /**.:. VALIDAR EL COLOR .:. */
  if (!cl_vh.trim()) {
    errors.cl_vh = 'Debe indicar el color del vehículo, por favor verifique.';
  } else if (cl_vh.trim().length > 50) {
    errors.cl_vh =
      'El color no debe exceder los 50 caracteres, por favor verifique.';
  }

  /**.:. VALIDAR LOS PUESTOS .:. */
  if (!nr_pu_vh.trim()) {
    errors.nr_pu_vh =
      'Debe indicar la cantidad de puestos del vehículo, por favor verifique.';
  } else if (nr_pu_vh.trim().length > 4) {
    errors.nr_pu_vh =
      'La cantidad de puestos no debe exceder los 4 caracteres, por favor verifique.';
  } else if (!expRegNu.test(nr_pu_vh.trim())) {
    errors.nr_pu_vh =
      'La cantidad de puestos no es válida, por favor verifique.';
  } else if (nr_pu_vh.trim() === '0') {
    errors.nr_pu_vh =
      'La cantidad de puestos no puede ser 0, por favor verifique.';
  }

  /**.:. VALIDAR EL AÑO .:. */
  if (!an_vh.trim()) {
    errors.an_vh = 'Debe indicar el año del vehículo, por favor verifique.';
  } else if (an_vh.trim().length > 4) {
    errors.an_vh =
      'El año no debe exceder los 4 caracteres, por favor verifique.';
  } else if (!expRegNu.test(an_vh.trim())) {
    errors.an_vh = 'El año no es válido, por favor verifique.';
  } else if (an_vh.trim() === '0') {
    errors.an_vh = 'El año no puede ser 0, por favor verifique.';
  } else if (Number(an_vh.trim()) < 1904) {
    errors.an_vh = 'Año invalido, por favor verifique.';
  }

  /**.:. VALIDAR EL PESO .:. */
  if (!pe_vh.trim()) {
    errors.pe_vh = 'Debe indicar el peso del vehículo, por favor verifique.';
  } else if (pe_vh.trim().length > 4) {
    errors.pe_vh =
      'El peso no debe exceder los 4 caracteres, por favor verifique.';
  } else if (!expRegNu.test(pe_vh.trim())) {
    errors.pe_vh = 'El peso no es válido, por favor verifique.';
  } else if (pe_vh.trim() === '0') {
    errors.pe_vh = 'El peso no puede ser 0, por favor verifique.';
  } else if (Number(pe_vh.trim()) < 100) {
    errors.pe_vh = 'El peso no puede ser menor a 100, por favor verifique.';
  }

  /**.:. VALIDAR EL TIPO .:. */
  if (tp_vh.length === 0 || tp_vh === '') {
    errors.tp_vh = 'Debe indicar el tipo de vehículo, por favor verifique.';
  }

  /**.:. VALIDAR EL USO .:. */
  if (uso_vh.length === 0 || uso_vh === '') {
    errors.uso_vh = 'Debe indicar el uso del vehículo, por favor verifique.';
  }

  return errors;
};

export default validationsFromVehiculo;
