import Modal from "react-bootstrap/Modal";
import { AiFillAlert } from "react-icons/ai";
import Button from "react-bootstrap/Button";
const ManejadorDeErrores = (props) => {
  return (
    <>
      <Modal
        className="manejadorDeErrores"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="manejadorDeErrores__header">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="manejadorDeErrores__header-title"
          >
            <AiFillAlert style={{ color: "red" }} /> Upps!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="manejadorDeErrores__body">
          <h4>Lo sentimos</h4>
          <p>{props.error}</p>
        </Modal.Body>
        <Modal.Footer className="manejadorDeErrores__footer">
          <Button style={{ borderRadius: "0px" }} onClick={props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManejadorDeErrores;
