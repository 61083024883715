import axios from '../../../../api/axios';
import actualizarTokenNoLog from '../../../../helpers/actualizarTokenNoLog';

const postMunicipioUnico = async (
  municipioJSON,
  setTokenCambiar,
  setTokenVencido,
  setMostrarAgregarMunicipio,
  setToken,
  estadoElegido,
  setMunicipioVeri,
  setAplicarMunicipio,
  setNavbarErrores,
  setMostrarErrores
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  let json = JSON.stringify({
    municipio: {
      id_es: estadoElegido.value,
      ds_mn: municipioJSON.ds_mn,
    },
    trace: '0',
    token: token,
  });

  const handleResponse = async data => {
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    if (data.status === 200) {
      localStorage.setItem(
        'RCV_DIGITAL_MUNICIPIO',
        JSON.stringify(data.data.data)
      );
      setMunicipioVeri(true);
      localStorage.removeItem('RCV_DIGITAL_MARCA_NUEVA');
      setTimeout(() => {
        setMostrarAgregarMunicipio({
          estado: false,
          valorEstado: '',
          valorMunicipio: '',
        });
      }, 1000);
      localStorage.setItem(
        'RCV_DIGITAL_MUNICIPIO_GUARDADO',
        JSON.stringify(data.data.data)
      );
      setAplicarMunicipio(true);
    }
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        console.log(error);
      }
    } else {
      setNavbarErrores([error.response.data.message]);
      setMostrarErrores(true);
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_NUEVO_MUNICIPIO, json);
    handleResponse(data);
  } catch (error) {
    handleError(error);
  }
};

export default postMunicipioUnico;
