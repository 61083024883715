import { useState, createContext, useEffect } from 'react';
import ModalCorrecto from '../components/ModalCorrecto/ModalCorrecto';
import ComponentEdit from '../components/Proceso/Pasos/ResumeItems/ComponentEdit';
import Modal from 'react-bootstrap/Modal';
import Terminos from '../components/Terminos/Terminos';
import NavbarErrors from '../components/NavbarLite/NavbarErrors';
import Pasos from '../components/Proceso/Pasos/PasoConteo/Pasos';
import { useLocation, useNavigate } from 'react-router-dom';
export const ProcesoContext = createContext(undefined);

const ProcesoProvider = ({ children }) => {
  const [paso, setPaso] = useState(0);
  const [pasoVerificado, setPasoVerificado] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [fullscreen, setFullscreen] = useState();
  const [show, setShow] = useState();
  const [errors, setErrors] = useState({});
  const [errorAdvice, setErrorAdvice] = useState(0);
  const [showEdition, setShowEdition] = useState();
  const [componenteEdit, setComponenteEdit] = useState();
  const [referencia, setReferencia] = useState('');
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);
  const [setUserProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const [login, setLogin] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'))
  );

  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'))
  );

  const handlePrev = (url, pasoParam) => {
    localStorage.setItem('RCV_DIGITAL_PASO', pasoParam);
    navigate(url);
    setPaso(pasoParam);
  };
  const cambiarPaso = (pasonuevo, pasoproceso) => {
    localStorage.setItem('RCV_DIGITAL_PASO_VERIFICADO', pasonuevo);
    localStorage.setItem('RCV_DIGITAL_PASO', pasonuevo);
    setPaso(pasoproceso);
  };

  const proceso = [1, 2, 3, 4, 5, 6, 7];
  return (
    <div className="proceso">
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <ComponentEdit
        showEdition={showEdition}
        setShowEdition={setShowEdition}
        componenteEdit={componenteEdit}
      />
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terminos y condiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Terminos />
        </Modal.Body>
      </Modal>
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />

      {location.pathname === '/proceso/clases' ||
      location.pathname === '/proceso/planes' ? null : (
        <div className={`proceso__pasos`}>
          <Pasos proceso={proceso} paso={paso} />
        </div>
      )}

      <ProcesoContext.Provider
        value={{
          setErrorAdvice,
          handlePrev,
          setPaso,
          paso,
          setReferencia,
          token,
          referencia,
          setMostrarErrores,
          setNavbarErrores,
          setShow,
          setFullscreen,
          setToken,
          setMostrarModalCorrecto,
          setMensajeCorrecto,
          login,
          setLogin,
          setUserProfile,
          errors,
          setErrors,
          errorAdvice,
          setShowEdition,
          setComponenteEdit,
          setPasoVerificado,
          pasoVerificado,
          cambiarPaso,
        }}
      >
        {children}
      </ProcesoContext.Provider>
    </div>
  );
};

export default ProcesoProvider;
