import Navbar from '../Navbar/Navbar';
import Volver from '../Volver/Volver';
import Acerca from './Acerca/Acerca';
import Mision from './Mision/Mision';
import Valores from './Valores/Valores';
import Vision from './Vision/Vision';

const LeerMas = () => {
  return (
    <div className="leerMas">
      <div className="leerMas__content">
        <Volver />
        <Acerca />
        <Mision />
        <Valores />
        <Vision />
      </div>
    </div>
  );
};

export default LeerMas;
