import { useEffect, useState, useContext } from 'react';
import validationsFormCliente from '../../../pages/Validators/validationsFormCliente';
import { format } from 'date-fns';
import { postClient } from '../../../helpers/postCliente';
import FormularioCliente from './Cliente/FormularioCliente';
import Botones from './Botones/Botones';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import { Link, useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';
import AgregarMunicipio from './Cliente/AgregarMunicipio';

const Cliente = () => {
  const context = useProcesoProvider();
  const {
    errors,
    setErrors,
    setErrorAdvice,
    handlePrev,
    setMostrarErrores,
    setNavbarErrores,
    errorAdvice,
    cambiarPaso,
  } = context;
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [isChecked, setIsChecked] = useState(true);
  const [aplicarMunicipio, setAplicarMunicipio] = useState(false);
  const navigate = useNavigate();
  const [propietarioVeri, setPropietarioVeri] = useState(isChecked);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [cargandoMunicipios, setCargandoMunicipios] = useState(false);
  const [opeCel, setOpeCel] = useState([]);

  const [errorVer, setErrorVer] = useState();
  const [cargandoEstados, setCargandoEstados] = useState(true);
  const [cargandoOperadora, setCargandoOperadora] = useState(true);
  const [clienteJson] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_CLIENTE'))
  );
  const [mostrarAgregarMunicipio, setMostrarAgregarMunicipio] = useState({
    estado: false,
    valorEstado: '',
    valorMunicipio: '',
  });

  let fcDefNac = new Date();
  fcDefNac.setMonth(fcDefNac.getMonth() - 216);
  const [formData, setFormData] = useState(
    !clienteJson
      ? {
          cd_cl: '',
          nb_cl: '',
          ap_cl: '',
          rz_cl: '',
          fc_cl: format(fcDefNac, 'yyyy-MM-dd'),
          id_es: '',
          es_sel: '',
          mn_sel: '',
          id_mn: '',
          se_cl: '',
          dr_cl: '',
          em_cl: '',
          id_op: '',
          op_sel: '',
          tf_cl_cl_01: '',
          soy_pr: isChecked,
          tp_cl: 'N',
        }
      : {
          cd_cl: clienteJson.cd_cl,
          nb_cl: clienteJson.nb_cl,
          ap_cl: clienteJson.ap_cl,
          fc_cl: clienteJson.fc_cl,
          id_es: clienteJson.id_es,
          es_sel: clienteJson.es_sel,
          mn_sel: clienteJson.mn_sel,
          id_mn: clienteJson.id_mn,
          se_cl: clienteJson.se_cl,
          dr_cl: clienteJson.dr_cl,
          em_cl: clienteJson.em_cl,
          id_op: clienteJson.id_op,
          op_sel: clienteJson.op_sel,
          tf_cl_cl_01: clienteJson.tf_cl_cl_01,
          soy_pr: clienteJson.soy_pr,
          tp_cl: clienteJson.tp_cl,
        }
  );
  const { es_sel, op_sel, mn_sel } = formData;
  const [municipioSeleccionado, setMunicipioSeleccionado] = useState(mn_sel);
  const [selectedEstado, setSelectedEstado] = useState(es_sel);
  const [selectedOperadora, setSelectedOperadora] = useState(op_sel);

  const limpiar = tp_cl => {
    setFormData({
      ...formData,
      cd_cl: '',
      nb_cl: '',
      ap_cl: '',
      rz_cl: '',
      tp_cl: tp_cl,
    });
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value.toUpperCase();

    if (name === 'cd_cl') {
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        newValue = value.toUpperCase();
      } else {
        if ((newValue === 'V' || newValue === 'J') && !newValue.includes('-')) {
          newValue += '-';
        }
        if (formData?.tp_cl === 'N') {
          newValue = newValue.split('-').slice(0, 2).join('-');
        }
      }
    }

    setErrorAdvice(0);
    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };
  useEffect(() => {
    cambiarPaso(2, 0);
  }, []);

  useEffect(() => {
    if (aplicarMunicipio) {
      const urlMunicipios = `v1/getCombo?p1=${selectedEstado[0].value}&p2=&p3=&p4=&op=3&token=`;
      consultarApi(
        urlMunicipios,
        0,
        setMunicipios,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
      setAplicarMunicipio(false);
    }
  }, [selectedEstado, aplicarMunicipio]);

  const handleSubmit = e => {
    e.preventDefault();
    let formErrors = validationsFormCliente(formData);
    if (Object.keys(formErrors).length > 0) {
      setNavbarErrores(formErrors);
      setMostrarErrores(true);
      setErrorAdvice(1);
    } else {
      setErrorAdvice(2);
      if (isChecked) {
        setPropietarioVeri(true);

        postClient(
          formData,
          propietarioVeri,
          setErrorVer,
          setNavbarErrores,
          setToken,
          setTokenVencido,
          setTokenCambiar
        );
      } else {
        postClient(
          formData,
          propietarioVeri,
          setErrorVer,
          setNavbarErrores,
          setToken,
          setTokenVencido,
          setTokenCambiar
        );
      }
    }
  };

  useEffect(() => {
    if (errorVer === true) {
      setMostrarErrores(true);
      setErrorVer(undefined);
    }

    if (errorVer === false) {
      if (isChecked) {
        localStorage.setItem('RCV_DIGITAL_CLIENTE', JSON.stringify(formData));
        localStorage.setItem(
          'RCV_DIGITAL_PROPIETARIO',
          JSON.stringify(formData)
        );
        setTimeout(() => {
          navigate('/proceso/vehiculo');
        }, 300);
      } else {
        localStorage.setItem('RCV_DIGITAL_CLIENTE', JSON.stringify(formData));
        localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
        setTimeout(() => {
          navigate('/proceso/propietario');
        }, 300);
      }
    }
  }, [errorVer]);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      esPropietario: isChecked,
    }));
  }, [isChecked]);

  const buscarOperadora = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_OPERADORAS_CELULAR,
      0,
      setOpeCel,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoOperadora(false);
      }, 500)
    );
  };

  const buscarEstados = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_ESTADOS,
      0,
      setEstados,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoEstados(false);
      }, 500)
    );
  };

  const buscarMunicipios = () => {
    const urlMunicipios = `v1/getCombo?p1=${selectedEstado[0].value}&p2=&p3=&p4=&op=3&token=`;
    consultarApi(
      urlMunicipios,
      0,
      setMunicipios,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoMunicipios(false);
      }, 500)
    );
  };

  useEffect(() => {
    if (selectedEstado) {
      localStorage.removeItem('RCV_DIGITAL_ESTADO_SELECCIONADO');
    }
    const [data] = selectedEstado;

    if (data) {
      const { value, name, id, label } = data;
      const estado = JSON.stringify({
        value,
        label,
      });
      localStorage.setItem('RCV_DIGITAL_ESTADO_SELECCIONADO', estado);
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedEstado,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        es_sel: [],
        id_es: '',
      });
    }
  }, [selectedEstado]);

  useEffect(() => {
    const [data] = selectedOperadora;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedOperadora,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        op_sel: [],
        id_op: '',
      });
    }
  }, [selectedOperadora]);

  useEffect(() => {
    const [data] = municipioSeleccionado;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: municipioSeleccionado,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    }
  }, [municipioSeleccionado]);

  return (
    <div
      className={`cliente  ${
        errorAdvice === 0
          ? ''
          : errorAdvice === 1
          ? 'error'
          : errorAdvice === 3
          ? 'success'
          : ''
      }`}
    >
      {mostrarAgregarMunicipio.estado ? (
        <AgregarMunicipio
          mostrarAgregarMunicipio={mostrarAgregarMunicipio}
          setMostrarAgregarMunicipio={setMostrarAgregarMunicipio}
          setAplicarMunicipio={setAplicarMunicipio}
        />
      ) : null}
      <div className="cliente__header">
        <h2>
          Datos del <span>cliente.</span>
        </h2>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>}
        >
          <Link to={'/'}>
            <IoClose />
            <span className="cancelacion">Cancelar</span>
          </Link>
        </OverlayTrigger>
      </div>

      <FormularioCliente
        estados={estados}
        formData={formData}
        errors={errors}
        handleChange={handleChange}
        selectedEstado={selectedEstado}
        setSelectedEstado={setSelectedEstado}
        selectedOperadora={selectedOperadora}
        setSelectedOperadora={setSelectedOperadora}
        opeCel={opeCel}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        limpiar={limpiar}
        buscarEstados={buscarEstados}
        buscarOperadora={buscarOperadora}
        cargandoEstados={cargandoEstados}
        cargandoOperadora={cargandoOperadora}
        cargandoMunicipios={cargandoMunicipios}
        municipios={municipios}
        buscarMunicipios={buscarMunicipios}
        municipioSeleccionado={municipioSeleccionado}
        setMunicipioSeleccionado={setMunicipioSeleccionado}
        setMostrarAgregarMunicipio={setMostrarAgregarMunicipio}
        aplicarMunicipio={aplicarMunicipio}
      />
      <Botones
        handlePrev={handlePrev}
        handleSubmit={handleSubmit}
        url={'/proceso/planes'}
      />
    </div>
  );
};

export default Cliente;
