import React from 'react';

const HeaderPages = ({ titulo, info }) => {
  return (
    <div className="headerpage">
      <h1 className="headerpage__titulo">
        {titulo.split(' ').length > 1
          ? titulo.split(' ')[0] + ' ' + titulo.split(' ')[1]
          : titulo.split(' ')[0]}
      </h1>
      <span className="headerpage__info">{info}</span>
    </div>
  );
};

export default HeaderPages;
