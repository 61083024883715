import CryptoJS from 'crypto-js';
const conseguirValor = (
  prop,
  setValorSup,
  setValorClienteQR,
  setImgContratoQR,
  setImagenqr
) => {
  const key = CryptoJS.lib.WordArray.create(
    Uint8Array.from([
      1, 1, 3, 2, 0, 6, 7, 8, 9, 10, 11, 12, 17, 14, 15, 10, 15, 18, 19, 20, 21,
      29, 40, 42,
    ])
  );

  const iv = CryptoJS.lib.WordArray.create(
    Uint8Array.from([4, 1, 5, 3, 7, 2, 0, 1])
  );

  const plaintext = JSON.stringify({
    pl: prop?.pl_vh?.toUpperCase(),
    cd: prop?.cd_cl?.toUpperCase(),
    opt: 1,
  });

  const ciphertext = CryptoJS.TripleDES.encrypt(plaintext, key, {
    iv: iv,
  }).toString();

  let sHex = '';

  for (let i = 0; i < ciphertext.length; i++) {
    let sValue = ciphertext[i].charCodeAt(0).toString(16);
    sHex += sValue;
  }

  setValorSup(sHex);

  setValorClienteQR(
    `https://www.venezuelaresponsable.com.ve/verify.php?id=${prop.id_co}`
  );

  setTimeout(() => {
    const canvaCliente = document.getElementById('clienteQR');
    const imgURLCliente = canvaCliente.toDataURL('image/png');
    setImgContratoQR(imgURLCliente);
  }, 2000);

  setTimeout(() => {
    const canvas = document.getElementById('seguridadQR');
    const imgURL = canvas.toDataURL('image/png');
    setImagenqr(imgURL);
  }, 2000);
};

export default conseguirValor;
