import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';

const postModelo = async (
  modeloJSON,
  setNavbarErrores,
  setTokenCambiar,
  setMostrarErrores,
  setTokenVencido,
  setModeloVeri,
  setMostrarAgregarMarca,
  setAgregarCompleto,
  setAplicarMarca,
  setToken
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  let marca = JSON.parse(localStorage.getItem('RCV_DIGITAL_MARCA_NUEVA'));
  let json = JSON.stringify({
    modelo: {
      id_mc: marca.id_mc,
      ds_md: modeloJSON.ds_md,
    },
    trace: '0',
    token: token,
  });

  const handleResponse = async data => {
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    if (data.status === 200) {
      localStorage.setItem(
        'RCV_DIGITAL_MODELO',
        JSON.stringify(data.data.data)
      );
      localStorage.removeItem('RCV_DIGITAL_MARCA_NUEVA');
      setModeloVeri(true);
      setTimeout(() => {
        setMostrarAgregarMarca({
          estado: false,
          valorMarca: '',
          valorModelo: '',
        });
        setAgregarCompleto(true);
      }, 1000);
      /*window.location.reload();*/
      setAplicarMarca(true);
      localStorage.setItem(
        'RCV_DIGITAL_MODELO_GUARDADO',
        JSON.stringify(data.data.data)
      );
      setTimeout(() => {
        localStorage.removeItem('RCV_DIGITAL_MODELO_GUARDADO');
      }, 1000);
    }
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        console.log('actualizo sin log');
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        console.log(error);
      }
    } else {
      setNavbarErrores([error.response.data.message]);
      setMostrarErrores(true);
    }
  };
  try {
    const data = await axios.post(process.env.REACT_APP_NUEVA_MODELO, json);
    handleResponse(data);
  } catch (error) {
    handleError(error);
  }
};
export default postModelo;
