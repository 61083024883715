import { useEffect, useState } from 'react';
import AppRoutes from './AppRoutes';
import { getToken } from './helpers/getToken';
import LoadingStart from './components/LoadingStart/LoadingStart';
import TokenProvider from './context/TokenContext';

const App = () => {
  const [tokenI, setTokenI] = useState();
  const [tokenController, setTokenController] = useState(false);
  const loginConfirm = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const [tokenExp, setTokenExp] = useState('');

  useEffect(() => {
    const getTokens = async () => {
      const dataToken = await getToken();
      setTokenI(dataToken);
      if (loginConfirm === null) {
        localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(dataToken));
      }
    };
    getTokens();
  }, []);

  const [token, setToken] = useState();
  useEffect(() => {
    setTimeout(() => {
      setToken(JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN')));
    }, 2000);
  }, []);

  return tokenI ? (
    <div>
      <TokenProvider>
        <AppRoutes
          token={token}
          tokenI={tokenI}
          setTokenExp={setTokenExp}
          setTokenController={setTokenController}
          setToken={setToken}
          tokenExp={tokenExp}
          tokenController={tokenController}
        />
      </TokenProvider>
    </div>
  ) : (
    <LoadingStart />
  );
};

export default App;
