import React, { useState, useContext } from 'react';
import updatePassword from '../../../helpers/updatePassword';
import { useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa';
import { LoginContext } from '../../../context/LoginContext';
import { TokenContext } from '../../../context/TokenContext';

const NuevaContrasena = ({
  email,
  id,
  setNavbarErrores,
  setMostrarErrores,
  setMostrarModalCorrecto,
  setMensajeCorrecto,
  location,
}) => {
  const style = {
    fontWeight: 700,
    top: '-16px',
    left: '0',
    fontSize: '0.8em',
    backgroundColor: 'transparent',
    color: 'black',
  };
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const { setLog } = useContext(LoginContext);
  const [showContra, setShowContra] = useState(false);
  const [cargando, setCargando] = useState(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');
  const [focused, setFocused] = useState();
  const [contraJSON, setContraJSON] = useState({
    contraseña: '',
    contraseñaVeri: '',
  });

  const contraVerificada = form => {
    let errors = {};
    if (form?.contraseña != form.contraseñaVeri) {
      errors.contraseña = 'Las contraseñas deben coincidir';
    }
    if (form?.contraseña === '' || form.contraseña === undefined) {
      errors.contraseña = 'Debes colocar una contraseña';
    }
    if (form?.contraseña?.length < 6) {
      errors.contraseña = 'Su contraseña debe superar los 6 caracteres.';
    }
    if (form?.contraseña?.length > 20) {
      errors.contraseña = 'Su contraseña no debe superar los 20 caracteres.';
    }
    return errors;
  };

  const handleChange = e => {
    e.preventDefault();
    const { value, name } = e.target;
    setContraJSON(current => {
      return {
        ...current,
        [name]: value,
      };
    });
  };
  const aplicarContraseña = async e => {
    setCargando(true);
    e.preventDefault();
    const validador = contraVerificada(contraJSON);
    if (Object.keys(validador).length > 0) {
      setNavbarErrores(validador);
      setMostrarErrores(true);
      setCargando(false);
    } else {
      const resolve = await updatePassword(
        email,
        id,
        contraJSON.contraseña,
        setNavbarErrores,
        setMostrarErrores,
        setTokenCambiar,
        setTokenVencido
      );
      if (resolve === true) {
        setMensajeCorrecto('Su contraseña se ha modificado exitosamente');
        setMostrarModalCorrecto(true);
        setCargando(false);
        if (location != null && location != undefined) {
          localStorage.removeItem('LAST_LOCATION');
          setLog(false);
          localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
          localStorage.removeItem('RCV_DIGITAL_LOGIN');
          window.location.replace('/proceso/resumen');
          setCargando(false);
        } else {
          setTimeout(() => {
            setLog(false);
            localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
            localStorage.removeItem('RCV_DIGITAL_LOGIN');
            navigate('/login/1');
            setCargando(false);
          }, 1000);
        }
      }
    }
  };

  return (
    <div className="necesitas__carta">
      <div className="necesitas__carta-header">
        <FaKey />
        <h2>Necesitas una nueva contraseña</h2>
      </div>
      <div className="necesitas__carta-formContainer">
        <form
          className="necesitas__carta-formContainer-form"
          onSubmit={aplicarContraseña}
        >
          <div className="necesitas__carta-formContainer-form-contra1">
            <label
              htmlFor="pw_cn_us"
              style={selected === 'pw_cn_us' ? style : null}
              className={
                focused === 'contraseña'
                  ? 'focused'
                  : '' + contraJSON.contraseña
                  ? 'focused'
                  : ''
              }
            >
              Nueva contraseña
            </label>
            <input
              onChange={handleChange}
              name="contraseña"
              type={showContra === false ? 'password' : 'text'}
              onFocus={() => setSelected('pw_cn_us')}
              onBlur={() => setSelected('')}
            ></input>
            <span
              onClick={() =>
                showContra === false
                  ? setShowContra(true)
                  : setShowContra(false)
              }
            >
              {!showContra ? <FaRegEyeSlash /> : <FaRegEye />}
            </span>
          </div>
          <div className="necesitas__carta-formContainer-form-contra2">
            <label
              htmlFor="pw_cn"
              style={selected === 'pw_cn' ? style : null}
              className={
                focused === 'contraseñaVeri'
                  ? 'focused'
                  : '' + contraJSON.contraseñaVeri
                  ? 'focused'
                  : ''
              }
            >
              Confirma la contraseña
            </label>
            <input
              onChange={handleChange}
              name="contraseñaVeri"
              type={showContra === false ? 'password' : 'text'}
              onFocus={() => {
                setSelected('pw_cn');
                setFocused('contraseñaVeri');
              }}
              onBlur={() => {
                setSelected('');
                setFocused('');
              }}
            ></input>
            <span
              onClick={() =>
                showContra === false
                  ? setShowContra(true)
                  : setShowContra(false)
              }
            >
              {!showContra ? <FaRegEyeSlash /> : <FaRegEye />}
            </span>
          </div>
          <button
            type="submit"
            className="necesitas__carta-formContainer-form-button"
          >
            {cargando ? (
              <div className="lds-ellipsisg">
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
              </div>
            ) : (
              'CAMBIAR'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NuevaContrasena;
