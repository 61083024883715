import React, { useState, useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';

const BuscarAgente = ({ data, setData }) => {
  const [valorBusqueda, setValorBusqueda] = useState();
  useEffect(() => {
    const resultado = data?.filter(item =>
      item?.ds?.toLowerCase().includes(valorBusqueda?.toLowerCase())
    );
    setData(resultado);
  }, [valorBusqueda]);

  return (
    <div className="buscarAgente">
      <label>
        <BiSearch />
        <input
          placeholder="Buscar aliado.."
          type="text"
          onChange={e => setValorBusqueda(e.target.value)}
        />
      </label>
    </div>
  );
};

export default BuscarAgente;
