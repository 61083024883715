import { useState, createContext, useEffect } from 'react';
import { getToken } from '../helpers/getToken';
import VencimientoDeToken from '../components/VencimientoDeToken/VencimientoDeToken';
export const TokenContext = createContext(undefined);
const TokenProvider = ({ children }) => {
  const [token, setToken] = useState();
  const [tokenVencido, setTokenVencido] = useState();
  const [tokenCambiar, setTokenCambiar] = useState(false);
  const loginConfirm = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));

  useEffect(() => {
    const getTokens = async () => {
      const dataToken = await getToken();
      setToken(dataToken);
      if (loginConfirm === null) {
        localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(dataToken));
      }
    };
    getTokens();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setToken(JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN')));
    }, 2000);
  }, []);

  const cancelar = () => {
    localStorage.removeItem('RCV_DIGITAL_PLAN_AN');
    localStorage.removeItem('LAST_LOCATION');
    localStorage.removeItem('PROPIETARIO_ID');
    localStorage.removeItem('CLIENT_ID');
    localStorage.removeItem('RCV_DIGITAL_CLIENTE');
    localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
    localStorage.removeItem('RCV_DIGITAL_VEHICULO');
    localStorage.removeItem('VEHICULO_ID');
    localStorage.removeItem('RCV_DIGITAL_CARRITO');
    localStorage.removeItem('RCV_DIGITAL_REGISTROA');
    localStorage.removeItem('RCV_CODIGO_DESCUENTO');
    localStorage.removeItem('RCV_CODIGO_USDESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_LOGIN');
    localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
    localStorage.removeItem('RCV_DIGITAL_PASO');
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
    setTokenCambiar(false);
  };

  useEffect(() => {
    const tokenVeri = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
    if (!tokenVeri && loginConfirm != null) {
      cancelar();
    }
  }, []);

  return (
    <TokenContext.Provider
      value={{
        token,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar,
      }}
    >
      {!tokenCambiar ? null : (
        <VencimientoDeToken
          setToken={setToken}
          setTokenCambiar={setTokenCambiar}
          tokenVencido={tokenVencido}
        />
      )}

      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
