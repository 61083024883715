import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';

export const postVehiculo = async (
  formData,
  setErrorVer,
  setNavbarErrores,
  setToken,
  setTokenVencido,
  setTokenCambiar
) => {
  const vehiId = JSON.parse(localStorage.getItem('VEHICULO_ID'));
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let clase = JSON.parse(localStorage.getItem(`RCV_DIGITAL_CLASE`));
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));

  const json = JSON.stringify({
    vehiculo: {
      id_md: formData.md_sel[0].value,
      id_cl_vh: clase.id_cl_vh,
      id_tp_vh: formData.tp_sel[0].value,
      id_uso: formData.uso_sel[0].value,
      pl_vh: vehiId != null ? formData.pl_vh : formData.pl_vh.toUpperCase(),
      cl_vh: formData.cl_vh.toUpperCase(),
      se_ca_vh: formData.se_ca_vh.toUpperCase(),
      se_mo_vh: formData.se_mo_vh.toUpperCase(),
      nr_pu_vh: formData.nr_pu_vh,
      an_vh: formData.an_vh,
      pe_vh: formData.pe_vh,
      id_vh: vehiId,
    },
    trace: '0',
    token: token,
    ori: 'web',
  });

  const handleResponse = async data => {
    localStorage.setItem('VEHICULO_ID', JSON.stringify(data.data.data.id_vh));
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    setErrorVer(false);
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        console.log(error);
      }
    } else if (
      (error.response && error.response.data.statusCode === 426) ||
      error.response.status === 426
    ) {
      localStorage.setItem(
        'VEHICULO_ID',
        JSON.stringify(error.response.data.data.id_vh)
      );
      console.log(error.response.data.message);
      setErrorVer(false);
    } else {
      setErrorVer(true);
      if (error.response && error.response.data) {
        setNavbarErrores([error.response.data.message]);
      } else {
        setNavbarErrores(['Un error inesperado ha ocurrido']);
      }
      console.log(error);
      throw error;
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_VEHICULOS, json);
    handleResponse(data);
  } catch (error) {
    await handleError(error);
  }
};
