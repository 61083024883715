import React from 'react';

const Vision = () => {
  return (
    <div className="leerMas__content-vision">
      <div className="leerMas__content-vision-header">
        <h1>Visión</h1>
      </div>
      <div className="leerMas__content-vision-info">
        <ul className="leerMas__content-vision-info-parrafo">
          <li>
            <span>Nuestra Aspiración:</span> Como empresa de garantías
            administradas, nos esforzamos por ser la primera elección de
            nuestros clientes y colaboradores en todo el país. Buscamos destacar
            no solo por la gestión eficiente de garantías, sino también como un
            símbolo de compromiso y excelencia en cada interacción.
          </li>
          <li>
            <span>Compromiso con la Responsabilidad y Excelencia:</span> Nuestra
            visión va más allá de la gestión de garantías; aspiramos a ser
            reconocidos como un faro de responsabilidad y excelencia. Cada
            acción que emprendemos se guía por estos principios, asegurando que
            nuestra reputación refleje el más alto estándar de integridad y
            calidad.
          </li>
          <li>
            <span>Impacto Nacional:</span> Visualizamos una presencia fuerte y
            positiva en todos los rincones del país, siendo la elección
            preferida de aquellos que buscan garantías administradas con un
            toque de distinción y confiabilidad.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Vision;
