import { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CgMathPlus, CgMathMinus } from 'react-icons/cg';

const Items = ({
  data,
  carrito,
  setCarrito,
  setMostrarErrores,
  setNavbarErrores,
  indice,
}) => {
  const [isChecked, setIsChecked] = useState();
  const [newCantidad, setNewCantidad] = useState(1);
  const [carritoJSON, setCarritoJSON] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_CARRITO'))
  );
  const [objetoEncontradoJSON, setObjetoEncontradoJSON] = useState();

  useEffect(() => {
    setIsChecked(carrito[data.ds_pro]?.isChecked || false);
  }, [data.ds_pro, carrito]);

  useEffect(() => {
    const objetoEncontrado = carritoJSON?.find(
      objeto => objeto?.ds_pro === data?.ds_pro
    );

    if (objetoEncontrado?.ds_pro === data?.ds_pro) {
      setIsChecked(true);
      setNewCantidad(objetoEncontrado?.cantidad);
      setObjetoEncontradoJSON(objetoEncontrado);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (isChecked === false) {
        setNewCantidad(1);
      }
    }, 2000);
  }, [isChecked]);

  const handleItemClick = () => {
    const newCarrito = { ...carrito };

    if (!isChecked) {
      newCarrito[data.ds_pro] = {
        img_pro: data.im_pro,
        ds_pro: data.ds_pro,
        isChecked: true,
        cantidad: 1,
        mt_pro: data.mt_pro,
        id_pro: data.id_pro,
      };
    } else {
      delete newCarrito[data.ds_pro];
    }

    setCarrito(newCarrito);

    localStorage.setItem(
      'RCV_DIGITAL_CARRITO',
      JSON.stringify(Object.values(newCarrito))
    );
  };

  const handleMinusClick = e => {
    e.stopPropagation();
    setNewCantidad(prev => prev - 1);
  };

  const handlePlusClick = e => {
    e.stopPropagation();
    setNewCantidad(prev => prev + 1);
  };

  const handleCantidadChange = e => {
    e.stopPropagation();
    setNewCantidad(e.target.value);
  };

  useEffect(() => {
    if (newCantidad > 3) {
      setNavbarErrores(['La cantidad no puede ser mayor que 3']);
      setMostrarErrores(true);
      setNewCantidad(1);
    }
    if (newCantidad <= 0) {
      setNavbarErrores(['La cantidad no puede ser menor o igual a 0']);
      setMostrarErrores(true);
      setNewCantidad(1);
    }
    if (newCantidad <= 3 || newCantidad > 0) {
      if (carrito[data.ds_pro]) {
        const newCarrito = { ...carrito };
        newCarrito[data.ds_pro].cantidad = newCantidad;
        setCarrito(newCarrito);

        localStorage.setItem(
          'RCV_DIGITAL_CARRITO',
          JSON.stringify(Object.values(newCarrito))
        );
      }
      if (objetoEncontradoJSON) {
        const updatedCarritoJSON = carritoJSON.map(obj =>
          obj.ds_pro === data.ds_pro ? { ...obj, cantidad: newCantidad } : obj
        );

        localStorage.setItem(
          'RCV_DIGITAL_CARRITO',
          JSON.stringify(updatedCarritoJSON)
        );

        objetoEncontradoJSON.cantidad = newCantidad;
      }
    }
  }, [newCantidad]);

  return (
    <div
      className={`productos__items-item ${isChecked ? 'checked' : ''} `}
      key={indice}
      onClick={handleItemClick}
    >
      <span
        className={`productos__items-item-check ${isChecked ? 'checked' : ''}`}
      >
        <FaCheck />
      </span>
      <img
        className={`productos__items-item-img ${isChecked ? 'checked' : ''}`}
        src={data?.im_pro}
        alt="captura"
      />
      <span className="productos__items-item-ds">{data?.ds_pro}</span>
      <span className="productos__items-item-mt">REF {data?.mt_pro}</span>
      <span className="productos__items-item-mtbs">
        BS{' '}
        {data?.mtbs_pro.toLocaleString('es-VE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </span>
      {data?.in_ct_pro ? (
        <div
          className={`productos__items-item-cantidad ${
            isChecked ? 'active' : ''
          }`}
        >
          <CgMathMinus onClick={handleMinusClick} />
          <input
            className=""
            type="text"
            name="quantity"
            min="1"
            max="3"
            value={newCantidad}
            onChange={handleCantidadChange}
            onClick={e => e.stopPropagation()}
          />
          <CgMathPlus onClick={handlePlusClick} />
        </div>
      ) : null}
    </div>
  );
};

export default Items;
