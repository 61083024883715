import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    right: "120px",
    top: "25px",
  },
  font: {
    fontSize: "12px",
    fontFamily: "Arial",
  },
  font2: {
    fontSize: "12px",
    fontFamily: "Arial",
    color: "red",
  },
});

const ContratoCod = ({contratoData}) => {
  const partes = contratoData?.cd_co?.split("-");
  const resultado = partes && partes.length >= 5 ? partes[0] + "-" + partes[4] : "";
  return(
  <div style={styles.container}>
    <View>
      <Text style={styles.font2}>N°</Text>
    </View>
    <View>
      <Text style={styles.font}>{resultado}</Text>
    </View>
  </div>
  )
};
export default ContratoCod;
