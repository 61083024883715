import React from 'react';
const Botones = ({ handlePrev, handleSubmit, url }) => {
  return (
    <div className="proceso__botones">
      <div></div>
      <div className="proceso__botones-contenedor">
        <button className="anterior" onClick={() => handlePrev(url)}>
          ANTERIOR
        </button>
        <button className="siguiente" onClick={handleSubmit}>
          SIGUIENTE
        </button>
      </div>
    </div>
  );
};

export default Botones;
