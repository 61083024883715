import React, { useState, useContext, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { LoginContext } from '../../../../context/LoginContext';
import postModeloUnico from '../../../../helpers/postModeloUnico';
import { TokenContext } from '../../../../context/TokenContext';
import { FaCheck } from 'react-icons/fa';

const AgregarModelo = ({
  setMostrarAgregarModelo,
  mostrarAgregarModelo,
  setAplicarModelo,
}) => {
  const [modeloJSON, setModeloJSON] = useState({
    id_mc: '',
    ds_md: '',
  });
  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modeloVeri, setModeloVeri] = useState(false);

  useEffect(() => {
    if (mostrarAgregarModelo.estado) {
      setModeloJSON({
        ds_md: mostrarAgregarModelo?.valorModelo.toUpperCase(),
      });
    }
  }, [mostrarAgregarModelo]);

  const { setTokenCambiar, setTokenVencido, setToken } =
    useContext(TokenContext);

  const marcaElegida = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_MARCA_SELECCIONADA')
  );
  const { setNavbarErrores, setMostrarErrores } = useContext(LoginContext);
  const verificarModelo = form => {
    let errors = {};
    if (form.ds_md.length > 25) {
      errors.ds_md = 'Excedes el número de caracteres permitidos';
    }

    if (form.length === 0 || form.ds_md === '') {
      errors.ds_md =
        'Debe indicar el modelo del vehículo, por favor verifique.';
    }
    return errors;
  };

  const agregar = e => {
    e.preventDefault();
    const validadorModelo = verificarModelo(modeloJSON);
    if (Object.keys(validadorModelo).length > 0) {
      setNavbarErrores(validadorModelo);
      setMostrarErrores(true);
    } else {
      setLoadingModelo(true);
      setTimeout(() => {
        postModeloUnico(
          modeloJSON,
          setTokenCambiar,
          setTokenVencido,
          setMostrarAgregarModelo,
          setToken,
          marcaElegida,
          setModeloVeri,
          setAplicarModelo,
          setNavbarErrores,
          setMostrarErrores
        ).finally(setLoadingModelo(false));
      }, 2000);
      setTimeout(() => {
        localStorage.removeItem('RCV_DIGITAL_MODELO_GUARDADO');
      }, 6000);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = value.toUpperCase();

    if (name === 'ds_md') {
      setModeloJSON({
        ...modeloJSON,
        [name]: newValue,
      });
    }
  };

  return (
    <div className="agregarModelo">
      <div
        className="agregarModelo__sombra"
        onClick={() =>
          setMostrarAgregarModelo({
            estado: false,
            valorMarca: '',
            valorModelo: '',
          })
        }
      ></div>
      <div className="agregarModelo__carta">
        <div className="agregarModelo__carta-header">
          <span className="agregarMarca__card-header-titulo">
            <span>Agregar </span>Modelo
          </span>
          <span
            className="agregarMarca__card-header-boton"
            onClick={() =>
              setMostrarAgregarModelo({
                estado: false,
                valorMarca: '',
                valorModelo: '',
              })
            }
          >
            <IoClose />
          </span>
        </div>
        <div className="agregarModelo__carta-marca">
          <h1>Marca: {marcaElegida && marcaElegida.label}</h1>
        </div>
        <form className="agregarModelo__carta-formulario" onSubmit={agregar}>
          <div className="agregarModelo__carta-formulario-inputContainer">
            <input
              type="text"
              name="ds_md"
              value={modeloJSON.ds_md}
              disabled={modeloVeri ? true : false}
              className={modeloVeri ? 'sucess' : ''}
              onChange={handleChange}
            />
            <FaCheck className={modeloVeri ? 'active' : ''} />
          </div>

          <div className="agregarModelo__carta-formulario-footer">
            <button
              className="cancelar"
              onClick={e => {
                e.preventDefault();
                setMostrarAgregarModelo({
                  estado: false,
                  valorMarca: '',
                  valorModelo: '',
                });
              }}
            >
              CANCELAR
            </button>

            <button
              className="aceptar"
              type="submit"
              disabled={loadingModelo ? true : false}
            >
              {loadingModelo ? (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              ) : (
                'ACEPTAR'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarModelo;
