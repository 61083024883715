import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import RCVLogo from '../../../styles/images/f7c38f45-fb8c-44ea-8d00-62d7d2427a4d (1).jpg';

const styles = StyleSheet.create({
  container: {
    width: '57%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '13px',
    marginLeft: '10px',
    paddingTop: '10px',
    alignItems: 'center',
  },
  containerColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  containerColumnInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '250px',
  },
  containerRowInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerRight: {
    alignSelf: 'flex-end',
  },
  fontBold: {
    fontSize: '7px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  font: {
    fontSize: '7px',
    fontStyle: 'normal',
  },

  fontOri: {
    transform: 'rotate(90deg)',
    fontSize: '12px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
    position: 'absolute',
    right: '-20px',
    top: '-30px',
  },

  image: {
    height: '35px',
    width: '70px',
    position: 'absolute',
    right: '2px',
  },
  imageFondo: {
    height: '100px',
    width: '150px',
    position: 'absolute',
    right: '60px',
    top: '-10px',
    zIndex: '-2',
    opacity: 0.1,
  },
});
const FacturaTicketPlan = ({ factura }) => {
  const partes = factura?.cd_co?.split('-');
  const resultado =
    partes && partes.length >= 5 ? partes[0] + '-' + partes[4] : '';
  const fechaInicio = factura?.Fecha_Inicio.split(' ')[0];
  const fechaFin = factura?.Fecha_Final.split(' ')[0];

  return (
    <div style={styles.container}>
      <div style={styles.containerColumn}>
        <View style={styles.containerRight}>
          <Text style={styles.fontBold}>VENEZUELA RESPONSABLE, C.A</Text>
        </View>
        <div style={styles.containerColumnInfo}>
          <div style={styles.containerRowInfo}>
            <View>
              <Text style={styles.fontBold}>
                R.I.F.:{' '}
                <Text style={styles.font}>{factura?.cd_cl.toUpperCase()}</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}>RIF: J-29804784-4</Text>
            </View>
          </div>
          <div style={styles.containerRowInfo}>
            <View>
              <Text style={styles.fontBold}>
                CONTRATANTE:{' '}
                <Text style={styles.font}>{factura?.rz_cl.toUpperCase()}</Text>
              </Text>
            </View>
            <Image style={styles.image} src={RCVLogo} />
          </div>
          <div>
            <View>
              <Text style={styles.fontBold}>
                PAQUETE: <Text style={styles.font}>{factura?.ds_pq}</Text>
              </Text>
            </View>
          </div>
          <div>
            <View>
              <Text style={styles.fontBold}>
                COBERTURA: <Text style={styles.font}>{factura?.Cobertura}</Text>
              </Text>
            </View>
          </div>
          <div>
            <View>
              <Text style={styles.fontBold}>
                DESDE: <Text style={styles.font}>{fechaInicio}</Text>
              </Text>
            </View>
          </div>
          <div style={styles.containerRowInfo}>
            <View>
              <Text style={styles.fontBold}>
                HASTA: <Text style={styles.font}>{fechaFin}</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}>
                CONTRATO: <Text style={styles.font}>{resultado}</Text>
              </Text>
            </View>
          </div>
          <div style={styles.containerRowInfo}>
            <View>
              <Text style={styles.fontBold}>
                CODIGO WEB: <Text style={styles.font}>{factura?.cd_web}</Text>
              </Text>
            </View>
            <View>
              <Text style={styles.fontBold}>
                PLACA:{' '}
                <Text style={styles.font}>{factura?.pl_vh.toUpperCase()}</Text>
              </Text>
            </View>
          </div>
          <div>
            <View>
              <Text style={styles.font}>
                Asistencia de Traslado al Contratante - (ATC) Telf:
                (0412)162.82.33
              </Text>
            </View>
          </div>
        </div>
      </div>
      <View>
        <Text style={styles.fontOri}>{factura?.FC_FACTURA}</Text>
      </View>
      <Image style={styles.imageFondo} src={RCVLogo} />
    </div>
  );
};
export default FacturaTicketPlan;
