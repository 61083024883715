import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import TableTotales from '../TableTotales';
import TableTotalesComisiones from './TableTotalesComisiones';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '4px',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableRowTotales: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableColHeader: {
    fontFamily: 'Arial',
    height: '20px',
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 4,
    padding: 4,
    paddingTop: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: ' center',
  },
  tableCol: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 6,
    padding: 2,
  },
  tableColAgente: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 5,
    padding: 2,
  },
  tableColTotales: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 6,
    padding: 2,
  },
  tableColContra: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 6,
    padding: 2,
  },
  tableColContraTotales: {
    fontFamily: 'Arial',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 6,
    padding: 2,
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  tableColMonto: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 6,
    padding: 2,
  },
  tableColMontoTotales: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 6,
    padding: 2,
  },
});

const TableComision = ({ reporte }) => {
  const porcentaje = reporte?.VentasCommission[0]?.pc_cm_ag;
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>FECHA</Text>
        <Text style={[styles.tableColHeader, { width: '3%' }]}>CONTRATO</Text>
        <Text style={[styles.tableColHeader, { width: '25.5%' }]}>
          CONTRATANTE
        </Text>{' '}
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>RIF</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>AGENTE</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>EXENTO</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>BASE</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>I.V.A</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>I.G.T.F</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>
          DESCUENTO
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>INGRESO</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>
          BASE COMISI&Oacute;N
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>
          COMISI&Oacute;N {porcentaje}%
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>
          COMISI&Oacute;N {porcentaje}% REF
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>TASA</Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>
          INGRESO REF
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>
          COBERTURA
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.5%' }]}>PLACA</Text>
      </View>
      {reporte?.VentasCommission?.map((row, index) => {
        const agenteSplit = row?.nb_agente;
        const agenteParseado = agenteSplit.split(' ');
        const agenteNombre = agenteParseado[0] + ' ' + agenteParseado[1];
        const baseComision = row?.mt_ba_tt + row?.mt_ex_tt - row?.mt_ds_tt;
        return (
          <View key={index} style={styles.tableRow}>
            <Text style={[styles.tableCol, { width: '5.5%' }]}>
              {row?.fecha}
            </Text>
            <Text style={[styles.tableCol, { width: '3%' }]}>
              {row?.tp_fc === 'NC' ? '' : row?.cd_contrato}
            </Text>

            <Text style={[styles.tableColContra, { width: '25.5%' }]}>
              {row?.contratante}
            </Text>
            <Text style={[styles.tableCol, { width: '5.5%' }]}>{row?.rif}</Text>
            <Text style={[styles.tableColAgente, { width: '5.5%' }]}>
              {agenteNombre}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_ex_tt.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_ba_tt.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_iv_tt.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_igtf.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_ds_tt.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_ge_tt.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {baseComision.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>

            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_cm_ag.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>

            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.mt_cm_ba_ag.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>

            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.tasa.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {(row?.mt_ge_tt / row.tasa).toFixed(2)}
            </Text>
            <Text style={[styles.tableColMonto, { width: '5.5%' }]}>
              {row?.cobertura.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <Text style={[styles.tableCol, { width: '5.5%' }]}>
              {row?.placa}
            </Text>
          </View>
        );
      })}
      <TableTotalesComisiones reporte={reporte} />
    </View>
  );
};

export default TableComision;
