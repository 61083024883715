import React, { useState, useContext } from 'react';
import updatePassword from '../../../helpers/updatePassword';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../../context/LoginContext';
import { TokenContext } from '../../../context/TokenContext';

const ContraseñaPerfil = ({
  setNavbarErrores,
  setMostrarErrores,
  setMensajeCorrecto,
  setMostrarModalCorrecto,
}) => {
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const contraVerificada = form => {
    let errors = {};
    if (form?.contraseña != form.contraseñaVeri) {
      errors.contraseña = 'Las contraseñas deben coincidir';
    }
    if (form?.contraseña === '' || form.contraseña === undefined) {
      errors.contraseña = 'Debes colocar una contraseña';
    }
    if (form?.contraseña?.length < 6) {
      errors.contraseña = 'Su contraseña debe superar los 6 caracteres.';
    }
    if (form?.contraseña?.length > 20) {
      errors.contraseña = 'Su contraseña no debe superar los 20 caracteres.';
    }
    return errors;
  };
  const navigate = useNavigate();
  const { setLog } = useContext(LoginContext);

  const [mostrar, setMostrar] = useState('password');
  const [mostrarContraseña, setMostrarContraseña] = useState('password');
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const [email] = useState(userProfile?.em_us);
  const [focused, setFocused] = useState();
  const [id] = useState(userProfile?.id_us);
  const [cargando, setCargando] = useState(false);
  const [contraJSON, setContraJSON] = useState({
    contraseña: '',
    contraseñaVeri: '',
  });
  const handleChange = e => {
    e.preventDefault();
    const { value, name } = e.target;
    setContraJSON(current => {
      return {
        ...current,
        [name]: value,
      };
    });
  };
  const cambiarContraseña = async e => {
    setCargando(true);
    e.preventDefault();
    const validador = contraVerificada(contraJSON);
    if (Object.keys(validador).length > 0) {
      setNavbarErrores(validador);
      setMostrarErrores(true);
    } else {
      let contra = contraJSON.contraseña;

      const resolve = await updatePassword(
        email,
        id,
        contra,
        setNavbarErrores,
        setMostrarErrores,
        setTokenCambiar,
        setTokenVencido
      );
      if (resolve === true) {
        setCargando(false);
        setMensajeCorrecto('Su contraseña ha sido cambiada correctamente.');
        setMostrarModalCorrecto(true);
        setLog(false);
        setTimeout(() => {
          localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
          localStorage.removeItem('RCV_DIGITAL_LOGIN');
          navigate('/login/1');
        }, 2000);
      }
    }
  };
  return (
    <div className="contraseñaPerfil">
      <div className="contraseñaPerfil__contraseña">
        <div className="contraseñaPerfil__contraseña-header">
          <h5>
            Actualiza tu <span>contraseña</span>.
          </h5>
        </div>
        <form
          onSubmit={cambiarContraseña}
          className="contraseñaPerfil__contraseña-form"
        >
          <div className="contraseñaPerfil__contraseña-form-contraseña">
            <span
              for="contraseña"
              className={
                focused === 'contraseña'
                  ? 'focused'
                  : '' + contraJSON.contraseña
                  ? 'focused'
                  : ''
              }
            >
              Contraseña nueva
            </span>
            <input
              name="contraseña"
              autoComplete="false"
              type={mostrarContraseña}
              onChange={handleChange}
              onFocus={e => {
                e.preventDefault();
                setFocused('contraseña');
              }}
              onBlur={e => {
                e.preventDefault();
                setFocused('');
              }}
            ></input>
            <button
              onClick={e => {
                e.preventDefault();
                mostrarContraseña === 'text'
                  ? setMostrarContraseña('password')
                  : setMostrarContraseña('text');
              }}
            >
              {mostrarContraseña === 'text' ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
          <div className="contraseñaPerfil__contraseña-form-contraseñaVeri">
            <span
              for="contraseñaVeri"
              className={
                focused === 'contraseñaVeri'
                  ? 'focused'
                  : '' + contraJSON.contraseñaVeri
                  ? 'focused'
                  : ''
              }
            >
              Repite la contraseña
            </span>
            <input
              name="contraseñaVeri"
              autoComplete="false"
              type={mostrar}
              onChange={handleChange}
              onFocus={e => {
                e.preventDefault();
                setFocused('contraseñaVeri');
              }}
              onBlur={e => {
                e.preventDefault();
                setFocused('');
              }}
            ></input>

            <button
              onClick={e => {
                e.preventDefault();
                mostrar === 'text'
                  ? setMostrar('password')
                  : setMostrar('text');
              }}
            >
              {mostrar === 'text' ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
          <button
            disabled={cargando}
            type="submit"
            className="contraseñaPerfil__contraseña-form-submit"
          >
            {cargando ? (
              <div className="lds-ellipsisg">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              'CAMBIAR CONTRASEÑA'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContraseñaPerfil;
