import React, { useState, useEffect, useContext } from 'react';
import consultarApi from '../../helpers/consultarApi';
import { LoginContext } from '../../context/LoginContext';
import { TokenContext } from '../../context/TokenContext';
import ReportesComisionesPDF from '../Reportes/CalculoComisiones/ReportesComisionesPDF';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const CalculoComisionesResultado = () => {
  const [parametros, setParametros] = useState();
  const { setNavbar } = useContext(LoginContext);
  const [currentDate] = useState(new Date());
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const [fecha, setFecha] = useState('');
  const [hora, setHora] = useState('');
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);
  const [reporte, setReporte] = useState();
  useEffect(() => {
    const parametrosObtenidos = localStorage.getItem('RCV_DIGITAL_AGENTE_DATA');
    if (parametrosObtenidos) {
      const parametroParseado = JSON.parse(parametrosObtenidos);
      setParametros(parametroParseado);
      setNavbar(false);
      let urlReportes = `${process.env.REACT_APP_COMISIONES}?p1=${parametroParseado?.perfil}&p2=${parametroParseado?.descuento}&p3=&p4=&dd=${parametroParseado?.fechaIni}&ht=${parametroParseado?.fechaFin}&op=8&token=`;
      consultarApi(
        urlReportes,
        0,
        setReporte,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(localStorage.removeItem('RCV_DIGITAL_AGENTE_DATA'));
    }
    console.log(reporte);
  }, []);

  useEffect(() => {
    setReporte(reporte);
    const fechaFormateada = format(currentDate, 'dd/MM/yyyy', { locale: es });
    const horaFormateada = format(currentDate, 'hh:mm:ss a', { locale: es });
    setFecha(fechaFormateada);
    setHora(horaFormateada);
  }, [reporte]);
  return (
    <div>
      <ReportesComisionesPDF
        fechaini={parametros?.fechaIni}
        fechafin={parametros?.fechaFin}
        reporte={reporte}
        userProfile={userProfile}
        fecha={fecha}
        hora={hora}
      />
    </div>
  );
};

export default CalculoComisionesResultado;
