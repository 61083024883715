import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';

const ModalRechazo = ({
  info,
  rechazarContrato,
  modalRechazar,
  setModalRechazar,
  comentarioHandler,
  comentarioRef,
  showConfirm,
  setShowConfirm,
  cargandoRechazar,
}) => {
  const [showComment, setShowComment] = useState(false);
  const partes = info?.cd_co?.split('-');
  const resultado =
    partes && partes.length >= 5 ? partes[0] + '-' + partes[4] : '';

  return (
    <div className={modalRechazar ? 'modalRechazo' : 'modalRechazo active'}>
      <div className="modalRechazo__card">
        <div
          className={
            showComment
              ? 'modalRechazo__card-info active'
              : 'modalRechazo__card-info'
          }
        >
          <h5 className="modalRechazo__card-info-header">
            ¿Estás seguro/a de que quieres rechazar el contrato?{' '}
          </h5>
          <h5 className="modalRechazo__card-info-header-info">{resultado}</h5>
        </div>
        <div
          className={
            !showConfirm
              ? 'modalRechazo__card-comentario'
              : 'modalRechazo__card-comentario active'
          }
        >
          <h5 className="modalRechazo__card-headerdes">Especifica el motivo</h5>
          <textarea
            ref={comentarioRef}
            name="comentarios"
            rows="10"
            cols="40"
            onChange={() => comentarioHandler()}
          ></textarea>
        </div>
        <div
          className={
            !showConfirm
              ? 'modalRechazo__card-buttons'
              : 'modalRechazo__card-buttons active'
          }
        >
          <button
            onClick={() => {
              setShowConfirm(true);
              setShowComment(false);
            }}
            className="modalRechazo__card-buttons-cancelar"
          >
            ATRAS
          </button>
          <button
            onClick={() => {
              rechazarContrato(info.id_co, info.id_fc);
              setTimeout(() => {
                setShowConfirm(true);
                setShowComment(false);
              }, 2000);
            }}
            className="modalRechazo__card-buttons-rechazar"
          >
            {cargandoRechazar ? (
              <div className="lds-ellipsisg">
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
              </div>
            ) : (
              'RECHAZAR'
            )}
          </button>
        </div>
        <div
          className={
            showConfirm
              ? 'modalRechazo__card-confirmarechazo'
              : 'modalRechazo__card-confirmarechazo active'
          }
        >
          <div className="modalRechazo__card-confirmarechazo-buttons">
            <button
              onClick={() => {
                setModalRechazar(false);
              }}
              className="modalRechazo__card-confirmarechazo-buttons-cancelar"
            >
              CERRAR
            </button>
            <button
              className="modalRechazo__card-confirmarechazo-buttons-rechazar"
              onClick={() => {
                setShowConfirm(false);
                setShowComment(true);
              }}
            >
              ACEPTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRechazo;
