import React, { useState, useRef, useEffect } from 'react';
import { FaLock } from 'react-icons/fa';
import { BsPersonFill } from 'react-icons/bs';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

const DatosForm = ({
  loguearse,
  datosDelFormulario,
  datosForm,
  setDatosForm,
  opcion,
  loading,
}) => {
  const [selected, setSelected] = useState('');
  const [showContra, setShowContra] = useState(false);
  const style = {
    fontWeight: 700,
    top: '-16px',
    left: '0',
    fontSize: '0.8em',
    backgroundColor: 'transparent',
    color: 'black',
  };

  const formRef = useRef(null);

  useEffect(() => {
    setDatosForm({
      usuario: {
        us_us: '',
        pw_us: '',
      },
    });
  }, [opcion]);
  useEffect(() => {
    formRef.current.querySelectorAll('input').forEach(input => {
      input.value = '';
    });
  }, [opcion]);

  return (
    <form
      ref={formRef}
      id="loginForm"
      className="iniciarSesion__container-form"
      onSubmit={loguearse}
    >
      <div className="iniciarSesion__container-form-usuario">
        <label
          htmlFor="us_us"
          className={datosForm.us_us ? 'focused' : ''}
          style={selected === 'us_us' ? style : null}
        >
          <BsPersonFill /> Usuario
        </label>
        <input
          type="text"
          name="us_us"
          value={datosForm.us_us}
          onFocus={() => setSelected('us_us')}
          onBlur={() => setSelected('')}
          onChange={datosDelFormulario}
          autoComplete="off"
        ></input>
      </div>
      <div className="iniciarSesion__container-form-contraseña">
        <label
          htmlFor="pw_us"
          className={datosForm.pw_us ? 'focused' : ''}
          style={selected === 'pw_us' ? style : null}
        >
          <FaLock /> Contraseña
        </label>
        <input
          type={showContra === false ? 'password' : 'text'}
          name="pw_us"
          value={datosForm.pw_us}
          onFocus={() => setSelected('pw_us')}
          onBlur={() => setSelected('')}
          onChange={datosDelFormulario}
          autoComplete="off"
        ></input>
        <span
          className="iniciarSesion__container-form-contraseña-mostrar"
          onClick={() =>
            showContra === false ? setShowContra(true) : setShowContra(false)
          }
        >
          {!showContra ? <FaRegEyeSlash /> : <FaRegEye />}
        </span>
      </div>
      <button
        type="submit"
        className="iniciarSesion__container-form-button"
        form="loginForm"
        disabled={loading}
      >
        {loading ? (
          <div className="lds-ellipsisg">
            <div style={{ backgroundColor: 'white' }}></div>
            <div style={{ backgroundColor: 'white' }}></div>
            <div style={{ backgroundColor: 'white' }}></div>
            <div style={{ backgroundColor: 'white' }}></div>
          </div>
        ) : (
          'INICIAR SESIÓN'
        )}
      </button>
    </form>
  );
};

export default DatosForm;
