import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: '125px',
    right: '120px',
    display: 'flex',
    flexDirection: 'column',
    height: '30px',
  },
  containerRed: {
    position: 'absolute',
    top: '125px',
    right: '140px',
    display: 'flex',
    flexDirection: 'column',
    height: '30px',
  },
  font: {
    fontSize: '8px',
  },
  fontBold: {
    fontSize: '8px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});

const FacturaVendedor = ({ factura }) => {
  let nombre = factura?.NOMBRE.split(' ');
  return (
    <div style={nombre?.length <= 2 ? styles.containerRed : styles.container}>
      <View>
        <Text style={styles.font}>
          <Text style={styles.fontBold}>USUARIO: </Text>
          {factura?.NOMBRE.toUpperCase()}
        </Text>
      </View>
    </div>
  );
};
export default FacturaVendedor;
