import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: "176px",
    right: "144px",
    display: "flex",
    flexDirection: "column",
    height: "30px",
    gap: "4px",
  },
  font: {
    fontSize: "7px",
  },
  fontBold: {
    fontSize: "7px",
    fontFamily: "Arial",
    fontStyle: "bold",
  },
});

const FacturaFinCon = ({ factura }) => {
  const fechaFin = factura?.Fecha_Final.split(" ")[0];
  return (
    <div style={styles.container}>
    <View >
      <Text style={styles.font}>
        <Text style={styles.fontBold}>FECHA FIN CONTRATO: </Text>{fechaFin}
      </Text>
    </View>
    </div>
  );
};
export default FacturaFinCon;
