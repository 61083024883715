import { Link } from 'react-router-dom';
import LogoVR from '../../img/logo_n_ulimate.png';
const NavbarLeft = () => {
  return (
    <div className="navbar__container-left">
      <Link to={'/'}>
        <img
          className={'navbarLite__left-logo'}
          alt="logo de la emprese Venezuela Responsable"
          src={LogoVR}
        ></img>
      </Link>
    </div>
  );
};

export default NavbarLeft;
