const pagoErrores = (referencia, bancoElegido) => {
  let errors = {};

  if (bancoElegido === undefined || bancoElegido === null) {
    errors.bancoElegido = 'Debes seleccionar un banco para poder continuar';
  }

  if (referencia.length === 0) {
    errors.referencia = 'La referencia es necesaria';
  }

  if (referencia.length === 3) {
    errors.referencia = 'La referencia no es valida';
  }

  if (referencia.length > 15) {
    errors.referencia = 'La referencia no es valida';
  }

  return errors;
};

export default pagoErrores;
