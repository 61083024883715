import React, { useState, useEffect, useRef } from 'react';
import { IoBagCheckOutline } from 'react-icons/io5';

const PagoConfirmado = ({
  setPagoConfirmacion,
  pagoConfirmacion,
  referencia,
  total,
  bancoElegido,
  pagarConfirmado,
  procesandoPago,
  activo,
}) => {
  const [seconds, setSeconds] = useState(15);
  const pagandoRef = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (pagoConfirmacion) setSeconds(15);
  }, [pagoConfirmacion]);

  useEffect(() => {
    if (seconds === 0) {
      setPagoConfirmacion(false);
    }
  }, [seconds]);
  return (
    <div className={`pagoConfirmado ${pagoConfirmacion}`}>
      <div className="pagoConfirmado__overlay"></div>
      <div className="pagoConfirmado__carta">
        <div className="pagoConfirmado__carta-header">
          <div className="pagoConfirmado__carta-header-title">
            <IoBagCheckOutline /> <h3>Confirma los datos antes de pagar</h3>
          </div>
          <div className="pagoConfirmado__carta-header-totalref">
            <span className="pagoConfirmado__carta-header-totalref-nombre">
              TOTAL REF:
            </span>{' '}
            <span className="pagoConfirmado__carta-header-totalref-valor">
              {total[5]?.mt_ba.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className="pagoConfirmado__carta-header-referencia">
            {' '}
            <span className="pagoConfirmado__carta-header-referencia-nombre">
              REFERENCIA:
            </span>{' '}
            <span className="pagoConfirmado__carta-header-referencia-valor">
              {referencia}
            </span>
          </div>
          <div className="pagoConfirmado__carta-header-referencia">
            {' '}
            <span className="pagoConfirmado__carta-header-referencia-nombre">
              BANCO ELEGIDO:
            </span>{' '}
            <span className="pagoConfirmado__carta-header-referencia-valor">
              {bancoElegido?.ds_bc}
            </span>
          </div>
          <div className="pagoConfirmado__carta-header-referencia">
            {' '}
            <span className="pagoConfirmado__carta-header-referencia-nombre">
              TIPO DE PAGO:
            </span>{' '}
            <span className="pagoConfirmado__carta-header-referencia-valor">
              {activo?.banco}
            </span>
          </div>
        </div>
        <div className="pagoConfirmado__carta-footer">
          <button
            className="cancelar"
            onClick={() => setPagoConfirmacion(false)}
            disabled={procesandoPago}
          >
            CANCELAR<span>{seconds}</span>
          </button>
          <button
            className="confirmar"
            ref={pagandoRef}
            onClick={() => pagarConfirmado()}
            disabled={procesandoPago ? true : false}
          >
            {procesandoPago ? (
              <div className="lds-ellipsisg">
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
              </div>
            ) : (
              <div>CONFIRMAR</div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PagoConfirmado;
