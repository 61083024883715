import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocumentFactura from '../../PDFViewer/PDFDocumentFactura';

const PDFFacturaRenderizador = ({ factura, imagenqr }) => {
  return (
    <PDFViewer width="100%" height="1000">
      <PDFDocumentFactura factura={factura} imagenqr={imagenqr} />
    </PDFViewer>
  );
};

export default PDFFacturaRenderizador;
