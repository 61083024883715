import { useState, useEffect, useContext } from 'react';
import Clase from './Clases/Clase';
import { useNavigate } from 'react-router-dom';
import { TokenContext } from '../../../context/TokenContext';
import consultarApi from '../../../helpers/consultarApi';
import useProcesoProvider from '../../../hooks/useProcesoProvider';

const Clases = () => {
  const navigate = useNavigate();
  const [clases, setClases] = useState([]);
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [loading, setLoading] = useState(true);
  const context = useProcesoProvider();
  const { cambiarPaso } = context;

  useEffect(() => {
    setLoading(true);
    consultarApi(
      process.env.REACT_APP_GET_ALL_CLASS,
      0,
      setClases,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(() => setLoading(false));
  }, [token]);

  useEffect(() => {
    cambiarPaso(0, 0);
  }, []);

  const handleSubmit = (e, x) => {
    e.preventDefault();
    const {
      id_cl_vh,
      cd_cl_vh,
      tp_cl_vh,
      ds_cl_vh: ds_cl,
      im_pq,
      mt_pr_mn: pr_min,
      mt_pr_mx: pr_max,
      mt_pq_cb_ba_mx: cb_max,
      mt_pq_cb_ba_mn: cb_min,
    } = x;

    const useClase = {
      id_cl_vh,
      cd_cl_vh,
      ds_cl_vh: ds_cl,
      tp_cl_vh,
      mt_pr_mx: pr_max,
      mt_pr_mn: pr_min,
      mt_pq_cb_ba_mx: cb_max,
      mt_pq_cb_ba_mn: cb_min,
      im_pq,
    };
    localStorage.setItem('RCV_DIGITAL_CLASE', JSON.stringify(useClase));

    setTimeout(() => {
      navigate('/proceso/planes');
    }, 300);
  };
  return (
    <div className="clasesContainer">
      <div className="clasesContainer__header">
        <h2>
          Elige la <span>CLASE</span> de tu veh&iacute;culo
        </h2>
      </div>

      <Clase clases={clases} handleSubmit={handleSubmit} loading={loading} />
    </div>
  );
};

export default Clases;
