import React, { useEffect, useState, useContext, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TiArrowSortedDown } from 'react-icons/ti';
import {
  TbReportSearch,
  TbPointFilled,
  TbReportAnalytics,
  TbReportMoney,
} from 'react-icons/tb';
import { BiSupport } from 'react-icons/bi';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { BiNotepad } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { MdDiscount } from 'react-icons/md';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';

const icons = {
  TbReportSearch,
  TbReportAnalytics,
  BiSupport,
  MdOutlineDashboardCustomize,
  BiNotepad,
  CgProfile,
  TbReportMoney,
  MdDiscount,
};
const IconoDinamico = ({ icono }) => {
  const Icono = icons[icono];
  if (!Icono) return <TbPointFilled />;
  return <Icono />;
};

const MenuDinamico = ({ menuRes, handleLogout, setMenuRes, login }) => {
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [menu, setMenu] = useState();
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [tokenAuth] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN_AUTH'))
  );
  const menuRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      consultarApi(
        process.env.REACT_APP_GET_MENU,
        0,
        setMenu,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
    }, 500);
  }, [tokenAuth, token, login]);

  useEffect(() => {
    if (menu === null) {
      consultarApi(
        process.env.REACT_APP_GET_MENU,
        0,
        setMenu,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
    }
  }, [menu]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuRes(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRes]);

  const handleSubMenuHover = index => {
    setActiveSubMenu(index);
  };

  const handleSubMenuLeave = () => {
    setActiveSubMenu(null);
  };

  return (
    <header
      className={`menuDinamico ${menuRes ? 'active' : ''}`}
      id="menudinamico"
      ref={menuRef}
    >
      <div
        className={
          activeSubMenu !== null
            ? 'menuDinamico__overlay activo'
            : 'menuDinamico__overlay'
        }
      ></div>
      <ul className="menuDinamico__lista">
        {menu &&
          menu.map((x, indice) => {
            if (x.li_tp) {
              return (
                <NavLink
                  activeclassname="active"
                  className="menuDinamico__lista-item"
                  key={indice}
                  to={x.op_mn[0].op[0].ur_pg}
                >
                  {x.ds_mn}
                </NavLink>
              );
            } else {
              return (
                <ul
                  className={`menuDinamico__lista-item ${
                    activeSubMenu === indice ? 'active' : ''
                  } ${!x.li_tp ? 'plegable' : ''}`}
                  key={indice}
                  onMouseOver={() => handleSubMenuHover(indice)}
                  onMouseLeave={() => handleSubMenuLeave()}
                >
                  {x.ds_mn}
                  <TiArrowSortedDown />
                  <ul
                    className={`menuDinamico__lista-item-submenu ${
                      activeSubMenu === indice ? 'active' : ''
                    }`}
                  >
                    {x.op_mn.map((y, i) => (
                      <ul
                        className="menuDinamico__lista-item-submenu-nombre"
                        key={i}
                      >
                        <span>{y.ds_sm}</span>
                        {y.op.map((l, o) => {
                          const tooltip = (
                            <Tooltip id="tooltip">
                              <strong>{l.tt_pg}</strong>
                            </Tooltip>
                          );
                          return (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={tooltip}
                              key={o}
                              className="menuDinamicoOverlay"
                            >
                              <NavLink
                                onClick={() => {
                                  setActiveSubMenu(null);
                                  setMenuRes(false);
                                  document
                                    .getElementById('menudinamico')
                                    .classList.remove('active');
                                }}
                                activeclassname="active"
                                to={l.ur_pg}
                                key={o}
                                className="menuDinamico__lista-item-submenu-nombre-opcion"
                              >
                                <IconoDinamico icono={l.ic_pg} />

                                {l.nb_pg}
                              </NavLink>
                            </OverlayTrigger>
                          );
                        })}
                      </ul>
                    ))}
                  </ul>
                </ul>
              );
            }
          })}
      </ul>
      <div
        className="menuDinamico__logout"
        onClick={() => {
          handleLogout();
        }}
      >
        <span className="menuDinamico__logout-cerrar">
          Cerrar sesi&oacute;n
        </span>

        <span className="menuDinamico__logout-spanIcon">
          <AiOutlinePoweroff />
        </span>
      </div>
    </header>
  );
};

export default MenuDinamico;
