import { useState, useEffect, useContext } from 'react';
import NavbarLeft from './NavbarLeft';
import NavbarRight from './NavbarRight';
import MenuDinamico from './MenuDinamico/MenuDinamico';
import { HiOutlineMenu } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import NavbarErrors from '../NavbarLite/NavbarErrors';
import { useLocation, useNavigate } from 'react-router-dom';
import { TokenContext } from '../../context/TokenContext';

const Navbar = ({
  login,
  setLogin,
  setLoginStatus,
  setPrivado,
  setTokenExp,
  setTokenController,
  navbarErrores,
  mostrarErrores,
  setMostrarErrores,
  tasa,
}) => {
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const navigate = useNavigate();
  let location = useLocation();
  let fecha = new Date();
  let meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Setiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  let dias_semana = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  const [mostrarMenu, setMostrarMenu] = useState(false);
  const [menuRes, setMenuRes] = useState(false);
  const { token, setToken } = useContext(TokenContext);
  let profile = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_PROFILEOBJ') || 'null'
  );

  useEffect(() => {
    if (login != undefined && login != false && login != null) {
      setTimeout(() => {
        setLogin(JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN')));
        setUserProfile(
          JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
        );
        setPrivado(true);
      }, 1000);
    }
  }, [login]);

  const handleLogout = () => {
    setLogin(false);
    localStorage.removeItem('RCV_DIGITAL_TOKEN');
    setTimeout(() => {
      setLoginStatus(false);
      if (location.pathname === '/') {
        window.location.reload();
      }
      navigate('/');
      localStorage.removeItem('RCV_DIGITAL_LOGIN');
      localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
    }, 1000);
  };

  return (
    <>
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <header
        className={`navbar ${
          location.pathname != '/' && !profile ? 'cambiar' : ''
        }`}
      >
        <nav className="navbar__container">
          <NavbarLeft />
          <div
            className={`navbar__container-fecha ${
              location.pathname != '/' && !profile ? 'onini' : 'outini'
            }`}
          >
            <span className="navbar__container-fecha-date">
              {`${dias_semana[fecha.getDay()]} ${fecha.getDate()} de ${
                meses[fecha.getMonth()]
              } de ${fecha.getUTCFullYear()}`}
            </span>
            <span className="navbar__container-fecha-bcv">{`Tasa BCV: ${
              tasa && Number(tasa?.mt_ts).toFixed(2)
            }`}</span>
          </div>
          {location.pathname === '/' && !profile ? null : (
            <NavbarRight
              login={login}
              mostrarMenu={mostrarMenu}
              setMostrarMenu={setMostrarMenu}
              userProfile={userProfile}
              setLoginStatus={setLoginStatus}
              setLogin={setLogin}
              handleLogout={handleLogout}
              version={tasa?.ve_si}
            />
          )}

          {login ? (
            <h3
              className="botonresponsive"
              onClick={() => {
                menuRes ? setMenuRes(false) : setMenuRes(true);
              }}
            >
              {menuRes ? (
                <IoMdClose className="botonresponsive__icon" />
              ) : (
                <HiOutlineMenu className="botonresponsive__icon" />
              )}
            </h3>
          ) : null}
        </nav>
      </header>
      {login && profile?.es_cl != 'C' ? (
        <MenuDinamico
          token={token}
          menuRes={menuRes}
          login={login}
          setTokenExp={setTokenExp}
          setTokenController={setTokenController}
          setToken={setToken}
          handleLogout={handleLogout}
          setMenuRes={setMenuRes}
        />
      ) : null}
    </>
  );
};

export default Navbar;
