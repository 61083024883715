import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  container: {
    width: '540px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    marginTop: '4px',
  },
  containerTable: {
    width: '100%',
    border: '1px solid black',
    borderRadius: '8px',
    height: '100px',
    display: 'flex',
  },

  containerColumns: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid black',
    maxWidth: '100%',
    width: '100%',
    height: '25px',
  },
  containerColumns2: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid black',
    maxWidth: '75%',
    height: '15px',
  },
  containerColumns3: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '100%',
    height: '15px',
  },

  montoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '145.5px',
    height: '83.5px',
    backgroundColor: 'white',
    justifyContent: 'center',
    gap: '5px',
    borderTopRightRadius: '8px',
    padding: '6px 0px 6px 0px',
    borderBottom: '1px solid black',
  },

  montoContainer2: {
    display: 'flex',
    flexDirection: 'column',
    width: '145.5px',
    height: '86.5px',
    backgroundColor: 'white',
    justifyContent: 'center',
    gap: '5px',
    borderTopRightRadius: '8px',
    padding: '6px 0px 6px 0px',
    borderBottom: '1px solid black',
  },

  montoContainerRows: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '2px',
    justifyContent: 'space-between',
    marginRight: '2px',
  },

  montoContainerText: {
    width: '50%',
  },
  montoContainerNumber: {
    width: '50%',
    textAlign: 'right',
  },

  columnView: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '80px',
  },
  columnViewItem: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '21.5px',
  },
  columnViewItem2: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '21.5px',
  },
  columnViewCod: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '45px',
  },
  columnViewCod2: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '32px',
  },
  columnViewDetalle: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '285px',
  },
  columnViewDetalle2: {
    display: 'flex',
    textAlign: 'start',
    alignItems: 'start',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '284px',
    paddingLeft: '2px',
  },
  columnViewUnidad: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '26px',
  },
  columnViewUnidad2: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '26px',
  },
  columnViewExc: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '63px',
  },

  columnViewExc2: {
    display: 'flex',
    textAlign: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'center',
    borderRight: '1px solid black',
    paddingRight: '2px',
    width: '63px',
  },
  columnViewPrecio: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '59px',
  },
  columnViewImporte: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '60px',
  },
  columnViewImporte2: {
    display: 'flex',
    textAlign: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '60px',
    paddingRight: '2px',
  },
  columnViewPrecio2: {
    display: 'flex',
    textAlign: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '59px',
    paddingRight: '2px',
  },
  columnView2: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '65px',
  },
  columnView2Es: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '325px',
  },
  columnView2Totalbs: {
    display: 'flex',
    textAlign: 'end',
    alignItems: 'flex-end',
    justifyContent: 'center',
    borderRight: '1px solid black',
    width: '100%',
  },
  font: {
    fontSize: '6.5px',
  },
  fontBold: {
    fontSize: '7px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  fontTotal: {
    fontSize: '8px',
  },
});
const FacturaPlan = ({ factura }) => {
  let productos = factura?.detalle;
  const facturaFiltrada = productos?.filter(item => item.in_im_dt_fc === true);
  const totalSubTotal = factura?.detalle[0]?.tg_su;
  const totalTotal = factura?.detalle[0]?.tg_tt;
  const totalExento = factura?.detalle[0]?.tg_ex;
  const totalDescuento = factura?.detalle[0]?.tg_ds;
  const totalIva = factura?.detalle[0]?.tg_iv;
  const totalBaseImponible = factura?.detalle[0]?.tg_bi;

  let montoTotalExentoRedondeado = totalExento?.toFixed(2);
  let montoTotalExentoFormateado = parseFloat(
    montoTotalExentoRedondeado
  ).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let montoTotalSubtotalRedondeado = totalSubTotal?.toFixed(2);
  let montoTotalSubtotalFormateado = parseFloat(
    montoTotalSubtotalRedondeado
  ).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let montoTotalTotalRedondeado = totalTotal?.toFixed(2);
  let montoTotalTotalFormateado = parseFloat(
    montoTotalTotalRedondeado
  ).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let montoTotalDescuentoRedondeado = totalDescuento?.toFixed(2);
  let montoTotalDescuentoFormateado = parseFloat(
    montoTotalDescuentoRedondeado
  ).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let montoTotalBaseImponibleRedondeado = totalBaseImponible?.toFixed(2);
  let montoTotalBaseImponibleFormateado = parseFloat(
    montoTotalBaseImponibleRedondeado
  ).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let montoTotalIvaRedondeado = totalIva?.toFixed(2);
  let montoTotalIvaFormateado = parseFloat(
    montoTotalIvaRedondeado
  ).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div style={styles.container}>
      <View>
        <Text style={styles.font}>{factura?.ds_pq}</Text>
      </View>
      <div style={styles.containerTable}>
        <div style={styles.containerColumns}>
          <View style={styles.columnViewItem}>
            <Text style={styles.font}>#</Text>
          </View>
          <View style={styles.columnViewCod}>
            <Text style={styles.font}>C&Oacute;DIGO</Text>
          </View>
          <View style={styles.columnViewDetalle}>
            <Text style={styles.font}>DESCRIPCI&Oacute;N</Text>
          </View>
          <View style={styles.columnViewUnidad}>
            <Text style={styles.font}>UND</Text>
          </View>
          <View style={styles.columnViewPrecio}>
            <Text style={styles.font}>PRECIO</Text>
            <Text style={styles.font}>UNITARIO</Text>
          </View>

          <div
            style={
              productos?.length >= 5
                ? styles.montoContainer2
                : styles.montoContainer
            }
          >
            <div style={styles.montoContainerRows}>
              <View style={styles.montoContainerText}>
                <Text style={styles.fontBold}>BASE IMPONIBLE:</Text>
              </View>
              <View style={styles.montoContainerNumber}>
                <Text style={styles.font}>
                  {' '}
                  {montoTotalBaseImponibleFormateado}
                </Text>
              </View>
            </div>
            <div style={styles.montoContainerRows}>
              <View style={styles.montoContainerText}>
                <Text style={styles.fontBold}>EXENTO:</Text>
              </View>
              <View style={styles.montoContainerNumber}>
                <Text style={styles.font}> {montoTotalExentoFormateado}</Text>
              </View>
            </div>
            <div style={styles.montoContainerRows}>
              <View style={styles.montoContainerText}>
                <Text style={styles.fontBold}>SUB-TOTAL:</Text>
              </View>
              <View style={styles.montoContainerNumber}>
                <Text style={styles.font}> {montoTotalSubtotalFormateado}</Text>
              </View>
            </div>
            <div style={styles.montoContainerRows}>
              <View style={styles.montoContainerText}>
                <Text style={styles.fontBold}>DESCUENTO:</Text>
              </View>
              <View style={styles.montoContainerNumber}>
                <Text style={styles.font}>
                  {' '}
                  {montoTotalDescuentoFormateado}
                </Text>
              </View>
            </div>
            <div style={styles.montoContainerRows}>
              <View style={styles.montoContainerText}>
                <Text style={styles.fontBold}>I.V.A.(16,00) %:</Text>
              </View>
              <View style={styles.montoContainerNumber}>
                <Text style={styles.font}> {montoTotalIvaFormateado}</Text>
              </View>
            </div>
            <div style={styles.montoContainerRows}>
              <View style={styles.montoContainerText}>
                <Text style={styles.fontBold}>TOTAL:</Text>
              </View>
              <View style={styles.montoContainerNumber}>
                <Text style={styles.fontTotal}>
                  {' '}
                  <Text style={styles.font}> {montoTotalTotalFormateado}</Text>
                </Text>
              </View>
            </div>
          </div>
        </div>

        {productos?.map((x, indice) => {
          let montoBaseRedondeado = x?.mt_dt_fc.toFixed(2);
          let montoBaseFormateado = parseFloat(
            montoBaseRedondeado
          ).toLocaleString('es-VE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          let montoBasexCantRedondeado = x?.ex_dt_fc.toFixed(2);
          let montoBasexCantFormateado = parseFloat(
            montoBasexCantRedondeado
          ).toLocaleString('es-VE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          let montoExentoRedondeado = x?.ct_x_mt.toFixed(2);
          let montoExentoFormateado = parseFloat(
            montoExentoRedondeado
          ).toLocaleString('es-VE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          if (x?.in_im_dt_fc === true) {
            return (
              <div style={styles.containerColumns2} key={indice}>
                <View style={styles.columnViewItem2}>
                  <Text style={styles.font}>{x.fila}</Text>
                </View>
                <View style={styles.columnViewCod}>
                  <Text style={styles.font}>{x.cd_dt_fc}</Text>
                </View>
                <View style={styles.columnViewDetalle2}>
                  <Text style={styles.font}>{x.ds_dt_fc}</Text>
                </View>
                <View style={styles.columnViewUnidad2}>
                  <Text style={styles.font}>{x.ct_dt_fc}</Text>
                </View>
                <View style={styles.columnViewPrecio2}>
                  <Text style={styles.font}>{montoBaseFormateado}</Text>
                </View>
              </div>
            );
          }
        })}

        {facturaFiltrada?.length < 1 ? (
          <div>
            <div style={styles.containerColumns2}>
              <View style={styles.columnViewItem2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewCod}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewDetalle2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewUnidad2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewPrecio2}>
                <Text style={styles.font}></Text>
              </View>
            </div>
            <div style={styles.containerColumns2}>
              <View style={styles.columnViewItem2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewCod}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewDetalle2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewUnidad2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewPrecio2}>
                <Text style={styles.font}></Text>
              </View>
            </div>
          </div>
        ) : null}

        {facturaFiltrada?.length < 2 ? (
          <div>
            <div style={styles.containerColumns2}>
              <View style={styles.columnViewItem2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewCod}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewDetalle2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewUnidad2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewPrecio2}>
                <Text style={styles.font}></Text>
              </View>
            </div>
            <div style={styles.containerColumns2}>
              <View style={styles.columnViewItem2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewCod}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewDetalle2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewUnidad2}>
                <Text style={styles.font}></Text>
              </View>
              <View style={styles.columnViewPrecio2}>
                <Text style={styles.font}></Text>
              </View>
            </div>
          </div>
        ) : null}

        {facturaFiltrada?.length < 3 ? (
          <div style={styles.containerColumns2}>
            <View style={styles.columnViewItem2}>
              <Text style={styles.font}></Text>
            </View>
            <View style={styles.columnViewCod}>
              <Text style={styles.font}></Text>
            </View>
            <View style={styles.columnViewDetalle2}>
              <Text style={styles.font}></Text>
            </View>
            <View style={styles.columnViewUnidad2}>
              <Text style={styles.font}></Text>
            </View>
            <View style={styles.columnViewPrecio2}>
              <Text style={styles.font}></Text>
            </View>
          </div>
        ) : null}

        <div style={styles.containerColumns2}>
          <View style={styles.columnView2Totalbs}>
            <Text style={styles.font}>{factura?.MT_LETRA}</Text>
          </View>
        </div>
        <div style={styles.containerColumns3}>
          <View></View>
        </div>
      </div>
    </div>
  );
};
export default FacturaPlan;
