import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import RCVLogo from '../../../styles/images/f7c38f45-fb8c-44ea-8d00-62d7d2427a4d (1).jpg';
const styles = StyleSheet.create({
  container: {
    width: '540px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'flex-start',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
  },
  containerSon2: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerEsp: {
    width: '60px',
  },
  image: {
    width: '100px',
    height: '60px',
    justifySelf: 'start',
  },
  font: {
    fontFamily: 'Arial',
    fontStyle: 'bold',
    fontSize: '10px',
  },
  font2: {
    fontFamily: 'Arial',
    fontStyle: 'bold',
    fontSize: '14px',
    color: 'red',
  },
});
const FacturaLogo = ({ factura }) => {
  const numeroFormateado = String(factura?.nr_cn).padStart(8, '0');
  return (
    <div style={styles.container}>
      <Image style={styles.image} src={RCVLogo} />
      <div style={styles.containerSon}>
        <div div style={styles.containerSon2}>
          <View style={styles.containerEsp}>
            <Text style={styles.font}>
              FACTURA SERIE {factura?.cd_co.split('-')[0]}
            </Text>
          </View>
          <View>
            <Text style={styles.font2}>Nº {factura?.CD_FACTURA}</Text>
          </View>
        </div>
        <div div style={styles.containerSon2}>
          <View>
            <Text style={styles.font}>No. de CONTROL 00</Text>
          </View>
          <View>
            <Text style={styles.font2}>―{numeroFormateado}</Text>
          </View>
        </div>
      </div>
    </div>
  );
};
export default FacturaLogo;
