import React, { useState, useEffect } from 'react';
import validarRecuperacion from '../../Login/Helpers/ValidarRecuperacion';
import NavbarErrors from '../../NavbarLite/NavbarErrors';
import { Link } from 'react-router-dom';
import ModalCorrecto from '../../ModalCorrecto/ModalCorrecto';

const RecuperaContrasena = () => {
  const [email, setEmail] = useState('');
  const [confirmacion, setConfirmacion] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);
  const [captchaValido, setCaptchaValido] = useState(false);
  const [captchaResult, setCaptchaResult] = useState('');
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [selected, setSelected] = useState('');
  const style = {
    fontWeight: 700,
    top: '-16px',
    left: '0',
    fontSize: '0.8em',
    backgroundColor: 'transparent',
    color: 'black',
  };

  useEffect(() => {
    generarCaptcha();
  }, []);

  const generarCaptcha = () => {
    const numero1 = Math.floor(Math.random() * 10);
    const numero2 = Math.floor(Math.random() * 10);
    setNum1(numero1);
    setNum2(numero2);
  };

  const validacionRecuperarContraseña = data => {
    let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    let errors = {};
    if (data.length < 1) {
      errors.data = 'Por favor escriba su direccion de correo electronico';
    }

    if (!regexEmail.test(data)) {
      errors.data =
        'El formato de correo electronico no es el correcto, por favor verifique.';
    }

    if (data?.length > 100) {
      errors.data = 'Su correo no debe superar los 100 caracteres.';
    }

    return errors;
  };

  const enviarCorreo = async e => {
    setLoading(true);
    e.preventDefault();
    const validar = validacionRecuperarContraseña(email);
    if (Object.keys(validar).length > 0) {
      setNavbarErrores(validar);
      setMostrarErrores(true);
      setLoading(false);
      generarCaptcha();
    } else {
      let validacion = await validarRecuperacion(email, setNavbarErrores);
      setConfirmacion(validacion);
    }
  };

  useEffect(() => {
    if (confirmacion === true) {
      setMensajeCorrecto(
        'Listo, revisa tu correo e inicia sesion con el codigo que te hemos dado'
      );
      setMostrarModalCorrecto(true);
      setTimeout(() => {
        setLoading(false);
        window.location.href = '/login/1';
      }, 2000);
    }

    if (confirmacion === false) {
      setMostrarErrores(true);
      generarCaptcha();
      setTimeout(() => {
        setLoading(false);
        setConfirmacion(null);
      }, 1000);
    }
  }, [confirmacion]);

  const handleCaptchaChange = e => {
    setCaptchaResult(e.target.value);
    if (parseInt(e.target.value, 10) === num1 + num2) {
      setCaptchaValido(true);
    } else {
      setCaptchaValido(false);
    }
  };

  return (
    <div className="recuperaContraseña">
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />

      <div className="recuperaContraseña__body">
        <div className="recuperaContraseña__body-info">
          <h2>
            Recupera tu <span>contraseña</span>.
          </h2>
          <h5>
            Para recuperar tu contraseña, debes colocar tu correo
            electr&oacute;nico y resolver la operaci&oacute;n matem&aacute;tica
            y recibirás un correo electr&oacute;nico con la informaci&oacute;n
            necesaria.
          </h5>
          <label>
            <span style={selected === 'em_us' || email ? style : null}>
              Correo electr&oacute;nico
            </span>
            <input
              type="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
              onFocus={() => setSelected('em_us')}
              onBlur={() => setSelected('')}
            />
          </label>
          <div className="captcha">
            <span>Resuelve el captcha para continuar</span>
            <div className="captcha__img">
              <span>{`${num1} + ${num2} =`}</span>{' '}
              <input
                type="number"
                onChange={handleCaptchaChange}
                value={captchaResult}
              />
            </div>
          </div>
        </div>
        <div className="recuperaContraseña__body-footer">
          <div></div>
          <div className="recuperaContraseña__body-footer-botones">
            <Link to="/login/1">Anterior</Link>
            <button
              className={!captchaValido ? 'invalido' : ''}
              disabled={loading || !captchaValido}
              onClick={enviarCorreo}
            >
              {loading ? (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              ) : (
                'ENVIAR'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecuperaContrasena;
