import React from 'react';
import { useEffect, useState, useContext } from 'react';
import validationsFormCliente from '../../../../../pages/Validators/validationsFormCliente';
import { postClient } from '../../../../../helpers/postCliente';
import FormularioCliente from '../../Cliente/FormularioCliente';
import { TokenContext } from '../../../../../context/TokenContext';
import consultarApi from '../../../../../helpers/consultarApi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';

const ClienteEdicion = ({
  errors,
  setErrors,
  setErrorAdvice,
  setMostrarErrores,
  setNavbarErrores,
  errorAdvice,
  datosCliente,
  setShowEdition,
}) => {
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [isChecked, setIsChecked] = useState(true);
  const [cargandoMunicipios, setCargandoMunicipios] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [edition] = useState(true);
  const [cargandoEstados, setCargandoEstados] = useState(true);
  const [cargandoOperadora, setCargandoOperadora] = useState(true);
  const [propietarioVeri] = useState(false);
  const [estados, setEstados] = useState([]);
  const [opeCel, setOpeCel] = useState([]);
  const [aplicarMunicipio, setAplicarMunicipio] = useState(false);
  const [mostrarAgregarMunicipio, setMostrarAgregarMunicipio] = useState({
    estado: false,
    valorEstado: '',
    valorMunicipio: '',
  });
  const [errorVer, setErrorVer] = useState(undefined);
  let fcDefNac = new Date();
  fcDefNac.setMonth(fcDefNac.getMonth() - 216);

  const {
    cd_cl,
    dr_cl,
    ds_mn,
    em_cl,
    es_sel,
    fc_cl,
    nb_cl,
    op_sel,
    se_cl,
    tf_cl_cl_01,
    ap_cl,
    rz_cl,
    id_es,
    id_op,
    tp_cl,
    mn_sel,
  } = datosCliente;

  const [formData, setFormData] = useState({
    cd_cl,
    nb_cl,
    ap_cl,
    rz_cl,
    fc_cl,
    id_es,
    es_sel,
    ds_mn,
    se_cl,
    dr_cl,
    em_cl,
    id_op,
    op_sel,
    tf_cl_cl_01,
    tp_cl,
  });

  const [municipioSeleccionado, setMunicipioSeleccionado] = useState(mn_sel);
  const [selectedEstado, setSelectedEstado] = useState(es_sel);
  const [selectedOperadora, setSelectedOperadora] = useState(op_sel);

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value.toUpperCase();
    setErrorAdvice(0);
    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    let formErrors = validationsFormCliente(formData);
    if (Object.keys(formErrors).length > 0) {
      setNavbarErrores(formErrors);
      setMostrarErrores(true);
      setErrorAdvice(1);
    } else {
      setErrorAdvice(2);

      postClient(
        formData,
        propietarioVeri,
        setErrorVer,
        setNavbarErrores,
        setToken,
        setTokenVencido,
        setTokenCambiar
      );
    }
  };

  useEffect(() => {
    if (errorVer === true) {
      setMostrarErrores(true);
      setErrorVer(undefined);
    }

    if (errorVer === false) {
      localStorage.setItem('RCV_DIGITAL_CLIENTE', JSON.stringify(formData));
      setErrorVer(undefined);
      setShowEdition(false);
    }
  }, [errorVer]);

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_ESTADOS,
      token,
      0,
      setEstados,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
    consultarApi(
      process.env.REACT_APP_GET_ALL_OPERADORAS_CELULAR,
      token,
      0,
      setOpeCel,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);

  useEffect(() => {
    const [data] = selectedEstado;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedEstado,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        es_sel: [],
        id_es: '',
      });
    }
  }, [selectedEstado]);

  useEffect(() => {
    const [data] = selectedOperadora;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedOperadora,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        op_sel: [],
        id_op: '',
      });
    }
  }, [selectedOperadora]);

  useEffect(() => {
    if (aplicarMunicipio) {
      const urlMunicipios = `v1/getCombo?p1=${selectedEstado[0].value}&p2=&p3=&p4=&op=3&token=`;
      consultarApi(
        urlMunicipios,
        0,
        setMunicipios,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
      setAplicarMunicipio(false);
    }
  }, [selectedEstado, aplicarMunicipio]);

  const buscarOperadora = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_OPERADORAS_CELULAR,
      0,
      setOpeCel,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoOperadora(false);
      }, 2000)
    );
  };

  const buscarEstados = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_ESTADOS,
      0,
      setEstados,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoEstados(false);
      }, 2000)
    );
  };

  const buscarMunicipios = () => {
    const urlMunicipios = `v1/getCombo?p1=${selectedEstado[0].value}&p2=&p3=&p4=&op=3&token=`;
    consultarApi(
      urlMunicipios,
      0,
      setMunicipios,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoMunicipios(false);
      }, 2000)
    );
  };

  const limpiar = tp_cl => {
    setFormData({
      ...formData,
      cd_cl: '',
      nb_cl: '',
      ap_cl: '',
      rz_cl: '',
      tp_cl: tp_cl,
    });
  };
  return (
    <div className="clienteEdicion">
      <div className={`clienteEdicion__cliente  `}>
        <div className="clienteEdicion__cliente-header">
          <h2>
            Edita los datos del <span>Cliente</span>
          </h2>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">No editar</Tooltip>}
          >
            <IoClose onClick={() => setShowEdition(false)} cursor={'pointer'} />
          </OverlayTrigger>
        </div>

        <FormularioCliente
          estados={estados}
          formData={formData}
          errors={errors}
          handleChange={handleChange}
          selectedEstado={selectedEstado}
          setSelectedEstado={setSelectedEstado}
          selectedOperadora={selectedOperadora}
          setSelectedOperadora={setSelectedOperadora}
          opeCel={opeCel}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          limpiar={limpiar}
          buscarEstados={buscarEstados}
          buscarOperadora={buscarOperadora}
          cargandoEstados={cargandoEstados}
          cargandoOperadora={cargandoOperadora}
          cargandoMunicipios={cargandoMunicipios}
          municipios={municipios}
          buscarMunicipios={buscarMunicipios}
          municipioSeleccionado={municipioSeleccionado}
          setMunicipioSeleccionado={setMunicipioSeleccionado}
          setMostrarAgregarMunicipio={setMostrarAgregarMunicipio}
          aplicarMunicipio={aplicarMunicipio}
        />
        <div className="proceso__botones">
          <div></div>
          <div className="proceso__botones-contenedor">
            <button className="cancelar" onClick={() => setShowEdition(false)}>
              ATRAS
            </button>
            <button className="siguiente" onClick={handleSubmit}>
              ACEPTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClienteEdicion;
