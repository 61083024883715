import { BsFillPenFill } from 'react-icons/bs';
import ResumenVehiculoBody from './ResumenVehiculoBody';
import VehiculoEdicion from './ComponentesEdicion/VehiculoEdicion';
const VehiculoResume = ({
  datosVehiculo,
  setShowEdition,
  setComponenteEdit,
  setMostrarErrores,
  setNavbarErrores,
  setTokenController,
  setTokenExp,
  token,
  errors,
  setErrors,
  setErrorAdvice,
  errorAdvice,
  setToken,
}) => {
  const {
    an_vh,
    mc_sel,
    md_sel,
    nr_pu_vh,
    pe_vh,
    pl_vh,
    se_ca_vh,
    se_mo_vh,
    tp_sel,
    uso_sel,
    cl_vh,
  } = datosVehiculo;

  return (
    <div className="resumen__items elegido">
      <div className="resumen__items-header">
        <h5>Datos del veh&iacute;culo</h5>
        <button
          onClick={() => {
            setComponenteEdit(
              <VehiculoEdicion
                errors={errors}
                setErrors={setErrors}
                setErrorAdvice={setErrorAdvice}
                setTokenController={setTokenController}
                setTokenExp={setTokenExp}
                token={token}
                setMostrarErrores={setMostrarErrores}
                setNavbarErrores={setNavbarErrores}
                errorAdvice={errorAdvice}
                datosVehiculo={datosVehiculo}
                setShowEdition={setShowEdition}
                setToken={setToken}
              />
            );
            setShowEdition(true);
          }}
        >
          <BsFillPenFill />
        </button>
      </div>
      <ResumenVehiculoBody
        an_vh={an_vh}
        nr_pu_vh={nr_pu_vh}
        pe_vh={pe_vh}
        pl_vh={pl_vh}
        se_ca_vh={se_ca_vh}
        se_mo_vh={se_mo_vh}
        mc_sel={mc_sel}
        md_sel={md_sel}
        tp_sel={tp_sel}
        uso_sel={uso_sel}
        cl_vh={cl_vh}
      />
    </div>
  );
};

export default VehiculoResume;
