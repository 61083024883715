import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import RCVLogo from '../../../styles/images/f7c38f45-fb8c-44ea-8d00-62d7d2427a4d (1).jpg';
import RCVSello from '../../../styles/images/1e7ba4ee-6637-44fe-9ec1-61050f20e7a4-removebg-preview.png';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRight: '1px dashed black',
  },
  containerElements: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '6px',
  },
  containerDatos: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  containerFirma: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '0',
    right: '60px',
    height: '98px',
  },
  containerFirmaSite: {
    borderTop: '1px solid black',
    width: '130px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFirmaAño: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '130px',
  },
  containerOfi: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '200px',
  },
  containerQr: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5px',
  },
  font: {
    fontSize: '6px',
    fontStyle: 'normal',
  },
  fontBold: {
    fontSize: '6px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  img: {
    height: '80px',
    width: '80px',
  },
  imgFondo: {
    height: '100px',
    width: '150px',
    position: 'absolute',
    right: '60px',
    top: '5px',
    zIndex: '-2',
    opacity: 0.1,
  },
  imgSello: {
    height: '70px',
    width: '110px',
    position: 'absolute',
    right: '65px',
    top: '5px',
    opacity: 0.8,
  },
  imgSelloFac: {
    width: '120px',
    position: 'absolute',
    left: '0px',
    top: '10px',
    opacity: 0.8,
  },
});
const FacturaTicketVehi = ({ factura, imagenqr }) => (
  <div style={styles.container}>
    <div style={styles.containerElements}>
      <div style={styles.containerDatos}>
        <View>
          <Text style={styles.fontBold}>
            PLACA:{' '}
            <Text style={styles.font}>{factura?.pl_vh.toUpperCase()}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            MARCA: <Text style={styles.font}>{factura?.mc_vh}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            MODELO: <Text style={styles.font}>{factura?.md_vh}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            COLOR: <Text style={styles.font}>{factura?.co_vh}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            CLASE: <Text style={styles.font}>{factura?.ds_cl_vh}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            TIPO: <Text style={styles.font}>{factura?.ds_tp_vh}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            USO: <Text style={styles.font}>{factura?.ds_uso}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            S. CARR:{' '}
            <Text style={styles.font}>{factura?.se_ca_vh.toUpperCase()}</Text>
          </Text>
        </View>
        <View>
          <Text style={styles.fontBold}>
            TELEFONO: <Text style={styles.font}>{factura?.TF_CLIENTE}</Text>
          </Text>
        </View>
      </div>
      <div style={styles.containerFirma}>
        <View style={styles.containerFirmaAño}>
          <Text style={styles.fontBold}>AÑO: {factura?.an_vh}</Text>
        </View>
        <Image style={styles.imgSelloFac} src={RCVSello} />
        <View style={styles.containerFirmaSite}>
          <Text style={styles.fontBold}>firma y sello</Text>
        </View>
      </div>
      <div style={styles.containerQr}>
        <View>
          <Text style={styles.fontBold}>CLIENTE</Text>
        </View>
        {imagenqr && <Image style={styles.img} src={imagenqr} />}
      </div>
    </div>
    <View style={styles.containerOfi}>
      <Text style={styles.font}>
        Oficina Principal: Av.8 Santa Rita Entre Calle 63 Y 64 Nº 63-49 Al Lado
        De Ferreteria Tanoca Telfs: (+58) - 0261-7193919
      </Text>
    </View>
    <Image style={styles.imgFondo} src={RCVLogo} />
    {/**  <Image style={styles.imgSello} src={RCVSello} />*/}
  </div>
);
export default FacturaTicketVehi;
