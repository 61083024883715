import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    position: "absolute",
    right: "30px",
    top: "80px",
    width: "80px",
  },
  containerSon: {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
  },
  font: {
    fontSize: "8px",
  },
  fontBold: {
    fontSize: "8px",
    fontFamily: "Arial",
    fontStyle: "bold",
  },
});

const ContratoFecha = ({contratoData}) => (
  <div style={styles.container}>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>DESDE:</Text>
      </View>
      <View>
        <Text style={styles.font}>{contratoData?.fc_inicio}</Text>
      </View>
    </div>

    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>HASTA:</Text>
      </View>
      <View>
        <Text style={styles.font}>{contratoData?.fc_final}</Text>
      </View>
    </div>
  </div>
);
export default ContratoFecha;
