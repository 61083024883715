import axios from '../api/axios';
import { getToken } from './getToken';

const updateUser = async ({
  nombre,
  apellido,
  setTokenCambiar,
  setTokenVencido,
  setNavbarErrores,
  setMostrarErrores,
}) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const userProfileValue = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_PROFILEOBJ')
  );
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));

  const json = JSON.stringify({
    usuario: {
      id_us: userProfileValue.id_us,
      nb_us: nombre.toUpperCase(),
      ap_us: apellido.toUpperCase(),
      em_us: userProfileValue.em_us.toUpperCase(),
      sx_us: userProfileValue.sx_us.toUpperCase(),
    },
    trace: '0',
    token: token,
    ori: 'web',
  });

  const handleResponse = async data => {
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    const jsonUpdater = JSON.stringify({
      ap_us: apellido.toUpperCase(),
      em_us: userProfileValue.em_us.toUpperCase(),
      id_us: userProfileValue.id_us,
      nb_us: nombre.toUpperCase(),

      oficina: {
        cd_of: userProfileValue.oficina.cd_of,
        ds_of: userProfileValue.oficina.ds_of,
        id_of: userProfileValue.oficina.id_of,
      },
      ori: null,
      pw_us: null,
      rol: {
        cd_ro: userProfileValue.rol.cd_ro,
        ds_ro: userProfileValue.rol.ds_ro,
        id_ro: userProfileValue.rol.id_ro,
      },
      sx_us: userProfileValue.sx_us,
      tp_us: 1,
      us_us: null,
    });
    localStorage.setItem('RCV_DIGITAL_PROFILEOBJ', jsonUpdater);
  };

  try {
    const data = await axios.post(process.env.REACT_APP_UPDATE_USER, json);
    handleResponse(data);
    return true;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      setTokenCambiar(true);
      setTokenVencido(error.response.data.token);
      console.log(error.response.data.token);
    } else {
      setNavbarErrores(error.response.data.message);
      setMostrarErrores(true);
      throw error;
    }
  }
};

export default updateUser;
