import { IoClose } from 'react-icons/io5';
import { useState, useContext, useEffect } from 'react';
import postMarca from '../../../../helpers/postMarca';
import { LoginContext } from '../../../../context/LoginContext';
import postModelo from '../../../../helpers/postModelo';
import { FaCheck } from 'react-icons/fa';
import { TokenContext } from '../../../../context/TokenContext';

const AgregarMarca = ({
  setMostrarAgregarMarca,
  setAgregarCompleto,
  setAplicarMarca,
  mostrarAgregarMarca,
}) => {
  const { setTokenCambiar, setTokenVencido, setToken } =
    useContext(TokenContext);
  const [marcaVeri, setMarcaVeri] = useState(false);
  const [modeloVeri, setModeloVeri] = useState(false);
  const [loadingMarca, setLoadingMarca] = useState(false);
  const [loadingModelo, setLoadingModelo] = useState(false);
  const [errorVer, setErrorVer] = useState(false);
  const [marcaJSON, setMarcaJSON] = useState({
    ds_mc: '',
  });

  const [modeloJSON, setModeloJSON] = useState({
    id_mc: '',
    ds_md: '',
  });

  const { setNavbarErrores, setMostrarErrores } = useContext(LoginContext);

  const verificarMarca = form => {
    let errors = {};
    let caracteresEspeciales = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (form.ds_mc.length > 25) {
      errors.ds_mc = 'Excedes el número de caracteres permitidos';
    }
    if (caracteresEspeciales.test(form.ds_mc)) {
      errors.ds_mc = 'No se permiten caracteres especiales';
    }
    if (form.length === 0 || form.ds_mc === '') {
      errors.ds_mc = 'Debe indicar la marca del vehículo, por favor verifique.';
    }
    return errors;
  };

  const verificarModelo = form => {
    let errors = {};
    let caracteresEspeciales = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (form.ds_md.length > 25) {
      errors.ds_md = 'Excedes el número de caracteres permitidos';
    }
    if (caracteresEspeciales.test(form.ds_md)) {
      errors.ds_md = 'No se permiten caracteres especiales';
    }

    if (form.length === 0 || form.ds_md === '') {
      errors.ds_md =
        'Debe indicar el modelo del vehículo, por favor verifique.';
    }
    return errors;
  };

  const agregar = async e => {
    e.preventDefault();
    const validadorMarca = verificarMarca(marcaJSON);
    const validadorModelo = verificarModelo(modeloJSON);
    const errores = [
      ...Object.values(validadorMarca),
      ...Object.values(validadorModelo),
    ];
    if (errores.length > 0) {
      setNavbarErrores(errores);
      setMostrarErrores(true);
    } else {
      setLoadingMarca(true);
      setLoadingModelo(true);
      let marca = JSON.parse(localStorage.getItem('RCV_DIGITAL_MARCA_NUEVA'));
      setTimeout(async () => {
        if (marca === null) {
          setErrorVer(false);
          postMarca(
            marcaJSON,
            setNavbarErrores,
            setTokenCambiar,
            setTokenVencido,
            setMostrarErrores,
            setMarcaVeri,
            setToken,
            setMostrarAgregarMarca
          ).finally(setLoadingMarca(false));
        } else {
          setTimeout(() => {
            postModelo(
              modeloJSON,
              setNavbarErrores,
              setTokenCambiar,
              setMostrarErrores,
              setTokenVencido,
              setModeloVeri,
              setMostrarAgregarMarca,
              setAgregarCompleto,
              setAplicarMarca,
              setToken
            ).finally(setLoadingModelo(false));
          }, 2000);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (mostrarAgregarMarca.estado) {
      setMarcaJSON({
        ds_mc: mostrarAgregarMarca?.valorMarca.toUpperCase(),
      });
    }
  }, [mostrarAgregarMarca]);

  useEffect(() => {
    if (errorVer === true) {
      setMostrarErrores(true);
      setLoadingModelo(false);
    }
  }, [errorVer]);

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = value.toUpperCase();
    if (name === 'ds_mc') {
      setMarcaJSON({
        ...marcaJSON,
        [name]: newValue,
      });
    }

    if (name === 'ds_md') {
      setModeloJSON({
        ...modeloJSON,
        [name]: newValue,
      });
    }
  };

  useEffect(() => {
    if (marcaVeri) {
      const validador = verificarModelo(modeloJSON);
      if (Object.keys(validador).length > 0) {
      } else {
        setTimeout(() => {
          postModelo(
            modeloJSON,
            setNavbarErrores,
            setTokenCambiar,
            setErrorVer,
            setTokenVencido,
            setModeloVeri,
            setMostrarAgregarMarca,
            setAgregarCompleto,
            setAplicarMarca,
            setToken
          ).finally(setLoadingModelo(false));
        }, 2000);
        setTimeout(() => {
          localStorage.removeItem('RCV_DIGITAL_MARCA_GUARDADA');
        }, 6000);
      }
    }
  }, [marcaVeri]);

  useEffect(() => {
    let marca = JSON.parse(localStorage.getItem('RCV_DIGITAL_MARCA_NUEVA'));
    if (marca != null) {
      setMarcaJSON({
        ds_mc: marca.ds_mc,
      });
      setMarcaVeri(true);
    }
  }, []);

  return (
    <div className="agregarMarca">
      <div
        className="agregarMarca__sombra"
        onClick={() =>
          setMostrarAgregarMarca({
            estado: false,
            valorMarca: '',
            valorModelo: '',
          })
        }
      ></div>
      <div className="agregarMarca__card">
        <div className="agregarMarca__card-header">
          <span className="agregarMarca__card-header-titulo">
            Agregar <span>Marca</span> y <span>Modelo</span>
          </span>
          <span
            className="agregarMarca__card-header-boton"
            onClick={() =>
              setMostrarAgregarMarca({
                estado: false,
                valorMarca: '',
                valorModelo: '',
              })
            }
          >
            <IoClose />
          </span>
        </div>
        <form className="agregarMarca__card-form" onSubmit={agregar}>
          <label>Marca</label>
          <div className="agregarMarca__card-form-contenedor">
            <input
              className={`agregarMarca__card-form-contenedor-marca ${
                marcaVeri ? 'sucess' : ''
              }`}
              type="text"
              name="ds_mc"
              onChange={handleChange}
              value={marcaJSON.ds_mc}
              disabled={marcaVeri ? true : false}
            />

            <FaCheck
              className={`agregarMarca__card-form-contenedor-check ${
                marcaVeri ? 'active' : ''
              }`}
            />
          </div>
          <label>Modelo</label>
          <div className="agregarMarca__card-form-contenedorModelo">
            <input
              className={`agregarMarca__card-form-contenedorModelo-modelo ${
                modeloVeri ? 'sucess' : ''
              }`}
              type="text"
              name="ds_md"
              onChange={handleChange}
              value={modeloJSON.ds_md}
              disabled={modeloVeri ? true : false}
            />

            <FaCheck
              className={`agregarMarca__card-form-contenedorModelo-checkmo ${
                modeloVeri ? 'active' : ''
              }`}
            />
          </div>
          <div className="agregarModelo__carta-formulario-footer">
            <button
              className="cancelar"
              onClick={e => {
                e.preventDefault();
                setMostrarAgregarMarca({
                  estado: false,
                  valorMarca: '',
                  valorModelo: '',
                });
              }}
            >
              CANCELAR
            </button>

            <button
              className="aceptar"
              type="submit"
              disabled={loadingModelo || loadingMarca ? true : false}
            >
              {loadingModelo || loadingMarca ? (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              ) : (
                'ACEPTAR'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarMarca;
