import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';

const ReportesPDF = ({
  reporte,
  userProfile,
  fecha,
  hora,
  fechaini,
  fechafin,
}) => {
  return (
    <PDFViewer width="100%" height="1000">
      <PDFDocument
        reporte={reporte}
        userProfile={userProfile}
        fecha={fecha}
        hora={hora}
        fechaini={fechaini}
        fechafin={fechafin}
      />
    </PDFViewer>
  );
};

export default ReportesPDF;
