import React, { useEffect } from 'react';
import mapadevenezuela from '../../styles/images/Logo en png.png';
import { Link } from 'react-router-dom';
import imageRCV from '../../styles/images/IMG_20230727_151123-_2_.webp';
import logoVr from '../../styles/images/Logo en png.png';
const Inicio = () => {
  useEffect(() => {
    localStorage.removeItem('RCV_DIGITAL_PLAN_AN');
    localStorage.removeItem('LAST_LOCATION');
    localStorage.removeItem('PROPIETARIO_ID');
    localStorage.removeItem('CLIENT_ID');
    localStorage.removeItem('RCV_DIGITAL_CLIENTE');
    localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
    localStorage.removeItem('RCV_DIGITAL_VEHICULO');
    localStorage.removeItem('VEHICULO_ID');
    localStorage.removeItem('RCV_DIGITAL_CARRITO');
    localStorage.removeItem('RCV_DIGITAL_REGISTROA');
    localStorage.removeItem('RCV_CODIGO_DESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_PASO');
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_USDESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_LOCATION');
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_CUPON');
    localStorage.removeItem('TOTAL_PAGO');
    localStorage.removeItem('RCV_DIGITAL_PASO_VERIFICADO');
    localStorage.removeItem('RCV_DIGITAL_S/P');
    localStorage.removeItem('RCV_DIGITAL_DESCONTADO');
  }, []);
  return (
    <div className="inicio">
      <div className="inicio__container">
        <div className="inicio__container-header">
          <img
            className="inicio__container-header-logo"
            alt="logo de venezuela responsable"
            src={logoVr}
          />
          <h2>BIENVENIDO</h2>
          <Link to="/proceso/clases">
            <button
              className="dashboardInfo__button"
              style={{ margin: '0px 0px 0px 0px' }}
            >
              COMPRAR
            </button>
          </Link>
          <Link to="/">
            <button
              className="dashboardInfo__button"
              style={{ margin: '20px 0px 0px 0px', backgroundColor: '#074577' }}
            >
              IR A PAGINA PRINCIPAL
            </button>
          </Link>
          <Link to="/dashboard">
            <button className="dashboardInfo__button">
              IR A MIS CONTRATOS
            </button>
          </Link>
        </div>
        <div className="inicio__imgContainer">
          <img
            src={imageRCV}
            alt="mapa de venezuela"
            className="sucursaleshero__imgContenedor-venezuela "
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Inicio;
