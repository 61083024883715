import { useState, useEffect, useContext } from 'react';
import validationsFromVehiculo from '../../../pages/Validators/validationsFromVehiculo';
import { postVehiculo } from '../../../helpers/postVehiculo';
import FormularioVehiculo from './Vehiculo/FormularioVehiculo';
import { Link, useNavigate } from 'react-router-dom';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';
import AgregarModelo from './Vehiculo/AgregarModelo';
import AgregarMarca from './Vehiculo/AgregarMarca';

const Vehiculo = () => {
  const context = useProcesoProvider();

  const {
    errors,
    setErrors,
    setErrorAdvice,
    setPaso,
    handlePrev,
    setMostrarErrores,
    setNavbarErrores,
    errorAdvice,
    cambiarPaso,
  } = context;
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [vehiculoJson] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_VEHICULO'))
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState(
    !vehiculoJson
      ? {
          pl_vh: '',
          mc_vh: '',
          mc_sel: [],
          md_vh: '',
          md_sel: [],
          se_ca_vh: '',
          se_mo_vh: '',
          cl_vh: '',
          nr_pu_vh: '',
          an_vh: '',
          pe_vh: '',
          tp_vh: '',
          tp_sel: [],
          uso_vh: '',
          uso_sel: [],
        }
      : {
          pl_vh: vehiculoJson.pl_vh,
          mc_vh: vehiculoJson.mc_vh,
          mc_sel: vehiculoJson.mc_sel,
          md_vh: vehiculoJson.md_vh,
          md_sel: vehiculoJson.md_sel,
          se_ca_vh: vehiculoJson.se_ca_vh,
          se_mo_vh: vehiculoJson.se_mo_vh,
          cl_vh: vehiculoJson.cl_vh,
          nr_pu_vh: vehiculoJson.nr_pu_vh,
          an_vh: vehiculoJson.an_vh,
          pe_vh: vehiculoJson.pe_vh,
          tp_vh: vehiculoJson.tp_vh,
          tp_sel: vehiculoJson.tp_sel,
          uso_vh: vehiculoJson.uso_vh,
          uso_sel: vehiculoJson.uso_sel,
        }
  );
  const { mc_sel, md_sel, tp_sel, uso_sel } = formData;
  const [selectedMarca, setSelectedMarca] = useState(mc_sel);
  const [selectedModelo, setSelectedModelo] = useState(md_sel);
  const [selectedTipo, setSelectedTipo] = useState(tp_sel);
  const [selectedUso, setSelectedUso] = useState(uso_sel);
  const [marcas, setMarcas] = useState([]);
  const [selected, setSelected] = useState('');
  const [cargandoMarcas, setCargandoMarcas] = useState(false);
  const [cargandoModelos, setCargandoModelos] = useState(false);
  const [cargandoTipos, setCargandoTipos] = useState(false);
  const [marca, setMarca] = useState('');
  const [tipos, setTipos] = useState([]);
  const [usos, setUsos] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [errorVer, setErrorVer] = useState();
  const [mostrarAgregarMarca, setMostrarAgregarMarca] = useState({
    estado: false,
    valorMarca: '',
    valorModelo: '',
  });
  const [mostrarAgregarModelo, setMostrarAgregarModelo] = useState({
    estado: false,
    valorMarca: '',
    valorModelo: '',
  });
  const [aplicarMarca, setAplicarMarca] = useState(false);
  const [aplicarModelo, setAplicarModelo] = useState(false);
  const [agregarCompleto, setAgregarCompleto] = useState(false);

  const buscarUso = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_USOS_VEHICULO,
      0,
      setUsos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoTipos(false);
      }, 500)
    );
  };

  const buscarTipos = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_TIPOS_VEHICULO,
      0,
      setTipos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoTipos(false);
      }, 500)
    );
  };

  const buscarMarca = () => {
    if (marcas.length === 0) {
      setCargandoMarcas(true);
    }
    consultarApi(
      process.env.REACT_APP_GET_ALL_MARCAS,
      0,
      setMarcas,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoMarcas(false);
      }, 500)
    );
  };

  const buscarModelo = () => {
    if (modelos.length === 0) {
      setCargandoModelos(true);
    }
    let url = `v1/getCombo?p1=${marca}&p2=&p3=&p4=&op=9&token=`;
    consultarApi(
      url,
      0,
      setModelos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoModelos(false);
      }, 500)
    );
  };

  const datosCliente = JSON.parse(localStorage.getItem('RCV_DIGITAL_CLIENTE'));

  let paso = datosCliente.soy_pr ? 1 : 2;
  let url = datosCliente.soy_pr ? '/proceso/cliente' : '/proceso/propietario';

  useEffect(() => {
    if (marcas.length != 0 || agregarCompleto === true) {
      let url = `v1/getCombo?p1=${marca}&p2=&p3=&p4=&op=9&token=`;
      consultarApi(
        url,
        0,
        setModelos,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
    }
    if (aplicarModelo) {
      let url = `v1/getCombo?p1=${marca}&p2=&p3=&p4=&op=9&token=`;
      consultarApi(
        url,
        0,
        setModelos,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
      setAplicarModelo(false);
    }
  }, [marca, aplicarModelo]);

  useEffect(() => {
    const [data] = selectedUso;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedUso,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        uso_sel: [],
        uso_vh: '',
      });
    }
  }, [selectedUso]);

  useEffect(() => {
    const [data] = selectedTipo;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedTipo,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        tp_sel: [],
        tp_vh: '',
      });
    }
  }, [selectedTipo]);

  useEffect(() => {
    if (selectedMarca) {
      localStorage.removeItem('RCV_DIGITAL_MARCA_SELECCIONADA');
    }
    const [data] = selectedMarca;
    if (data) {
      const { name, id, value, label } = data;
      const marca = JSON.stringify({
        value,
        label,
      });
      localStorage.setItem('RCV_DIGITAL_MARCA_SELECCIONADA', marca);
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedMarca,
      });
      setMarca(value);

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        mc_sel: [],
        mc_vh: '',
      });
    }

    if (!data) {
      setSelectedModelo([]);
      setModelos([]);
      setFormData({
        pl_vh: formData.pl_vh,
        mc_vh: '',
        mc_sel: [],
        md_vh: '',
        md_sel: [],
        se_ca_vh: formData.se_ca_vh,
        se_mo_vh: formData.se_mo_vh,
        cl_vh: formData.cl_vh,
        nr_pu_vh: formData.nr_pu_vh,
        an_vh: formData.an_vh,
        pe_vh: formData.pe_vh,
        tp_vh: formData.tp_vh,
        tp_sel: formData.tp_sel,
        uso_vh: formData.uso_vh,
        uso_sel: formData.uso_sel,
      });
    }
  }, [selectedMarca]);

  useEffect(() => {
    const [data] = selectedModelo;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedModelo,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        md_sel: [],
        md_vh: '',
      });
    }
  }, [selectedModelo]);

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = value.toUpperCase();
    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (!!errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    let formErrors = validationsFromVehiculo(formData);

    if (Object.keys(formErrors).length > 0) {
      setNavbarErrores(formErrors);
      setMostrarErrores(true);
      setErrorAdvice(1);
    } else {
      postVehiculo(
        formData,
        setErrorVer,
        setNavbarErrores,
        setToken,
        setTokenVencido,
        setTokenCambiar
      );
    }
  };

  useEffect(() => {
    if (errorVer === true) {
      setMostrarErrores(true);
    }

    if (errorVer === false) {
      localStorage.setItem('RCV_DIGITAL_VEHICULO', JSON.stringify(formData));
      localStorage.removeItem('RCV_DIGITAL_MARCA_SELECCIONADA');
      navigate('/proceso/producto');
    }
  }, [errorVer]);

  useEffect(() => {
    cambiarPaso(4, 2);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setErrorAdvice(0);
    }, 1000);
  }, []);

  return (
    <div
      className={`cliente  ${
        errorAdvice === 0
          ? ''
          : errorAdvice === 1
          ? 'error'
          : errorAdvice === 3
          ? 'success'
          : ''
      }`}
    >
      {mostrarAgregarModelo.estado ? (
        <AgregarModelo
          setMostrarAgregarModelo={setMostrarAgregarModelo}
          mostrarAgregarModelo={mostrarAgregarModelo}
          setAplicarModelo={setAplicarModelo}
        />
      ) : null}
      {mostrarAgregarMarca.estado ? (
        <AgregarMarca
          setMostrarAgregarMarca={setMostrarAgregarMarca}
          setAgregarCompleto={setAgregarCompleto}
          setAplicarMarca={setAplicarMarca}
          mostrarAgregarMarca={mostrarAgregarMarca}
        />
      ) : null}
      <div className="cliente__header">
        <h2>
          Datos del <span>Veh&iacute;culo.</span>
        </h2>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>}
        >
          <Link to={'/'}>
            <IoClose />
          </Link>
        </OverlayTrigger>
      </div>
      <FormularioVehiculo
        buscarUso={buscarUso}
        buscarTipos={buscarTipos}
        buscarMarca={buscarMarca}
        buscarModelo={buscarModelo}
        formData={formData}
        tipos={tipos}
        usos={usos}
        errors={errors}
        handleChange={handleChange}
        selectedMarca={selectedMarca}
        setSelectedMarca={setSelectedMarca}
        marcas={marcas}
        selectedModelo={selectedModelo}
        setSelectedModelo={setSelectedModelo}
        modelos={modelos}
        selectedTipo={selectedTipo}
        setSelectedTipo={setSelectedTipo}
        selectedUso={selectedUso}
        setSelectedUso={setSelectedUso}
        setMostrarAgregarMarca={setMostrarAgregarMarca}
        setMostrarAgregarModelo={setMostrarAgregarModelo}
        setSelected={setSelected}
        selected={selected}
        setFormData={setFormData}
        aplicarMarca={aplicarMarca}
        aplicarModelo={aplicarModelo}
        cargandoMarcas={cargandoMarcas}
        cargandoModelos={cargandoModelos}
      />
      <div className="proceso__botones">
        <div></div>
        <div
          className="proceso__botones-contenedor"
          style={{ marginTop: '50px' }}
        >
          <button
            className="anterior"
            onClick={() => {
              handlePrev(url);
              localStorage.removeItem('RCV_DIGITAL_MARCA_SELECCIONADA');
            }}
          >
            ANTERIOR
          </button>
          <button className="siguiente" onClick={handleSubmit}>
            SIGUIENTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default Vehiculo;
