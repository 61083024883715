import React from 'react';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { CiMail } from 'react-icons/ci';
import { FaWhatsapp } from 'react-icons/fa';
import ReactWhatsapp from 'react-whatsapp';
const Soporte = () => {
  return (
    <div className="soporte">
      <h1>Soporte disponible de lunes a viernes de 8:00am a 5:00pm</h1>
      <div className="soporte__carta">
        <header>
          <h2>¿Necesitas ayuda? ¡Contáctanos!</h2>
          <h3>
            Nuestro equipo de soporte está aquí para ayudarte. Puedes ponerte en
            contacto con nosotros de las siguientes maneras.
          </h3>
        </header>
        <ul>
          <span>N&uacute;meros del Call Center</span>
          <span className="info">
            Puedes hacer click en el icono de whatsapp para iniciar un chat
          </span>
          <li>
            <MdOutlineSupportAgent />
            <span>
              0412-5145856{' '}
              <ReactWhatsapp
                className="btn btn-square btn-outline-light rounded-circle me-1"
                number="58-412-514-5856"
                message="Hola Venezuela Responsable"
              >
                <i className="fab fa-whatsapp"></i>
              </ReactWhatsapp>
            </span>
            <span>
              0412-1628233{' '}
              <ReactWhatsapp
                className="btn btn-square btn-outline-light rounded-circle me-1"
                number="58-412-162-8233"
                message="Hola Venezuela Responsable"
              >
                <i className="fab fa-whatsapp"></i>
              </ReactWhatsapp>
            </span>
          </li>
        </ul>
      </div>
      <div className="soporte__opciones">
        <div className="soporte__opciones-header">
          <h1>Nuestras redes</h1>
          <h2>Puedes hacer click en ellas para redireccionarte</h2>
        </div>
        <div className="soporte__opciones-body">
          <div className="soporte__opciones-body-opcion gmail">
            <CiMail /> <span>venezuelaresponsable@gmail.com</span>
          </div>
          <div className="soporte__opciones-body-opcion facebook">
            {' '}
            <a
              className="contact__container-item"
              href="https://www.facebook.com/people/Venezuela-Responsable/100063717486873/?fref=ts"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            Venezuela Responsable
          </div>
          <div
            className="soporte__opciones-body-opcion instagram"
            style={{
              backgroundColor:
                'background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
            }}
          >
            <a
              className="contact__container-item"
              href="https://www.instagram.com/venezuelaresponsable/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            @venezuelaresponsable
          </div>
        </div>
      </div>
    </div>
  );
};

export default Soporte;
