import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: "101px",
    right: "100px",
  },
  font: {
    fontSize: "8px",
  },
  fontBold: {
    fontSize: "8px",
    fontFamily: "Arial",
    fontStyle: "bold",
  },
});

const FacturaContrato = ({factura}) => {
  const partes = factura?.cd_co?.split("-");
  const resultado = partes && partes.length >= 5 ? partes[0] + "-" + partes[4] : "";
  return(
  <View style={styles.container}>
    <Text style={styles.font}>
      <Text style={styles.fontBold}>CONTRATO: </Text>{resultado}
    </Text>
  </View>
  )
};
export default FacturaContrato;
