import React from 'react';

const ResumeTotal = ({ resumen }) => {
  return (
    <div className="resumen__items elegido">
      <div className="resumen__items-header">
        <h5>Total</h5>
      </div>
      <div className="resumen__items-body">
        <ul className="resumen__items-body-total">
          {resumen?.map((x, indice) => {
            return (
              <li className="resumen__items-body-total-ref" key={indice}>
                <span>{x.ds}</span>
                <div className="resumen__items-body-total-ref-montos">
                  <span>{Number(x.mt_bs).toFixed(2)} </span>
                  <span className="refe">
                    {' '}
                    {x.mt_ba && x.mt_ba !== '0'
                      ? Number(x.mt_ba).toFixed(2)
                      : ''}
                    {x.ds === 'Total' ? 'REF' : ''}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ResumeTotal;
