import LogoVR from "../../img/logo512.png";
const LoadingStart = () => {
    return (
        <div className="loading-overlay">
        <div className="loading-spinner">
          <div className="spinner"></div>
          <img src={LogoVR} alt="logo_vr" className="loading-logo" />
        </div>
      </div>
      );
}

export default LoadingStart;