import axios from '../api/axios';

const updatePassword = async (
  email,

  id,
  contra,
  setNavbarErrores,
  setMostrarErrores,
  setTokenCambiar,
  setTokenVencido
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const json = JSON.stringify({
    clave: {
      em_us: email,
      id_us: id,
      pw_us: contra,
    },
    trace: '0',
    token: token,
  });

  try {
    await axios.post(process.env.REACT_APP_RECUPERAR, json);
    return true;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      setTokenCambiar(true);
      setTokenVencido(error.response.data.token);
      console.log(error.response.data.token);
    } else {
      setNavbarErrores(error.response.data.message);
      setMostrarErrores(true);
      return false;
    }
  }
};

export default updatePassword;
