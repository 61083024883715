import { Col, Container, Row } from 'react-bootstrap';
import Maraimg from '../../styles/images/529.jpg';
import { Link } from 'react-router-dom';

export const AboutUs = () => {
  return (
    <Container fluid="xxl" className="py-5">
      <br />
      <br />
      <Container>
        <Row className="g-5">
          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.1s">
            <div className="img-border">
              <img className="img-fluid" src={Maraimg} alt="" loading="lazy" />
            </div>
          </Col>
          <Col lg={6} className="wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <h6
                style={{ backgroundColor: '#e8e8e6' }}
                className="section-title text-start text-primary pe-2"
              >
                Sobre nosotros
              </h6>
              <h1
                className="display-6 mb-4 fw-bold"
                style={{ color: '#072146' }}
              >
                L&iacute;deres en el mercado con{' '}
                <span className="text-primary fw-bold">14 años</span> de
                experiencia
              </h1>
              <p style={{ textAlign: 'start', fontSize: '1.1em' }}>
                Somos una empresa dedicada a la venta de garantías administradas
                para las personas propietarias de vehículos en Venezuela,
                apoyándonos en un equipo de gran valor para la empresa,
                altamente calificado y comprometido, garantizando la
                satisfacción de nuestros clientes para así continuar creciendo
                sólidamente y adquirir nuevos compromisos que beneficien a la
                comunidad y al medio ambiente.
              </p>
              <br />
              <Link
                variant="primary"
                style={{ width: '200px', color: 'white', borderRadius: '10px' }}
                className="buttonizer"
                to="/leermas"
              >
                LEER MÁS
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
