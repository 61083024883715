import React, { useState, useEffect } from 'react';
import Switch from '../../../Switch/Switch';
import Typehead from '../../../Typehead/Typehead';

const FormularioCliente = ({
  estados,
  formData,
  errors,
  handleChange,
  selectedEstado,
  setSelectedEstado,
  selectedOperadora,
  setSelectedOperadora,
  opeCel,
  isChecked,
  setIsChecked,
  edition,
  limpiar,
  buscarEstados,
  buscarOperadora,
  cargandoEstados,
  cargandoOperadora,
  cargandoMunicipios,
  municipios,
  buscarMunicipios,
  municipioSeleccionado,
  setMunicipioSeleccionado,
  setMostrarAgregarMunicipio,
  aplicarMunicipio,
}) => {
  const [limpiaMunicipio, setLimpiaMunicipio] = useState(false);
  const agregaMunicipio = (e, dato) => {
    e.preventDefault();
    setMostrarAgregarMunicipio({
      estado: true,
      valorMunicipio: dato,
    });
  };

  const municipio = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_MUNICIPIO_GUARDADO')
  );

  useEffect(() => {
    if (aplicarMunicipio) {
      const json = [
        {
          label: municipio?.ds_mn,
          value: municipio?.id_mn,
          name: 'id_mn',
          id: 'mn_sel',
        },
      ];

      setMunicipioSeleccionado(json);
    }
  }, [aplicarMunicipio]);

  useEffect(() => {
    setLimpiaMunicipio(true);
    setTimeout(() => {
      setLimpiaMunicipio(false);
    }, 1000);
  }, [selectedEstado]);

  return (
    <form className="cliente__formulario">
      <div className="cliente__formulario-tipoCliente">
        {'Tipo de cliente: '}
        <div>
          <label>
            {' Natural '}
            <input
              type="radio"
              name="tp_cl"
              value="N"
              defaultChecked={formData.tp_cl === 'N'}
              onChange={e => {
                handleChange(e);
                limpiar('N');
              }}
              autoComplete="off"
            />
          </label>
          {'   '}

          <label>
            {' Jurídico '}

            <input
              type="radio"
              name="tp_cl"
              value="J"
              defaultChecked={formData.tp_cl === 'J'}
              onChange={e => {
                handleChange(e);
                limpiar('J');
              }}
              autoComplete="off"
            />
          </label>
        </div>
      </div>

      <div className="cliente__formulario-cedula">
        <input
          type="text"
          id="cedula"
          name="cd_cl"
          value={formData.cd_cl}
          onChange={handleChange}
          className={errors.cd_cl ? 'error' : ''}
          autoComplete="off"
        />
        <label htmlFor="cedula" className={formData.cd_cl ? 'focused' : ''}>
          {formData.tp_cl === 'N' ? 'Cédula o RIF' : 'RIF'}
        </label>
        <span>Ejem: J-01234567-8 / V-15723890</span>
      </div>
      <div className="primero">
        {formData.tp_cl === 'N' ? (
          <>
            <div className="cliente__formulario-cedula">
              <input
                type="text"
                id="nombre"
                name="nb_cl"
                value={formData.nb_cl}
                onChange={handleChange}
                className={errors.nb_cl ? 'error' : ''}
                autoComplete="off"
              />
              <label className={formData.nb_cl ? 'focused' : ''}>Nombre</label>
              <span>Ejem: JAVIER JOSE</span>
            </div>
            <div className="cliente__formulario-cedula">
              <input
                type="text"
                id="apellido"
                name="ap_cl"
                value={formData.ap_cl}
                onChange={handleChange}
                autoComplete="off"
                className={errors.ap_cl ? 'error' : ''}
              />
              <label className={formData.ap_cl ? 'focused' : ''}>
                Apellido
              </label>
              <span>Ejem: CORDERO PADILLA</span>
            </div>
          </>
        ) : (
          <div className="cliente__formulario-razonSocial">
            <input
              type="text"
              id="razonSocial"
              name="rz_cl"
              value={formData.rz_cl}
              className={errors.rz_cl ? 'error' : ''}
              autoComplete="off"
              onChange={handleChange}
            />
            <label className={formData.rz_cl ? 'focused' : ''}>
              Raz&oacute;n Social
            </label>
          </div>
        )}

        {formData.tp_cl === 'N' ? (
          <div className="cliente__formulario-fecha">
            <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>
            <input
              type="date"
              id="fechaNacimiento"
              name="fc_cl"
              value={formData.fc_cl}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
        ) : null}
      </div>
      <div className="primero">
        <div className="cliente__formulario-estado">
          <Typehead
            data={estados}
            funcion={buscarEstados}
            nombre={'Estado'}
            setSeleccion={setSelectedEstado}
            seleccion={selectedEstado}
            datosJson={{ idSel: 'id_es', idNombre: 'es_sel' }}
            mensajeNoResultado={'Este estado no esta disponible'}
            agregar={false}
            cargando={cargandoEstados}
            disabled={false}
          />
        </div>
        <div className="cliente__formulario-cedula">
          <Typehead
            data={municipios}
            funcion={buscarMunicipios}
            nombre={'Municipio'}
            setSeleccion={setMunicipioSeleccionado}
            seleccion={municipioSeleccionado}
            datosJson={{ idSel: 'id_mn', idNombre: 'mn_sel' }}
            mensajeNoResultado={'Este municipio no esta disponible'}
            agregar={true}
            agrega={agregaMunicipio}
            cargando={cargandoMunicipios}
            disabled={selectedEstado.length > 0 ? false : true}
            limpiador={limpiaMunicipio}
          />
        </div>
        <div className="cliente__formulario-cedula">
          <input
            type="text"
            id="sector"
            name="se_cl"
            className={errors.se_cl ? 'error' : ''}
            value={formData.se_cl}
            onChange={handleChange}
            autoComplete="off"
          />
          <label className={formData.se_cl ? 'focused' : ''}>Sector</label>
        </div>
        <div className="cliente__formulario-direccion">
          <input
            type="text"
            id="direccion"
            name="dr_cl"
            className={errors.dr_cl ? 'error' : ''}
            value={formData.dr_cl}
            onChange={handleChange}
            autoComplete="off"
          />
          <label className={formData.dr_cl ? 'focused' : ''}>Dirección</label>
        </div>
      </div>

      <div className="primero">
        <div className="cliente__formulario-correo">
          <input
            type="email"
            id="correo"
            name="em_cl"
            className={errors.em_cl ? 'error' : ''}
            value={formData.em_cl}
            onChange={handleChange}
            autoComplete="off"
          />
          <label className={formData.em_cl ? 'focused' : ''}>Correo</label>
        </div>
        <div className="cliente__formulario-telefono">
          <Typehead
            data={opeCel}
            funcion={buscarOperadora}
            nombre={'Operadoras'}
            setSeleccion={setSelectedOperadora}
            seleccion={selectedOperadora}
            datosJson={{ idSel: 'id_op', idNombre: 'op_sel' }}
            mensajeNoResultado={'Esta operadora no esta disponible'}
            agregar={false}
            cargando={cargandoOperadora}
          />
        </div>
        <div className="cliente__formulario-numero">
          <input
            type="text"
            id="numero"
            name="tf_cl_cl_01"
            className={errors.tf_cl_cl_01 ? 'error' : ''}
            value={formData.tf_cl_cl_01}
            onChange={handleChange}
            autoComplete="off"
            maxLength="7"
          />
          <label className={formData.tf_cl_cl_01 ? 'focused' : ''}>
            Número
          </label>
        </div>
      </div>
      {!edition ? (
        <label className="cliente__formulario-propietario">
          {'¿Eres propietario? '}
          <Switch isChecked={isChecked} setIsChecked={setIsChecked} />
        </label>
      ) : null}
    </form>
  );
};

export default FormularioCliente;
