import { Container, Row } from 'react-bootstrap';
import Fact from './Fact';

const Facts = ({ offset }) => {
  const fechaActual = new Date();
  const fechaObjetivo = new Date(2010, 0, 1);
  const diferenciaEnMilisegundos = fechaActual - fechaObjetivo;
  const milisegundosPorAño = 1000 * 60 * 60 * 24 * 365.25;
  const diferenciaEnAños = diferenciaEnMilisegundos / milisegundosPorAño;
  return (
    <Container fluid="xxl" className="py-2">
      <Container>
        <Row className="g-4">
          <Fact
            offset={offset}
            className="fact_card"
            title="Años de experiencia"
            valor={Math.floor(diferenciaEnAños)}
            intervalo={100}
            faIcons="fa fa-certificate"
            delay="0.1s"
          />
          <Fact
            offset={offset}
            className="fact_card"
            title="Equipo de trabajo más de"
            valor={100}
            intervalo={100}
            faIcons="fa fa-users-cog"
            delay="0.3s"
          />
          <Fact
            offset={offset}
            className="fact_card"
            title="Clientes satisfechos más de"
            valor={10000}
            intervalo={1}
            faIcons="fa fa-users"
            delay="0.5s"
          />
          <Fact
            offset={offset}
            className="fact_card"
            title="Siniestros atendidos más de"
            valor={10000}
            intervalo={1}
            faIcons="fas fa-car-crash"
            delay="0.7s"
          />
        </Row>
      </Container>
    </Container>
  );
};

export default Facts;
