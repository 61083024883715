import React, { useState, useEffect, useRef } from 'react';

const Typehead = ({
  data,
  funcion,
  nombre,
  setSeleccion,
  seleccion,
  datosJson,
  agrega,
  mensajeNoResultado,
  agregar,
  cargando,
  disabled,
  limpiador,
}) => {
  const [valorBusqueda, setValorBusqueda] = useState('');
  const [busqueda, setBusqueda] = useState([]);
  const [contador, setContador] = useState();
  const [mostrarDatos, setMostrarDatos] = useState(false);
  const [valor, setValor] = useState(null);
  const [indiceSeleccionado, setIndiceSeleccionado] = useState(-1);
  const [agregarNuevo, setAgregarNuevo] = useState(false);
  const inputRef = useRef(null);
  const [itemS, setItemS] = useState(null);

  useEffect(() => {
    const resultado = data?.filter(item =>
      item?.ds?.toLowerCase().includes(valorBusqueda.toLowerCase())
    );

    setBusqueda(resultado);
    setIndiceSeleccionado(-1);
  }, [valorBusqueda]);

  const handleBlur = () => {
    setMostrarDatos(false);
  };

  const handleSelect = item => {
    setValor(item.ds);
    const json = {
      id: datosJson.idNombre,
      label: item?.ds,
      name: datosJson.idSel,
      value: item?.cd,
    };
    setValorBusqueda('');
    setIndiceSeleccionado(-1);
    setSeleccion([json]);
    inputRef.current.blur();
  };

  useEffect(() => {
    if (itemS) {
      handleSelect(itemS);
    }
  }, [itemS]);

  useEffect(() => {
    setContador(inputRef?.current?.value?.length);
    if (inputRef?.current?.value?.length >= 4) {
      setAgregarNuevo(true);
    }
    if (inputRef?.current?.value?.length < 4 || busqueda?.length <= 0) {
      setAgregarNuevo(false);
    }
  }, [contador, valorBusqueda]);

  const handleKeyDown = e => {
    if (!mostrarDatos) return;

    if (e.key === 'ArrowDown') {
      setIndiceSeleccionado(prev =>
        prev < (busqueda?.length ? busqueda?.length : data?.length) - 1
          ? prev + 1
          : 0
      );
    } else if (e.key === 'ArrowUp') {
      setIndiceSeleccionado(prev =>
        prev > 0
          ? prev - 1
          : (busqueda?.length ? busqueda?.length : data?.length) - 1
      );
    } else if (e.key === 'Enter' && indiceSeleccionado >= 0) {
      const selectedItem = busqueda?.length
        ? busqueda[indiceSeleccionado]
        : data[indiceSeleccionado];
      handleSelect(selectedItem);
    } else if (e.key === 'Enter' && indiceSeleccionado < 0) {
      const selectedItemN = busqueda[0];
      handleSelect(selectedItemN);
    }
  };

  useEffect(() => {
    if (limpiador) {
      setSeleccion('');
      setValorBusqueda('');
      setValor(null);
    }
  }, [limpiador]);

  return (
    <div className="typehead">
      <label
        className={
          seleccion?.length > 0 || mostrarDatos || valor || valorBusqueda
            ? 'focused'
            : ''
        }
      >
        {nombre}
      </label>
      <input
        className={disabled ? 'disabled' : ''}
        ref={inputRef}
        type="text"
        onChange={e => {
          setValorBusqueda(e.target.value);
          setSeleccion('');
          setValor(null);
        }}
        onFocus={() => {
          setMostrarDatos(true);
          funcion();
        }}
        disabled={disabled}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={
          seleccion
            ? seleccion[0]?.label
            : valor
            ? valor.toUpperCase()
            : valorBusqueda.toUpperCase()
        }
      />

      <ul className={mostrarDatos ? 'mostrar' : ''}>
        {cargando ? (
          <li className="typeheadli">
            <div className="lds-ellipsisg">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </li>
        ) : null}
        {!valorBusqueda ? (
          data &&
          data.map((x, i) => (
            <li
              key={i}
              onMouseDown={() => setItemS(x)}
              className={indiceSeleccionado === i ? 'selected' : ''}
            >
              {x.ds.toUpperCase()}
            </li>
          ))
        ) : busqueda.length > 0 ? (
          busqueda.map((x, i) => {
            return (
              <li
                key={i}
                onMouseDown={() => handleSelect(x)}
                className={indiceSeleccionado === i ? 'selected' : ''}
              >
                {x.ds.toUpperCase()}
              </li>
            );
          })
        ) : (
          <li className="emptyLabelAgregar">
            <span>{mensajeNoResultado}</span>
            {agregar ? (
              <button
                className="cliente__formulario-marca-agregar"
                onClick={e => {
                  e.preventDefault();
                  agrega(e, valorBusqueda);
                  setValorBusqueda('');
                  inputRef.current.value = '';
                }}
              >
                AGREGAR
              </button>
            ) : null}
          </li>
        )}
        {agregarNuevo && agregar ? (
          <li
            className="elementoAgrega"
            onMouseDown={e => {
              e.preventDefault();
              agrega(e, valorBusqueda);
              setValorBusqueda('');
              inputRef.current.value = '';
            }}
          >
            <button className="cliente__formulario-marca-agregar">
              AGREGAR
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default Typehead;
