import React, { useState } from 'react';
import NavbarLite from '../NavbarLite/NavbarLite';
import terminosYCondiciones from './terminoYCondiciones/terminoYCondiciones';

const Terminos = () => {
  const [contenido, setContenido] = useState(0);

  return (
    <div>
      <div className="terminos">
        <div className="terminos__container">
          <div className="terminos__container-title">
            <h1>Venezuela Responsable C.A</h1>
            <h5>J-29804784-4</h5>
          </div>
          <div className="terminos__container-listado">
            <ul className="terminos__container-listado-list">
              <li
                className="terminos__container-listado-list-item"
                onClick={() => setContenido(0)}
              >
                CONDICIONADO GENERAL
              </li>
              <li
                className="terminos__container-listado-list-item"
                onClick={() => setContenido(1)}
              >
                CONDICIONADO ASISTENCIA VIAL
              </li>
              <li
                className="terminos__container-listado-list-item"
                onClick={() => setContenido(2)}
              >
                CONDICIONADO DE GRÚAS
              </li>
              <li
                className="terminos__container-listado-list-item"
                onClick={() => setContenido(3)}
              >
                CONDICIONADO FUNERARIO
              </li>
            </ul>
          </div>

          <div className="terminos__container-header">
            {terminosYCondiciones[contenido].title}
          </div>
          <div className="terminos__container-subtitle">
            {terminosYCondiciones[contenido]?.subtitle}
          </div>
          <div className="terminos__container-contenido">
            {terminosYCondiciones[contenido].contenido}
          </div>
        </div>
        <div div className="terminos__footer">
          <p>
            Dirección Fiscal: AV.8 SANTA RITA Entre Calle 63-64 casa No63-49
            Zona Postal 4001 0500-YO CON VR (9626687) Teléfonos: 0261 –
            8141154/7922591 0414 – 6862821/6867604
            venezuelaresponsable@hotmail.com venezuelaresponsable@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terminos;
