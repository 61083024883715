import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { FaRegCopy } from 'react-icons/fa';
import DashboardEsqueletos from '../Esqueletos/DashboardEsqueletos';

const TablaCupones = ({
  descuentos,
  setMostrarEditor,
  setEdicionProp,
  loading,
}) => {
  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Copiar URL</strong>
    </Tooltip>
  );
  const copiarAlPortapapeles = url => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('URL copiada al portapapeles');
      })
      .catch(error => {
        alert('Error al copiar la URL');
      });
  };
  return (
    <table>
      <thead>
        <tr>
          <td>Cupón</td>
          <td>Descripción</td>
          <td>Monto</td>
          <td>Tipo</td>
          <td>OTP</td>
          <td>URL</td>
          <td>Estado</td>
          <td>Acción</td>
        </tr>
      </thead>
      <tbody>
        {descuentos &&
          descuentos.map((x, i) => {
            return loading ? (
              <DashboardEsqueletos />
            ) : (
              <tr key={i}>
                <td>{x.cd_prom}</td>
                <td>{x.ds_prom}</td>
                <td>{x.mt_prom.toFixed(2)}</td>
                <td>{x.tp_mt_prom === 'D' ? 'DLS' : 'BS.'}</td>
                <td>{x.is_otp ? 'SI' : 'NO'}</td>

                <td>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={tooltip}
                    className="menuDinamicoOverlay"
                  >
                    <button>
                      <FaRegCopy
                        onClick={() => copiarAlPortapapeles(x.rutaVenta)}
                      />
                    </button>
                  </OverlayTrigger>
                  &nbsp; &nbsp;
                  {x.rutaVenta}
                </td>
                <td
                  className={
                    x.es_rg === 'A'
                      ? 'ACTIVO'
                      : x.es_rg === 'X'
                      ? 'PROTEGIDO'
                      : 'INACTIVO'
                  }
                >
                  {x.es_rg === 'A'
                    ? 'ACTIVO'
                    : x.es_rg === 'X'
                    ? 'PROTEGIDO'
                    : 'INACTIVO'}
                </td>
                <td>
                  {x.es_rg === 'X' ? null : (
                    <button
                      className="botoneditar"
                      onClick={() => {
                        setMostrarEditor(true);
                        setEdicionProp(x);
                      }}
                    >
                      EDITAR
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default TablaCupones;
