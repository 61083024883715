import React, { useState, useEffect } from 'react';
import imgTicket from '../../../../styles/images/ticket.png';
import consultarApi from '../../../../helpers/consultarApi';
import { IoIosCheckmark } from 'react-icons/io';
const CuponDes = ({
  setMostrarErrores,
  setNavbarErrores,
  setReferido,
  setTokenCambiar,
  setTokenVencido,
  setToken,
  cancelar,
  valorAgente,
  setValorAgente,
  quitarReferido,
  cuponAplicado,
  setCuponAplicado,
  setPorLink,
}) => {
  const [mostrar, setMostrar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [valorCupon, setValorCupon] = useState('');
  const plan = JSON.parse(localStorage.getItem('RCV_DIGITAL_PLAN'));
  const preguntaCupon = JSON.parse(localStorage.getItem('RCV_DIGITAL_CUPON'));
  const [cupon, setCupon] = useState(null);
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    if (preguntaCupon) {
      setCupon([preguntaCupon]);
      setValorCupon(preguntaCupon.valorCupon);
      setCuponAplicado(true);
    }
  }, []);

  const handleSearch = e => {
    e.preventDefault();
    const { value } = e.target;
    const isNumeric = /^\d+$/.test(value);

    if (isNumeric) {
      fetchAgentEndpoint(value);
    } else {
      fetchCouponEndpoint(value);
    }
  };

  const fetchAgentEndpoint = agentId => {
    setValorAgente(agentId.toUpperCase());
  };

  const fetchCouponEndpoint = couponCode => {
    setValorCupon(couponCode.toUpperCase());
  };

  const obtenerAgente = async () => {
    const urlAgente = `v1/getAgent?p1=${valorAgente}&p2=&p3=&p4=&op=1&token=`;

    const respuesta = await consultarApi(
      urlAgente,
      0,
      setReferido,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );

    if (respuesta) {
      setNavbarErrores([respuesta.errorData.message]);
      setMostrarErrores(true);
      setCargando(false);
    } else {
      setTimeout(() => {
        setReferido(prevRef => {
          if (prevRef && prevRef.length > 0) {
            setPorLink(false);
            prevRef[0].valorAgente = valorAgente;
            setMostrar(false);
            setCuponAplicado(true);
            localStorage.setItem('RCV_DIGITAL_CUPON_ASIGNADO', true);
            localStorage.setItem(
              'RCV_DIGITAL_REFERIDO',
              JSON.stringify(...prevRef)
            );
          } else {
            console.error('No se encontró el referido no existe');
          }
          return prevRef;
        });
      }, 1000);
    }
  };

  const obtenerDescuento = async () => {
    const urlCupon = `v1/getCupon?p1=0&p2=${valorCupon}&p3=${plan?.id_pq}&p4=&op=1&token=`;
    console.log(urlCupon);
    const respuesta = await consultarApi(
      urlCupon,
      0,
      setCupon,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );

    if (respuesta) {
      setNavbarErrores([respuesta.errorData.message]);
      setMostrarErrores(true);
      setCargando(false);
    } else {
      setTimeout(() => {
        setCupon(prevCupon => {
          if (prevCupon && prevCupon.length > 0) {
            prevCupon[0].valorCupon = valorCupon;
            setCuponAplicado(true);
            localStorage.setItem(
              'RCV_DIGITAL_CUPON',
              JSON.stringify(...prevCupon)
            );
            setCargando(false);
          } else {
            console.error('No se encontró el cupón o está vacío');
          }
          return prevCupon;
        });
      }, 1000);
    }
  };

  const quitarDescuento = () => {
    setCuponAplicado(false);
    setValorCupon('');
    setMostrar(false);
    localStorage.removeItem('RCV_DIGITAL_CUPON');
    localStorage.removeItem('RCV_DIGITAL_CUPON_ASIGNADO');
    quitarReferido();

    setCargando(false);
  };

  useEffect(() => {
    let codigoDescuentoLink = localStorage.getItem('RCV_DIGITAL_CODIGO_CUPON');

    if (codigoDescuentoLink) {
      const codigoParseado = JSON.parse(codigoDescuentoLink);

      const urlCupon = `v1/getCupon?p1=0&p2=${codigoParseado.codigoDescuento}&p3=${plan?.id_pq}&p4=&op=1&token=`;
      console.log(urlCupon);

      consultarApi(
        urlCupon,
        0,
        setCupon,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(() => {
        localStorage.setItem('RCV_DIGITAL_DESCONTADO', true);
      });

      setTimeout(() => {
        setCupon(prevCupon => {
          if (prevCupon && prevCupon.length > 0) {
            console.log(prevCupon);

            const cuponCode = codigoParseado.codigoDescuento.toUpperCase();
            prevCupon[0].valorCupon = cuponCode;

            setValorCupon(cuponCode);
            setCuponAplicado(true);

            localStorage.setItem(
              'RCV_DIGITAL_CUPON',
              JSON.stringify(...prevCupon)
            );

            setCargando(false);
            localStorage.removeItem('RCV_DIGITAL_CODIGO_CUPON');
          } else {
            console.error('No se encontró el cupón o está vacío');
          }
          return prevCupon;
        });
      }, 1000);
    }
  }, []);
  console.log(cupon);
  const gestionar = () => {
    setCargando(true);
    if (valorCupon.length > 0) {
      obtenerDescuento();
    }

    if (valorAgente.length > 1) {
      obtenerAgente();
    }
  };

  return (
    <div className="cuponDes">
      {cuponAplicado ? (
        <div className="cuponDes__aplicado">
          <div className="cuponDes__aplicado-header">
            <h2>{cupon ? cupon && cupon[0]?.ds_cu : 'Cupon aplicado!'}</h2>
          </div>

          <button onClick={quitarDescuento}>Quitar</button>
        </div>
      ) : (
        <>
          <div
            className={`cuponDes__pregunta ${mostrar ? '' : 'active'}`}
            onClick={() => setMostrar(true)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span>
              ¿Tienes cup&oacute;n?<span>Haz click aqui</span>
            </span>
            <img
              src={imgTicket}
              alt="imagen de un cupon"
              className={`${isHovered ? 'hovered' : ''}`}
            />
          </div>
          <div className={`cuponDes__aplicacion ${mostrar ? '' : 'active'}`}>
            <div className="cuponDes__aplicacion-conteninput">
              <label>Cup&oacute;n</label>
              <input type="text" onChange={handleSearch}></input>
            </div>

            <div className="cuponDes__aplicacion-contenbutton">
              <button className="cancelar" onClick={() => setMostrar(false)}>
                Cancelar
              </button>
              <button
                className="aplicar"
                onClick={() => gestionar()}
                disabled={cargando ? true : false}
              >
                {cargando ? (
                  <div className="lds-ellipsisg">
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                    <div style={{ backgroundColor: 'white' }}></div>
                  </div>
                ) : (
                  'Aplicar'
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CuponDes;
