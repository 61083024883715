import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  page: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: '2px solid black',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: '5px',
  },
  text: {
    fontSize: 7,
  },
});

const PieDePagina = ({ nombre }) => {
  return (
    <View style={styles.page}>
      <Text
        style={styles.text}
        render={({ pageNumber, totalPages }) =>
          `Página ${pageNumber} de ${totalPages}`
        }
      />
      <Text style={styles.text}>Reporte: {nombre}</Text>
    </View>
  );
};

export default PieDePagina;
