import { HiArrowSmRight, HiArrowSmLeft } from 'react-icons/hi';
const Pagination = ({ page, contador, setContador, datos }) => {
  const estilos = {
    backgroundColor: '#2196f3',
    color: 'white',
  };
  return (
    <div className="pagination">
      {page > contador || page === 0 ? null : (
        <button
          className="pagination__botones"
          onClick={() => setContador(contador - 1)}
        >
          <HiArrowSmLeft />
        </button>
      )}{' '}
      {datos?.map((x, index) => (
        <div
          style={index === contador ? estilos : null}
          className="pagination__pages"
          id={index}
          key={index}
          onClick={() => setContador(index)}
        >
          {index + 1}
        </div>
      ))}
      {page === 0 ? <span>Pagina</span> : null}
      {page === contador ? null : (
        <button
          className="pagination__botones"
          onClick={() => setContador(contador + 1)}
        >
          <HiArrowSmRight />
        </button>
      )}
    </div>
  );
};

export default Pagination;
