import {
  Document,
  Page,
  Text,
  View,
  Font,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '60px',
    alignItems: 'center',
  },
  font: {
    fontSize: '9px',
  },
  fontBold: {
    fontSize: '9px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  fontBold2: {},
  firmaContainer: {
    marginTop: '30px',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
  },

  firmaContainer2: {
    marginTop: '30px',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '160px',
    top: '14px',
  },
});
const ContratoFirmas = ({ contratoData }) => (
  <div style={styles.container}>
    <div>
      <div>
        <View>
          <Text style={styles.fontBold}>Datos del Firmante:</Text>
        </View>
        <View>
          <Text style={styles.font}>{contratoData?.nombre?.toUpperCase()}</Text>
        </View>
        <View>
          <Text style={styles.font}>{contratoData?.cd_cl}</Text>
        </View>
      </div>

      <View style={styles.firmaContainer}>
        <Text style={styles.fontBold}>FIRMA DEL CONTRATANTE</Text>
      </View>
    </div>

    <div>
      <View style={styles.firmaContainer2}>
        <Text style={styles.fontBold}>FIRMA AUTORIZADA</Text>
      </View>
    </div>
  </div>
);

export default ContratoFirmas;
