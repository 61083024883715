import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';
import { getToken } from './getToken';

const postMarca = async (
  marcaJSON,
  setNavbarErrores,
  setTokenCambiar,
  setTokenVencido,
  setMostrarErrores,
  setMarcaVeri,
  setToken,
  setMostrarAgregarMarca
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  let json = JSON.stringify({
    marca: {
      ds_mc: marcaJSON.ds_mc,
    },
    trace: '0',
    token: token,
  });

  const handleResponse = async data => {
    localStorage.setItem(
      'RCV_DIGITAL_MARCA_NUEVA',
      JSON.stringify(data.data.data)
    );
    localStorage.setItem(
      'RCV_DIGITAL_MARCA_GUARDADA',
      JSON.stringify(data.data.data)
    );
    localStorage.setItem('RCV_DIGITAL_MARCA', JSON.stringify(data.data.data));
    setMarcaVeri(true);

    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      setMostrarAgregarMarca({
        estado: false,
        valorMarca: '',
        valorModelo: '',
      });
      if (login === undefined || login === null) {
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        console.log(error);
      }
    } else {
      setNavbarErrores([error.response.data.message]);
      setMostrarErrores(true);
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_NUEVA_MARCA, json);
    handleResponse(data);
  } catch (error) {
    handleError(error);
  }
};

export default postMarca;
