import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: '137px',
    right: '50px',
    display: 'flex',
    flexDirection: 'column',
    height: '30px',
    gap: '4px',
  },
  font: {
    fontSize: '7px',
  },
  fontBold: {
    fontSize: '7px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});

const FacturaImpreso = ({ factura }) => {
  const fecha = new Date();
  const fechaInicio = factura?.Fecha_Inicio.split(' ')[0];
  return (
    <div style={styles.container}>
      <View>
        <Text style={styles.font}>
          <Text style={styles.fontBold}>IMPRESO: </Text>
          {`${fecha.getDate()}/${
            fecha.getMonth() + 1
          }/${fecha.getFullYear()} ${fecha.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          })}`}
        </Text>
      </View>
      <View>
        <Text style={styles.font}>
          <Text style={styles.fontBold}>FECHA DE EMISION: </Text>
          {fechaInicio}
        </Text>
      </View>
      <View>
        <Text style={styles.font}>
          <Text style={styles.fontBold}>FORMA DE PAGO: </Text>
          {factura?.ds_tp_pg === 'ZELLE' ? 'DIVISAS' : factura?.ds_tp_pg}
        </Text>
      </View>
    </div>
  );
};
export default FacturaImpreso;
