import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import TableTotales from './TableTotales';
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '4px',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableRowTotales: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },
  tableColHeader: {
    fontFamily: 'Arial',
    height: '20px',
    width: '100%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 4,
    padding: 4,
    paddingTop: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: ' center',
  },
  tableCol: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 6,
    padding: 2,
  },
  tableColTotales: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'center',
    fontSize: 6,
    padding: 2,
  },
  tableColContra: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 6,
    padding: 2,
  },
  tableColContraTotales: {
    fontFamily: 'Arial',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'start',
    fontSize: 6,
    padding: 2,
    fontWeight: 'bold',
    fontStyle: 'bold',
  },
  tableColMonto: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 6,
    padding: 2,
  },
  tableColMontoTotales: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    minHeight: '12px',
    borderRightWidth: 1,
    textAlign: 'right',
    fontSize: 6,
    padding: 2,
  },
});

const Table = ({ reporte }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>FECHA</Text>
        <Text style={[styles.tableColHeader, { width: '3%' }]}>CONTRATO</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>CONTROL</Text>
        <Text style={[styles.tableColHeader, { width: '25.5%' }]}>
          CONTRATANTE
        </Text>{' '}
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>RIF</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>AGENTE</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>EXENTO</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>BASE</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>I.V.A</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>I.G.T.F</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>
          DESCUENTO
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>INGRESO</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>TASA</Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>
          INGRESO REF
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>
          COBERTURA
        </Text>
        <Text style={[styles.tableColHeader, { width: '5.2%' }]}>PLACA</Text>
      </View>
      {reporte?.VentasDiarias?.map((row, index) => (
        <View key={index} style={styles.tableRow}>
          <Text style={[styles.tableCol, { width: '5.2%' }]}>{row?.fecha}</Text>
          <Text style={[styles.tableCol, { width: '3%' }]}>
            {row?.tp_fc === 'NC' ? '' : row?.cd_contrato}
          </Text>
          <Text style={[styles.tableCol, { width: '5.2%' }]}>
            {row?.nr_control}
          </Text>

          <Text style={[styles.tableColContra, { width: '25.5%' }]}>
            {row?.contratante}
          </Text>
          <Text style={[styles.tableCol, { width: '5.2%' }]}>{row?.rif}</Text>
          <Text style={[styles.tableCol, { width: '5.2%' }]}>
            {row?.us_agente}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.mt_ex_tt.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.mt_ba_tt.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.mt_iv_tt.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.mt_igtf.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.mt_ds_tt.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.mt_ge_tt.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.tasa.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {(row?.mt_ge_tt / row.tasa).toFixed(2)}
          </Text>
          <Text style={[styles.tableColMonto, { width: '5.2%' }]}>
            {row?.cobertura.toLocaleString('es-VE', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
          <Text style={[styles.tableCol, { width: '5.2%' }]}>{row?.placa}</Text>
        </View>
      ))}
      <TableTotales reporte={reporte} />
    </View>
  );
};

export default Table;
