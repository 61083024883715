import React from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Volver = () => {
  return (
    <div className="volver">
      <Link to="/">
        <BsArrowLeft />
        Volver
      </Link>
    </div>
  );
};

export default Volver;
