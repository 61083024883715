import { useEffect, useState, useContext } from 'react';
import Ticket from './Ticket/Ticket';
import useProcesoProvider from '../../../../hooks/useProcesoProvider';
import pagoErrores from './pagoErrores';
import consultarApi from '../../../../helpers/consultarApi';
import { TokenContext } from '../../../../context/TokenContext';
import { postContrato } from '../../../../helpers/postContrato';
import { useNavigate } from 'react-router-dom';
import PagoConfirmado from './PagoConfirmado.js/PagoConfirmado';

const Pago = () => {
  const context = useProcesoProvider();
  const {
    setErrorAdvice,
    handlePrev,
    setChequearPago,
    setReferencia,
    referencia,
    setMostrarErrores,
    setNavbarErrores,
    setLogin,
    cambiarPaso,
  } = context;
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [verified] = useState(undefined);
  const [tipoPago, setTipoPago] = useState([]);
  const [cargandoTipoPagos, setCargandoTipoPagos] = useState(true);
  const [bancoElegido, setBancoElegido] = useState();
  const [pagoConfirmacion, setPagoConfirmacion] = useState(false);
  const [pagoStatus, setPagoStatus] = useState({
    code: 0,
    message: '',
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activo, setActivo] = useState(false);
  const [total] = useState(JSON.parse(localStorage.getItem('TOTAL_PAGO')));
  const [procesandoPago, setProcesandoPago] = useState(false);
  useEffect(() => {
    if (verified != undefined) {
      setLogin(JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN')));
    }
  }, [verified]);

  useEffect(() => {
    setCargandoTipoPagos(true);
    consultarApi(
      process.env.REACT_APP_GET_PAYMETHOD,
      0,
      setTipoPago,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(() => setCargandoTipoPagos(false));
  }, [token]);

  useEffect(() => {
    cambiarPaso(8, 6);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setErrorAdvice(0);
    }, 1000);
  }, []);
  useEffect(() => {
    setErrorAdvice(0);
  }, []);
  const pagar = () => {
    setErrorAdvice(3);
    const pagoErroresVeri = pagoErrores(referencia, bancoElegido);
    if (Object.keys(pagoErroresVeri).length > 0) {
      setNavbarErrores(pagoErroresVeri);
      setMostrarErrores(true);
    } else {
      setPagoConfirmacion(true);
    }
  };
  const pagarConfirmado = () => {
    setProcesandoPago(true);
    postContrato(
      referencia,
      setPagoStatus,
      bancoElegido?.id_fp,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      setLoading
    );
  };
  useEffect(() => {
    if (pagoStatus.code === 2) {
      setProcesandoPago(false);
      setNavbarErrores([pagoStatus.message]);
      setMostrarErrores(true);
      setLoading(2);
    }

    if (pagoStatus.code === 1) {
      setProcesandoPago(false);
      localStorage.setItem('RCV_DIGITAL_PAGO', JSON.stringify(true));
      setLoading(3);
      navigate('/dashboard');
    }
  }, [pagoStatus]);
  if (!context) {
    return <div>cargando...</div>;
  }

  return (
    <div className="pagoContainer">
      {pagoConfirmacion ? (
        <PagoConfirmado
          setPagoConfirmacion={setPagoConfirmacion}
          referencia={referencia}
          total={total}
          bancoElegido={bancoElegido}
          pagarConfirmado={pagarConfirmado}
          procesandoPago={procesandoPago}
          activo={activo}
        />
      ) : null}

      <div className="pago">
        <div className="pago__contenedor">
          <Ticket
            setChequearPago={setChequearPago}
            setErrorAdvice={setErrorAdvice}
            tipoPago={tipoPago}
            bancoElegido={bancoElegido}
            setBancoElegido={setBancoElegido}
            setReferencia={setReferencia}
            referencia={referencia}
            setMostrarErrores={setMostrarErrores}
            setNavbarErrores={setNavbarErrores}
            total={total}
            cargandoTipoPagos={cargandoTipoPagos}
            activo={activo}
            setActivo={setActivo}
          />
        </div>

        <div className="proceso__botones">
          <div></div>
          <div className="proceso__botones-contenedor">
            <button
              className="anterior"
              onClick={() => handlePrev('/proceso/resumen')}
            >
              ANTERIOR
            </button>
            <button className="botonpagar" onClick={() => pagar()}>
              <span></span>PAGAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pago;
