import { Text, View, StyleSheet } from '@react-pdf/renderer';
const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    left: '12px',
    bottom: '-45px',
    position: 'absolute,',
  },
  font: {
    fontSize: '7px',
  },
});
const ContratoPag = () => (
  <View style={styles.container}>
    <Text style={styles.font}>Página: 1 de 1</Text>
  </View>
);
export default ContratoPag;
