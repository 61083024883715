import React, { useEffect, useState, useContext } from 'react';
import ReportesPDF from './ReportesPDF';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Navigate, useParams } from 'react-router-dom';
import { LoginContext } from '../../context/LoginContext';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';

const VentasDiariasResultado = () => {
  let { fechaini, fechafin } = useParams();
  const [fecha, setFecha] = useState('');
  const [hora, setHora] = useState('');
  const [reporte, setReporte] = useState();
  const [currentDate] = useState(new Date());
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);

  const useAuth = () => {
    let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
    const user = { loggedIn: login ? true : false };

    return user && user.loggedIn;
  };

  const isAuth = useAuth();
  const { setNavbar } = useContext(LoginContext);

  useEffect(() => {
    setNavbar(false);
    let urlReportes = `v1/getDaySales?p1=&p2=&p3=&p4=&dd=${fechaini}&ht=${fechafin}&op=6&token=`;
    consultarApi(
      urlReportes,
      0,
      setReporte,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, []);

  useEffect(() => {
    setReporte(reporte);
    const fechaFormateada = format(currentDate, 'dd/MM/yyyy', { locale: es });
    const horaFormateada = format(currentDate, 'hh:mm:ss a', { locale: es });
    setFecha(fechaFormateada);
    setHora(horaFormateada);
  }, [reporte]);

  return isAuth ? (
    <div>
      <ReportesPDF
        fechaini={fechaini}
        fechafin={fechafin}
        reporte={reporte}
        userProfile={userProfile}
        fecha={fecha}
        hora={hora}
      />
    </div>
  ) : (
    <Navigate to="/login/1" />
  );
};

export default VentasDiariasResultado;
