import { useRef, useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { FaCar, FaBoxOpen } from 'react-icons/fa';
import { HiMenu } from 'react-icons/hi';
import { AiOutlineUser, AiOutlinePoweroff } from 'react-icons/ai';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import LogoVR from '../../img/logo_n_ulimate.png';

const NavbarLite = () => {
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  let userProfile = JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'));
  let userVerified = userProfile?.rol.cd_ro;
  const dropdown = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  let location = useLocation();

  const handleLogout = async () => {
    localStorage.removeItem('RCV_DIGITAL_LOGIN');
    localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
    localStorage.removeItem('RCV_DIGITAL_TOKEN');
    setShow(false);
    setTimeout(() => {
      if (location.pathname === '/') {
        window.location.reload();
      }
      navigate('/');
    }, 1000);
  };

  return (
    <div className="navbarLiteContainer">
      <nav className="navbarLite">
        <div className="navbarLite__left">
          <Link to={'/'}>
            <img
              className={'navbarLite__left-logo'}
              alt="logo de la emprese Venezuela Responsable"
              src={LogoVR}
            ></img>
          </Link>
        </div>

        <div className="navbarLite__right">
          <div className="navbarLite__right-item">
            {login ? (
              <div
                className="navbarLite__right-item-info"
                onClick={() => {
                  show === false ? setShow(true) : setShow(false);
                }}
              >
                <span className="navbarLite__right-item-info-nombre">{`${
                  userProfile.nb_us + ' ' + userProfile.ap_us
                }`}</span>
                <TiArrowSortedDown className="navbarLite__right-item-info-boton" />
                <span
                  className="navbarLite__right-item-info-off"
                  onClick={() => handleLogout()}
                >
                  Cerrar sesi&oacute;n
                </span>
              </div>
            ) : (
              <div className="me-3 mx-auto loginOptions">
                <Link to="/login/1">
                  <Button className="loginOptions__login">
                    INICIAR SESIÓN <i className="bi bi-person-circle"></i>
                  </Button>
                </Link>
                <Link to="/login/2">
                  <Button className="loginOptions__signup">
                    REGISTRARSE <i className="bi bi-person-plus-fill"></i>
                  </Button>
                </Link>
              </div>
            )}

            <HiMenu
              className={
                show === false
                  ? 'navbarLite__right-item-burguer'
                  : 'navbarLite__right-item-burguer active'
              }
              onClick={() => {
                show === false ? setShow(true) : setShow(false);
              }}
            />
            <AiOutlineClose
              className={
                show === false
                  ? 'navbarLite__right-item-closer'
                  : 'navbarLite__right-item-closer active'
              }
              onClick={() => {
                show === false ? setShow(true) : setShow(false);
              }}
            />
            {login ? (
              <ul
                className={
                  show === false
                    ? 'navbarLite__right-item-list'
                    : 'navbarLite__right-item-list active'
                }
                ref={dropdown}
              >
                <li>{userProfile.nb_us + ' ' + userProfile.ap_us}</li>
                <li>
                  <Link to="/consulta/perfil">
                    <AiOutlineUser style={{ fontSize: '1.6em' }} />
                    PERFIL
                  </Link>
                </li>
                {userVerified === '0001' ||
                userVerified === '0000' ||
                userVerified === '0016' ? (
                  <li>
                    <Link to="/admin">
                      <FaBoxOpen style={{ fontSize: '1.6em' }} />
                      ADMINISTRACIÓN
                    </Link>
                  </li>
                ) : null}

                <li>
                  <Link to="/dashboard">
                    <FaCar style={{ fontSize: '1.6em' }} />
                    RCV
                  </Link>
                </li>
                <li className="apagador" onClick={() => handleLogout()}>
                  <AiOutlinePoweroff /> CERRAR SESIÓN
                </li>
              </ul>
            ) : (
              <ul
                className={
                  show === false
                    ? 'navbarLite__right-item-lista'
                    : 'navbarLite__right-item-lista active'
                }
                ref={dropdown}
              >
                <li>
                  <Link to="/login/1">
                    Iniciar sesión <i className="bi bi-person-circle"></i>
                  </Link>
                </li>
                <li>
                  <Link to="/login/2">
                    Registrarse <i className="bi bi-person-plus-fill"></i>
                  </Link>
                </li>
                <li onClick={() => setShow(false)}>
                  <TiArrowSortedUp />
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavbarLite;
