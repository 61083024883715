import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const useVerify = () => {
  let profile = JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'));
  let typeOfProfile = profile?.es_cl;
  return typeOfProfile && typeOfProfile;
};

const ContraProtect = ({ element: Element, ...rest }) => {
  const location = useLocation();
  const typeUser = useVerify();

  return typeUser != 'C' ? (
    <Outlet />
  ) : (
    <Navigate to="/necesita" state={{ location }} />
  );
};

export default ContraProtect;
