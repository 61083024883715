import React, { useEffect, useState, useRef } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayTrigger } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { FaShare } from 'react-icons/fa';

const CodigoAgente = ({ perfil }) => {
  const [imagen, setImagen] = useState(null);
  const canvasRef = useRef(null);

  const obtenerCodigoAgente = url => {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    return urlParams.get('vagen');
  };

  const copiarAlPortapapeles = () => {
    navigator.clipboard
      .writeText(perfil.ur_ag)
      .then(() => {
        alert('URL copiada al portapapeles');
      })
      .catch(error => {
        alert('Error al copiar la URL');
      });
  };

  const tooltip = (
    <Tooltip id="tooltip">
      <strong>Copiar URL</strong>
    </Tooltip>
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const qrCanvas = document.getElementById('seguridadQR');
      const qrX = (canvas.width - 256) / 2;
      ctx.drawImage(qrCanvas, qrX, 30, 256, 256);

      ctx.fillStyle = '#074577';
      ctx.font = 'bold 24px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(
        `Agente: ${perfil.nb_us} ${perfil.ap_us}`,
        canvas.width / 2,
        320
      );

      ctx.fillStyle = '#2196f3';
      ctx.fillText(
        `Tu código de agente es: ${obtenerCodigoAgente(perfil.ur_ag)}`,
        canvas.width / 2,
        360
      );

      const imgURL = canvas.toDataURL('image/png');
      setImagen(imgURL);
    }
  }, [perfil]);

  const compartirQr = () => {
    if (!imagen) {
      console.error('No se encontró la imagen para compartir');
      return;
    }

    fetch(imagen)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'codigo_agente.png', {
          type: 'image/png',
        });
        const shareData = {
          title: 'Código Agente',
          text: 'Haz tu asistencia vial tú mismo, con este link obtendrás un descuento especial',
          url: 'https://www.venezuelaresponsable.com.ve',
          files: [file],
        };
        if (navigator.share) {
          navigator
            .share(shareData)
            .then(() => console.log('Compartido exitosamente'))
            .catch(error => console.error('Error al compartir', error));
        } else {
          console.error('El navegador no soporta la API de compartir');
        }
      })
      .catch(error => console.error('Error al convertir imagen a blob', error));
  };

  const compartirUrl = () => {
    const shareDataUrl = {
      title: 'Código Agente',
      text: 'Aquí tienes tu código de agente:',
      url: perfil?.ur_ag,
    };

    if (navigator.share) {
      navigator
        .share(shareDataUrl)
        .then(() => console.log('Compartido exitosamente'))
        .catch(error => console.error('Error al compartir', error));
    } else {
      console.error('El navegador no soporta la API de compartir');
    }
  };

  return (
    <>
      <ul className="codigoAgente">
        <li>
          Agente:{' '}
          <span>
            {perfil.nb_us} {perfil.ap_us}
          </span>
        </li>
        <li>
          Tu código de agente es:{' '}
          <span>{perfil?.ur_ag && obtenerCodigoAgente(perfil.ur_ag)}</span>
        </li>
        <li>
          Tu URL: <span>{perfil?.ur_ag}</span>
          <OverlayTrigger
            placement="bottom"
            overlay={tooltip}
            className="menuDinamicoOverlay"
          >
            <button>
              {' - '}
              <FaRegCopy onClick={copiarAlPortapapeles} />
            </button>
          </OverlayTrigger>
        </li>
        <li className="qr">
          <QRCodeCanvas
            value={perfil?.ur_ag}
            size={256}
            fgColor="#000000"
            bgColor="transparent"
            level="L"
            includeMargin={true}
            id="seguridadQR"
          />
          <span>Tu código QR</span>
          <button className="compartir" onClick={compartirQr}>
            COMPARTIR QR <FaShare />
          </button>
          <button className="compartirurl" onClick={compartirUrl}>
            COMPARTIR URL <FaShare />
          </button>
        </li>
      </ul>
      <canvas
        ref={canvasRef}
        width="600"
        height="400"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default CodigoAgente;
