import React, { useState, useContext } from 'react';
import postEditarDescuento from '../../helpers/postEditarDescuento';

const EditorDescuento = ({
  setMostrarEditor,
  setNavbarErrores,
  setMostrarErrores,
  edicionProp,
  setToken,
  setTokenVencido,
  setTokenCambiar,
  setChangeListener,
}) => {
  const [formEdicion, setFormEdicion] = useState({
    id_prom: edicionProp.id_prom,
    cd_prom: edicionProp.cd_prom,
    ds_prom: edicionProp.ds_prom,
    mt_prom: edicionProp.mt_prom,
    tp_mt_prom: edicionProp.tp_mt_prom,
    is_otp: edicionProp.is_otp,
    es_rg: edicionProp.es_rg,
  });
  const [loading, setLoading] = useState(false);

  const validarDescuento = () => {
    let error = {};
    if (formEdicion.mt_prom === '') {
      error.mt_prom = 'El nombre del código no puede estar en blanco';
    }
    if (formEdicion.mt_prom <= 0) {
      error.mt_prom =
        'El monto del descuento no puede ser inferior o igual a 0';
    }
    console.log(error);
    return error;
  };

  const editarDescuento = e => {
    setLoading(true);
    e.preventDefault();
    const errorDeValidacion = validarDescuento();
    if (Object.keys(errorDeValidacion).length > 0) {
      setNavbarErrores(errorDeValidacion);
      setMostrarErrores(true);
      setLoading(false);
    } else {
      postEditarDescuento(
        formEdicion,
        setMostrarEditor,
        setToken,
        setNavbarErrores,
        setTokenVencido,
        setTokenCambiar
      ).finally(() => {
        setLoading(false);
        setChangeListener(prev => prev + 1);
      });
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setFormEdicion({
      ...formEdicion,
      [name]: name === 'is_otp' ? value === 'true' : value.toUpperCase(),
    });
  };
  return (
    <div className="creadorDescuento" onSubmit={editarDescuento}>
      creador de descuento , crealo aqui
      <div
        className="creadorDescuento__sombra"
        onClick={() => setMostrarEditor(false)}
      ></div>
      <form>
        <span>Cupón</span>
        <label>
          C&oacute;digo:
          <input
            value={formEdicion.cd_prom}
            type="text"
            name="cd_prom"
            onChange={handleChange}
            maxLength={20}
          />
        </label>
        <label>
          Descripción:
          <input
            value={formEdicion.ds_prom}
            type="text"
            name="ds_prom"
            onChange={handleChange}
          />
        </label>
        <label>
          Monto:
          <input
            type="number"
            value={formEdicion.mt_prom}
            name="mt_prom"
            onChange={handleChange}
          />
        </label>
        <div className="creadorDescuento__radios">
          Tipo método:
          <label>
            Bolivares
            <input
              type="radio"
              name="tp_mt_prom"
              value="B"
              checked={formEdicion.tp_mt_prom === 'B'}
              onChange={handleChange}
            />
          </label>
          <label>
            Dolares
            <input
              type="radio"
              name="tp_mt_prom"
              value="D"
              checked={formEdicion.tp_mt_prom === 'D'}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="creadorDescuento__radios">
          ¿Es de un solo uso (OTP)?
          <label>
            Si
            <input
              type="radio"
              name="is_otp"
              value={true}
              checked={formEdicion.is_otp === true}
              onChange={handleChange}
            />
          </label>
          <label>
            No
            <input
              type="radio"
              name="is_otp"
              value={false}
              checked={formEdicion.is_otp === false}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="creadorDescuento__radios">
          Estado
          <label>
            Activo
            <input
              type="radio"
              name="es_rg"
              value="A"
              checked={formEdicion.es_rg === 'A'}
              onChange={handleChange}
            />
          </label>
          <label>
            Inactivo
            <input
              type="radio"
              name="es_rg"
              value="I"
              checked={formEdicion.es_rg === 'I'}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="creadorDescuento__footer">
          <button className="crear" disabled={loading}>
            {' '}
            {loading ? (
              <div className="lds-ellipsisg">
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
              </div>
            ) : (
              'EDITAR'
            )}
          </button>
          <button className="cancelar" onClick={() => setMostrarEditor(false)}>
            CANCELAR
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditorDescuento;
