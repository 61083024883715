import { useEffect, useState, useContext } from 'react';
import Items from './ItemsProductos/Items';
import Botones from './Botones/Botones';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import { Link, useNavigate } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';
import Pagination from '../../Pagination/Pagination';

const Producto = () => {
  const context = useProcesoProvider();
  const {
    setErrorAdvice,
    setPaso,
    handlePrev,
    setMostrarErrores,
    setNavbarErrores,
    cambiarPaso,
  } = context;
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState(undefined);
  const [carrito, setCarrito] = useState([]);
  const [page, setPage] = useState(0);
  const [contador, setContador] = useState(0);
  const [datos, setDatos] = useState([]);
  const [planes, setPlanes] = useState(null);
  const [cargandoProductos, setCargandoProductos] = useState(true);

  useEffect(() => {
    const planObtenido = localStorage.getItem('RCV_DIGITAL_PLAN');
    if (planObtenido) {
      const planParseado = JSON.parse(planObtenido);
      setPlanes(planParseado);
    }
  }, []);

  useEffect(() => {
    setCargandoProductos(true);
    if (planes) {
      const urlProducto = `v1/getProducts?p1=${planes.id_pq}&p2=&p3=&p4=&op=1&token=`;
      consultarApi(
        urlProducto,
        0,
        setResponseData,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(() => setCargandoProductos(false));
    }
  }, [planes, token, setTokenCambiar, setTokenVencido, setToken]);

  useEffect(() => {
    if (responseData !== undefined) {
      const sinProducto = localStorage.getItem('RCV_DIGITAL_S/P');
      if (!responseData || sinProducto) {
        localStorage.setItem('RCV_DIGITAL_S/P', true);
        navigate('/proceso/descuento');
      } else {
        const grupos = responseData.reduce((acc, _, index) => {
          if (index % 4 === 0) {
            acc.push(responseData.slice(index, index + 4));
          }
          return acc;
        }, []);
        setDatos(grupos);
        setPage(grupos.length - 1);
      }
    }
  }, [responseData, navigate, setPaso]);

  const handleSubmit = e => {
    e.preventDefault();
    setErrorAdvice(2);
    setTimeout(() => {
      navigate('/proceso/descuento');
    }, 300);
  };

  useEffect(() => {
    cambiarPaso(5, 3);
  }, [cambiarPaso]);

  useEffect(() => {
    setTimeout(() => {
      setErrorAdvice(0);
    }, 1000);
  }, [setErrorAdvice]);

  return (
    <div className="productos">
      {cargandoProductos ? (
        <div className="lds-ellipsisg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="productos__items">
          <div className="productos__items-header">
            <h2>
              Elige <span>productos</span> y/o <span>servicios</span> (opcional)
            </h2>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>
              }
            >
              <Link to={'/'}>
                <IoClose />
              </Link>
            </OverlayTrigger>
          </div>
          <div className="itemsContainer">
            {datos &&
              datos[contador]?.map((x, indice) => {
                return (
                  <Items
                    indice={indice}
                    key={indice}
                    data={x}
                    setCarrito={setCarrito}
                    carrito={carrito}
                    setMostrarErrores={setMostrarErrores}
                    setNavbarErrores={setNavbarErrores}
                  />
                );
              })}
          </div>
          <div className="productos__footer">
            <Pagination
              page={page}
              contador={contador}
              setContador={setContador}
              datos={datos}
            />
            <Botones
              handlePrev={() => handlePrev('/proceso/vehiculo')}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Producto;
