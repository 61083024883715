import { useState, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Typehead from '../../../Typehead/Typehead';
import AgregarMarca from './AgregarMarca';
const FormularioVehiculo = ({
  usos,
  tipos,
  formData,
  errors,
  handleChange,
  selectedMarca,
  setSelectedMarca,
  marcas,
  selectedModelo,
  setSelectedModelo,
  modelos,
  selectedTipo,
  setSelectedTipo,
  selectedUso,
  setSelectedUso,
  setMostrarAgregarMarca,
  setMostrarAgregarModelo,
  setSelected,
  selected,
  setFormData,
  aplicarMarca,
  aplicarModelo,
  buscarMarca,
  buscarModelo,
  buscarTipos,
  buscarUso,
  cargandoMarcas,
  cargandoModelos,
}) => {
  const style = {
    fontWeight: 700,
    top: '-16px',
    left: '0',
    fontSize: '0.8em',
    backgroundColor: 'transparent',
    color: 'black',
  };
  const typeaheadRef = useRef(null);
  const modeloAgregarRef = useRef(null);
  const [limpiar, setLimpiar] = useState(false);
  const [clickedSelection, setClickedSelection] = useState({});
  const marca = JSON.parse(localStorage.getItem('RCV_DIGITAL_MARCA_GUARDADA'));
  const modelo = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_MODELO_GUARDADO')
  );
  const marcaElegida = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_MARCA_SELECCIONADA')
  );

  useEffect(() => {
    if (aplicarMarca) {
      const json = [
        {
          label: marca?.ds_mc,
          value: marca?.id_mc,
          name: 'mc_vh',
          id: 'mc_sel',
        },
      ];
      const jsonModelo = [
        {
          label: modelo?.ds_md,
          value: modelo?.id_md,
          name: 'md_vh',
          id: 'md_sel',
        },
      ];
      setSelectedMarca(json);

      setSelectedModelo(jsonModelo);
    }
  }, [aplicarMarca]);

  useEffect(() => {
    if (aplicarModelo) {
      const json = [
        {
          label: modelo?.ds_md,
          value: modelo?.id_md,
          name: 'md_vh',
          id: 'md_sel',
        },
      ];

      setSelectedModelo(json);
    }
  }, [aplicarModelo]);

  const limpiarMarca = selected => {
    setClickedSelection(selected[0]);
  };

  const limpiarModelo = selected => {
    setClickedSelection(selected[0]);
  };

  useEffect(() => {
    if (limpiar) {
      setSelectedMarca([]);
      setSelectedModelo([]);

      setFormData({
        pl_vh: formData.pl_vh,
        mc_vh: '',
        mc_sel: [],
        md_vh: '',
        md_sel: [],
        se_ca_vh: formData.se_ca_vh,
        se_mo_vh: formData.se_mo_vh,
        cl_vh: formData.cl_vh,
        nr_pu_vh: formData.nr_pu_vh,
        an_vh: formData.an_vh,
        pe_vh: formData.pe_vh,
        tp_vh: formData.tp_vh,
        tp_sel: formData.tp_sel,
        uso_vh: formData.uso_vh,
        uso_sel: formData.uso_sel,
      });
      setLimpiar(false);
    }
  }, [limpiar, selectedMarca]);

  const agregaMarca = (e, dato) => {
    e.preventDefault();
    setMostrarAgregarMarca({
      estado: true,
      valorMarca: dato,
      valorModelo: '',
    });
    limpiarMarca(e);
    setLimpiar(true);
  };

  const agregaModelo = (e, dato) => {
    e.preventDefault();
    setMostrarAgregarModelo({
      estado: true,
      valorModelo: dato,
    });
    limpiarModelo(e);
  };

  return (
    <form className="cliente__formulario" style={{ marginTop: '20px' }}>
      <div className="cliente__formulario-cedula">
        <input
          type="text"
          id="placa"
          name="pl_vh"
          value={formData.pl_vh}
          onChange={handleChange}
          autoComplete="off"
          className={errors.pl_vh ? 'error' : ''}
        />
        <label htmlFor="placa" className={formData.pl_vh ? 'focused' : ''}>
          Placa
        </label>
      </div>
      <div className="segundo">
        <div className="cliente__formulario-marca">
          <Typehead
            data={marcas}
            funcion={buscarMarca}
            nombre={'Marca'}
            setSeleccion={setSelectedMarca}
            seleccion={selectedMarca}
            datosJson={{ idSel: 'mc_vh', idNombre: 'mc_sel' }}
            agrega={agregaMarca}
            mensajeNoResultado={'Esta marca no esta disponible'}
            agregar={true}
            cargando={cargandoMarcas}
            disabled={false}
          />
          {/**
          <label
            htmlFor="placa"
            className={formData.mc_vh ? 'focused' : ''}
            style={selected === 'mc_vh' ? style : null}
          >
            Marca
          </label>
          <Typeahead
            id="mc_vh"
            defaultValue={selectedMarca}
            onChange={setSelectedMarca}
            onFocus={() => setSelected('mc_vh')}
            onBlur={() => setSelected('')}
            ref={typeaheadRef}
            options={marcas?.map(({ ds, cd }) => ({
              label: ds,
              value: cd,
              name: 'mc_vh',
              id: 'mc_sel',
            }))}
            placeholder=""
            selected={selectedMarca}
            emptyLabel={
              <div className="emptyLabelAgregar">
                <span>Esta marca no esta disponible</span>
                <button
                  className="cliente__formulario-marca-agregar"
                  onClick={e => {
                    e.preventDefault();
                    setMostrarAgregarMarca({
                      estado: true,
                      valorMarca: typeaheadRef.current.state.text,
                      valorModelo: '',
                    });
                    limpiarMarca(e);
                    setLimpiar(true);
                  }}
                >
                  AGREGAR
                </button>
              </div>
            }
            autoComplete="off"
            className={errors.mc_vh ? 'error' : ''}
          />
          */}
        </div>

        <div className="cliente__formulario-modelo">
          <Typehead
            data={modelos}
            funcion={buscarModelo}
            nombre={'Modelo'}
            setSeleccion={setSelectedModelo}
            seleccion={selectedModelo}
            datosJson={{ idSel: 'md_vh', idNombre: 'md_sel' }}
            agrega={agregaModelo}
            mensajeNoResultado={'Este modelo no esta disponible'}
            agregar={true}
            cargando={cargandoModelos}
            disabled={selectedMarca.length > 0 ? false : true}
          />
          {/**
           * 
           * <label
            htmlFor="placa"
            className={formData.md_vh ? 'focused' : ''}
            style={selected === 'md_vh' ? style : null}
          >
            Modelo
          </label>
          <Typeahead
            id="md_vh"
            defaultValue={selectedModelo}
            onChange={setSelectedModelo}
            ref={modeloAgregarRef}
            onFocus={() => setSelected('md_vh')}
            onBlur={() => setSelected('')}
            options={
              Array.isArray(modelos)
                ? modelos?.map(({ ds, cd }) => ({
                    label: ds,
                    value: cd,
                    name: 'md_vh',
                    id: 'md_sel',
                  }))
                : []
            }
            emptyLabel={
              <div className="emptyLabelAgregar">
                <span>Este modelo no esta disponible</span>
                {marcaElegida ? (
                  <button
                    className="cliente__formulario-marca-agregar"
                    onClick={e => {
                      e.preventDefault();
                      setMostrarAgregarModelo({
                        estado: true,
                        valorModelo: modeloAgregarRef.current.state.text,
                      });
                      limpiarModelo(e);
                    }}
                  >
                    AGREGAR
                  </button>
                ) : null}
              </div>
            }
            placeholder=""
            selected={selectedModelo}
            autoComplete="off"
          />
           */}
        </div>
      </div>
      <div className="segundo">
        <div className="cliente__formulario-serial">
          <input
            type="text"
            id="se_ca_vh"
            name="se_ca_vh"
            value={formData.se_ca_vh}
            onChange={handleChange}
            className={errors.se_ca_vh ? 'error' : ''}
            autoComplete="off"
          />
          <label className={formData.se_ca_vh ? 'focused' : ''}>
            Serial carroceria
          </label>
        </div>
        <div className="cliente__formulario-serial">
          <input
            type="text"
            id="se_mo_vh"
            name="se_mo_vh"
            value={formData.se_mo_vh}
            onChange={handleChange}
            className={errors.se_mo_vh ? 'error' : ''}
            autoComplete="off"
          />
          <label className={formData.se_mo_vh ? 'focused' : ''}>
            Serial motor
          </label>
          <span>
            Tambi&eacute;n puedes colocar la cantidad de ejes o cilindros. Ej:
            4CIL
          </span>
        </div>
      </div>
      <div className="segundo">
        <div className="cliente__formulario-serial">
          <input
            type="text"
            id="cl_vh"
            name="cl_vh"
            value={formData.cl_vh}
            onChange={handleChange}
            className={errors.cl_vh ? 'error' : ''}
            autoComplete="off"
          />
          <label className={formData.cl_vh ? 'focused' : ''}>Color</label>
        </div>
        <div className="cliente__formulario-serial">
          <input
            type="text"
            id="nr_pu_vh"
            name="nr_pu_vh"
            value={formData.nr_pu_vh}
            onChange={handleChange}
            className={errors.nr_pu_vh ? 'error' : ''}
            autoComplete="off"
          />
          <label className={formData.nr_pu_vh ? 'focused' : ''}>
            Cantidad de puestos
          </label>
        </div>
        <div className="cliente__formulario-serial">
          <input
            type="text"
            id="an_vh"
            name="an_vh"
            value={formData.an_vh}
            onChange={handleChange}
            autoComplete="off"
            className={errors.an_vh ? 'error' : ''}
          />
          <label className={formData.an_vh ? 'focused' : ''}>Año</label>
        </div>
        <div className="cliente__formulario-serial">
          <input
            type="text"
            id="pe_vh"
            name="pe_vh"
            value={formData.pe_vh}
            onChange={handleChange}
            className={errors.pe_vh ? 'error' : ''}
            autoComplete="off"
          />
          <label
            className={formData.pe_vh ? 'focused' : ''}
          >{`Peso (Kg)`}</label>
        </div>
      </div>
      <div className="segundo">
        <div className="cliente__formulario-marca">
          <Typehead
            data={tipos}
            funcion={buscarTipos}
            nombre={'Tipo'}
            setSeleccion={setSelectedTipo}
            seleccion={selectedTipo}
            datosJson={{ idSel: 'tp_vh', idNombre: 'tp_sel' }}
            mensajeNoResultado={'Este tipo no esta disponible'}
            agregar={false}
          />
          {/** 
          <label
            htmlFor="marca"
            className={formData.tp_vh ? 'focused' : ''}
            style={selected === 'tp_vh' ? style : null}
          >
            Tipo
          </label>
          <Typeahead
            id="tp_vh"
            value={selectedTipo}
            onChange={setSelectedTipo}
            onFocus={() => setSelected('tp_vh')}
            onBlur={() => setSelected('')}
            options={tipos.map(({ ds, cd }) => ({
              label: ds,
              value: cd,
              name: 'tp_vh',
              id: 'tp_sel',
            }))}
            placeholder=""
            selected={selectedTipo}
            autoComplete="off"
            className={errors.tp_vh ? 'error' : ''}
          />
          */}
        </div>
        <div className="cliente__formulario-modelo">
          <Typehead
            data={usos}
            funcion={buscarUso}
            nombre={'Uso'}
            setSeleccion={setSelectedUso}
            seleccion={selectedUso}
            datosJson={{ idSel: 'uso_vh', idNombre: 'uso_sel' }}
            mensajeNoResultado={'El uso que escribiste no esta disponible'}
            agregar={false}
          />
          {/** 
          <label
            htmlFor="placa"
            className={formData.uso_vh ? 'focused' : ''}
            style={selected === 'uso_vh' ? style : null}
          >
            Uso
          </label>
          <Typeahead
            id="uso_vh"
            value={selectedUso}
            onChange={setSelectedUso}
            onFocus={() => setSelected('uso_vh')}
            onBlur={() => setSelected('')}
            options={usos.map(({ ds, cd }) => ({
              label: ds,
              value: cd,
              name: 'uso_vh',
              id: 'uso_sel',
            }))}
            placeholder=""
            selected={selectedUso}
            autoComplete="off"
            className={errors.uso_vh ? 'error' : ''}
          />
          */}
        </div>
      </div>
    </form>
  );
};

export default FormularioVehiculo;
