import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import HeaderPages from '../../HeadersPages/HeaderPages';
import Pagination from '../../Pagination/Pagination';
import MostrarInfo from './MostrarInfo/MostrarInfo';
import DashboardEsqueletos from '../../Esqueletos/DashboardEsqueletos';

const DashboardTabla = ({
  vehiContratos,
  cargandoContratos,
  setBusqueda,
  busqueda,
  opcion,
  setOpcion,
  setBuscar,
}) => {
  const [datos, setDatos] = useState([]);
  const [page, setPage] = useState();
  const [contador, setContador] = useState(0);
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const [infoRechazo, setInfoRechazo] = useState('');
  const [filtroEstatus, setFiltroEstatus] = useState('TODOS');
  const [seconds, setSeconds] = useState(15);

  const searchInputRef = useRef(null);

  useEffect(() => {
    const filtrarPorEstatus = (contratos, estatus) => {
      if (estatus === 'TODOS') return contratos;
      return contratos.filter(
        contrato => contrato?.contratos[0]?.estatus === estatus
      );
    };

    const contratosFiltrados = filtrarPorEstatus(vehiContratos, filtroEstatus);

    const grupos = contratosFiltrados?.reduce((acc, _, index) => {
      if (index % 10 === 0) {
        acc.push(contratosFiltrados?.slice(index, index + 10));
      }
      return acc;
    }, []);

    setDatos(grupos);
    setPage(grupos?.length - 1);
  }, [vehiContratos, filtroEstatus]);

  const handleClickFactura = (id_co, id_fc) => {
    localStorage.setItem('linkState', JSON.stringify({ id_co, id_fc }));
  };

  const handleClickContrato = id_co => {
    localStorage.setItem('linkStateContrato', JSON.stringify({ id_co }));
  };

  const handleClickFacturaDescarga = (id_co, id_fc, pl_vh) => {
    localStorage.setItem(
      'linkStateFacturaDescarga',
      JSON.stringify({ id_co, id_fc, pl_vh })
    );
  };

  const handleClickContratoDescarga = (id_co, pl_vh) => {
    localStorage.setItem(
      'linkStateContratoDescarga',
      JSON.stringify({ id_co, pl_vh })
    );
  };

  const opcionHandler = e => {
    e.preventDefault();
    const { value } = e.target;
    setOpcion(value);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const buscadorDeContratos = e => {
    e.preventDefault();
    const { value } = e.target;
    setBusqueda(value);
  };

  const buscarParams = e => {
    e.preventDefault();
    const urlContratos = `v1/getHistoryForUser?p1=${busqueda}&p2=&p3=&p4=&op=${opcion}&token=`;
    setBuscar(urlContratos);
  };
  return (
    <div className="dashboardTabla">
      <MostrarInfo
        mostrarInfo={mostrarInfo}
        setMostrarInfo={setMostrarInfo}
        infoRechazo={infoRechazo}
        seconds={seconds}
        setSeconds={setSeconds}
      />
      <HeaderPages
        titulo={'Tus Asistencias'}
        info={'Haz clic en el que quieres ver o descargar.'}
      />
      <Link to="/proceso/clases">
        <button className="dashboardInfo__button">COMPRAR </button>
      </Link>

      <div className="filtroEstatus">
        <label htmlFor="estatus">Filtrar por estado </label>{' '}
        <select
          id="estatus"
          value={filtroEstatus}
          onChange={e => setFiltroEstatus(e.target.value)}
        >
          <option value="TODOS">TODOS</option>
          <option value="PENDIENTE">PENDIENTE</option>
          <option value="ACTIVO">ACTIVO</option>
          <option value="RECHAZADO">RECHAZADO</option>
        </select>
      </div>
      <div className="dashboardTabla__busqueda">
        <div className="dashboardTabla__busqueda-contenedor">
          <input
            ref={searchInputRef}
            type="search"
            onChange={buscadorDeContratos}
            placeholder={'Criterio de búsqueda'}
          />
          <button onClick={buscarParams}>BUSCAR</button>
        </div>
        <div className="dashboardTabla__busqueda-opciones">
          <label>
            <input
              name={'filtro'}
              type="radio"
              checked={opcion === '2'}
              value={'2'}
              onChange={opcionHandler}
            />{' '}
            Placa
          </label>
          <label>
            <input
              name={'filtro'}
              value={'3'}
              type="radio"
              checked={opcion === '3'}
              onChange={opcionHandler}
            />{' '}
            Marca
          </label>
          <label>
            <input
              name={'filtro'}
              type="radio"
              checked={opcion === '4'}
              value={'4'}
              onChange={opcionHandler}
            />{' '}
            Modelo
          </label>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>Placa</td>
            <td className="opcional">Año</td>
            <td className="opcional">Marca</td>
            <td className="opcional">Modelo</td>
            <td className="opcional">Color</td>
            <td className="opcional">Cod.Contrato</td>
            <td className="opcional">Fecha.Ini</td>
            <td className="opcional">Fecha.Fin</td>
            <td>Estado</td>
            <td>Acci&oacute;n</td>
          </tr>
        </thead>
        <tbody>
          {cargandoContratos ? (
            <DashboardEsqueletos />
          ) : (
            datos &&
            datos[contador]?.map((x, indice) => {
              let estado = x?.contratos[0]?.estatus;
              const partes = x.contratos[0].cd_co?.split('-');
              const resultado =
                partes && partes.length >= 5 ? partes[0] + '-' + partes[4] : '';
              return (
                <tr key={indice}>
                  <td>{x.pl_vh}</td>
                  <td className="opcional">{x.an_vh}</td>
                  <td className="opcional">{x.ds_mc}</td>
                  <td className="opcional"> {x.ds_md}</td>
                  <td className="opcional">{x.cl_vh}</td>
                  <td className="opcional">{resultado}</td>
                  <td className="opcional">{x.contratos[0].fc_in_co}</td>
                  <td className="opcional">
                    {estado === 'ACTIVO' ? x.contratos[0].fc_fi_co : ''}
                  </td>
                  <td className={`${estado}`}>{estado}</td>
                  <td>
                    {estado === 'VENCIDO' ? (
                      <span>Tienes que renovar</span>
                    ) : estado === 'PENDIENTE' ? (
                      <button disabled={true}>ESPERANDO...</button>
                    ) : estado === 'RECHAZADO' ? (
                      <button
                        className="informacion"
                        onClick={() => {
                          setMostrarInfo(true);
                          setInfoRechazo(x.contratos[0].ob_ac);
                          setSeconds(15);
                        }}
                      >
                        INFORMACI&Oacute;N
                      </button>
                    ) : (
                      <div className="dashboardTabla__links">
                        <Link
                          to={`/dashboard/contrato/pdf`}
                          target="_blank"
                          onClick={() =>
                            handleClickContrato(x.contratos[0].id_co)
                          }
                          className="contrato-link"
                        >
                          CONTRATO
                        </Link>
                        <Link
                          to={`/dashboard/contrato/pdfac`}
                          state={{ test: 'test' }}
                          onClick={() =>
                            handleClickFactura(
                              x.contratos[0].id_co,
                              x.contratos[0].id_fc
                            )
                          }
                          target="_blank"
                          className="factura-link"
                        >
                          FACTURA
                        </Link>
                        <div className="dashboardTabla__links-mobile">
                          <Link
                            to={'/dashboard/contrato/descargapdf'}
                            target="_blank"
                            className="descargacon"
                            onClick={() =>
                              handleClickContratoDescarga(
                                x.contratos[0].id_co,
                                x.pl_vh
                              )
                            }
                          >
                            DESCARGAR CONTRATO
                          </Link>
                          <Link
                            to={'/dashboard/contrato/descargapdfac'}
                            target="_blank"
                            className="descargafac"
                            onClick={() =>
                              handleClickFacturaDescarga(
                                x.contratos[0].id_co,
                                x.contratos[0].id_fc,
                                x.pl_vh
                              )
                            }
                          >
                            DESCARGAR FACTURA
                          </Link>
                          {/** <ContratoMobile contratoId={x.contratos[0].id_co} />
                           <FacturaMobile
                            facturaId={x.contratos[0].id_fc}
                            contratoId={x.contratos[0].id_co}
                          />*/}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <Pagination
        page={page}
        contador={contador}
        setContador={setContador}
        datos={datos}
      />
    </div>
  );
};

export default DashboardTabla;
