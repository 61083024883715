import { useState } from 'react';
import NuevaContraseña from './NuevaContrasena/NuevaContrasena';
import ModalCorrecto from '../ModalCorrecto/ModalCorrecto';
import NavbarErrors from '../NavbarLite/NavbarErrors';
const NecesitaContrasena = () => {
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const [newEmail] = useState(userProfile?.em_us);
  const [id] = useState(userProfile?.id_us);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  return (
    <div className="necesitas">
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <div>
        <NuevaContraseña
          setNavbarErrores={setNavbarErrores}
          setMostrarErrores={setMostrarErrores}
          setMostrarModalCorrecto={setMostrarModalCorrecto}
          setMensajeCorrecto={setMensajeCorrecto}
          email={newEmail}
          id={id}
        />
      </div>
    </div>
  );
};

export default NecesitaContrasena;
