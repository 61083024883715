import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    width: '500px',
    textAlign: 'center',
    marginTop: '2px',
  },
  fontTitle: {
    fontSize: '7px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  font: {
    fontSize: '5.5px',
  },
  fontBold: {
    fontSize: '5.5px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});

const FacturaCopia = () => {
  return (
    <div>
      <View style={styles.container}>
        <Text style={styles.fontTitle}>ORIGINAL</Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.fontBold}>
          Esta Factura va sin Tachadura ni Enmienda - Original Blanca - Copia
          sin Derecho a Crédito Fiscal
        </Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.font}>
          impreso por:
          <Text style={styles.fontBold}>
            {' '}
            Imprenta Occidental de Venezuela , c.a.
          </Text>{' '}
          Rif. J-29583772-0 Dirección: Calle 79A No. 11-22 Sector Las Veritas,
          Maracaibo. Telf.: (0261)714.83.23{' '}
          <Text style={styles.fontBold}> Nº PROVIDENCIA:SENIAT 04/01051 </Text>
        </Text>
      </View>
      <View style={styles.container}>
        <Text style={styles.font}>
          de Fecha: 28-10-2008 Control desde el No.00-007000 hasta el
          No.00-009000 Factura desde el No.007000 hasta el No.09000 Serie C -
          Región Zuliana - Fecha Elaboración: 02/01/2024
        </Text>
      </View>
    </div>
  );
};

export default FacturaCopia;
