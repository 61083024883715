import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Protected from './components/Routes/Protected';

import BackToTopButton from './components/ScrollToTop/BackToTopButton';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import LeerMas from './components/LeerMas/LeerMas';
import Administracion from './components/Administracion/Administracion';
import RecuperarContraseña from './components/Login/RecuperarContraseña';
import ActualizarPerfil from './components/ActualizarPerfil/ActualizarPerfil';
import NecesitaContrasena from './components/NecesitaContrasena/NecesitaContrasena';
import ContraProtect from './components/Routes/ContraProtect';
import Activacion from './components/Activacion/Activacion';
import Terminos from './components/Terminos/Terminos';
import InicioRegistroNavbar from './components/InicioRegistroNavbar/InicioRegistroNavbar';
import NavbarProvider from './context/LoginContext';
import ProcesoProvider from './context/ProcesoContext';
import Clases from './components/Proceso/Pasos/Clases';
import Planes from './components/Proceso/Pasos/Planes';
import Cliente from './components/Proceso/Pasos/Cliente';
import Propietario from './components/Proceso/Pasos/Propietario';
import Vehiculo from './components/Proceso/Pasos/Vehiculo';
import Producto from './components/Proceso/Pasos/Productos';
import Resumen from './components/Proceso/Pasos/Resumen';
import Pago from './components/Proceso/Pasos/Pago/Pago';
import DescuentosYPromos from './components/Proceso/Pasos/DescuentosYPromos';
import Sucursales from './components/Sucursales/Sucursales';
import VentasDiarias from './components/Reportes/VentasDiarias';
import Inicio from './components/Inicio/Inicio';
import VentasDiariasResultado from './components/Reportes/VentasDiariasResultado';
import Soporte from './components/Soporte/Soporte';
import consultarApi from './helpers/consultarApi';
import { TokenContext } from './context/TokenContext';
import Contrato from './components/Dashboard/Contrato';
import PDFContratoVista from './components/Dashboard/PDFContratoVista/PDFContratoVista';
import PDFFacturaVista from './components/Dashboard/PDFFacturaVista/PDFFacturaVista';
import RecuperaContrasena from './components/IniciarYRegistro/RecuperarContraseña/RecuperaContrasena';
import VentasDiariasPorAgente from './components/Reportes/VentasDiariasPorAgente/VentasDiariasPorAgente';
import VentasDiariasPorAgenteResultado from './components/Reportes/VentasDiariasPorAgente/VentasDiariasPorAgenteResultado';
import GuardaReferido from './components/GuardaReferido/GuardaReferido';
import PDFContratoDescarga from './components/Dashboard/PDFContratoVista/PDFContratoDescarga';
import PDFFacturaDescarga from './components/Dashboard/PDFContratoVista/PDFFacturaDescarga';
import CalculoComisiones from './components/CalculoComisiones/CalculoComisiones';
import CalculoComisionesResultado from './components/CalculoComisiones/CalculoComisionesResultado';
import LoadingStart from './components/LoadingStart/LoadingStart';
import DescuentoAdmin from './components/DescuentoAdmin/DescuentoAdmin';

const AppRoutes = ({
  tokenI,
  setTokenExp,
  setTokenController,
  tokenController,
  tokenExp,
}) => {
  const [loginStatus, setLoginStatus] = useState({
    data: {
      value: '',
      message: '',
    },
  });

  const components = {
    Administracion,
    Dashboard,
    RecuperarContraseña,
    NecesitaContrasena,
    ActualizarPerfil,
    VentasDiarias,
    VentasDiariasPorAgente,
    Inicio,
    Soporte,
    CalculoComisiones,
    DescuentoAdmin,
  };

  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [menu, setMenu] = useState();
  const [privado, setPrivado] = useState(false);
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));

  const ComponenteDinamico = ({ component }) => {
    const Componente = components[component];
    if (!Componente) return <>Nada</>;
    return <Componente />;
  };

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_MENU,
      0,
      setMenu,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token, privado]);
  if (menu === null) {
    return <LoadingStart />;
  }
  const datosOrganizados = [];

  menu &&
    menu?.forEach(item => {
      item.op_mn.forEach(subitem => {
        subitem.op.forEach(subsubitem => {
          datosOrganizados.push({
            fn_pg: subsubitem.fn_pg,
            ur_pg: subsubitem.ur_pg,
          });
        });
      });
    });

  return (
    <BrowserRouter>
      <NavbarProvider
        setPrivado={setPrivado}
        token={token}
        login={login}
        setTokenExp={setTokenExp}
        setTokenController={setTokenController}
        setToken={setToken}
        tokenExp={tokenExp}
        tokenController={tokenController}
      >
        <GuardaReferido />
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home tokenI={tokenI} />} />
          <Route
            path="/login/:id"
            element={
              <InicioRegistroNavbar
                loginStatus={loginStatus}
                setLoginStatus={setLoginStatus}
              />
            }
          />{' '}
          <Route element={<ContraProtect />}>
            <Route path="inicio" element={<Inicio />} />
          </Route>
          <Route
            path="/proceso/*"
            element={
              <ProcesoProvider>
                <Routes>
                  <Route element={<ContraProtect />}>
                    <Route path="clases" element={<Clases />} />
                    <Route path="planes" element={<Planes />} />
                    <Route path="cliente" element={<Cliente />} />
                    <Route path="propietario" element={<Propietario />} />
                    <Route path="vehiculo" element={<Vehiculo />} />
                    <Route path="producto" element={<Producto />} />
                    <Route path="resumen" element={<Resumen />} />
                    <Route element={<Protected />}>
                      <Route path="pago" element={<Pago />} />
                    </Route>
                    <Route path="descuento" element={<DescuentosYPromos />} />
                  </Route>
                </Routes>
              </ProcesoProvider>
            }
          />
          {privado === true && datosOrganizados.length > 0 ? (
            <>
              {datosOrganizados.map((x, i) => (
                <Route
                  key={i}
                  path={x.ur_pg}
                  element={<ComponenteDinamico component={x.fn_pg} />}
                />
              ))}
              <Route path="/*" element={<NotFound />} />
            </>
          ) : (
            <Route path="/*" element={<LoadingStart />} />
          )}
          <Route
            path="/ventasdiarias/resultado/:fechaini/:fechafin"
            element={<VentasDiariasResultado />}
          />
          <Route
            path="/ventasdiariasporagente/resultado"
            element={<VentasDiariasPorAgenteResultado />}
          />
          <Route path="/terminos" element={<Terminos />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/leermas" element={<LeerMas />} />
          <Route path="/activacion" element={<Activacion />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/sucursales" element={<Sucursales />} />
          <Route path="/necesita" element={<NecesitaContrasena />} />
          <Route path="/dashboard/contrato" element={<Contrato />} />
          <Route
            path="/calculocomisiones/resultado"
            element={<CalculoComisionesResultado />}
          />
          <Route
            path="/dashboard/contrato/pdf/:id_co?"
            element={<PDFContratoVista />}
          />
          <Route
            path="/dashboard/contrato/pdfac"
            element={<PDFFacturaVista />}
          />
          <Route
            path="/dashboard/contrato/descargapdf"
            element={<PDFContratoDescarga />}
          />
          <Route
            path="/dashboard/contrato/descargapdfac"
            element={<PDFFacturaDescarga />}
          />
          <Route path="/recuperacontrasena" element={<RecuperaContrasena />} />
        </Routes>

        <BackToTopButton />
      </NavbarProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
