import React from 'react';

const PlanEsqueleto = () => {
  return Array(6)
    .fill(0)
    .map((_, index) => (
      <div className="planesqueleto" key={index}>
        <div className="planesqueleto__icon" />
        <span className="planesqueleto__text" />
        <span className="planesqueleto__text2"> </span>
      </div>
    ));
};

export default PlanEsqueleto;
