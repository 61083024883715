import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import Arial from '../../../styles/Fonts/arial.ttf';
import ArialBold from '../../../styles/Fonts/arial-bold.ttf';

Font.register({ family: 'Arial', src: Arial });

Font.register({
  family: 'Arial',
  fonts: [
    { src: Arial, fontStyle: 'normal', fontWeight: 'normal' },
    { src: ArialBold, fontStyle: 'bold' },
  ],
});

const stylesInt = StyleSheet.create({
  fontBold: {
    fontFamily: 'Arial',
    fontStyle: 'bold',
    fontSize: '8px',
  },
});

const ContratoVehi = ({ styles, contratoData }) => {
  return (
    <div style={styles.container}>
      <div style={styles.containerSon}>
        <div style={styles.containerSon2}>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              PLACA: {contratoData && contratoData?.pl_vh?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              MARCA: {contratoData && contratoData?.ds_mc?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              MODELO: {contratoData && contratoData?.ds_md?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              COLOR: {contratoData && contratoData?.cl_vh?.toUpperCase()}
            </Text>
          </View>
        </div>

        <div style={styles.containerSon2}>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              TIPO: {contratoData && contratoData?.ds_tp_vh?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              USO: {contratoData && contratoData?.ds_uso?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              CLASE: {contratoData && contratoData?.ds_cl_vh}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              AÑO: {contratoData && contratoData?.an_vh}
            </Text>
          </View>
        </div>

        <div style={styles.containerSon2}>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              PESO: {contratoData && contratoData?.pe_vh?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              PUESTO: {contratoData && contratoData?.nr_pu_vh?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              S/N. CARR: {contratoData && contratoData?.se_ca_vh?.toUpperCase()}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={stylesInt.fontBold}>
              S/N. MOTOR:{' '}
              {contratoData && contratoData?.se_mo_vh?.toUpperCase()}
            </Text>
          </View>
        </div>
      </div>
    </div>
  );
};

export default ContratoVehi;
