import { useRef } from 'react';
import { BiSearch } from 'react-icons/bi';

const Buscador = ({ setValorBusqueda, valorBusqueda }) => {
  const input = useRef();
  const handleSearch = () => {
    setValorBusqueda(input.current.value);
  };
  return (
    <div className="buscador">
      <div className={'buscador__container'}>
        <input
          placeholder={valorBusqueda}
          ref={input}
          className="buscador__container-input"
          type="text"
          onChange={() => handleSearch()}
        ></input>
        <BiSearch className="buscador__container-icon" />
      </div>
    </div>
  );
};

export default Buscador;
