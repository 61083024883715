import {
  BsPersonLinesFill,
  BsPersonFillCheck,
  BsFillClipboardDataFill,
  BsFillBagPlusFill,
  BsFileTextFill,
  BsCartFill,
  BsPostcardHeartFill,
} from 'react-icons/bs';
const Pasos = ({ paso, proceso }) => {
  return (
    <div className={`proceso__pasos-card`}>
      <div className={`proceso__proceso`}>
        <div className="proceso__proceso-barra">
          <div className="proceso__proceso-barra-icons">
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 0 ? 'active' : ' '
              }`}
            >
              <BsPersonLinesFill />
            </div>
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 1 ? 'active' : ' '
              }`}
            >
              <BsPersonFillCheck />
            </div>
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 2 ? 'active' : ' '
              }`}
            >
              <BsFillClipboardDataFill />
            </div>
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 3 ? 'active' : ' '
              }`}
            >
              <BsFillBagPlusFill />
            </div>
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 4 ? 'active' : ' '
              }`}
            >
              <BsPostcardHeartFill />
            </div>
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 5 ? 'active' : ' '
              }`}
            >
              <BsFileTextFill />
            </div>
            <div
              className={`proceso__proceso-barra-icons-icon ${
                paso >= 6 ? 'active' : ' '
              }`}
            >
              <BsCartFill />
            </div>
          </div>
          <div
            className="proceso__proceso-barra-progreso"
            style={{
              width: `${(paso / (proceso.length - 1)) * 100}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Pasos;
