import React from 'react';
import { BsCheck, BsX } from 'react-icons/bs';

const Switch = ({ isChecked, setIsChecked }) => {
  return (
    <div
      className={`switch ${isChecked ? 'active' : null}`}
      onClick={() => {
        isChecked ? setIsChecked(false) : setIsChecked(true);
      }}
    >
      <div className="switch__item"></div>
      <span className={`switch__button ${isChecked ? 'active' : null}`}>
        {isChecked ? (
          <BsCheck className="switch__button-checked" />
        ) : (
          <BsX className="switch__button-notchecked" />
        )}
      </span>
    </div>
  );
};

export default Switch;
