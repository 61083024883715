import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

const Fact = ({ title, valor, intervalo, faIcons, delay, offset }) => {
  const [time, setTime] = useState(0);
  const [active, setActive] = useState(true);
  useEffect(() => {
    if (offset >= 500) {
      let interval = null;

      if (active) {
        interval = setInterval(() => {
          if (time >= valor) {
            setTime(valor);
            setActive(false);
            clearInterval(interval);
          } else {
            setTime((time) => time + 10);
          }
        }, intervalo);
      } else {
        setActive(false);
        clearInterval(interval);
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [time, offset]);

  return (
    <Col lg={3} md={6} className="wow fadeInUp" data-wow-delay={delay}>
      <div className="fact-item fact_card rounded text-center h-100 p-5">
        <i className={`${faIcons} fa-4x text-primary mt-4 mb-4`}></i>
        <h5 className="mb-3">{title}</h5>
        <h1 className="display-5 mb-3" data-toggle="counter-up">
          {time}
        </h1>
      </div>
    </Col>
  );
};

export default Fact;
