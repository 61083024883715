import axios from '../../../api/axios';
import actualizarTokenNoLog from '../../../helpers/actualizarTokenNoLog';

const AddNewUser = async (
  registroForm,
  setNavbarErrores,
  setSingErrId,
  singErrId,
  setRegistro,
  setToken
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const { nb_us, ap_us, em_us, us_us, pw_us, sx_us } = registroForm;

  const json = JSON.stringify({
    usuario: {
      nb_us: nb_us.toUpperCase(),
      ap_us: ap_us.toUpperCase(),
      em_us: em_us.toUpperCase(),
      us_us: us_us.toUpperCase(),
      pw_us: pw_us,
      sx_us: sx_us,
      tp_us: 1,
    },
    trace: '0',
    token: token,
  });

  const handleResponse = async data => {
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    setRegistro(true);
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      actualizarTokenNoLog(setToken);
    } else {
      setNavbarErrores([error.response.data.message]);
      setSingErrId(singErrId + 1);
      console.log(error);
      setRegistro(false);
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_NUEVO_USUARIO, json);
    handleResponse(data);
  } catch (error) {
    handleError(error);
  }
};

export default AddNewUser;
