import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: '250px',
    top: '75px',
    border: '2px solid black',
    borderRadius: '10px',
    padding: '5px',
    width: '300px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  font: {
    fontSize: '10px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
  containerFechas: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    gap: '3px',
  },
});
const ContratoVigencia = ({ contratoData }) => (
  <View style={styles.container}>
    <Text style={styles.font}>VIGENCIA ANUAL</Text>
    <div style={styles.containerFechas}>
      <View>
        <Text style={styles.font}>DESDE:</Text>
      </View>
      <View>
        <Text style={styles.font}>{contratoData?.fc_inicio}</Text>
      </View>

      <View>
        <Text style={styles.font}>HASTA:</Text>
      </View>
      <View>
        <Text style={styles.font}>{contratoData?.fc_final}</Text>
      </View>
    </div>
  </View>
);
export default ContratoVigencia;
