import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    width: "540px",
    justifyContent: "flex-start",
    alignItems: "start",
    marginTop: "5px",
  },
  font: {
    fontSize: "6.5px",
  },
});
const FacturaIns = () => (
  <View style={styles.container}>
    <Text style={styles.font}>
      Inscrita en el registro mercantil cuarto de la circunscripción judicial de
      maracaibo edo-zulia registrado bajo el nº. 36 tomo 59-a
    </Text>
  </View>
);
export default FacturaIns;
