import { useState, useEffect, useRef } from 'react';
import NombrePerfil from '../ActualizarPerfil/Nombre/NombrePerfil';
import ContraseñaPerfil from './Contraseña/ContraseñaPerfil';
import NavbarErrors from '../NavbarLite/NavbarErrors';
import ModalCorrecto from '../ModalCorrecto/ModalCorrecto';
import { FaUserEdit, FaKey, FaRegHandshake } from 'react-icons/fa';
import CodigoAgente from './Codigo/CodigoAgente';
import { IoIosMore } from 'react-icons/io';

const ActualizarPerfil = () => {
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [opcion, setOpcion] = useState(1);
  const menuMbRef = useRef(null);
  const [perfil, setPerfil] = useState();
  const [opcionesMb, setOpcionesMb] = useState(false);

  useEffect(() => {
    const perfilObtenido = localStorage.getItem('RCV_DIGITAL_PROFILEOBJ');
    if (perfilObtenido) {
      const perfilParseado = JSON.parse(perfilObtenido);
      setPerfil(perfilParseado);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuMbRef.current && !menuMbRef.current.contains(event.target)) {
        setOpcionesMb(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuMbRef]);

  const opciones = [
    {
      opcion: 1,
      nombre: 'NOMBRE',
      icono: <FaUserEdit />,
    },
    {
      opcion: 2,
      nombre: 'CONTRASEÑA',
      icono: <FaKey />,
    },
    perfil?.ur_ag
      ? {
          opcion: 3,
          nombre: 'CODIGO AGENTE',
          icono: <FaRegHandshake />,
        }
      : {},
  ];

  return (
    <div className="perfil">
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <div className="perfil__barra">
        <ul>
          {opciones?.map((x, i) => {
            return (
              <li key={i} onClick={() => setOpcion(x.opcion)}>
                {x.icono} {x.nombre}
              </li>
            );
          })}
        </ul>
      </div>
      <ul
        className="perfil__barramb"
        ref={menuMbRef}
        onClick={() => {
          opcionesMb ? setOpcionesMb(false) : setOpcionesMb(true);
        }}
      >
        {opcionesMb ? (
          opciones?.map((x, i) => {
            return (
              <li key={i} onClick={() => setOpcion(x.opcion)}>
                {x.icono} {x.nombre}
              </li>
            );
          })
        ) : (
          <li className="perfil__barramb-opciones">
            OPCIONES <IoIosMore />
          </li>
        )}
      </ul>

      <div className="perfil__contenedor">
        {opcion === 1 ? (
          <NombrePerfil
            setNavbarErrores={setNavbarErrores}
            setMostrarErrores={setMostrarErrores}
            setMensajeCorrecto={setMensajeCorrecto}
            setMostrarModalCorrecto={setMostrarModalCorrecto}
          />
        ) : null}
        {opcion === 2 ? (
          <ContraseñaPerfil
            setNavbarErrores={setNavbarErrores}
            setMostrarErrores={setMostrarErrores}
            setMensajeCorrecto={setMensajeCorrecto}
            setMostrarModalCorrecto={setMostrarModalCorrecto}
          />
        ) : null}
        {opcion === 3 && perfil.ur_ag ? <CodigoAgente perfil={perfil} /> : null}
      </div>
    </div>
  );
};

export default ActualizarPerfil;
