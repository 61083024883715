import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
const MostrarInfo = ({
  mostrarInfo,
  setMostrarInfo,
  infoRechazo,
  seconds,
  setSeconds,
}) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      setMostrarInfo(false);
    }
  }, [seconds]);
  return (
    <div className={`mostrarInfo ${mostrarInfo}`}>
      <div
        className="mostrarInfo__sombra"
        onClick={() => setMostrarInfo(false)}
      ></div>
      <div className="mostrarInfo__carta">
        <div className="mostrarInfo__carta-header">
          <h5>
            Contrato <span>rechazado</span>.
          </h5>
          <IoClose onClick={() => setMostrarInfo(false)} />
        </div>
        <div className="mostrarInfo__carta-info">
          Motivo: <span>{infoRechazo}</span>
        </div>
        <div></div>
        <div className="mostrarInfo__carta-footer">
          <button onClick={() => setMostrarInfo(false)}>
            ENTENDIDO <span>{seconds}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MostrarInfo;
