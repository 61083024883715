import { useEffect, useState } from 'react';
import { BsFillClipboardCheckFill } from 'react-icons/bs';
const ModalCorrecto = ({
  mostrarModalCorrecto,
  setMostrarModalCorrecto,
  mensajeCorrecto,
}) => {
  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSeconds(15);
  }, [mostrarModalCorrecto]);

  useEffect(() => {
    if (seconds === 0) {
      setMostrarModalCorrecto(false);
    }
  }, [seconds]);

  return (
    <div className={`modalCorrecto ${mostrarModalCorrecto ? 'active' : ''}`}>
      <div
        className="modalCorrecto__sombra"
        onClick={() => setMostrarModalCorrecto(false)}
      ></div>
      <div className="modalCorrecto__card">
        <div className="modalCorrecto__card-title">
          <BsFillClipboardCheckFill />
        </div>
        <div className="modalCorrecto__card-descripcion">
          <p className="modalCorrecto__card-descripcion-parrafo">
            {mensajeCorrecto}
          </p>
        </div>
        <div className="modalCorrecto__card-footer">
          <button onClick={() => setMostrarModalCorrecto(false)}>
            <span>CONTINUAR</span>
            <div className="modalCorrecto__card-footer-conteo">{seconds}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalCorrecto;
