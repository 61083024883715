import Switch from '../../Switch/Switch';
import { useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
function RegistroForm({
  registrarse,
  datosDelFormulario,
  registroForm,
  usuRef,
  isChecked,
  setIsChecked,
  setShow,
  setFullscreen,
  loading,
}) {
  const handleShow = breakpoint => {
    setFullscreen(breakpoint);
    setShow(true);
  };
  const [showContra, setShowContra] = useState(false);
  const [selected, setSelected] = useState('');
  const style = {
    fontWeight: 700,
    top: '-16px',
    left: '0',
    fontSize: '0.8em',
    backgroundColor: 'transparent',
    color: 'black',
  };
  return (
    <form className={`registro__container-form `} onSubmit={registrarse}>
      <div className="registro__container-form-doble">
        <div className="registro__container-form-doble-contenedor">
          <label
            htmlFor="nb_us"
            className={registroForm.nb_us ? 'focused' : ''}
            style={selected === 'nb_us' ? style : null}
          >
            Nombres
          </label>
          <input
            value={registroForm.nb_us}
            name="nb_us"
            type="text"
            onChange={datosDelFormulario}
            onFocus={() => setSelected('nb_us')}
            onBlur={() => setSelected('')}
            autoComplete="off"
          ></input>
        </div>
        <div className="registro__container-form-doble-contenedor">
          <label
            htmlFor="ap_us"
            className={registroForm.ap_us ? 'focused' : ''}
            style={selected === 'ap_us' ? style : null}
          >
            Apellidos
          </label>
          <input
            value={registroForm.ap_us}
            name="ap_us"
            type="text"
            onChange={datosDelFormulario}
            onFocus={() => setSelected('ap_us')}
            onBlur={() => setSelected('')}
            autoComplete="off"
          ></input>
        </div>
      </div>
      <div className="registro__container-form-radio">
        <label htmlFor="" className="registro__container-form-radio-label">
          Sexo
        </label>
        <div className="registro__container-form-radio-options">
          <label htmlFor="">Masculino:</label>
          <input
            type="radio"
            name="group1"
            value="M"
            defaultChecked
            id="formSingUpOP2"
            onChange={datosDelFormulario}
            autoComplete="off"
          ></input>
          <label htmlFor="">Femenino</label>
          <input
            onChange={datosDelFormulario}
            type="radio"
            name="group1"
            value="F"
            id="formSingUpOP1"
            autoComplete="off"
          ></input>
        </div>
      </div>
      <div className="registro__container-form-contenedor">
        <label
          htmlFor="em_us"
          className={registroForm.em_us ? 'focused' : ''}
          style={selected === 'em_us' ? style : null}
        >
          Correo electr&oacute;nico
        </label>
        <input
          name="em_us"
          type="email"
          value={registroForm.em_us}
          onChange={datosDelFormulario}
          onFocus={() => setSelected('em_us')}
          onBlur={() => setSelected('')}
          autoComplete="off"
        ></input>
      </div>
      <div className="registro__container-form-contenedor">
        <label
          htmlFor="us_us"
          className={registroForm.us_us ? 'focused' : ''}
          style={selected === 'us_us' ? style : null}
        >
          Usuario
        </label>
        <input
          name="us_us"
          type="text"
          value={registroForm.us_us}
          onChange={datosDelFormulario}
          ref={usuRef}
          onFocus={() => setSelected('us_us')}
          onBlur={() => setSelected('')}
          autoComplete="off"
        ></input>
      </div>
      <div className="registro__container-form-doble">
        <div className="registro__container-form-doble-contenedor">
          <label
            htmlFor="pw_us"
            className={registroForm.pw_us ? 'focused' : ''}
            style={selected === 'pw_us' ? style : null}
          >
            Contraseña
          </label>
          <input
            value={registroForm.pw_us}
            name="pw_us"
            type={showContra === false ? 'password' : 'text'}
            onChange={datosDelFormulario}
            onFocus={() => setSelected('pw_us')}
            onBlur={() => setSelected('')}
            autoComplete="off"
          ></input>
          <span
            className="iniciarSesion__container-form-contraseña-mostrar"
            onClick={() =>
              showContra === false ? setShowContra(true) : setShowContra(false)
            }
          >
            {!showContra ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        <div className="registro__container-form-doble-contenedor">
          <label
            htmlFor="pw_cn_us"
            className={registroForm.pw_cn_us ? 'focused' : ''}
            style={selected === 'pw_cn_us' ? style : null}
          >
            Confirma la contraseña
          </label>
          <input
            value={registroForm.pw_cn_us}
            onFocus={() => setSelected('pw_cn_us')}
            onBlur={() => setSelected('')}
            name="pw_cn_us"
            type={showContra === false ? 'password' : 'text'}
            onChange={datosDelFormulario}
            autoComplete="off"
          ></input>
          <span
            className="iniciarSesion__container-form-contraseña-mostrar"
            onClick={() =>
              showContra === false ? setShowContra(true) : setShowContra(false)
            }
          >
            {!showContra ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
      </div>
      <div className="registro__container-form-footer">
        <Switch
          name="ac_tc"
          onChange={datosDelFormulario}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />
        <div className="registro__container-form-footer-contenedor">
          Aceptar{' '}
          <span onClick={handleShow}>t&eacute;rminos y condiciones</span>
        </div>
      </div>

      <button
        type="submit"
        className="registro__container-form-button"
        disabled={loading}
      >
        {loading ? (
          <div className="lds-ellipsisg">
            <div style={{ backgroundColor: 'white' }}></div>
            <div style={{ backgroundColor: 'white' }}></div>
            <div style={{ backgroundColor: 'white' }}></div>
            <div style={{ backgroundColor: 'white' }}></div>
          </div>
        ) : (
          'REGÍSTRARSE AHORA'
        )}
      </button>
    </form>
  );
}

export default RegistroForm;
