import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import { FechaActual } from '../components/sections/FechaActual';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <br />
        <br />
        <Container
          fluid="xxl"
          className="py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <Container className="text-center">
            <Row className="justify-content-center">
              <Col className="col-lg-6">
                <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                <h1 className="display-1">404</h1>
                <h1 className="mb-4">P&aacute;gina no encontrada</h1>
                <p className="mb-4">
                  ¡Lo sentimos, la p&aacute;gina que has buscado no existe en
                  nuestro sitio web!
                </p>
                <Button
                  variant="primary"
                  size="lg"
                  onClick={e => {
                    navigate('/');
                  }}
                >
                  VOLVER
                </Button>
              </Col>
            </Row>
          </Container>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default NotFound;
