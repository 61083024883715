const BancoSeleccion = ({ bancoElegido, obtenerBanco, bancos }) => {
  return (
    <select
      value={bancoElegido ? bancoElegido.ds_bc : ''}
      onChange={obtenerBanco}
    >
      <option>Selecciona el banco...</option>
      {bancos &&
        bancos.map((x, indice) => {
          return (
            <option key={indice} value={x.ds_bc}>
              {x.ds_bc}
            </option>
          );
        })}
    </select>
  );
};

export default BancoSeleccion;
