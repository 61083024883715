import React, { useState, useEffect } from 'react';
import postTokenNuevo from '../../helpers/postTokenNuevo';

const VencimientoDeToken = ({ setToken, tokenVencido, setTokenCambiar }) => {
  const cancelar = () => {
    localStorage.removeItem('RCV_DIGITAL_PLAN_AN');
    localStorage.removeItem('LAST_LOCATION');
    localStorage.removeItem('PROPIETARIO_ID');
    localStorage.removeItem('CLIENT_ID');
    localStorage.removeItem('RCV_DIGITAL_CLIENTE');
    localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
    localStorage.removeItem('RCV_DIGITAL_VEHICULO');
    localStorage.removeItem('VEHICULO_ID');
    localStorage.removeItem('RCV_DIGITAL_CARRITO');
    localStorage.removeItem('RCV_DIGITAL_REGISTROA');
    localStorage.removeItem('RCV_CODIGO_DESCUENTO');
    localStorage.removeItem('RCV_CODIGO_USDESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_LOGIN');
    localStorage.removeItem('RCV_DIGITAL_PROFILEOBJ');
    localStorage.removeItem('RCV_DIGITAL_PASO');
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
    setTokenCambiar(false);
  };

  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      cancelar();
    }
  }, [seconds]);

  const actualizar = () => {
    postTokenNuevo(setToken, tokenVencido, setTokenCambiar, cancelar);
  };
  return (
    <div className="vencimientoDeToken">
      <div className="vencimientoDeToken__carta">
        <div className="vencimientoDeToken__carta-header">
          <span>
            Su sesi&oacute;n ha finalizado, presione{' '}
            <span className="aceptar">ACEPTAR</span> si desea continuar activo.
            ¿Desea actualizar su sesi&oacute;n?
          </span>
        </div>
        <div className="vencimientoDeToken__carta-footer">
          <button className="actualizar" onClick={() => actualizar()}>
            ACEPTAR
          </button>
          <button className="cancelar" onClick={() => cancelar()}>
            CANCELAR <span>{seconds}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default VencimientoDeToken;
