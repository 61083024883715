import React, { useState, useEffect } from 'react';

const Consultar = ({ mostrarGenerar, setMostrarGenerar }) => {
  const [seconds, setSeconds] = useState(15);
  const [captchaValido, setCaptchaValido] = useState(false);
  const [captchaResult, setCaptchaResult] = useState('');

  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSeconds(15);
  }, [mostrarGenerar]);

  useEffect(() => {
    if (seconds === 0) {
      /*setMostrarGenerar(false);*/
    }
  }, [seconds]);

  useEffect(() => {
    generarCaptcha();
  }, []);

  const generarCaptcha = () => {
    const numero1 = Math.floor(Math.random() * 10);
    const numero2 = Math.floor(Math.random() * 10);
    setNum1(numero1);
    setNum2(numero2);
  };

  const handleCaptchaChange = e => {
    setCaptchaResult(e.target.value);
    if (parseInt(e.target.value, 10) === num1 + num2) {
      setCaptchaValido(true);
    } else {
      setCaptchaValido(false);
    }
  };
  return (
    <div className={`consultar ${mostrarGenerar ? 'active' : ''}`}>
      <div
        className="consultar__sombra"
        onClick={e => {
          e.preventDefault();
          setMostrarGenerar(false);
        }}
      ></div>
      <form className="consultar__form">
        <label>
          <span>R.I.F/C&eacute;dula:</span>
          <input type="text" />
        </label>
        <label>
          <span>Placa:</span>
          <input type="text" />
        </label>
        <div className="consultar__form-captcha">
          <span>Resuelve el captcha para continuar</span>
          <div className="consultar__form-captcha-datos">
            <span>{`${num1} + ${num2} =`}</span>{' '}
            <input
              type="number"
              onChange={handleCaptchaChange}
              value={captchaResult}
            />
          </div>
        </div>
        <div className="consultar__form-footer">
          <button
            className="cancelar"
            onClick={e => {
              e.preventDefault();
              setMostrarGenerar(false);
            }}
          >
            CANCELAR
            <span>{seconds}</span>
          </button>
          <input
            disabled={!captchaValido}
            className={`consultar__form-footer-submit ${
              captchaValido ? 'active' : ''
            }`}
            type="submit"
            value={'CONSULTAR'}
          />
        </div>
      </form>
    </div>
  );
};

export default Consultar;
