const CarritoResume = ({ carrito }) => {
  return (
    <div className="resumen__items elegido">
      <div className="resumen__items-header">
        <h5>Productos y/o servicios</h5>
      </div>
      <div className="resumen__items-body">
        <ul className="resumen__items-body-carritem">
          {carrito?.map((x, indice) => {
            return (
              <li key={indice} className="resumen__items-body-carritem-item">
                <span>{x.ds}</span>
                <span>
                  {' '}
                  REF {Number(x.mt_ba).toFixed(2)}x{x.ct}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CarritoResume;
