import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../../../context/TokenContext';
import { LoginContext } from '../../../../context/LoginContext';
import postMunicipioUnico from './postMunicipioUnico';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa';

const AgregarMunicipio = ({
  mostrarAgregarMunicipio,
  setMostrarAgregarMunicipio,
  setAplicarMunicipio,
}) => {
  const [municipioJSON, setMunicipioJSON] = useState({
    id_mn: '',
    ds_mn: '',
  });

  const [loadingMunicipio, setLoadingMunicipio] = useState(false);
  const [municipioVeri, setMunicipioVeri] = useState(false);

  useEffect(() => {
    if (mostrarAgregarMunicipio.estado) {
      setMunicipioJSON({
        ds_mn: mostrarAgregarMunicipio?.valorMunicipio.toUpperCase(),
      });
    }
  }, []);

  const { setTokenCambiar, setTokenVencido, setToken } =
    useContext(TokenContext);

  const estadoElegido = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_ESTADO_SELECCIONADO')
  );

  const { setNavbarErrores, setMostrarErrores } = useContext(LoginContext);

  const verificarMunicipio = form => {
    let errors = {};
    let caracteresEspeciales = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (form.ds_mn.length > 25) {
      errors.ds_mn = 'Excedes el número de caracteres permitidos';
    }
    if (caracteresEspeciales.test(form.ds_mn)) {
      errors.ds_mn = 'No se permiten caracteres especiales';
    }

    if (form.length === 0 || form.ds_mn === '') {
      errors.ds_mn = 'Debe indicar el municipio, por favor verifique.';
    }
    return errors;
  };

  const agregar = e => {
    e.preventDefault();
    const validadorMunicipio = verificarMunicipio(municipioJSON);
    if (Object.keys(validadorMunicipio).length > 0) {
      setNavbarErrores(validadorMunicipio);
      setMostrarErrores(true);
    } else {
      setLoadingMunicipio(true);
      setTimeout(() => {
        postMunicipioUnico(
          municipioJSON,
          setTokenCambiar,
          setTokenVencido,
          setMostrarAgregarMunicipio,
          setToken,
          estadoElegido,
          setMunicipioVeri,
          setAplicarMunicipio,
          setNavbarErrores,
          setMostrarErrores
        ).finally(setLoadingMunicipio(false));
      }, 2000);
      setTimeout(() => {
        localStorage.removeItem('RCV_DIGITAL_MUNICIPIO_GUARDADO');
      }, 6000);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = value.toUpperCase();

    if (name === 'ds_mn') {
      setMunicipioJSON({
        ...municipioJSON,
        [name]: newValue,
      });
    }
  };

  return (
    <div className="agregarMunicipio">
      <div
        className="agregarMunicipio__sombra"
        onClick={() =>
          setMostrarAgregarMunicipio({
            estado: false,
            valorEstado: '',
            valorMunicipio: '',
          })
        }
      ></div>
      <div className="agregarMunicipio__carta">
        <div className="agregarMunicipio__carta-header">
          <span className="agregarMarca__card-header-titulo">
            <span>Agregar </span>Municipio
          </span>
          <span
            className="agregarMarca__card-header-boton"
            onClick={() =>
              setMostrarAgregarMunicipio({
                estado: false,
                valorEstado: '',
                valorMunicipio: '',
              })
            }
          >
            <IoClose />
          </span>
        </div>
        <div className="agregarModelo__carta-marca">
          <h1>Estado: {estadoElegido && estadoElegido.label}</h1>
        </div>
        <form className="agregarModelo__carta-formulario" onSubmit={agregar}>
          <div className="agregarModelo__carta-formulario-inputContainer">
            <input
              type="text"
              name="ds_mn"
              value={municipioJSON.ds_mn}
              disabled={municipioVeri ? true : false}
              className={municipioVeri ? 'sucess' : ''}
              onChange={handleChange}
            />
            <FaCheck className={municipioVeri ? 'active' : ''} />
          </div>

          <div className="agregarModelo__carta-formulario-footer">
            <button
              className="cancelar"
              onClick={e => {
                e.preventDefault();
                setMostrarAgregarMunicipio({
                  estado: false,
                  valorEstado: '',
                  valorMunicipio: '',
                });
              }}
            >
              CANCELAR
            </button>

            <button
              className="aceptar"
              type="submit"
              disabled={loadingMunicipio ? true : false}
            >
              {loadingMunicipio ? (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              ) : (
                'ACEPTAR'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarMunicipio;
