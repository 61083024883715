import React, { useState, useEffect, useRef, useContext } from 'react';
import validationsFormRegistro from '../../../pages/Validators/validationsFormRegistro';
import RegistroForm from './RegistroForm';
import AddNewUser from '../../Login/Helpers/AddNewUser';
import { TokenContext } from '../../../context/TokenContext';

const Registro = ({
  setMostrarErrores,
  setNavbarErrores,
  setShow,
  setFullscreen,
  setMostrarModalCorrecto,
  setMensajeCorrecto,
  setOpcion,
  opcion,
  cambiarOpcion,
  setCambiarOpcion,
}) => {
  const [singErrId, setSingErrId] = useState(0);
  const { setToken } = useContext(TokenContext);
  const [registro, setRegistro] = useState();
  const [username, setUsername] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const usuRef = useRef();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const [registroForm, setRegistroForm] = useState({
    nb_us: '',
    ap_us: '',
    em_us: '',
    us_us: '',
    pw_us: '',
    sx_us: 'M',
    tp_us: 1,
    pw_cn_us: '',
    ac_tc: false,
  });

  useEffect(() => {
    setRegistroForm({
      ...registroForm,
      us_us: username,
    });
  }, [username]);

  useEffect(() => {
    setRegistroForm({
      ...registroForm,
      sx_us: 'M',
    });
  }, []);

  useEffect(() => {
    setRegistroForm({
      ...registroForm,
      ac_tc: isChecked,
    });
  }, [isChecked]);

  const datosDelFormulario = e => {
    const { name, value } = e.target;
    if (name === 'em_us') {
      const usernamePart = value.split('@')[0];
      setUsername(usernamePart);
      usuRef.current.value = usernamePart;
    }

    if (name === 'group1') {
      if (document.querySelector('input[name="group1"]:checked')) {
        setError({
          ...error,
          sx_us: null,
        });
      }

      setRegistroForm({
        ...registroForm,
        sx_us: value,
      });
    } else {
      setRegistroForm({
        ...registroForm,
        [name]: value,
      });
    }

    if (name === 'ac_tc') {
      setRegistroForm({
        ...registroForm,
        [name]: isChecked,
      });
    }

    if (!!error[name]) {
      setError({
        ...error,
        [name]: null,
      });
    }
  };

  const registrarse = e => {
    e.preventDefault();
    setLoading(true);

    let errorForm = validationsFormRegistro(registroForm);
    if (Object.keys(errorForm).length > 0) {
      setMostrarErrores(true);
      setNavbarErrores(errorForm);
      setLoading(false);
    } else {
      setLoading(true);
      AddNewUser(
        registroForm,
        setNavbarErrores,
        setSingErrId,
        singErrId,
        setRegistro,
        setToken
      );
    }
  };

  useEffect(() => {
    if (registro === true) {
      setRegistroForm({
        nb_us: '',
        ap_us: '',
        em_us: '',
        us_us: '',
        pw_us: '',
        sx_us: 'M',
        tp_us: 1,
        pw_cn_us: '',
        ac_tc: false,
      });
      setLoading(false);
      setIsChecked(false);
      setMensajeCorrecto(
        'Te has registrado perfectamente, revisa tu correo y activa tu cuenta'
      );
      setTimeout(() => {
        setOpcion(false);
        setMostrarModalCorrecto(true);
      }, 1000);
    }
    if (registro === false) {
      setRegistro(null);
      setLoading(false);
    }
  }, [registro]);

  useEffect(() => {
    if (singErrId > 0) {
      setMostrarErrores(true);
      setLoading(false);
      setSingErrId(0);
    }
  }, [singErrId]);

  return (
    <div
      className={`registro ${opcion === 3 ? 'ocultar' : ''} ${
        cambiarOpcion === false ? 'opcion' : ''
      }`}
    >
      <div className="registro__container">
        <RegistroForm
          registrarse={registrarse}
          datosDelFormulario={datosDelFormulario}
          registroForm={registroForm}
          usuRef={usuRef}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          setShow={setShow}
          setFullscreen={setFullscreen}
          loading={loading}
        />
        <div className="registro__container-opcion">
          <span>Ya tengo una cuenta</span>{' '}
          <button
            onClick={() => {
              cambiarOpcion ? setCambiarOpcion(false) : setCambiarOpcion(true);
            }}
          >
            Iniciar Sesi&oacute;n
          </button>
        </div>
      </div>
    </div>
  );
};

export default Registro;
