import React, { useState, useEffect, useContext } from 'react';
import 'leaflet/dist/leaflet.css';
import Map from './Map';
import ListaSucursales from './ListaSucursales';
import Volver from '../Volver/Volver';
import imgSatelite from '../../styles/images/dcab2b380bfcdafbe2b7301f6a723c18.jpg';
import imgRelieve from '../../styles/images/mapa-politico-america-latina.png';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';
import { Link } from 'react-router-dom';

const Sucursales = () => {
  const [position, setPosition] = useState([
    10.367315663296521, -71.24163554150886,
  ]);
  const { setTokenCambiar, setTokenVencido, setToken, token, cancelar } =
    useContext(TokenContext);
  const [zoom, setZoom] = useState(7);
  const [loading, setLoading] = useState(false);
  const [sede, setSede] = useState('Selecciona una sucursal');
  const [direccion, setDireccion] = useState('');
  const [sucursales, setSucursales] = useState([]);
  const [seleccionado, setSeleccionado] = useState('relieve');
  const style = {
    border: '3px solid #2196f3',
  };
  const [mapGround, setMapGround] = useState({
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1);
  }, [loading]);

  const selectManejador = e => {
    setPosition(
      e?.target.value
        .split('+')[0]
        .split(',')
        .map(x => Number(x))
    );
    setSede(e.target.value.split('+')[1]);
    setZoom(15);
    setLoading(true);
    setDireccion(e.target.value.split('+')[2]);
  };

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_SUCURSAL,
      0,
      setSucursales,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, []);

  return (
    <div className="sucursales">
      <Link to="/" className="inicioRegistro__volver">
        Volver al inicio
      </Link>
      <div className="sucursales__capas">
        <h5>Capas</h5>
        <div className="sucursales__capas-opciones">
          <img
            loading="lazy"
            alt="imagen de relieve"
            className="sucursales__capas-opciones-buttonRelieve"
            style={seleccionado === 'relieve' ? style : null}
            onClick={() => {
              setMapGround({
                attribution:
                  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
              });
              setSeleccionado('relieve');
            }}
            src={imgRelieve}
          ></img>
          <img
            loading="lazy"
            alt="imagen de mapa desde punto de vista satelital"
            className="sucursales__capas-opciones-buttonSatelite"
            style={seleccionado === 'satelite' ? style : null}
            onClick={() => {
              setMapGround({
                attribution:
                  'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
              });
              setSeleccionado('satelite');
            }}
            src={imgSatelite}
          ></img>
        </div>
      </div>
      <div className="sucursales__header">
        <h1>
          <span>V</span>ENEZUELA <span>R</span>ESPONSABLE
        </h1>
        <span className="sucursales__header-subtitle">J-29804784-4</span>
        <h2>{sede}</h2>
      </div>
      <div className="sucursales__body">
        <div className="sucursales__headerdireccion">
          <span>{direccion}</span>
        </div>

        <div className="sucursalesselect">
          <select onChange={selectManejador}>
            <option value={[10.681595135387784, -71.6086244965347]}>
              Seleccionar...
            </option>
            {sucursales &&
              sucursales?.map((x, indice) => {
                return (
                  <option
                    key={indice}
                    value={[
                      [x.la_of_ma, x.lo_of_ma] +
                        '+' +
                        x?.ds_of_ma +
                        '+' +
                        x?.dr_of_ma,
                    ]}
                  >
                    {x.ds_of_ma}
                  </option>
                );
              })}
          </select>
        </div>
        {loading ? (
          <div className="sucursales__body-cargando">cargando...</div>
        ) : (
          <Map
            sucursales={sucursales}
            position={position}
            zoom={zoom}
            mapGround={mapGround}
          />
        )}
        <ListaSucursales
          setZoom={setZoom}
          setPosition={setPosition}
          setLoading={setLoading}
          setSede={setSede}
          setDireccion={setDireccion}
          sucursales={sucursales}
        />
      </div>
      <div className="sucursales__footer">
        <span>{direccion}</span>
      </div>
    </div>
  );
};

export default Sucursales;
