import React from 'react';

const ListaSucursales = ({
  setZoom,
  setPosition,
  setLoading,
  setSede,
  setDireccion,
  sucursales,
}) => {
  const ubiHandler = x => {
    setZoom(15);
    setPosition([x.la_of_ma, x.lo_of_ma]);
    setLoading(true);
    setSede(x.nombre);
    setDireccion(x.dr_of_ma);
  };
  return (
    <ul className="sucursales__body-lista">
      {sucursales &&
        sucursales.map((x, indice) => {
          return (
            <li key={indice} onClick={() => ubiHandler(x)}>
              {x.ds_of_ma}
            </li>
          );
        })}
    </ul>
  );
};

export default ListaSucursales;
