import { useEffect, useState, useContext } from 'react';
import ModalCorrecto from '../components/ModalCorrecto/ModalCorrecto';
import consultarApi from '../helpers/consultarApi';
import { TokenContext } from '../context/TokenContext';
import DashboardTabla from '../components/Dashboard/DashboardTabla/DashboardTabla';
import DashboardInfo from '../components/DashboardInfo/DashboardInfo';

const Dashboard = () => {
  const [vehiContratos, setVehiContratos] = useState([]);
  let pago = JSON.parse(localStorage.getItem('RCV_DIGITAL_PAGO'));
  const [cargandoContratos, setCargandoContratos] = useState(true);
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);
  const [busqueda, setBusqueda] = useState('');
  const [opcion, setOpcion] = useState('1');
  const [buscar, setBuscar] = useState(
    `v1/getHistoryForUser?p1=${busqueda}&p2=&p3=&p4=&op=${opcion}&token=`
  );

  useEffect(() => {
    if (busqueda === '') {
      setBuscar('v1/getHistoryForUser?p1=&p2=&p3=&p4=&op=1&token=');
    }
  }, [busqueda]);

  useEffect(() => {
    consultarApi(
      buscar,
      0,
      setVehiContratos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(() => setCargandoContratos(false));
  }, [token, buscar]);

  useEffect(() => {
    localStorage.removeItem('RCV_DIGITAL_PLAN_AN');
    localStorage.removeItem('LAST_LOCATION');
    localStorage.removeItem('PROPIETARIO_ID');
    localStorage.removeItem('CLIENT_ID');
    localStorage.removeItem('RCV_DIGITAL_CLIENTE');
    localStorage.removeItem('RCV_DIGITAL_PROPIETARIO');
    localStorage.removeItem('RCV_DIGITAL_VEHICULO');
    localStorage.removeItem('VEHICULO_ID');
    localStorage.removeItem('RCV_DIGITAL_CARRITO');
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('TOTAL_PAGO');
    localStorage.removeItem('RCV_DIGITAL_PASO_VERIFICADO');
    localStorage.removeItem('RCV_DIGITAL_S/P');
    localStorage.removeItem('RCV_DIGITAL_DESCONTADO');
    localStorage.removeItem('RCV_DIGITAL_USDESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_REFERIDO');
    localStorage.removeItem('RCV_DIGITAL_CUPON');
  }, []);
  useEffect(() => {
    if (pago === true) {
      setMensajeCorrecto(
        'Excelente!, su pago está siendo procesado, al ser aprobado podrás descargar el contrato y la factura.'
      );
      setMostrarModalCorrecto(true);
      localStorage.removeItem('RCV_DIGITAL_PAGO');
    }
  }, []);

  return (
    <div className="newDashboard">
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <DashboardTabla
        vehiContratos={vehiContratos}
        cargandoContratos={cargandoContratos}
        setBusqueda={setBusqueda}
        busqueda={busqueda}
        opcion={opcion}
        setOpcion={setOpcion}
        setBuscar={setBuscar}
      />
    </div>
  );
};

export default Dashboard;
