import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import Facebook from '../../../styles/images/facebook.png';
import Instagram from '../../../styles/images/instagram.png';

const styles = StyleSheet.create({
  container: {
    width: '450px',
    display: 'flex',
    gap: '3px',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  },
  font: {
    fontSize: '7px',
  },
});
const ContratoFooterInfoRedes = () => (
  <div style={styles.container}>
    <View>
      <Text style={styles.font}>
        Para más información de nuestras clausulas y/o condiciones expuestas en
        este contrato visite http://www.venezuelaresponsable.com.ve
      </Text>
    </View>
    <View>
      <Text style={styles.font}>
        Nuevas tendencias y promociones ingresa en <Image src={Instagram} />{' '}
        @venezuelaresponsable, <Image src={Facebook} /> Venezuela Responsable
      </Text>
    </View>
  </div>
);
export default ContratoFooterInfoRedes;
