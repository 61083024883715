import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useState, useContext } from 'react';
import PDFDocumentFactura from '../../PDFViewer/PDFDocumentFactura';
import { TokenContext } from '../../../context/TokenContext';
import { getToken } from '../../../helpers/getToken';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';

const FacturaMobile = ({ facturaId, contratoId }) => {
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const [factura, setFactura] = useState();
  const token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const [isLoading, setIsLoading] = useState(true);
  const [valorClienteQR, setValorClienteQR] = useState();
  const [imgContratoQR, setImgContratoQR] = useState();
  const [descargando, setDescargando] = useState(false);

  const fetchFactura = async () => {
    try {
      const response = await axios.get(
        `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getBill?p1=${facturaId}&p2=&p3=&p4=&op=3&token=${token}`
      );
      setFactura(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        if (login === undefined || login === null) {
          const tokenAct = await getToken();
          localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(tokenAct));

          let urlNueva = `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getBill?p1=${facturaId}&p2=&p3=&p4=&op=3&token=${tokenAct}`;
          try {
            axios.get(urlNueva).then(response => {
              setFactura(response.data.data);
              localStorage.setItem(
                'RCV_DIGITAL_TOKEN',
                JSON.stringify(response.data.token)
              );
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          setTokenCambiar(true);
          setTokenVencido(error.response.data.token);
          console.log(error.response.data.token);
        }
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const conseguirValorFactura = () => {
    setValorClienteQR(
      `https://www.venezuelaresponsable.com.ve/verify.php?id=${contratoId}`
    );
    setTimeout(() => {
      const canvaCliente = document.getElementById(`clienteQR-${facturaId}`);
      const imgURLCliente = canvaCliente.toDataURL('image/png');
      setImgContratoQR(imgURLCliente);
    }, 2000);
  };

  const descargarFactura = () => {
    setDescargando(true);
    fetchFactura();
    setTimeout(() => {
      conseguirValorFactura();
    }, 1000);

    setTimeout(() => {
      setDescargando(false);
      document.getElementById(`linkFactura-${facturaId}`).click();
    }, 6000);
  };

  return (
    <div className="facturaMobile">
      <button
        disabled={descargando}
        className="facturaMobile__descargar"
        onClick={() => descargarFactura()}
      >
        {descargando ? 'DESCARGANDO...' : 'DESCARGAR FACTURA'}
      </button>

      <QRCodeCanvas
        value={valorClienteQR}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id={`clienteQR-${facturaId}`}
        style={{ display: 'none' }}
      />
      <div>
        <PDFDownloadLink
          className="downloadButton"
          document={
            <PDFDocumentFactura factura={factura} imagenqr={imgContratoQR} />
          }
          fileName={`FA_${facturaId}.pdf`}
        >
          <button
            id={`linkFactura-${facturaId}`}
            style={{
              backgroundColor: '#19FC6D',
              border: 'none',
              borderRadius: '20px',
              display: 'none',
            }}
          ></button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default FacturaMobile;
