import { useContext } from 'react';
import { ProcesoContext } from '../context/ProcesoContext';

const useProcesoProvider = () => {
  const context = useContext(ProcesoContext);

  if (context && context) {
    return context;
  } else {
    throw new Error('No hay contexto');
  }
};

export default useProcesoProvider;
