import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';
import imgPlaca from '../../styles/images/MINFRA_PFMT2007.png';
import FacturaMobile from './ContratoFacturaMobile/FacturaMobile';
import ContratoMobile from './ContratoFacturaMobile/ContratoMobile';

const Contrato = () => {
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [contrato, setContrato] = useState([]);
  const [placa, setPlaca] = useState();
  const [contratoFiltrado, setContratoFiltrado] = useState();

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_HISTORIAL,
      0,
      setContrato,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);

  useEffect(() => {
    const storedState = localStorage.getItem('linkStatePlaca');
    if (storedState && contrato.length > 0) {
      let storeParse = JSON.parse(storedState);
      const vehiculo = contrato.find(item => item.pl_vh === storeParse.pl_vh);
      setPlaca(storeParse.pl_vh);
      setContratoFiltrado(vehiculo?.contratos);
      localStorage.removeItem('linkStatePlaca');
    }
  }, [contrato]);

  const handleClickFactura = (id_co, id_fc) => {
    localStorage.setItem('linkState', JSON.stringify({ id_co, id_fc }));
  };
  const handleClickContrato = id_co => {
    localStorage.setItem('linkStateContrato', JSON.stringify({ id_co }));
  };
  return (
    <div className="contrato">
      {contratoFiltrado &&
        contratoFiltrado.map((x, indice) => {
          return (
            <div className="contrato__container" key={indice}>
              <ul key={indice} className="contrato__container-carta">
                <li className="contrato__container-carta-placa">
                  <span>{placa}</span>
                  <img src={imgPlaca} alt="imagen de placa venezolana" />
                </li>

                <li className={`contrato__container-carta-estado ${x.estatus}`}>
                  {x.estatus}
                </li>
                {x.estatus === 'PENDIENTE' ? (
                  'Tu contrato esta a la espera de ser aprobado'
                ) : x.estatus === 'RECHAZADO' ? (
                  'Tu contrato ha sido rechazado'
                ) : (
                  <>
                    <div className="contrato__container-carta-escritorio">
                      <Link
                        to={`/dashboard/contrato/pdf`}
                        target="_blank"
                        onClick={() => handleClickContrato(x.id_co)}
                        className="contrato"
                      >
                        CONTRATO
                      </Link>{' '}
                      <Link
                        to={`/dashboard/contrato/pdfac`}
                        state={{ test: 'test' }}
                        onClick={() => handleClickFactura(x.id_co, x.id_fc)}
                        target="_blank"
                        className="factura"
                      >
                        FACTURA
                      </Link>
                    </div>
                    <div className="contrato__container-carta-mobile">
                      <ContratoMobile contratoId={x.id_co} />
                      <FacturaMobile facturaId={x.id_fc} contratoId={x.id_co} />
                    </div>
                  </>
                )}
                <li>{x?.ob_ac}</li>
              </ul>
              <div className="contrato__container-descripcion">
                <p>
                  <span>PLAN:</span> {x.ds_pq}
                </p>
                <p>
                  <span>FECHA.FIN:</span> {x.fc_fi_co}
                </p>
                <p>
                  <span>FECHA.INI:</span> {x.fc_in_co}
                </p>
                <p>
                  <span>DATOS:</span> {x.contratante}
                </p>
                <p>
                  <span>COD:CONTRATO:</span> {x.cd_co}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Contrato;
