import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { getToken } from "../../helpers/getToken";

let fecha = new Date();
let meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Setiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
let dias_semana = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

export const FechaActual = ({setTokenController, setTokenExp, token }) => {
  let login = JSON.parse(localStorage.getItem("RCV_DIGITAL_LOGIN"));

  const logMyErrors = (err) => {
    const MySwal = withReactContent(Swal);
    localStorage.removeItem("RCV_DIGITAL_PROFILEOBJ");
    localStorage.removeItem("RCV_DIGITAL_LOGIN");
  };

  const [tasa, setTasa] = useState();

  const obtenerTasa = async () => {
    let url = `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getCombo?p1=&p2=&p3=&p4=&op=60&token=${token}`;
    try {
      axios
        .get(url)
        .then((response) => {
          setTasa(response.data.data[0].ds);
          localStorage.setItem(
            "RCV_DIGITAL_TOKEN",
            JSON.stringify(response.data.token)
          );
        })
        .catch(/*async (error) => {
          if (error.response && error.response.status === 403) {
            if (login === undefined || login === null) {
              const tokenAct = await getToken();
              localStorage.setItem(
                "RCV_DIGITAL_TOKEN",
                JSON.stringify(tokenAct)
              );
              let urlNueva = `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getCombo?p1=&p2=&p3=&p4=&op=60&token=${tokenAct}`;
              try {
                axios.get(urlNueva).then((response) => {
                  setTasa(response.data.data[0].ds);
                  localStorage.setItem(
                    "RCV_DIGITAL_TOKEN",
                    JSON.stringify(response.data.token)
                  );
                });
              } catch (error) {
                console.log(error);
              }
            } else {
              setTokenController(true);
              setTokenExp(error.response.data.token);
              console.log(error.response.data.token);
            }
          }
        }*/
      
        );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    obtenerTasa();
  }, [token]);

/*
  const { data, isLoading } = useAxios(
    `${process.env.REACT_APP_GET_TASA}`
  );*/
  
  return (
    <Container fluid className="d-md-flex bd-highlight bg-dark text-white fw-bold">
      <Row className="w-100 bd-highlight ms-1">
        {`Fecha: ${dias_semana[fecha.getDay()]} ${fecha.getDate()} de ${
          meses[fecha.getMonth()]
        } de ${fecha.getUTCFullYear()}`}
      </Row>
      <Row className="flex-shrink-0 bd-highlight mx-1">
        {tasa && tasa.length > 0 ? `Tasa del día (BCV): ${tasa}` : "Cargando tasa..."}
      </Row>
    </Container>
  );
};
