import React, { useEffect, useState, useContext } from 'react';
import validationsFromVehiculo from '../../../../../pages/Validators/validationsFromVehiculo';
import { postVehiculo } from '../../../../../helpers/postVehiculo';
import FormularioVehiculo from '../../Vehiculo/FormularioVehiculo';
import consultarApi from '../../../../../helpers/consultarApi';
import { TokenContext } from '../../../../../context/TokenContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';

const VehiculoEdicion = ({
  errors,
  setErrors,
  setErrorAdvice,
  setMostrarErrores,
  setNavbarErrores,
  datosVehiculo,
  setShowEdition,
}) => {
  const {
    an_vh,
    mc_sel,
    md_sel,
    nr_pu_vh,
    pe_vh,
    pl_vh,
    se_ca_vh,
    se_mo_vh,
    tp_sel,
    uso_sel,
    mc_vh,
    md_vh,
    cl_vh,
    tp_vh,
    uso_vh,
  } = datosVehiculo;
  const [formData, setFormData] = useState({
    pl_vh: pl_vh,
    mc_vh: mc_vh,
    mc_sel: mc_sel,
    md_vh: md_vh,
    md_sel: md_sel,
    se_ca_vh: se_ca_vh,
    se_mo_vh: se_mo_vh,
    cl_vh: cl_vh,
    nr_pu_vh: nr_pu_vh,
    an_vh: an_vh,
    pe_vh: pe_vh,
    tp_vh: tp_vh,
    tp_sel: tp_sel,
    uso_vh: uso_vh,
    uso_sel: uso_sel,
  });
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [selectedMarca, setSelectedMarca] = useState(mc_sel);
  const [selectedModelo, setSelectedModelo] = useState(md_sel);
  const [selectedTipo, setSelectedTipo] = useState(tp_sel);
  const [selectedUso, setSelectedUso] = useState(uso_sel);
  const [marcas, setMarcas] = useState([]);
  const [marca, setMarca] = useState('');
  const [tipos, setTipos] = useState([]);
  const [usos, setUsos] = useState([]);
  const [modelos, setModelos] = useState();
  const [cargandoMarcas, setCargandoMarcas] = useState(false);
  const [cargandoModelos, setCargandoModelos] = useState(false);
  const [cargandoTipos, setCargandoTipos] = useState(false);
  const [errorVer, setErrorVer] = useState();
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (marcas.length != 0) {
      let url = `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getCombo?p1=${marca}&p2=&p3=&p4=&op=9&token=${token}`;
      fetch(url)
        .then(res => res.json())
        .then(data => {
          setModelos(data.data);
        })
        .catch(error => console.log(error));
    }
  }, [marca]);

  useEffect(() => {
    const [data] = selectedUso;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedUso,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        uso_sel: [],
        uso_vh: '',
      });
    }
  }, [selectedUso]);

  useEffect(() => {
    const [data] = selectedTipo;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedTipo,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        tp_sel: [],
        tp_vh: '',
      });
    }
  }, [selectedTipo]);

  useEffect(() => {
    const [data] = selectedMarca;

    if (data) {
      const { name, id, value } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedMarca,
      });
      setMarca(value);

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        mc_sel: [],
        mc_vh: '',
      });
    }
  }, [selectedMarca]);

  useEffect(() => {
    const [data] = selectedModelo;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedModelo,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        md_sel: [],
        md_vh: '',
      });
    }
  }, [selectedModelo]);

  const handleChange = e => {
    const { name, value } = e.target;
    const newValue = value.toUpperCase();
    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (!!errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    let formErrors = validationsFromVehiculo(formData);
    if (Object.keys(formErrors).length > 0) {
      setNavbarErrores(formErrors);
      setMostrarErrores(true);
      setErrorAdvice(1);
    } else {
      postVehiculo(
        formData,
        setErrorVer,
        setNavbarErrores,
        setToken,
        setTokenVencido,
        setTokenCambiar
      );
    }
  };

  const buscarUso = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_USOS_VEHICULO,
      0,
      setUsos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoTipos(false);
      }, 2000)
    );
  };

  const buscarTipos = () => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_TIPOS_VEHICULO,
      0,
      setTipos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoTipos(false);
      }, 2000)
    );
  };

  const buscarMarca = () => {
    if (marcas.length === 0) {
      setCargandoMarcas(true);
    }
    consultarApi(
      process.env.REACT_APP_GET_ALL_MARCAS,
      0,
      setMarcas,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoMarcas(false);
      }, 500)
    );
  };

  const buscarModelo = () => {
    if (modelos.length === 0) {
      setCargandoModelos(true);
    }
    let url = `v1/getCombo?p1=${marca}&p2=&p3=&p4=&op=9&token=`;
    consultarApi(
      url,
      0,
      setModelos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(
      setTimeout(() => {
        setCargandoModelos(false);
      }, 500)
    );
  };

  useEffect(() => {
    if (errorVer === true) {
      setMostrarErrores(true);
    }

    if (errorVer === false) {
      localStorage.setItem('RCV_DIGITAL_VEHICULO', JSON.stringify(formData));
      setErrorVer(undefined);
      setShowEdition(false);
    }
  }, [errorVer]);

  useEffect(() => {
    setTimeout(() => {
      setErrorAdvice(0);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="clienteEdicion">
      <div className={`clienteEdicion__cliente  `}>
        <div className="clienteEdicion__cliente-header">
          <h2>
            Edita los datos de tu <span>Vehículo</span>
          </h2>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">No editar</Tooltip>}
          >
            <IoClose onClick={() => setShowEdition(false)} cursor={'pointer'} />
          </OverlayTrigger>
        </div>
        <FormularioVehiculo
          buscarUso={buscarUso}
          buscarTipos={buscarTipos}
          buscarMarca={buscarMarca}
          buscarModelo={buscarModelo}
          formData={formData}
          tipos={tipos}
          usos={usos}
          errors={errors}
          handleChange={handleChange}
          selectedMarca={selectedMarca}
          setSelectedMarca={setSelectedMarca}
          marcas={marcas}
          selectedModelo={selectedModelo}
          setSelectedModelo={setSelectedModelo}
          modelos={modelos}
          selectedTipo={selectedTipo}
          setSelectedTipo={setSelectedTipo}
          selectedUso={selectedUso}
          setSelectedUso={setSelectedUso}
          setSelected={setSelected}
          selected={selected}
          setFormData={setFormData}
          cargandoMarcas={cargandoMarcas}
          cargandoModelos={cargandoModelos}
        />
        <div className="proceso__botones">
          <div></div>
          <div className="proceso__botones-contenedor">
            <button className="cancelar" onClick={() => setShowEdition(false)}>
              ATRAS
            </button>
            <button className="siguiente" onClick={handleSubmit}>
              ACEPTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehiculoEdicion;
