const MetodoDePago = ({
  tipoPago,
  activo,
  estiloSeleccionado,
  setBancos,
  setBancoElegido,
  setActivo,
}) => {
  return (
    <div className="pago__contenedor-checkout-metodo">
      <h5>M&eacute;todos de pago</h5>
      <ul className="pago__contenedor-checkout-metodo-items">
        {tipoPago?.map((x, indice) => {
          return (
            <li
              style={activo.valor === x.id_tp_pg ? estiloSeleccionado : null}
              key={indice}
              onClick={() => {
                setBancos(x.bancue);
                setBancoElegido(undefined);
                setActivo({
                  valor: x.id_tp_pg,
                  banco: x.ds_tp_pg,
                });
              }}
            >
              {x.ds_tp_pg}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MetodoDePago;
