import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { getToken } from '../../../helpers/getToken';
import { TokenContext } from '../../../context/TokenContext';
import PDFFacturaRenderizador from './PDFFacturaRenderizador';
import { LoginContext } from '../../../context/LoginContext';
import { QRCodeCanvas } from 'qrcode.react';

const PDFFacturaVista = () => {
  const [factura, setFactura] = useState();
  const [imgContratoQR, setImgContratoQR] = useState();
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const [isLoading, setIsLoading] = useState(true);
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const { setNavbar } = useContext(LoginContext);
  const [valorClienteQR, setValorClienteQR] = useState();

  useEffect(() => {
    const storedState = localStorage.getItem('linkState');
    if (storedState) {
      let storeParse = JSON.parse(storedState);
      setNavbar(false);
      conseguirValorFactura(storeParse.id_co);
      fetchFactura(storeParse.id_fc);
      localStorage.removeItem('linkState');
    }
  }, []);
  const fetchFactura = async prop => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}v1/getBill?p1=${prop}&p2=&p3=&p4=&op=3&token=${token}`
      );
      setFactura(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        if (login === undefined || login === null) {
          const tokenAct = await getToken();
          localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(tokenAct));

          let urlNueva = `${process.env.REACT_APP_URL_API}v1/getBill?p1=${prop}&p2=&p3=&p4=&op=3&token=${tokenAct}`;
          try {
            axios.get(urlNueva).then(response => {
              setFactura(response.data.data);
              localStorage.setItem(
                'RCV_DIGITAL_TOKEN',
                JSON.stringify(response.data.token)
              );
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          setTokenCambiar(true);
          setTokenVencido(error.response.data.token);
          console.log(error.response.data.token);
        }
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const conseguirValorFactura = id => {
    setValorClienteQR(
      `https://www.venezuelaresponsable.com.ve/verify.php?id=${id}`
    );
    setTimeout(() => {
      const canvaCliente = document.getElementById('clienteQR');
      const imgURLCliente = canvaCliente.toDataURL('image/png');
      setImgContratoQR(imgURLCliente);
    }, 2000);
  };

  return (
    <div className="facturaMobile">
      <QRCodeCanvas
        value={valorClienteQR}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id="clienteQR"
        style={{ display: 'none' }}
      />
      {isLoading ? (
        <div className="facturaMobile__header">
          <span>CARGANDO FACTURA...</span>
          <div className="lds-ellipsisg">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <PDFFacturaRenderizador factura={factura} imagenqr={imgContratoQR} />
        </>
      )}
    </div>
  );
};

export default PDFFacturaVista;
