import React, { useState, useEffect, useContext } from 'react';
import consultarApi from '../../../helpers/consultarApi';
import { TokenContext } from '../../../context/TokenContext';
import NavbarErrors from '../../NavbarLite/NavbarErrors';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from '../PDFDocument';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import BuscarAgente from './BuscarAgente';

const VentasDiariasPorAgente = () => {
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [agentes, setAgentes] = useState();
  const [userProfile] = useState(
    JSON.parse(localStorage.getItem('RCV_DIGITAL_PROFILEOBJ'))
  );
  const [cargando, setCargando] = useState(false);
  const [fechaIni, setFechaIni] = useState();
  const [fechaFin, setFechaFin] = useState();
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [agentesBuscados, setAgentesBuscados] = useState();
  const [agenteSeleccionado, setAgenteSeleccionado] = useState();
  const [fecha, setFecha] = useState('');
  const [hora, setHora] = useState('');
  const [currentDate] = useState(new Date());
  const [reporteDescarga, setReporteDescarga] = useState();
  const [reporte, setReporte] = useState();
  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_AGENTES,
      0,
      setAgentes,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);

  function getPrimerDiaDelMes() {
    const hoy = new Date();
    setFechaIni(
      new Date(hoy.getFullYear(), hoy.getMonth(), 1).toISOString().substr(0, 10)
    );
  }

  function getUltimoDiaDelMes() {
    const hoy = new Date();
    setFechaFin(
      new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0)
        .toISOString()
        .substr(0, 10)
    );
  }

  useEffect(() => {
    getPrimerDiaDelMes();
    getUltimoDiaDelMes();
  }, []);

  const cambiarFecha = (e, tipo) => {
    if (tipo === 'ini') {
      setFechaIni(e.target.value);
    } else {
      setFechaFin(e.target.value);
    }
  };
  const buscar = () => {
    setCargando(true);
    if (!agenteSeleccionado) {
      setCargando(false);
      setNavbarErrores(['Debes elegir un agente']);
      setMostrarErrores(true);
    } else {
      let urlReportes = `v1/getDaySales?p1=${agenteSeleccionado?.cd}&p2=&p3=&p4=&dd=${fechaIni}&ht=${fechaFin}&op=7&token=`;
      consultarApi(
        urlReportes,
        0,
        setReporte,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(
        setTimeout(() => {
          setCargando(false);
        }, 2000)
      );
    }
  };

  useEffect(() => {
    if (reporte != undefined && reporte != null && reporte != '') {
      localStorage.setItem(
        'RCV_DIGITAL_AGENTE_DATA',
        JSON.stringify({
          fechaIni,
          fechaFin,
          agenteSeleccionado,
        })
      );
      setTimeout(() => {
        window.open(`/ventasdiariasporagente/resultado/`, '_blank');
      }, 1000);
    }
    if (reporte === null && cargando === true) {
      setCargando(false);
      setReporte('');
      setNavbarErrores(['No hay registros en las fechas seleccionadas']);
      setMostrarErrores(true);
    }
  }, [reporte]);
  const renderizar = () => {
    setCargando(true);
    if (!agenteSeleccionado) {
      setCargando(false);
      setNavbarErrores(['Debes elegir un agente']);
      setMostrarErrores(true);
    } else {
      const fechaFormateada = format(currentDate, 'dd/MM/yyyy', { locale: es });
      const horaFormateada = format(currentDate, 'hh:mm:ss a', { locale: es });
      setFecha(fechaFormateada);
      setHora(horaFormateada);
      let urlReportesDescarga = `v1/getDaySales?p1=${agenteSeleccionado?.cd}&p2=&p3=&p4=&dd=${fechaIni}&ht=${fechaFin}&op=7&token=`;
      consultarApi(
        urlReportesDescarga,
        0,
        setReporteDescarga,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(
        setTimeout(() => {
          setCargando(false);
        }, 2000)
      );

      setTimeout(() => {
        setTimeout(() => {
          document.getElementById('aqui').click();
        }, 1000);
      }, 3000);
    }
  };

  return (
    <div className="ventasDiariasPorAgente">
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <div className="ventasDiariasPorAgente__contenedor">
        <div className="ventasDiariasPorAgente__contenedor-fecha">
          <div className="ventasDiariasPorAgente__contenedor-fecha-header">
            <h2>
              Ventas diaras por <span>aliado</span>.
            </h2>
            <span className="titulo">
              Elige un aliado y selecciona el rango de fechas.
            </span>
            <span className={agenteSeleccionado ? 'agente' : 'noagente'}>
              {agenteSeleccionado
                ? agenteSeleccionado?.ds
                : 'No has seleccionado un aliado'}
            </span>
          </div>
          <div className="ventasDiariasPorAgente__contenedor-fecha-contenedor">
            <div>
              <input
                className=""
                type="date"
                onChange={e => cambiarFecha(e, 'ini')}
                value={fechaIni}
              />
            </div>
            <div>
              <input
                className=""
                type="date"
                onChange={e => cambiarFecha(e, 'fin')}
                value={fechaFin}
              />
            </div>
            <select
              className="seleccionarAgente"
              onChange={e => setAgenteSeleccionado(JSON.parse(e.target.value))}
            >
              <option disabled selected>
                Selecciona un aliado
              </option>
              {agentes &&
                agentes.map((x, i) => {
                  return (
                    <option
                      value={JSON.stringify({ cd: x.cd, ds: x.ds })}
                      key={i}
                    >
                      {x.ds}
                    </option>
                  );
                })}
            </select>
            <button
              className="botonpc"
              onClick={buscar}
              disabled={cargando === true ? true : false}
            >
              {cargando === false ? (
                'Aceptar'
              ) : (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              )}
            </button>
            <button className="botonmobile" onClick={() => renderizar()}>
              {cargando === false ? (
                'Aceptar'
              ) : (
                <div className="lds-ellipsisg">
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                  <div style={{ backgroundColor: 'white' }}></div>
                </div>
              )}
            </button>
          </div>
        </div>

        <ul className="ventasDiariasPorAgente__contenedor-agentes">
          <li className="opcional">
            <BuscarAgente data={agentes} setData={setAgentesBuscados} />
          </li>
          {!agentesBuscados
            ? agentes &&
              agentes.map((x, indice) => {
                return (
                  <li
                    className={agenteSeleccionado?.ds === x?.ds ? 'active' : ''}
                    key={indice}
                    onClick={() => setAgenteSeleccionado(x)}
                  >
                    {x.ds}
                  </li>
                );
              })
            : agentesBuscados.map((x, indice) => {
                return (
                  <li
                    className={agenteSeleccionado?.ds === x?.ds ? 'active' : ''}
                    key={indice}
                    onClick={() => setAgenteSeleccionado(x)}
                  >
                    {x.ds}
                  </li>
                );
              })}
        </ul>
      </div>

      <PDFDownloadLink
        document={
          <PDFDocument
            reporte={reporteDescarga}
            userProfile={userProfile}
            fecha={fecha}
            hora={hora}
            fechaini={fechaIni}
            fechafin={fechaFin}
          />
        }
        fileName={`REPORTE_VENTAS_DIARIAS.pdf`}
      >
        <button id="aqui" style={{ display: 'none' }}>
          aqui
        </button>
      </PDFDownloadLink>
    </div>
  );
};

export default VentasDiariasPorAgente;
