import React, { useState, useEffect } from 'react';
import { BsFillExclamationCircleFill, BsArrowLeft } from 'react-icons/bs';

const NavbarErrors = ({ navbarErrores, setMostrarErrores, mostrarErrores }) => {
  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      setMostrarErrores(false);
      setSeconds(15);
    }
  }, [seconds]);

  useEffect(() => {
    setSeconds(15);
  }, [mostrarErrores]);

  return (
    <div className={`navbarErrors ${mostrarErrores ? 'mostrar' : ''}`}>
      <div
        className="navbarErrors__sombra"
        onClick={() => setMostrarErrores(false)}
      ></div>
      <div className="navbarErrors__container">
        <BsFillExclamationCircleFill />
        <span>Upps! al parecer nos falto algo:</span>
        <ul>
          {Object.values(navbarErrores).map((x, indice) => {
            return <li key={indice}>{x}</li>;
          })}
        </ul>
        <button
          className="navbarErrors__container-close"
          onClick={() => setMostrarErrores(false)}
        >
          ENTENDIDO
          <div className="navbarErrors__container-close-conteo">{seconds}</div>
        </button>
      </div>
    </div>
  );
};

export default NavbarErrors;
