import { useState, useEffect, useContext, useRef } from 'react';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';
import CreadorDescuento from './CreadorDescuento';
import EditorDescuento from './EditorDescuento';
import NavbarErrors from '../NavbarLite/NavbarErrors';
import HeaderPages from '../HeadersPages/HeaderPages';
import TablaCupones from './TablaCupones';
import DescuentoCartas from './DescuentoCartas';
import BuscarAgente from '../Reportes/VentasDiariasPorAgente/BuscarAgente';
import Typehead from '../Typehead/Typehead';

const DescuentoAdmin = () => {
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [descuentos, setDescuentos] = useState();
  const [changeListener, setChangeListener] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agentes, setAgentes] = useState();
  const [agenteSeleccionado, setAgenteSeleccionado] = useState('');
  const [mostrarCreador, setMostrarCreador] = useState(false);
  const [mostrarEditor, setMostrarEditor] = useState(false);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [edicionProp, setEdicionProp] = useState();
  const [opcion, setOpcion] = useState('3');
  const [busqueda, setBusqueda] = useState('');
  const [buscar, setBuscar] = useState(
    `v1/getCuponList?p1=${busqueda}&p2=${agenteSeleccionado}&p3=&p4=&op=${opcion}&token=`
  );

  const searchInputRef = useRef(null);

  const urlDescuento = `v1/getCuponList?p1=${busqueda}&p2=${agenteSeleccionado}&p3=&p4=&op=${opcion}&token=`;

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_AGENTES,
      0,
      setAgentes,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);

  useEffect(() => {
    if (busqueda === '') {
      setBuscar('v1/getCuponList?p1=&p2=&p3=&p4=&op=3&token=');
    }
  }, [busqueda]);

  useEffect(() => {
    setLoading(true);
    consultarApi(
      urlDescuento,
      0,
      setDescuentos,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    ).finally(() => setLoading(false));
  }, [token, changeListener, buscar, agenteSeleccionado]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const buscadorDeDescuentos = e => {
    e.preventDefault();
    const { value } = e.target;
    setBusqueda(value);
  };

  const opcionHandler = e => {
    e.preventDefault();
    const { value } = e.target;
    setOpcion(value);
  };

  const handleSelect = e => {
    const { value } = e.target;
    setAgenteSeleccionado(value);
  };

  const buscarParams = e => {
    e.preventDefault();
    const urlContratos = `v1/getHistoryForUser?p1=${busqueda}&p2=&p3=&p4=&op=${opcion}&token=`;
    setBuscar(urlContratos);
  };

  return (
    <div className="descuentoAdmin">
      <HeaderPages
        titulo={'Cupones'}
        info={'Crea y edita los cupones de descuento.'}
      />
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      {mostrarCreador ? (
        <CreadorDescuento
          setMostrarCreador={setMostrarCreador}
          setNavbarErrores={setNavbarErrores}
          setMostrarErrores={setMostrarErrores}
          setToken={setToken}
          setTokenVencido={setTokenVencido}
          setTokenCambiar={setTokenCambiar}
          setChangeListener={setChangeListener}
        />
      ) : null}
      {mostrarEditor ? (
        <EditorDescuento
          setMostrarEditor={setMostrarEditor}
          setNavbarErrores={setNavbarErrores}
          setMostrarErrores={setMostrarErrores}
          edicionProp={edicionProp}
          setToken={setToken}
          setTokenVencido={setTokenVencido}
          setTokenCambiar={setTokenCambiar}
          setChangeListener={setChangeListener}
        />
      ) : null}
      <div className="descuentoAdmin__crear">
        <div className="descuentoAdmin__crear-inputContainer">
          <select onChange={handleSelect} className="agenteSeleccionador">
            <option>Seleccione agente...</option>
            {agentes &&
              agentes.map((x, i) => {
                return (
                  <option value={x.cd} key={i}>
                    {x.ds}
                  </option>
                );
              })}
          </select>
          <div className="descuentoAdmin__crear-inputContainer-buscador">
            {' '}
            <input
              ref={searchInputRef}
              type="search"
              onChange={buscadorDeDescuentos}
              placeholder={'Criterio de búsqueda'}
            />{' '}
            <button onClick={buscarParams}>BUSCAR</button>
          </div>

          <div className="descuentoAdmin__crear-opciones">
            <label>
              <input
                name={'filtro'}
                value={'2'}
                type="radio"
                checked={opcion === '2'}
                onChange={opcionHandler}
              />{' '}
              Código
            </label>
            <label>
              <input
                name={'filtro'}
                type="radio"
                checked={opcion === '3'}
                value={'3'}
                onChange={opcionHandler}
              />{' '}
              Descripción
            </label>
          </div>
        </div>
        <button onClick={() => setMostrarCreador(true)}>CREAR +</button>
      </div>
      <div className="descuentoAdmin__contenedor">
        <TablaCupones
          descuentos={descuentos}
          setMostrarEditor={setMostrarEditor}
          setEdicionProp={setEdicionProp}
          loading={loading}
        />
        {/** <div className="descuentoAdmin__contenedor-agentes">
          <ul className="ventasDiariasPorAgente__contenedor-agentes">
            <li className="opcional">
              <BuscarAgente data={agentes} setData={setAgentesBuscados} />
            </li>
            {!agentesBuscados
              ? agentes &&
                agentes.map((x, indice) => {
                  return (
                    <li
                      className={
                        agenteSeleccionado?.ds === x?.ds ? 'active' : ''
                      }
                      key={indice}
                      onClick={() => setAgenteSeleccionado(x)}
                    >
                      {x.ds}
                    </li>
                  );
                })
              : agentesBuscados.map((x, indice) => {
                  return (
                    <li
                      className={
                        agenteSeleccionado?.ds === x?.ds ? 'active' : ''
                      }
                      key={indice}
                      onClick={() => setAgenteSeleccionado(x)}
                    >
                      {x.ds}
                    </li>
                  );
                })}
          </ul>
        </div>*/}
      </div>
      <div className="descuentoCartasContenedor">
        <DescuentoCartas
          descuentos={descuentos}
          setMostrarEditor={setMostrarEditor}
          setEdicionProp={setEdicionProp}
        />
      </div>
    </div>
  );
};

export default DescuentoAdmin;
