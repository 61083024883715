import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';

const postPropietario = async (
  formData,
  setErrorVer,
  setNavbarErrores,
  setToken,
  setTokenVencido,
  setTokenCambiar
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));

  const json = JSON.stringify({
    cliente: {
      tp_cl: formData.tp_cl.toUpperCase(),
      cd_cl: formData.cd_cl,
      nb_cl: formData.nb_cl.toUpperCase(),
      ap_cl: formData.ap_cl.toUpperCase(),
      fc_cl: formData.fc_cl,
      rz_cl: formData.rz_cl,
      id_es: formData.id_es,
      id_mn: formData.id_mn,
      se_cl: formData.se_cl.toUpperCase(),
      dr_cl: formData.dr_cl.toUpperCase(),
      em_cl: formData.em_cl.toUpperCase(),
      id_tf_cl: formData.op_sel[0].value,
      nr_tf_cl: formData.tf_cl_cl_01,
    },
    trace: '0',
    token: token,
    ori: 'web',
  });

  const handleResponse = async data => {
    localStorage.setItem(
      'PROPIETARIO_ID',
      JSON.stringify(data.data.data.id_cl)
    );
    localStorage.setItem('STATUS_OP_PROPIETARIO', data.status);
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    setErrorVer(false);
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        console.log('actualizo sin log');
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        console.log(error);
      }
    } else if (error.response && error.response.data.statusCode === 426) {
      localStorage.setItem(
        'CLIENT_ID',
        JSON.stringify(error.response.data.data.id_cl)
      );
      console.log(error.response.data.message);
      setErrorVer(false);
    } else {
      setErrorVer(true);
      if (error.response && error.response.data) {
        setNavbarErrores([error.response.data.message]);
      } else {
        setNavbarErrores(['Un error inesperado ha ocurrido']);
      }
      console.log(error);
      throw error;
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_CLIENTES, json);
    handleResponse(data);
  } catch (error) {
    await handleError(error);
  }
};

export default postPropietario;
