import { useEffect } from 'react';
import BancoDetalle from '../Checkout/BancoDetalle';
import BancoSeleccion from '../Checkout/BancoSeleccion';
import MetodoDePago from '../Checkout/MetodoDePago';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Ticket = ({
  tipoPago,
  setBancoElegido,
  bancoElegido,
  setReferencia,
  referencia,
  total,
  cargandoTipoPagos,
  activo,
  setActivo,
}) => {
  const [bancos, setBancos] = useState([]);

  const obtenerBanco = event => {
    const seleccionado = bancos.find(x => x.ds_bc === event.target.value);
    setBancoElegido(seleccionado);
  };
  const estiloSeleccionado = {
    border: '2px solid #2196f3',
    color: activo.banco === 'ZELLE' ? 'white' : '#2196f3',
    backgroundColor: activo.banco === 'ZELLE' ? '#671CCA' : null,
  };
  const tomarReferencia = e => {
    const { value } = e.target;
    setReferencia(value);
  };

  useEffect(() => {
    setBancoElegido(undefined);
    setActivo({
      valor: tipoPago[0]?.id_tp_pg,
      banco: tipoPago[0]?.ds_tp_pg,
    });
    setBancos(tipoPago[0]?.bancue);
  }, [cargandoTipoPagos]);

  return (
    <div className="pago__contenedor-ticket">
      <div className="pago__contenedor-ticket-header">
        <h5>
          <span>R</span>esumen de compra
        </h5>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>}
        >
          <Link to={'/'}>
            <IoClose />
          </Link>
        </OverlayTrigger>
      </div>

      <MetodoDePago
        tipoPago={tipoPago}
        activo={activo}
        estiloSeleccionado={estiloSeleccionado}
        setBancos={setBancos}
        setBancoElegido={setBancoElegido}
        setActivo={setActivo}
      />
      {bancos?.length === 0 ? null : (
        <BancoSeleccion
          bancoElegido={bancoElegido}
          obtenerBanco={obtenerBanco}
          bancos={bancos}
        />
      )}
      <BancoDetalle bancoElegido={bancoElegido} />
      <div className="pago__contenedor-checkout-detalles-referencia">
        <input
          type="text"
          id="referencia"
          name="referencia"
          className=""
          autoComplete="off"
          onChange={tomarReferencia}
        />
        <label className={referencia && referencia ? 'focused' : ''}>
          Referencia
        </label>
      </div>
      <div className="pago__contenedor-checkout-footer">
        <div className="pago__contenedor-checkout-footer-detalles">
          <div className="pago__contenedor-checkout-footer-detalles-item">
            <span>Total REF:</span>
            <span>
              {total[5]?.mt_ba.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className="pago__contenedor-checkout-footer-detalles-itembs">
            <span>Total Bs:</span>
            <span>
              {total[5]?.mt_bs.toLocaleString('es-VE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
