import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocumentComisiones from './PDFDocumentComisiones';

const ReportesComisionesPDF = ({
  reporte,
  userProfile,
  fecha,
  hora,
  fechaini,
  fechafin,
}) => {
  return (
    <div>
      <PDFViewer width="100%" height="1000">
        <PDFDocumentComisiones
          reporte={reporte}
          userProfile={userProfile}
          fecha={fecha}
          hora={hora}
          fechaini={fechaini}
          fechafin={fechafin}
        />
      </PDFViewer>
    </div>
  );
};

export default ReportesComisionesPDF;
