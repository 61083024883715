import React, { useState, useEffect, useContext } from 'react';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import CuponDes from './Cupon/CuponDes';
import imagenAgente from '../../../styles/images/telemarketing (1).png';
import NavbarErrors from '../../NavbarLite/NavbarErrors';
import { TokenContext } from '../../../context/TokenContext';
import CartaReferido from './Descuentos/CartaReferido';

const DescuentosYPromos = () => {
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);
  const context = useProcesoProvider();
  const { handlePrev, cambiarPaso } = context;
  const navigate = useNavigate();
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [referido, setReferido] = useState(null);
  const [valorAgente, setValorAgente] = useState('');
  const [cuponAplicado, setCuponAplicado] = useState(false);
  const [porLink, setPorLink] = useState(false);
  const [cuponAsignadoArriba, setCuponAsignadoArriba] = useState(false);

  const quitarReferido = () => {
    setReferido(null);
    localStorage.removeItem('RCV_DIGITAL_REFERIDO');
    setValorAgente('');
  };

  useEffect(() => {
    let referidoLocal = localStorage.getItem('RCV_DIGITAL_REFERIDO');
    if (referidoLocal) {
      const codigoParseado = JSON.parse(referidoLocal);
      setReferido(codigoParseado);
      setPorLink(true);
    }
  }, []);

  useEffect(() => {
    const cuponAsignado = localStorage.getItem('RCV_DIGITAL_CUPON_ASIGNADO');
    if (cuponAsignado) {
      setCuponAplicado(cuponAsignado);
      setCuponAsignadoArriba(true);
    }
  }, []);

  useEffect(() => {
    cambiarPaso(6, 4);
  }, []);

  const handleSubmit = e => {
    setTimeout(() => {
      navigate('/proceso/resumen');
    }, 300);
  };
  const sinlink = () => {
    console.log('sin link');
  };
  return (
    <div className="descuentoYPromo">
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />

      <div className="descuentoYPromo__header">
        <h2>
          Aplica tu <span>descuento</span>.
        </h2>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>}
        >
          <Link to={'/'}>
            <IoClose />
          </Link>
        </OverlayTrigger>
      </div>
      <div className="descuentoContenedor">
        {/**  <div className="descuentoYPromo__descuento">
          {codigoAplicado ? (
            <DescuentoAplicado
              descuentoJSON={descuentoJSON}
              setTotalBs={setTotalBs}
              setTotalDolares={setTotalDolares}
              setCodigoAplicado={setCodigoAplicado}
              setDescuento={setDescuento}
              setShowCodigo={setShowCodigo}
              descuento={descuento}
              setEstaAplicado={setEstaAplicado}
            />
          ) : (
            <Descuento
              estaAplicado={estaAplicado}
              setEstaAplicado={setEstaAplicado}
              showCodigo={showCodigo}
              setShowCodigo={setShowCodigo}
              setCodigo={setCodigo}
              aplicar={aplicar}
              token={token}
              login={login}
              setTokenController={setTokenController}
              setTokenExp={setTokenExp}
              setToken={setToken}
              codigo={codigo}
              setDescuento={setDescuento}
              descuento={descuento}
              totalDolares={totalDolares}
              setTotalDolares={setTotalDolares}
              setTotalBs={setTotalBs}
              plan={plan}
              setCodigoAplicado={setCodigoAplicado}
              montoFormateado={montoFormateado}
              setMostrarErrores={setMostrarErrores}
              setNavbarErrores={setNavbarErrores}
            />
          )}
        </div>
        */}
        <CuponDes
          setMostrarErrores={setMostrarErrores}
          setNavbarErrores={setNavbarErrores}
          setReferido={setReferido}
          setTokenCambiar={setTokenCambiar}
          setTokenVencido={setTokenVencido}
          setToken={setToken}
          cancelar={cancelar}
          valorAgente={valorAgente}
          setValorAgente={setValorAgente}
          quitarReferido={
            !porLink && !cuponAsignadoArriba ? sinlink : quitarReferido
          }
          setCuponAplicado={setCuponAplicado}
          cuponAplicado={cuponAplicado}
          setPorLink={setPorLink}
        />
        {referido ? <CartaReferido referido={referido} /> : null}
      </div>

      <div className="descuentoYPromo__footer">
        <div></div>
        <div className="descuentoYPromo__footer-contenedor">
          <button
            className="descuentoYPromo__footer-volver"
            onClick={() => {
              const sinProducto = localStorage.getItem('RCV_DIGITAL_S/P');
              sinProducto
                ? handlePrev('/proceso/vehiculo')
                : handlePrev('/proceso/producto');
            }}
          >
            ANTERIOR
          </button>
          <button
            onClick={() => handleSubmit()}
            className="descuentoYPromo__footer-siguiente"
          >
            SIGUIENTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default DescuentosYPromos;
