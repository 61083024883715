import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    position: 'absolute',
    right: '25px',
    top: '20px',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'row',
  },
  font: {
    fontSize: '6px',
  },
  fontBold: {
    fontSize: '6px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});
const FacturaSedePrin = () => (
  <div style={styles.container}>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>Sede Principal:</Text>
      </View>
      <View>
        <Text style={styles.font}>
          Av. 8 Santa Rita, entre calles 63 y 64, No. 63-49, al lado de
          Ferreteria Tanoca. Maracaibo
        </Text>
      </View>
    </div>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.font}>
          Edo. Zulia. Telfs.: 0261-719.3619 / 0412-514.5856 / 0412-162.8233 /
        </Text>
      </View>
      <View>
        <Text style={styles.fontBold}> RIF. J-29804784-4</Text>
      </View>
    </div>
  </div>
);
export default FacturaSedePrin;
