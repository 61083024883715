import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  footer: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
  },
  footerFuente: {
    fontFamily: 'Arial',
    borderStyle: 'solid',
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'bold',
    fontSize: 8,
  },
});

const ResumenTotalGeneral = ({ reporte }) => {
  const sumarMtBaTt = campo => {
    const ventasDiarias = reporte?.ResumenVentasDiarias;
    let sumaMtBaTt = 0;

    ventasDiarias?.forEach(venta => {
      sumaMtBaTt += venta[campo];
    });

    if (sumaMtBaTt === 0) {
      return '0, 00';
    } else {
      return sumaMtBaTt.toLocaleString('es-VE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };
  return (
    <View style={styles.footer}>
      <Text style={styles.footerFuente}>Total General:</Text>
      <Text style={styles.footerFuente}> {sumarMtBaTt('sm_mt_ge_tt')}</Text>
    </View>
  );
};

export default ResumenTotalGeneral;
