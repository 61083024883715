import React from 'react';

const ResumenVehiculoBody = ({
  an_vh,
  mc_sel,
  nr_pu_vh,
  pe_vh,
  pl_vh,
  se_ca_vh,
  se_mo_vh,
  md_sel,
  tp_sel,
  uso_sel,
  cl_vh,
}) => {
  const clase = JSON.parse(localStorage.getItem('RCV_DIGITAL_CLASE'));
  return (
    <div className="resumen__items-body">
      <div className="resumen__items-body-item">
        <p>{pl_vh}</p>
        <span>Placa</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{mc_sel[0]?.label}</p>
        <span>Marca</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{md_sel[0]?.label}</p>
        <span>Modelo</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{cl_vh}</p>
        <span>Color</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{an_vh}</p>
        <span>Año</span>
      </div>

      <div className="resumen__items-body-item">
        <p>{nr_pu_vh}</p>
        <span>Numero de puestos</span>
      </div>

      <div className="resumen__items-body-item">
        <p>{pe_vh}</p>
        <span>Peso(kg)</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{se_ca_vh}</p>
        <span>Serial de carroceria</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{se_mo_vh}</p>
        <span>Serial del motor</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{tp_sel[0]?.label}</p>
        <span>Tipo</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{uso_sel[0]?.label}</p>
        <span>Uso</span>
      </div>
      <div className="resumen__items-body-item">
        <p>{clase?.ds_cl_vh}</p>
        <span>Clase</span>
      </div>
    </div>
  );
};

export default ResumenVehiculoBody;
