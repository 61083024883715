import React from 'react';

const BancoDetalle = ({ bancoElegido }) => {
  return (
    <div className="pago__contenedor-checkout-detalles-banco">
      {bancoElegido === undefined ? (
        <span>Elige un tipo de pago y el banco de tu preferencia.</span>
      ) : (
        <>
          <img src={bancoElegido?.im_bc} alt="banco" />
          <ul>
            <li>{bancoElegido?.ds_cu}</li>
            <li>{bancoElegido?.nb_cu}</li>
            <li>{bancoElegido?.rf_cu}</li>
          </ul>
        </>
      )}
    </div>
  );
};

export default BancoDetalle;
