const validationsFormCliente = form => {
  function calcularEdad(fecha) {
    var hoy = new Date();
    var nacimiento = new Date(fecha);
    var edad = hoy.getFullYear() - nacimiento.getFullYear();
    var m = hoy.getMonth() - nacimiento.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }

    return edad;
  }

  function esFechaValida(fecha) {
    const regexFecha = /^\d{4}-\d{2}-\d{2}$/;
    if (!regexFecha.test(fecha)) {
      return false;
    }
    const [year, month, day] = fecha.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }

  let errors = {};
  let regexCedula = /^[Vv|Ee]-\d{8}$/;
  /* let regexCedula = /^\d{8}$/;*/
  let regexRif = /^[Vv|Ee]-\d{8}-\d$/;
  let regexJuridico = /^[Jj|Vv|Gg|Ee]-\d{8}-\d$/;
  let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  let regexNroCel = /^\d{7}$/;

  const {
    tp_cl,
    cd_cl,
    nb_cl,
    ap_cl,
    rz_cl,
    fc_cl,
    id_es,
    se_cl,
    dr_cl,
    em_cl,
    id_op,
    tf_cl_cl_01,
    id_mn,
  } = form;

  if (tp_cl === 'N') {
    /** .:. SI ES NATURAL .:. */
    /**.:. VALIDAR LA CEDULA .:. */
    if (!cd_cl.trim()) {
      errors.cd_cl = 'Indica el número de cédula o rif, por favor verifique.';
    } else if (!regexCedula.test(cd_cl.trim())) {
      if (!regexRif.test(cd_cl.trim())) {
        errors.cd_cl =
          'El número de cédula ó rif no cumple con el formato, por favor verifique el ejemplo. Ejem: J-01234567-8 / V-15723890';
      }
    }
    /**.:. VALIDAR EL NOMBRE .:. */
    if (!nb_cl.trim()) {
      errors.nb_cl = 'Necesitamos el nombre del cliente, por favor verifique.';
    } else if (nb_cl.trim().length > 75) {
      errors.nb_cl =
        'El nombre no debe exceder los 75 caracteres, por favor verifique.';
    }
    /**.:. VALIDAR EL APELLIDO .:. */
    if (!ap_cl.trim()) {
      errors.ap_cl =
        'El apellido del cliente es importante, por favor verifique.';
    } else if (ap_cl.trim().length > 75) {
      errors.ap_cl =
        'El apellido no debe exceder los 75 caracteres, por favor verifique.';
    }
    /**.:. VALIDAR LA FECHA DE NACIMIENTO .:. */
    if (!esFechaValida(fc_cl)) {
      errors.fc_cl =
        'La fecha de nacimiento no es válida, por favor verifique.';
    } else if (calcularEdad(fc_cl) < 18) {
      errors.fc_cl = 'El cliente debe ser mayor de edad, por favor verifique.';
    }
  } else {
    /** .:. SI ES JURIDICO .:. */
    /**.:. VALIDAR EL RIF .:. */
    if (!cd_cl.trim()) {
      errors.cd_cl =
        'Debe indicar el número de cédula ó rif, por favor verifique.';
    } else if (!regexJuridico.test(cd_cl.trim())) {
      errors.cd_cl =
        'El rif no cumple con el formato, por favor verifique el ejemplo.';
    }
    /**.:. VALIDAR LA RAZON SOCIAL .:. */
    if (!rz_cl.trim()) {
      errors.rz_cl = 'Debe indicar la razón social del cliente, por favor.';
    }
  }

  /**.:. VALIDAR QUE SELECCIONO UN ESTADO .:. */
  if (id_es.length === 0 || id_es === 'Seleccione...') {
    errors.id_es = 'Elige un estado por favor.';
  }

  if (id_mn?.length === 0 || id_mn === '') {
    errors.id_mn = 'Elige un municipio por favor.';
  }
  /**.:. VALIDAR EL MUNICIPIO 
  if (!ds_mn.trim()) {
    errors.ds_mn = 'Escribe el municipio por favor';
  } else if (ds_mn.trim().length > 100) {
    errors.ds_mn =
      'El municipio no debe exceder los 100 caracteres, por favor .';
  }*/
  /**.:. VALIDAR EL SECTOR .:. */
  if (!se_cl.trim()) {
    errors.se_cl = 'Te falto el sector, por favor verifique.';
  } else if (se_cl.trim().length > 50) {
    errors.se_cl = 'El sector no debe exceder los 50 caracteres, por favor.';
  }
  /**.:. VALIDAR LA DIRECCION .:. */
  if (!dr_cl.trim()) {
    errors.dr_cl = 'Necesitamos la direccion, para poder continuar';
  } else if (dr_cl.trim().length > 200) {
    errors.dr_cl =
      'La dirección no debe exceder los 200 caracteres, por favor.';
  }
  /**.:. VALIDAR EL EMAIL .:. */
  if (!em_cl.trim()) {
    errors.em_cl =
      'El correo es importante en el proceso, debes proveernos de uno válido.';
  } else if (em_cl.trim().length > 100) {
    errors.em_cl =
      'El correo electrónico no debe exceder los 100 caracteres, por favor verifique.';
  } else if (!regexEmail.test(em_cl.trim())) {
    errors.em_cl =
      'El formato del correo electrónico no es válido, por favor verifique.';
  }
  /**.:. VALIDAR EL CODIGO DE AREA .:. */
  if (id_op.length === 0) {
    errors.id_op = 'Indica la operadora, por favor.';
  }
  /**.:. VALIDAR EL NUMERO DE CELULAR .:. */
  if (!tf_cl_cl_01.trim()) {
    errors.tf_cl_cl_01 =
      'Ingresa el número de telefónico del cliente, por favor';
  } else if (!regexNroCel.test(tf_cl_cl_01.trim())) {
    errors.tf_cl_cl_01 =
      'El número telefónico debe tener 7 dígitos, por favor verifique.';
  }

  return errors;
};

export default validationsFormCliente;
