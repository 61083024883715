import React from 'react';

const DashboardEsqueletos = () => {
  return (
    <tr className="skeletodashboard">
      <td>
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td className="opcional">
        <div className="skeletodashboard-box"></div>
      </td>
      <td>
        <div className="skeletodashboard-box"></div>
      </td>
      <td>
        <div className="skeletodashboard-box"></div>
      </td>
    </tr>
  );
};

export default DashboardEsqueletos;
