import { StyleSheet, Image } from '@react-pdf/renderer';
import Firma from '../../../../styles/images/fed9b1a5-734d-4f75-adb2-eb553e3f22ce-removebg-preview.png';

const styles = StyleSheet.create({
  img: {
    width: '70px',
    position: 'absolute',
    right: '125px',
    top: '-25px',
  },
});
const ContratoFirma = () => <Image src={Firma} style={styles.img} />;
export default ContratoFirma;
