import React from 'react';

const Valores = () => {
  return (
    <div className="leerMas__content-valores">
      <div className="leerMas__content-valores-header">
        <h1>Valores</h1>
      </div>
      <div className="leerMas__content-valores-info">
        <ul className="leerMas__content-valores-info-parrafo">
          <li>
            <span>Responsabilidad:</span> Nos comprometemos a proporcionar
            soluciones de responsabilidad civil para vehículos que cumplan con
            los más altos estándares éticos y legales.
          </li>
          <li>
            <span>Calidad de Servicio:</span> Buscamos la excelencia en cada
            interacción con nuestros clientes, ofreciendo servicios de calidad
            que superen sus expectativas.
          </li>
          <li>
            <span>Iniciativa:</span> Nos destacamos por ser proactivos en la
            identificación de las necesidades de nuestros clientes y en la
            búsqueda constante de mejoras en nuestros productos y servicios.
          </li>
          <li>
            <span>Compromiso:</span> Estamos dedicados a brindar un servicio
            confiable y comprometido, respaldando a nuestros clientes en todas
            las etapas de su experiencia con nosotros.
          </li>
          <li>
            <span>Honestidad:</span> Actuamos con transparencia y honestidad en
            todas nuestras interacciones, construyendo relaciones basadas en la
            confianza mutua.
          </li>
          <li>
            <span>Trabajo en Equipo:</span> Valoramos la colaboración interna y
            externa, reconociendo que el trabajo en equipo es esencial para
            ofrecer soluciones integrales y efectivas.
          </li>
          <li>
            <span>Solidaridad:</span> Nos comprometemos a ser solidarios con
            nuestros clientes, comprendiendo sus necesidades y brindando apoyo
            en momentos clave.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Valores;
