import { StyleSheet } from "@react-pdf/renderer";
import FacturaTicketPlan from "./FacturaTicketPlan";
import FacturaTicketVehi from "./FacturaTicketVehi";

const styles = StyleSheet.create({
  container: {
    width: "540px",
    marginTop: "4px",
    borderTop: "1px dashed black",
    borderBottom: "1px dashed black",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
});

const FacturaTickets = ({factura, imagenqr}) => (
  <div style={styles.container}>
    <FacturaTicketVehi factura={factura} imagenqr={imagenqr}/>
    <FacturaTicketPlan factura={factura}/>
  </div>
);
export default FacturaTickets;
