import axios from '../api/axios';

export const getToken = async () => {
  const json = JSON.stringify({
    token: {
      usu: 'ADMIN',
      pwd: process.env.REACT_APP_PWD,
      ori: 'web',
    },
    trace: '0',
  });
  try {
    const { data } = await axios.post(`v1/getToken`, json);
    return data.token;
  } catch (error) {
    console.log(error);
  }
};
