const validationsFormRegistro = (data) => {
  let regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  let errors = {};

  const { nb_us, ap_us, em_us, pw_us, us_us, pw_cn_us, ac_tc } = data;
  if (nb_us === '' || nb_us === undefined) {
    //Nombre de la persona
    errors.nb_us = 'Debe indicar su nombre, por favor verifique.';
  }

  if (nb_us?.length < 2) {
    errors.nb_us = 'Su nombre debe superar los 2 caracteres.';
  }

  if (nb_us?.length > 50) {
    errors.nb_us = 'Su nombre no debe superar los 50 caracteres.';
  }

  if (ap_us === '' || ap_us === undefined) {
    //Apellido de la persona
    errors.ap_us = 'Debe indicar su apellido, por favor verifique.';
  }

  if (ap_us?.length < 2) {
    errors.ap_us = 'Su apellido debe superar los 2 caracteres.';
  }

  if (ap_us?.length > 50) {
    errors.ap_us = 'Su apellido no debe superar los 50 caracteres.';
  }

  if (us_us === '' || us_us === undefined) {
    //Usuario de la persona
    errors.us_us = 'Por favor, elija un nombre de usuario';
  }

  if (us_us?.length < 2) {
    errors.us_us = 'Su usuario debe superar los 2 caracteres.';
  }

  if (us_us?.length > 20) {
    errors.us_us = 'Su usuario no debe superar los 20 caracteres.';
  }

  if (!document.querySelector('input[name="group1"]:checked')) {
    //Sexo de la persona
    errors.sx_us = 'Por favor, elija un sexo, este campo es obligatorio';
  }

  if (em_us === '' || em_us === undefined) {
    //Email de la persona
    errors.em_us = 'Por favor escriba su direccion de correo Electronico';
  }

  if (!regexEmail.test(em_us)) {
    errors.em_us =
      'El formato de correo electronico no es el correcto, por favor verifique.';
  }

  if (em_us?.length > 100) {
    errors.em_us = 'Su correo no debe superar los 100 caracteres.';
  }

  if (pw_us === '' || pw_us === undefined) {
    //Contraseña de la persona
    errors.pw_us = 'Por su seguridad es necesario tener una contraseña';
  }

  if (pw_us?.length < 6) {
    errors.pw_us = 'Su contraseña debe superar los 6 caracteres.';
  }

  if (pw_us?.length > 20) {
    errors.pw_us = 'Su contraseña no debe superar los 20 caracteres.';
  }

  if (pw_cn_us === '' || pw_cn_us === undefined) {
    //Contraseña confirmar de la persona
    errors.pw_cn_us = 'Debes tener una contraseña';
  } else if (pw_cn_us !== pw_us) {
    errors.pw_cn_us = 'Las contraseñas deben coincidir';
  }

  if (!ac_tc) {
    errors.ac_tc = 'Debe aceptar los terminos para poder continuar';
  }
  return errors;
};

export default validationsFormRegistro;
