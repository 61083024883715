import { PDFDownloadLink } from '@react-pdf/renderer';
import React, { useState, useContext, useEffect } from 'react';
import PDFDocument from '../../PDFViewer/PDFDocument';
import { getToken } from '../../../helpers/getToken';
import axios from 'axios';
import { TokenContext } from '../../../context/TokenContext';
import { es } from 'date-fns/locale';
import { format } from 'date-fns';
import { QRCodeCanvas } from 'qrcode.react';
import conseguirValor from '../helper/conseguirValor';

const ContratoMobile = ({ contratoId }) => {
  const [contratoData, setContratoData] = useState({ detalle: [] });
  const { setTokenCambiar, setTokenVencido, token } = useContext(TokenContext);
  const [isLoading, setIsLoading] = useState(true);
  const [imagenqr, setImagenqr] = useState();
  const [imgContratoQR, setImgContratoQR] = useState();
  const [currentDate] = useState(new Date());
  const [valorSup, setValorSup] = useState();
  const [valorClienteQR, setValorClienteQR] = useState();
  const [sumaTotal, setSumaTotal] = useState();
  const [planes, setPlanes] = useState();
  const [datos, setDatos] = useState();
  const [descargando, setDescargando] = useState(false);

  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const [tiempo, setTiempo] = useState({
    hora: '',
    fecha: '',
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getReport?p1=${contratoId}&p2=&p3=&p4=&op=1&token=${token}`
      );

      setContratoData(response.data.data[0] || { detalle: [] });
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 403) {
        if (!login) {
          try {
            const tokenAct = await getToken();
            localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(tokenAct));
            const retryResponse = await axios.get(
              `https://apicli.venezuelaresponsable.com.ve/AppVr.svc/v1/getReport?p1=${contratoId}&p2=&p3=&p4=&op=1&token=${tokenAct}`
            );
            console.log('Retry API Response:', retryResponse.data);
            setContratoData(retryResponse.data.data[0] || { detalle: [] });
          } catch (retryError) {
            console.error('Error retrying with new token:', retryError);
          }
        } else {
          setTokenCambiar(true);
          setTokenVencido(error.response.data.token);
          console.log('Token expired, new token:', error.response.data.token);
        }
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (Object.keys(contratoData).length > 1) {
      const planA = contratoData.detalle.filter(
        elemento => elemento.cd_tp_bn === '0001'
      );
      const planB = contratoData.detalle.filter(
        elemento => elemento.cd_tp_bn === '0002'
      );
      const planC = contratoData.detalle.filter(
        elemento => elemento.cd_tp_bn === '0003'
      );

      setPlanes({ planA, planB, planC });

      const sumaA = planA.reduce(
        (acumulador, elemento) => acumulador + elemento?.mt_pq_dt,
        0
      );
      const sumaB = planB.reduce(
        (acumulador, elemento) => acumulador + elemento?.mt_pq_dt,
        0
      );
      const sumaC = planC.reduce(
        (acumulador, elemento) => acumulador + elemento?.mt_pq_dt,
        0
      );

      setSumaTotal({ sumaTotal: sumaA + sumaB + sumaC, sumaA, sumaB, sumaC });

      setDatos({
        datosA: {
          cod: planes?.planA?.[0]?.cd_tp_bn,
          nombre: planes?.planA?.[0]?.ds_tp_bn,
        },
        datosB: {
          cod: planes?.planB?.[0]?.cd_tp_bn,
          nombre: planes?.planB?.[0]?.ds_tp_bn,
        },
        datosC: {
          cod: planes?.planC?.[0]?.cd_tp_bn,
          nombre: planes?.planC?.[0]?.ds_tp_bn,
        },
      });

      conseguirValor(
        contratoData,
        setValorSup,
        setValorClienteQR,
        setImgContratoQR,
        setImagenqr
      );
    }
  }, [contratoData]);

  useEffect(() => {
    const fechaFormateada = format(currentDate, 'dd/MM/yyyy', { locale: es });
    const horaFormateada = format(currentDate, 'hh:mm:ss a', { locale: es });
    setTiempo({
      hora: horaFormateada,
      fecha: fechaFormateada,
    });
  }, [currentDate]);

  const descargarContrato = () => {
    setDescargando(true);
    fetchData();
    setTimeout(() => {
      document.getElementById(`pdcontratodescarga-${contratoId}`).click();
      setDescargando(false);
    }, 6000);
  };

  return (
    <div className="contratoMobile">
      <button
        disabled={descargando}
        className="contratoMobile__descargar"
        onClick={() => descargarContrato()}
      >
        {descargando ? 'DESCARGANDO...' : 'DESCARGAR CONTRATO'}
      </button>
      <QRCodeCanvas
        value={valorSup}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id="seguridadQR"
        style={{ display: 'none' }}
      />
      <QRCodeCanvas
        value={valorClienteQR}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id="clienteQR"
        style={{ display: 'none' }}
      />

      <div
        style={{
          display: 'none',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <PDFDownloadLink
          document={
            <PDFDocument
              imagenqr={imagenqr}
              imgContratoQR={imgContratoQR}
              contratoData={contratoData}
              fecha={tiempo?.fecha}
              hora={tiempo?.hora}
              planA={planes?.planA}
              planB={planes?.planB}
              planC={planes?.planC}
              sumaA={sumaTotal?.sumaA}
              sumaB={sumaTotal?.sumaB}
              sumaC={sumaTotal?.sumaC}
              datosA={datos?.datosA}
              datosB={datos?.datosB}
              datosC={datos?.datosC}
              sumaTotal={sumaTotal?.sumaTotal}
            />
          }
          fileName={`CO_${contratoId}.pdf`}
        >
          <button id={`pdcontratodescarga-${contratoId}`}></button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default ContratoMobile;
