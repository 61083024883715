import React, { useState, useContext } from 'react';
import updateUser from '../../../helpers/updateUser';
import { LoginContext } from '../../../context/LoginContext';
import { TokenContext } from '../../../context/TokenContext';

const NombrePerfil = ({
  setNavbarErrores,
  setMostrarErrores,
  setMensajeCorrecto,
  setMostrarModalCorrecto,
}) => {
  const { setLog } = useContext(LoginContext);

  const [focused, setFocused] = useState();
  const [cargando, setCargando] = useState(false);
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const userProfileValue = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_PROFILEOBJ')
  );
  const [nombreJSON, setNombreJSON] = useState({
    nombre: '',
    apellido: '',
  });
  const nombreVerificado = form => {
    let errors = {};
    if (form?.nombre === '' || form?.nombre === undefined) {
      errors.nombre = 'Debe indicar su nombre, por favor verifique.';
    }
    if (form?.nombre.length < 2) {
      errors.nombre = 'Su nombre debe superar los 2 caracteres.';
    }
    if (form?.nombre.length > 50) {
      errors.nombre = 'Su nombre no debe superar los 50 caracteres.';
    }
    if (form?.apellido === '' || form?.apellido === undefined) {
      errors.apellido = 'Debe indicar su apellido, por favor verifique.';
    }
    if (form?.apellido.length < 2) {
      errors.apellido = 'Su apellido debe superar los 2 caracteres.';
    }
    if (form?.apellido.length > 50) {
      errors.apellido = 'Su apellido no debe superar los 50 caracteres.';
    }
    return errors;
  };

  const handleChange = e => {
    e.preventDefault();
    const { value, name } = e.target;
    setNombreJSON(current => {
      return {
        ...current,
        [name]: value,
      };
    });
  };

  const cambiarNombre = async e => {
    setCargando(true);
    e.preventDefault();

    const validador = nombreVerificado(nombreJSON);

    if (Object.keys(validador).length > 0) {
      setNavbarErrores(validador);
      setMostrarErrores(true);
    } else {
      let nombre = nombreJSON.nombre;
      let apellido = nombreJSON.apellido;
      const respuesta = await updateUser({
        nombre,
        apellido,
        setTokenCambiar,
        setTokenVencido,
        setNavbarErrores,
        setMostrarErrores,
      });
      if (respuesta === true) {
        setMensajeCorrecto('Su nombre se ha cambiado correctamente.');
        setMostrarModalCorrecto(true);
        setCargando(false);
        setLog(true);
      }
    }
  };

  return (
    <div className="nombrePerfil">
      <div className="nombrePerfil__nombre">
        <div className="nombrePerfil__nombre-header">
          <h5>
            Escribe tu nuevo <span>nombre</span>.
          </h5>
        </div>
        <form className="nombrePerfil__nombre-form" onSubmit={cambiarNombre}>
          <div className="nombrePerfil__nombre-form-nombre">
            <span
              htmlFor="nombre"
              className={
                focused === 'nombre'
                  ? 'focused'
                  : '' + nombreJSON.nombre
                  ? 'focused'
                  : ''
              }
            >
              {userProfileValue.nb_us.toUpperCase()}
            </span>
            <input
              name="nombre"
              type="text"
              onChange={handleChange}
              onFocus={e => {
                e.preventDefault();
                setFocused('nombre');
              }}
              onBlur={e => {
                e.preventDefault();
                setFocused('');
              }}
            ></input>
          </div>
          <div className="nombrePerfil__nombre-form-apellido">
            <span
              for="apellido"
              className={
                focused === 'apellido'
                  ? 'focused'
                  : '' + nombreJSON.apellido
                  ? 'focused'
                  : ''
              }
            >
              {userProfileValue.ap_us.toUpperCase()}
            </span>
            <input
              name="apellido"
              type="text"
              onChange={handleChange}
              onFocus={e => {
                e.preventDefault();
                setFocused('apellido');
              }}
              onBlur={e => {
                e.preventDefault();
                setFocused('');
              }}
            ></input>
          </div>
          <button
            disabled={cargando}
            className="nombrePerfil__nombre-form-submit"
            type="submit"
          >
            {cargando ? (
              <div className="lds-ellipsisg">
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
                <div style={{ backgroundColor: 'white' }}></div>
              </div>
            ) : (
              'CAMBIAR NOMBRE'
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NombrePerfil;
