import React, { useState } from 'react';
import IniciarYRegistro from '../IniciarYRegistro/IniciarYRegistro';
import Modal from 'react-bootstrap/Modal';
import Terminos from '../Terminos/Terminos';
import NavbarErrors from '../NavbarLite/NavbarErrors';
import ModalCorrecto from '../ModalCorrecto/ModalCorrecto';
import { useLocation } from 'react-router-dom';

const InicioRegistroNavbar = () => {
  const [verified, setVerified] = useState(undefined);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [fullscreen, setFullscreen] = useState();
  const [show, setShow] = useState();
  const [handlePrev] = useState(undefined);
  const [mensajeCorrecto, setMensajeCorrecto] = useState('');
  const [mostrarModalCorrecto, setMostrarModalCorrecto] = useState(false);
  const locationPath = useLocation();

  return (
    <div className="inicioRegistroNavbar">
      <ModalCorrecto
        mostrarModalCorrecto={mostrarModalCorrecto}
        setMostrarModalCorrecto={setMostrarModalCorrecto}
        mensajeCorrecto={mensajeCorrecto}
      />
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terminos y condiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Terminos />
        </Modal.Body>
      </Modal>
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <div className="inicioRegistroNavbar__contenedor">
        <IniciarYRegistro
          setVerified={setVerified}
          verified={verified}
          handlePrev={handlePrev}
          setMostrarErrores={setMostrarErrores}
          setNavbarErrores={setNavbarErrores}
          setShow={setShow}
          setFullscreen={setFullscreen}
          setMostrarModalCorrecto={setMostrarModalCorrecto}
          setMensajeCorrecto={setMensajeCorrecto}
          locationPath={locationPath}
        />
      </div>
    </div>
  );
};

export default InicioRegistroNavbar;
