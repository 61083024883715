import React, { useState, useEffect, useContext } from 'react';
import { TokenContext } from '../../../context/TokenContext';
import axios from 'axios';
import { getToken } from '../../../helpers/getToken';
import { QRCodeCanvas } from 'qrcode.react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { LoginContext } from '../../../context/LoginContext';
import PDFDocumentFactura from '../../PDFViewer/PDFDocumentFactura';

const PDFFacturaDescarga = () => {
  const { setNavbar } = useContext(LoginContext);
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const { setTokenCambiar, setTokenVencido } = useContext(TokenContext);
  const [factura, setFactura] = useState();
  const token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const [isLoading, setIsLoading] = useState(true);
  const [valorClienteQR, setValorClienteQR] = useState();
  const [imgContratoQR, setImgContratoQR] = useState();
  const [descargando, setDescargando] = useState(false);
  const [nombre, setNombre] = useState();

  const fetchFactura = async Id => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API}v1/getBill?p1=${Id}&p2=&p3=&p4=&op=3&token=${token}`
      );
      setFactura(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        if (login === undefined || login === null) {
          const tokenAct = await getToken();
          localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(tokenAct));

          let urlNueva = `${process.env.REACT_APP_URL_API}v1/getBill?p1=${Id}&p2=&p3=&p4=&op=3&token=${tokenAct}`;
          try {
            axios.get(urlNueva).then(response => {
              setFactura(response.data.data);
              localStorage.setItem(
                'RCV_DIGITAL_TOKEN',
                JSON.stringify(response.data.token)
              );
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          setTokenCambiar(true);
          setTokenVencido(error.response.data.token);
          console.log(error.response.data.token);
        }
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const conseguirValorFactura = contraId => {
    setValorClienteQR(
      `https://www.venezuelaresponsable.com.ve/verify.php?id=${contraId}`
    );
    setTimeout(() => {
      const canvaCliente = document.getElementById(`clienteQR`);
      const imgURLCliente = canvaCliente.toDataURL('image/png');
      setImgContratoQR(imgURLCliente);
    }, 2000);
  };

  useEffect(() => {
    const storedState = localStorage.getItem('linkStateFacturaDescarga');
    setNavbar(false);
    if (storedState) {
      let storeParse = JSON.parse(storedState);

      fetchFactura(storeParse.id_fc);
      setTimeout(() => {
        conseguirValorFactura(storeParse.id_co);
      }, 1000);
      setNombre(storeParse.pl_vh);
      localStorage.removeItem('linkStateFacturaDescarga');
      setTimeout(() => {
        setDescargando(false);
        document.getElementById(`linkFactura`).click();
        window.close();
      }, 6000);
    }
  }, []);

  return (
    <div className="facturaMobile">
      <div className="facturaMobile__header">
        <span>DESCARGANDO FACTURA...</span>
        <div className="lds-ellipsisg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <QRCodeCanvas
        value={valorClienteQR}
        size={256}
        fgColor="#000000"
        bgColor="transparent"
        level="L"
        includeMargin={true}
        id={`clienteQR`}
        style={{ display: 'none' }}
      />
      <div>
        <PDFDownloadLink
          className="downloadButton"
          document={
            <PDFDocumentFactura factura={factura} imagenqr={imgContratoQR} />
          }
          fileName={`FA_${nombre}.pdf`}
        >
          <button
            id={`linkFactura`}
            style={{
              backgroundColor: '#19FC6D',
              border: 'none',
              borderRadius: '20px',
              display: 'none',
            }}
          ></button>
        </PDFDownloadLink>
      </div>
    </div>
  );
};

export default PDFFacturaDescarga;
