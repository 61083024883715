import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import ContratoFirmas from './ContratoFirmas';
import ContratoSello from './ContratoSello';
import ContratoFirma from './ContratoFirma';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '540px',
    flexDirection: 'row',
    marginTop: '10px',
    gap: '10px',
    position: 'relative',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    height: '80px',
    width: '80px',
  },
  font: {
    fontSize: '6px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});

const ContratoQRS = ({ contratoData, imagenqr, imgContratoQR }) => {
  return (
    <div style={styles.container}>
      <div style={styles.containerSon}>
        <View>
          <Text style={styles.font}>CLIENTE</Text>
        </View>
        {imgContratoQR && <Image src={imgContratoQR} style={styles.img} />}
      </div>
      <ContratoSello />
      <ContratoFirmas contratoData={contratoData} />
      <ContratoFirma />
      <div style={styles.containerSon}>
        <View>
          <Text style={styles.font}>SEGURIDAD</Text>
        </View>
        {imagenqr && <Image src={imagenqr} style={styles.img} />}
      </div>
    </div>
  );
};
export default ContratoQRS;
