import { BsFillPenFill } from 'react-icons/bs';
import ResumenClienteBody from './ResumenClienteBody';
import ClienteEdicion from './ComponentesEdicion/ClienteEdicion';

const ClienteResume = ({
  datosCliente,
  setShowEdition,
  setComponenteEdit,
  setMostrarErrores,
  setNavbarErrores,
  setTokenController,
  setTokenExp,
  token,
  errors,
  setErrors,
  setErrorAdvice,
  errorAdvice,
  setToken,
}) => {
  const {
    cd_cl,
    dr_cl,
    ds_mn,
    em_cl,
    es_sel,
    mn_sel,
    fc_cl,
    nb_cl,
    op_sel,
    se_cl,
    tf_cl_cl_01,
    ap_cl,
    rz_cl,
  } = datosCliente;
  return (
    <div className="resumen__items elegido">
      <div className="resumen__items-header">
        <h5>Datos del cliente</h5>
        <button
          onClick={() => {
            setComponenteEdit(
              <ClienteEdicion
                errors={errors}
                setErrors={setErrors}
                setErrorAdvice={setErrorAdvice}
                setTokenController={setTokenController}
                setTokenExp={setTokenExp}
                token={token}
                setMostrarErrores={setMostrarErrores}
                setNavbarErrores={setNavbarErrores}
                errorAdvice={errorAdvice}
                datosCliente={datosCliente}
                setShowEdition={setShowEdition}
                setToken={setToken}
              />
            );
            setShowEdition(true);
          }}
        >
          <BsFillPenFill />
        </button>
      </div>
      <ResumenClienteBody
        cd_cl={cd_cl}
        ap_cl={ap_cl}
        dr_cl={dr_cl}
        mn_sel={mn_sel}
        em_cl={em_cl}
        es_sel={es_sel}
        fc_cl={fc_cl}
        nb_cl={nb_cl}
        op_sel={op_sel}
        se_cl={se_cl}
        tf_cl_cl_01={tf_cl_cl_01}
        rz_cl={rz_cl}
      />
    </div>
  );
};

export default ClienteResume;
