import { Link } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { AiOutlinePoweroff } from 'react-icons/ai';
const NavbarRight = ({ login, userProfile, handleLogout, version }) => {
  return (
    <div className="navbar__container-right">
      {login ? (
        <div className="navbar__container-right-item">
          <span className="navbar__container-right-item-usuario">{`${
            !userProfile ? ' ' : userProfile?.nb_us + ' ' + userProfile?.ap_us
          }`}</span>

          <span
            className="navbar__container-right-item-logout"
            onClick={() => {
              handleLogout();
            }}
          >
            <AiOutlinePoweroff />
          </span>
        </div>
      ) : (
        <div className="navbar__container-right-item-menu-acceder">
          <Link to="/login/1">
            <CgProfile /> <span>Acceder</span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default NavbarRight;
