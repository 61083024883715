import { useState, useEffect } from 'react';
import validarRecuperacion from './Helpers/ValidarRecuperacion';
import NavbarLite from '../NavbarLite/NavbarLite';
import HeadersPages from '../HeadersPages/HeaderPages';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineCheck } from 'react-icons/ai';
import ManejadorDeErrores from './ManejadorDeErroresModal/ManejadorDeErrores';

const RecuperarContraseña = () => {
  const [confirmacion, setConfirmacion] = useState(undefined);
  const [verificando, setVerificando] = useState(false);
  const [verificado, setVerificado] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const formHandler = async e => {
    e.preventDefault();
    let datos = e.target;
    let datosEspecificos = datos.correo.value;
    let validacion = await validarRecuperacion(
      datosEspecificos,
      setErrorMessage,
      setModalShow
    );
    setConfirmacion(validacion);
  };

  useEffect(() => {
    if (confirmacion === true) {
      setVerificando(true);
      setTimeout(() => {
        setVerificado(true);
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }, 2000);
    }
  }, [confirmacion]);

  return (
    <div>
      <NavbarLite />
      <ManejadorDeErrores
        show={modalShow}
        onHide={() => setModalShow(false)}
        error={errorMessage}
      />
      <div className="recuperarContraseña">
        <HeadersPages
          titulo={'Recuperar contraseña'}
          info={
            'Coloca tu correo, recibirás un email con la información necesaria'
          }
        />
        <form onSubmit={formHandler} className="recuperarContraseña__form">
          <input
            className="recuperarContraseña__form-input"
            name="correo"
            type="email"
          ></input>
          <button
            className="recuperarContraseña__form-boton buttonizer"
            type="submit"
          >
            Enviar
          </button>
        </form>
        {verificado ? (
          <div
            className={
              verificando
                ? 'recuperarContraseña__form-verificador active'
                : 'recuperarContraseña__form-verificador'
            }
          >
            <AiOutlineCheck style={{ color: 'green' }} /> Correcto, Revisa tu
            correo.
          </div>
        ) : (
          <div
            className={
              verificando
                ? 'recuperarContraseña__form-verificador active'
                : 'recuperarContraseña__form-verificador'
            }
          >
            <Spinner animation="border" variant="primary" /> Enviando...
          </div>
        )}
      </div>
    </div>
  );
};

export default RecuperarContraseña;
