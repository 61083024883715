import { StyleSheet, Image } from '@react-pdf/renderer';
import RCVSello from '../../../../styles/images/1e7ba4ee-6637-44fe-9ec1-61050f20e7a4.jpg';

const styles = StyleSheet.create({
  img: {
    width: '120px',
    position: 'absolute',
    right: '110px',
    top: '-10px',
    opacity: 0.8,
  },
});

const ContratoSello = () => <Image style={styles.img} src={RCVSello} />;
export default ContratoSello;
