import React from 'react';
import { Document, Page, StyleSheet, Text } from '@react-pdf/renderer';
import Table from './Table';
import HeaderReportes from './HeaderReportes';
/*import Tablaitem from './TablaItem';
import VencidoRenovado from './VencidoRenovado';*/
import ResumenVenta from './ResumenVenta';
import Tablaitem from './TablaItem';
import PieDePagina from './PieDePagina';

const styles = StyleSheet.create({
  page: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  resumen: {
    fontSize: 9,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    fontStyle: 'bold',
    width: '100%',
    textAlign: 'center',
    marginTop: '20px',
  },
});
const PDFDocument = ({
  reporte,
  userProfile,
  fecha,
  hora,
  fechaini,
  fechafin,
}) => {
  return (
    <Document>
      <Page size="LETTER" style={styles?.page} orientation="landscape">
        <HeaderReportes
          reporte={reporte}
          userProfile={userProfile}
          fecha={fecha}
          hora={hora}
        />
        <Text style={styles?.resumen}>
          RESUMEN DE VENTAS ({fechaini?.split('-').reverse().join('/')} -{' '}
          {fechafin?.split('-').reverse().join('/')})
        </Text>
        <Table reporte={reporte} />
        {/** <Tablaitem reporte={reporte} />
       Comentados por si son necesarios en un futuro
       * 
      <VencidoRenovado />
      */}
        <ResumenVenta reporte={reporte} />
        <PieDePagina nombre={'PDFDocument.js'} />
      </Page>
    </Document>
  );
};

export default PDFDocument;
