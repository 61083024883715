import React from 'react';

const Mision = () => {
  return (
    <div className="leerMas__content-mision">
      <div className="leerMas__content-mision-header">
        <h1>Misión</h1>
      </div>
      <div className="leerMas__content-mision-info">
        <ul className="leerMas__content-mision-info-parrafo">
          <li>
            <span>Nuestra Misión Principal:</span> Nos esforzamos por garantizar
            la completa satisfacción de nuestros clientes, proporcionando
            soluciones de garantías administradas que no solo cumplen, sino que
            superan sus expectativas. A través de un enfoque dedicado y un
            equipo apasionado, buscamos no solo crecer sólidamente, sino también
            adquirir nuevos compromisos que contribuyan al bienestar de la
            comunidad y al cuidado del medio ambiente.
          </li>
          <li>
            <span>Excelencia en el Servicio:</span> Buscamos la perfección en
            cada interacción, asegurando la calidad y eficacia en nuestros
            servicios de garantías administradas.
          </li>
          <li>
            <span>Compromiso con la Satisfacción del Cliente:</span> Nuestro
            compromiso va más allá de la transacción; nos esforzamos por
            construir relaciones sólidas y duraderas basadas en la confianza y
            la satisfacción continua del cliente.
          </li>
          <li>
            <span>Desarrollo Sostenible:</span> Reconocemos nuestra
            responsabilidad hacia la comunidad y el medio ambiente, buscando
            constantemente maneras de contribuir positivamente y minimizar
            nuestro impacto.
          </li>
          <li>
            <span>Crecimiento con Propósito:</span> A medida que crecemos, lo
            hacemos con un propósito claro: ser una fuerza positiva en la
            sociedad. Buscamos oportunidades para expandir nuestro impacto,
            beneficiando no solo a nuestros clientes, sino también a la
            comunidad en general y al entorno que compartimos.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Mision;
