import React, { useState, useEffect, useContext } from 'react';
import FormularioPropietario from '../../Propietario/FormularioPropietario';
import { BsX, BsCheck } from 'react-icons/bs';
import validationsFormCliente from '../../../../../pages/Validators/validationsFormCliente';
import postPropietario from '../../../../../helpers/postPropietario';
import consultarApi from '../../../../../helpers/consultarApi';
import { TokenContext } from '../../../../../context/TokenContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';

const PropietarioEdicion = ({
  errors,
  setErrors,
  setErrorAdvice,
  setMostrarErrores,
  setNavbarErrores,
  errorAdvice,
  datosPropietario,
  setShowEdition,
}) => {
  const {
    cd_cl,
    dr_cl,
    ds_mn,
    em_cl,
    es_sel,
    fc_cl,
    nb_cl,
    op_sel,
    se_cl,
    tf_cl_cl_01,
    ap_cl,
    rz_cl,
    id_es,
    id_op,
    tp_cl,
  } = datosPropietario;

  const [formData, setFormData] = useState({
    cd_cl,
    nb_cl,
    ap_cl,
    rz_cl,
    fc_cl,
    id_es,
    es_sel,
    ds_mn,
    se_cl,
    dr_cl,
    em_cl,
    id_op,
    op_sel,
    tf_cl_cl_01,
    tp_cl,
  });

  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [estados, setEstados] = useState([]);
  const [opeCel, setOpeCel] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState(es_sel);
  const [selectedOperadora, setSelectedOperadora] = useState(op_sel);
  const [errorVer, setErrorVer] = useState();

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value.toUpperCase();
    setErrorAdvice(0);
    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    let formErrors = validationsFormCliente(formData);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setErrorAdvice(1);
    } else {
      setErrorAdvice(2);

      postPropietario(
        formData,
        setErrorVer,
        setNavbarErrores,
        setToken,
        setTokenVencido,
        setTokenCambiar
      );
    }
  };
  useEffect(() => {
    if (errorVer === true) {
      setMostrarErrores(true);
    }

    if (errorVer === false) {
      localStorage.setItem('RCV_DIGITAL_PROPIETARIO', JSON.stringify(formData));
      setErrorVer(undefined);
      setShowEdition(false);
    }
  }, [errorVer]);

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_GET_ALL_ESTADOS,
      token,
      0,
      setEstados,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
    consultarApi(
      process.env.REACT_APP_GET_ALL_OPERADORAS_CELULAR,
      token,
      0,
      setOpeCel,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      setErrorAdvice(0);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const [data] = selectedEstado;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedEstado,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        es_sel: [],
        id_es: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEstado]);

  useEffect(() => {
    const [data] = selectedOperadora;

    if (data) {
      const { value, name, id } = data;
      setFormData({
        ...formData,
        [name]: value,
        [id]: selectedOperadora,
      });

      if (!!errors[name]) {
        setErrors({
          ...errors,
          [name]: null,
        });
      }
    } else {
      setFormData({
        ...formData,
        op_sel: [],
        id_op: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperadora]);
  return (
    <div className="clienteEdicion">
      <div className={`clienteEdicion__cliente  `}>
        <div className="clienteEdicion__cliente-header">
          <h2>
            Edita los datos del <span>Cliente</span>
          </h2>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">No editar</Tooltip>}
          >
            <IoClose onClick={() => setShowEdition(false)} cursor={'pointer'} />
          </OverlayTrigger>
        </div>
        <FormularioPropietario
          formData={formData}
          errors={errors}
          estados={estados}
          handleChange={handleChange}
          selectedEstado={selectedEstado}
          setSelectedEstado={setSelectedEstado}
          selectedOperadora={selectedOperadora}
          setSelectedOperadora={setSelectedOperadora}
          opeCel={opeCel}
        />
        <div className="proceso__botones">
          <div></div>
          <div className="proceso__botones-contenedor">
            <button className="cancelar" onClick={() => setShowEdition(false)}>
              CANCELAR <BsX />
            </button>
            <button className="siguiente" onClick={handleSubmit}>
              ACEPTAR <BsCheck />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropietarioEdicion;
