import { useEffect } from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"

const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scroll({
            top: 0,
            behavior: "smooth"
        })
    }
    
    return (
        <>
            {
                backToTopButton && (
                    <Button
                        onClick={scrollUp}
                        className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
                    >
                        <i className="bi bi-arrow-up"></i>
                    </Button>
                )
            }
        </>
    )
}
export default BackToTopButton
