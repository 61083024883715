import React from 'react';

const ClaseEsqueleto = () => {
  return Array(8)
    .fill(0)
    .map((_, index) => (
      <div className="clases__item skeleton" key={index}>
        <div className="skeleton__icon" />
        <span className="skeleton__text" />
      </div>
    ));
};

export default ClaseEsqueleto;
