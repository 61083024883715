import React from 'react';
import closeImg from '../../styles/images/error-icon-4.png';
import { Link } from 'react-router-dom';

const ActivacionIncorrecta = () => {
  return (
    <div className="activacionCorrecta">
      <div className="activacionCorrecta__container">
        <div className="activacionCorrecta__container-card">
          <img src={closeImg} alt="imagen de icono" />
          <p>
            Su cuenta no ha sido activada, por favor verifique su email o
            contacte con soporte
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActivacionIncorrecta;
