import axios from '../../../api/axios';
import actualizarTokenNoLog from '../../../helpers/actualizarTokenNoLog';

const validateUser = async (datosForm, setToken, setLoading) => {
  const token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));

  const requestData = JSON.stringify({
    usuario: {
      us_us: datosForm.us_us?.toUpperCase(),
      pw_us: datosForm.pw_us,
    },
    trace: '0',
    token: token,
  });

  const responseHandler = async response => {
    if (response.status === 200) {
      localStorage.setItem(
        'RCV_DIGITAL_TOKEN',
        JSON.stringify(response.data.token)
      );
      localStorage.setItem(
        'RCV_DIGITAL_TOKEN_AUTH',
        JSON.stringify(response.data.token)
      );
      localStorage.setItem(
        'RCV_DIGITAL_PROFILEOBJ',
        JSON.stringify(response.data.data)
      );
      localStorage.setItem(
        'RCV_DIGITAL_LOGIN',
        JSON.stringify(response.data.data.id_us)
      );

      return {
        response: true,
        json: JSON.stringify(response.data.data.id_us),
        dataperfil: response.data.data,
      };
    }
  };

  const errorHandler = async error => {
    if (error.response && error.response.status === 403) {
      setLoading(false);
      actualizarTokenNoLog(setToken);
    } else {
      return { status: false, message: error.response.data.message };
    }
  };
  try {
    const response = await axios.post(process.env.REACT_APP_LOGIN, requestData);
    return await responseHandler(response);
  } catch (error) {
    console.log(error);
    return await errorHandler(error);
  }
};

export default validateUser;
