import { BsFillPenFill } from 'react-icons/bs';
import ResumenPropietarioBody from './ResumenPropietarioBody';
import PropietarioEdicion from './ComponentesEdicion/PropietarioEdicion';
const PropietarioResume = ({
  datosPropietario,
  setShowEdition,
  setComponenteEdit,
  setMostrarErrores,
  setNavbarErrores,
  setTokenController,
  setTokenExp,
  token,
  errors,
  setErrors,
  setErrorAdvice,
  errorAdvice,
  setToken,
}) => {
  const {
    cd_cl,
    dr_cl,
    ds_mn,
    em_cl,
    es_sel,
    fc_cl,
    nb_cl,
    op_sel,
    se_cl,
    tf_cl_cl_01,
    ap_cl,
  } = datosPropietario;
  return (
    <div className="resumen__items elegido">
      <div className="resumen__items-header">
        <h5>Datos del Propietario</h5>
        <button
          onClick={() => {
            setComponenteEdit(
              <PropietarioEdicion
                errors={errors}
                setErrors={setErrors}
                setErrorAdvice={setErrorAdvice}
                setTokenController={setTokenController}
                setTokenExp={setTokenExp}
                token={token}
                setMostrarErrores={setMostrarErrores}
                setNavbarErrores={setNavbarErrores}
                errorAdvice={errorAdvice}
                datosCliente={datosPropietario}
                setShowEdition={setShowEdition}
                setToken={setToken}
              />
            );
            setShowEdition(true);
          }}
        >
          <BsFillPenFill />
        </button>
      </div>
      <ResumenPropietarioBody
        cd_cl={cd_cl}
        dr_cl={dr_cl}
        ds_mn={ds_mn}
        em_cl={em_cl}
        es_sel={es_sel}
        fc_cl={fc_cl}
        nb_cl={nb_cl}
        op_sel={op_sel}
        se_cl={se_cl}
        tf_cl_cl_01={tf_cl_cl_01}
        ap_cl={ap_cl}
      />
    </div>
  );
};

export default PropietarioResume;
