import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

/**
 * Determinar si el usuario esta logeado y retornar
 * @returns Retornar true o false
 */
export const useAuth = () => {
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));
  const user = { loggedIn: login ? true : false };

  return user && user.loggedIn;
};

const Protected = ({ element: Element, ...rest }) => {
  const isAuth = useAuth();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('LAST_LOCATION', JSON.stringify(location.pathname));
  }, []);

  return isAuth ? <Outlet /> : <Navigate to="/login/1" state={{ location }} />;
};

export default Protected;
