import ReactWhatsapp from 'react-whatsapp';
const Contact = () => {
  return (
    <div className="contact">
      <div className="contact__header"></div>
      <h1>Contáctanos</h1>
      <div className="contact__container wow fadeInUp">
        <a
          className="contact__container-item"
          href="https://www.facebook.com/people/Venezuela-Responsable/100063717486873/?fref=ts"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          className="contact__container-item"
          href="https://www.instagram.com/venezuelaresponsable/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <ReactWhatsapp
          className="contact__container-item"
          style={{ padding: '0px' }}
          number="58-412-514-5856"
          message="Hola Venezuela Responsable"
        >
          <i className="fab fa-whatsapp"></i>
        </ReactWhatsapp>
      </div>
    </div>
  );
};

export default Contact;
