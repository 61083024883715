import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '540px',
    alignItems: 'start',
    justifyContent: 'start',
    marginTop: '4px',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
  },
  containerEx: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
  },
  containerEx2: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
  },
  font: {
    fontSize: '8px',
  },
  fontBold: {
    fontFamily: 'Arial',
    fontSize: '8px',
    fontStyle: 'bold',
  },
});

const FacturaInfo = ({ factura }) => (
  <div style={styles.container}>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>OFICINA:</Text>
      </View>
      <View>
        <Text style={styles.font}>OFICINA VIRTUAL</Text>
      </View>
    </div>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>DIRECCION:</Text>
      </View>
      <View>
        <Text style={styles.font}>
          AVENIDA 8 SANTA RITA ENTRE CALLE 63 Y 64 A 100 MTS DE LA AV.
          UNIVERSIDAD
        </Text>
      </View>
    </div>
    <div style={styles.containerSon}>
      <div style={styles.containerEx}>
        <View>
          <Text style={styles.fontBold}>SUPERVISOR:</Text>
        </View>
        <View>
          <Text style={styles.font}>RAFAEL LEAL</Text>
        </View>
        <View>
          <Text style={styles.fontBold}>TELÉFONO:</Text>
        </View>
        <View>
          <Text style={styles.font}>(+58) - 0261-8141154 </Text>
        </View>
      </div>
    </div>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>CONTRATANTE:</Text>
      </View>
      <View>
        <Text style={styles.font}>{factura?.rz_cl.toUpperCase()}</Text>
      </View>
    </div>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>TELÉFONO:</Text>
      </View>
      <View>
        <Text style={styles.font}>(+58) - 0412-0884543</Text>
      </View>
    </div>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.font}>
          <Text style={styles.fontBold}>R.I.F.: </Text>
          {factura?.cd_cl}
        </Text>
      </View>
    </div>
    <div style={styles.containerEx2}>
      <View>
        <Text style={styles.fontBold}>DIRECCION:</Text>
      </View>
      <View>
        <Text style={styles.font}>{factura?.se_cl.toUpperCase()}</Text>
      </View>
    </div>
  </div>
);
export default FacturaInfo;
