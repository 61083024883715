import TableAdministration from './Table/TableAdministration';
import { useState, useContext, useEffect } from 'react';
import React from 'react';
import { useRef } from 'react';
import approveContracts from '../../helpers/approveContracts';
import Buscador from '../Buscador/Buscador';
import ManejadorDeErrores from '../Login/ManejadorDeErroresModal/ManejadorDeErrores';
import HeaderPages from '../HeadersPages/HeaderPages';
import ModalConfirmacion from './ModalConfirmacion/ModalConfirmacion';
import ModalRechazo from './ModalRechazo/ModalRechazo';
import rejectContracts from '../../helpers/rejectContracts';
import consultarApi from '../../helpers/consultarApi';
import { TokenContext } from '../../context/TokenContext';
import HistoryAdministration from './HistoryAdministration/HistoryAdministration';

const Administracion = () => {
  const [data, setData] = useState([]);
  const [valorBusqueda, setValorBusqueda] = useState('Buscar');
  const [modalShow, setModalShow] = useState(false);
  const [modalShowInfo, setModalShowInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [opcion, setOpcion] = useState(0);
  const [modalInfo, setModalInfo] = useState();
  const [modalRechazar, setModalRechazar] = useState(false);
  const [comentario, setComentario] = useState('');
  const [showConfirm, setShowConfirm] = useState(true);
  const comentarioRef = useRef();
  const [cargandoAprobar, setCargandoAprobar] = useState(false);
  const [cargandoOpcion, setCargandoOpcion] = useState(true);
  const [cargandoRechazar, setCargandoRechazar] = useState(false);
  const { setTokenCambiar, setTokenVencido, token, setToken, cancelar } =
    useContext(TokenContext);
  const [changeListener, setChangeListener] = useState(0);

  useEffect(() => {
    consultarApi(
      process.env.REACT_APP_HISTORIAL_ADMIN,
      0,
      setData,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );
  }, [token]);
  console.log(data);
  const comentarioHandler = () => {
    setComentario(comentarioRef.current.value);
  };

  const aprobar = (idco, idfc) => {
    setCargandoAprobar(true);
    approveContracts(
      idco,
      idfc,
      setModalShow,
      setErrorMessage,
      setTokenCambiar,
      setTokenVencido
    );
    setTimeout(() => {
      setChangeListener(prev => prev + 1);
      setCargandoAprobar(false);
      setModalShowInfo(false);
      consultarApi(
        process.env.REACT_APP_HISTORIAL_ADMIN,
        0,
        setData,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      );
    }, 1000);
  };

  const rechazarContrato = (idco, idfc) => {
    setCargandoRechazar(true);
    setComentario('');
    comentarioRef.current.value = '';
    rejectContracts(
      idco,
      idfc,
      comentario,
      setModalShow,
      setErrorMessage,
      setTokenCambiar,
      setTokenVencido
    );
    setTimeout(() => {
      setTimeout(() => {
        setModalRechazar(false);
        setCargandoRechazar(false);
        setChangeListener(prev => prev + 1);
      }, 1000);
    }, 1000);
  };

  const validar = datos => {
    setModalInfo(datos);
    setTimeout(() => {
      setModalShowInfo(true);
    }, 1000);
  };

  const rechazar = datos => {
    setModalInfo(datos);
    setTimeout(() => {
      setModalRechazar(true);
    }, 1000);
  };
  const cambiarOpcion = () => {
    setCargandoOpcion(false);
    opcion === 1 ? setOpcion(0) : setOpcion(1);
    setTimeout(() => {
      setCargandoOpcion(true);
    }, 1000);
  };

  return (
    <div className="administracionBody">
      <ModalConfirmacion
        info={modalInfo}
        modalShowInfo={modalShowInfo}
        setModalShowInfo={setModalShowInfo}
        aprobar={aprobar}
        cargandoAprobar={cargandoAprobar}
      />
      <ModalRechazo
        info={modalInfo}
        rechazarContrato={rechazarContrato}
        modalRechazar={modalRechazar}
        setModalRechazar={setModalRechazar}
        comentarioHandler={comentarioHandler}
        comentarioRef={comentarioRef}
        showConfirm={showConfirm}
        setShowConfirm={setShowConfirm}
        cargandoRechazar={cargandoRechazar}
      />
      <ManejadorDeErrores
        show={modalShow}
        onHide={() => setModalShow(false)}
        error={errorMessage}
      />

      {opcion === 0 ? (
        <div className="administracionBody__body">
          <div className="administracionBody__body-content">
            <HeaderPages
              titulo={'Administración'}
              info={'Verifica y aprueba los contratos pendientes.'}
            />
            {data && data.length === 0 ? (
              <Buscador
                setValorBusqueda={setValorBusqueda}
                valorBusqueda={valorBusqueda}
              />
            ) : null}

            <TableAdministration
              data={data}
              validar={validar}
              rechazar={rechazar}
              valorBusqueda={valorBusqueda}
              setData={setData}
            />
          </div>
        </div>
      ) : null}
      {opcion === 1 ? <HistoryAdministration /> : null}
      {cargandoOpcion ? (
        <div className="administracionBody__footer">
          <button onClick={cambiarOpcion}>
            {opcion === 0
              ? '     Desplegar historial de contratos'
              : 'Desplegar aprobador de contratos'}
          </button>
        </div>
      ) : (
        <div className="lds-ellipsisg">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};
export default Administracion;
