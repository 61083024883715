import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    position: 'absolute',
    right: '40px',
    top: '140px',
  },
  containerSon: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
  },
  font: {
    fontSize: '8px',
  },
  fontBold: {
    fontSize: '8px',
    fontFamily: 'Arial',
    fontStyle: 'bold',
  },
});
const ContratoImpresoFecha = ({ fecha, hora }) => (
  <div style={styles.container}>
    <div style={styles.containerSon}>
      <View>
        <Text style={styles.fontBold}>IMPRESO:</Text>
      </View>
      <View>
        <Text style={styles.font}>{fecha}</Text>
      </View>
      <View>
        <Text style={styles.font}>{hora}</Text>
      </View>
    </div>
  </div>
);
export default ContratoImpresoFecha;
