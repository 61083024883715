const ResumenPropietarioBody = ({
  cd_cl,
  dr_cl,
  ds_mn,
  em_cl,
  es_sel,
  fc_cl,
  nb_cl,
  op_sel,
  se_cl,
  tf_cl_cl_01,
  ap_cl,
}) => {
  <div className="resumen__items-body">
    <div className="resumen__items-body-item">
      <p>{cd_cl}</p>
      <span>Cedula</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{nb_cl}</p>
      <span>Nombre</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{ap_cl}</p>
      <span>Apellido</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{dr_cl}</p>
      <span>Direccion</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{es_sel && es_sel[0]?.label}</p>
      <span>Estado</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{se_cl}</p>
      <span>Sector</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{ds_mn}</p>
      <span>Municipio</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{em_cl}</p>
      <span>Email</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{fc_cl}</p>
      <span>Fecha</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{op_sel && op_sel[0]?.label}</p>
      <span>Operadora</span>
    </div>
    <div className="resumen__items-body-item">
      <p>{tf_cl_cl_01}</p>
      <span>Telefono</span>
    </div>
  </div>;
};

export default ResumenPropietarioBody;
