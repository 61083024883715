import React from 'react';
import imgAgente from '../../../../styles/images/telemarketing (1).png';

const CartaReferido = ({ referido }) => {
  const cuponAplicado = false;
  let mostrar = false;
  let isHovered = false;
  referido = referido.length > 0 ? referido[0] : referido;

  return (
    <div className="cuponDes" style={{ backgroundColor: '#074577' }}>
      {cuponAplicado ? (
        <div className="cuponDes__aplicado"></div>
      ) : (
        <>
          <div
            className={`cuponDes__pregunta ${mostrar ? '' : 'active'}`}
            style={{ pointerEvents: 'none' }}
          >
            <span style={{ color: 'white' }}>
              Asesorado por
              <span style={{ color: 'white' }}>
                {referido?.nb_us + ' ' + referido?.ap_us}
              </span>
            </span>
            <img
              src={imgAgente}
              alt="imagen de un cupon"
              className={`${isHovered ? 'hovered' : ''}`}
              style={{ right: '30px', height: '120px', width: '120px' }}
            />
          </div>
          <div className={`cuponDes__aplicacion ${mostrar ? '' : 'active'}`}>
            <div className="cuponDes__aplicacion-conteninput">
              <label>C&oacute;digo del cup&oacute;n</label>
              <input type="text"></input>
            </div>

            <div className="cuponDes__aplicacion-contenbutton"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default CartaReferido;
