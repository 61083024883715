import axios from '../../../api/axios';

const validarRecuperacion = async (email, setNavbarErrores) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  const json = JSON.stringify({
    clave: {
      em_us: email,
      id_us: '0',
      pw_us: '',
    },
    trace: '0',
    token: token,
  });

  try {
    let data = await axios.post(process.env.REACT_APP_RECUPERAR, json);
    return true;
  } catch (error) {
    console.log(error);
    setNavbarErrores([error.response.data.message]);
    return false;
  }
};

export default validarRecuperacion;
