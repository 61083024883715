import React from 'react';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';

const Map = ({ sucursales, position, zoom, mapGround }) => {
  const customIcon = new Icon({
    iconUrl: require('../../img/marcador-de-posicion.png'),
    iconSize: [38, 38],
  });

  return (
    <MapContainer className="sucursalesmapa" center={position} zoom={zoom}>
      <TileLayer attribution={mapGround?.attribution} url={mapGround?.url} />
      {sucursales &&
        sucursales?.map((x, indice) => {
          return (
            <Marker
              key={indice}
              position={[x.la_of_ma, x.lo_of_ma]}
              icon={customIcon}
            >
              <Popup>
                <strong>{x.hr_of_ma}</strong>
              </Popup>
            </Marker>
          );
        })}
    </MapContainer>
  );
};

export default Map;
